import './PurchaseCredits.scss';

import { Spin } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import CHECKGREEN from '../../../assets/images/Modal/green_check.png';
import ICONWARNING from '../../../assets/images/Modal/icon_warning.svg';
// import LOCK from '../../../assets/images/Modal/lock.png';
// import VISAMASTER from '../../../assets/images/Modal/visa-master-big.png';
import LIMITEDOFFER from '../../../assets/images/Pricing/limited_offer.png';
import MOSTPOPULAR from '../../../assets/images/Pricing/most_popular.png';
import PAYMENTMETHODS from '../../../assets/images/Pricing/payment_methods.png';
import { useSocket, wsEvents } from '../../../core/sockets/useSocket';
import { setCredits as setCreditsAction } from '../../../redux/actions/credits';
import { purchaseCredit } from '../../../services/payment-services/payment.service';
import { getBillingCredits } from '../../../services/prcing-service/pricing.service';
import { getToken } from '../../../services/token-service/token.service';
import { getUserData } from '../../../services/user-service/user.service';
import CreditsCard from '../../CreditsCard/CreditsCard';
import ImageComponent from '../../ImageComponent/ImageComponent';
import ImageN from '../../ImageN/ImageN';
import ChangeCC from '../ChangeCC/ChangeCC';
import ModalDialog from '../ModalDialog/ModalDialog';

const PurchaseCredits = ({ auto, closeModal, modalRef }) => {
  const userData = getUserData();
  const currentCredits = useSelector((state) => state.credits.creditBundles);
  const [credits, setCredits] = useState([]);
  const token = getToken();
  const changeCCRef = useRef(null);
  const [paymentData, setPaymentData] = useState();
  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();
  const getCurrentPricing = useCallback(async () => {
    try {
      const response = await getBillingCredits();
      dispatch(setCreditsAction(response.data.data));
      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch]);

  /*** close with sockets  ***/
  useSocket({
    type: wsEvents.PAYMENT_SUCCESS,
    callBack: () => {
      auto && closeModal(modalRef);
    }
  });
  useEffect(() => {
    getCurrentPricing();
  }, [getCurrentPricing]);
  useEffect(() => {
    setCredits(
      currentCredits?.bundles?.map((item) => {
        if (item.title === 'starter') {
          return { ...item, discount: '0' };
        }
        if (item.title === 'bronze') {
          return {
            ...item,
            discount: currentCredits.type === 'credit-new' ? '0' : '3'
          };
        }
        if (item.title === 'silver') {
          return {
            ...item,
            discount: currentCredits.type === 'credit-new' ? '20' : '6'
          };
        }
        if (item.title === 'gold') {
          return {
            ...item,
            discount: currentCredits.type === 'credit-new' ? '30' : '14'
          };
        }
        if (item.title === 'platinum') {
          return {
            ...item,
            discount: currentCredits.type === 'credit-new' ? '40' : '20'
          };
        }
        if (item.title === 'diamond') {
          return {
            ...item,
            discount: currentCredits.type === 'credit-new' ? '60' : '25',
            active: true
          };
        }

        return { ...item, active: false };
      })
    );
  }, [currentCredits]);
  const selectBundle = (bundle) => {
    const windowReference = window.open('about:blank', '_blank');
    setCredits(
      credits.map((item) => {
        if (item.title === bundle.title) {
          return { ...item, active: true };
        } else {
          return { ...item, active: false };
        }
      })
    );

    const selectedBundle = bundle.title;
    const currentUrl = window.location.toString();
    const redirect_url = currentUrl.split('?')[0];

    const data = {
      type: currentCredits.type,
      bundle: selectedBundle,
      redirect_url: redirect_url,
      charge: charge
    };
    setPaymentData(data);
    setLoader(true);

    if (charge) {
      purchaseCredit(data, token)
        .then((res) => {
          const url = res.data.redirect_url;
          windowReference.location = window.location.href === url ? url + '?paymentstatus=0' : url;
        })

        .catch((err) => {
          setLoader(true);
          console.log(err);
        });
    } else {
      purchaseCredit(data, token)
        .then((res) => {
          setLoader(true);
          windowReference.location = res.data.redirect_url;
          // window.open(res.data.redirect_url, '_blank');
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
  };
  const isCharge = () => {
    return userData?.credit_history.length > 0;
  };
  const charge = isCharge();
  const changeCCModal = () => {
    return (
      <ModalDialog className="ChangeCCWrapper" ref={changeCCRef} hideLogo={true}>
        <ChangeCC paymentData={paymentData} />
      </ModalDialog>
    );
  };
  const renderHeaderFirstPurchase = () => {
    return (
      <React.Fragment>
        <div className="PurchaseCreditsTitle">
          Oops! You ran out of credits. <br /> <span>Buy now</span> to keep flirting!
        </div>
      </React.Fragment>
    );
  };
  const renderHeaderSecondPurchase = () => {
    return (
      <React.Fragment>
        <div className="PurchaseCreditsSecondTitle">
          <ImageN name={ICONWARNING} className="TitleWarningIcon" />
          Oops! You ran out of <br /> credits. Buy now to keep flirting
        </div>
      </React.Fragment>
    );
  };
  return (
    <Spin spinning={loader} tip="Processing..." style={{ color: '#d41411' }}>
      <div className="PurchaseCredits">
        {charge ? renderHeaderSecondPurchase() : renderHeaderFirstPurchase()}
        <div className="OffersContainer">
          {credits?.map((item, index) => {
            const badge =
              item.title === 'gold' ? (
                <ImageComponent className="CreditsBadge" url={MOSTPOPULAR} />
              ) : item.title === 'diamond' ? (
                <ImageComponent className="CreditsBadge" url={LIMITEDOFFER} />
              ) : (
                ''
              );
            return (
              index > 0 && (
                <div
                  className="CreditsCardHolder"
                  key={index}
                  onClick={selectBundle.bind(null, item)}
                >
                  <CreditsCard
                    onClick={selectBundle.bind(null, item)}
                    title={item.title}
                    messageNumber={item.messages}
                    price={item.price}
                    discount={item.discount}
                    badge={badge}
                  />
                </div>
              )
            );
          })}
        </div>
        {/* <div className="WhyCreditsContainer">
          <div className="WhyCreditsTitle">Why credits?</div>
          <div className="WhyCreditsDesc">
            <div className="CheckImageContainer">
              <ImageComponent className="CheckImage" url={CHECKGREEN} />
            </div>
            <div className="WhyCreditsText">100% discrete purchase</div>
          </div>
          <div className="WhyCreditsDesc">
            <div className="CheckImageContainer">
              <ImageComponent className="CheckImage" url={CHECKGREEN} />
            </div>
            <div className="WhyCreditsText">100% anonymous enviroment</div>
          </div>
          <div className="WhyCreditsDesc">
            <div className="CheckImageContainer">
              <ImageComponent className="CheckImage" url={CHECKGREEN} />
            </div>
            <div className="WhyCreditsText">Total freedom (no subscription)</div>
          </div>
        </div> */}
        <div className="SecureContainer">
          <div className="SecureImages">
            <ImageComponent className="MasterVisa" url={PAYMENTMETHODS} />
          </div>
          {/* <div className="SecureText">
            <ImageComponent className="CheckImage" url={LOCK} />
            <span className="SafePayment"> SAFE, DISCRETE & SECURE PAYMENT </span>
          </div> */}
        </div>
        {changeCCModal()}
      </div>
    </Spin>
  );
};

export default PurchaseCredits;
