import './SignUp.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@reach/router';
import { format } from 'date-fns';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useGetCityByZipCode } from '../../core/APIHooks/userData/useGetCityByZipCode';
import { useEmailValidation } from '../../core/emailValidation/useEmailValidation';
import { handleAuthReactivation } from '../../pages/MainView/helpers/authReactivation';
import { showRootPage } from '../../services/navigation-service/navigation.service';
import { cleanupUserData } from '../../services/user-service/user.service';
import Button from '../Button/Button';
import DatePicker from '../Form/DatePicker/DatePicker';
import { Form } from '../Form/Form';
import Input from '../Form/Input/Input';
import Radio from '../Form/Radio/Radio';
import Select from '../Form/Select/Select';
import LoaderComponent from '../LoaderCompoment/LoaderComponent';

const schema = yup.object().shape({
  username: yup
    .string()
    .required('Username is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  password: yup
    .string()
    .required('Password is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  email: yup.string().required('Email is required!').email('Invalid Email Address'),
  birthday: yup
    .date()
    .required('Birthdate is required!')
    .test(
      'len',
      'You must be at least 18 years',
      (val) => moment().diff(moment(val), 'years') >= 18
    ),
  zip_code: yup.string().test('len', 'Must be exactly 5 characters', (val) => val.length === 5),
  city: yup.string().required('No city found with given zip code')
});

const SignUp = (props) => {
  const { openModal, closeModal, modalRef, verifyModalRef, getRegisterData, registerData } = props;
  const submitRef = useRef(null);
  const cityRef = useRef(null);

  const [cities, getCities] = useState();
  const [zipCode, getZipCodeByCity] = useState('');
  const [gender, getGender] = useState('');
  const [seeking, getSeeking] = useState('');
  const [usernameExist, getUsernameExist] = useState('');
  const [emailExist, getEmailExist] = useState('');
  const [genderError, getGenderError] = useState('');
  const { mutate: validateEmail, isLoading: emailLoading } = useEmailValidation();
  const navigate = useNavigate();
  const { mutate: getCity, isLoading: zipCodeLoading } = useGetCityByZipCode();

  const [loading, isLoading] = useState(false);

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setError,
    formState: { errors },
    control
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const radioChange = (name, e, value) => {
    switch (name) {
      case 'gender':
        getGender(value);
        value === 'male' && getSeeking('women');
        value === 'female' && getSeeking('men');
        break;
      case 'seeking':
        getSeeking(value);
        break;
      default:
        console.log(e);
    }
  };

  const onSubmit = (data) => {
    cleanupUserData();
    getUsernameExist();
    getEmailExist();
    getGenderError();
    isLoading(true);
    const formData = {
      ...data,
      gender,
      seeking
    };
    getRegisterData(formData);

    localStorage.removeItem('clickId');
    closeModal(modalRef);
    openModal(verifyModalRef);
  };

  var invalidChars = ['-', '+', 'e'];

  const preventCharacter = (e) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const getZipCode = (e) => {
    getZipCodeByCity(e.target.value);
    const zipCodeDaata = {
      zip_code: e.target.value
    };
    setTimeout(() => {
      if (e.target.value) {
        getCity(zipCodeDaata, {
          onSuccess: (data) => {
            getCities(data.data);
            document.getElementById('zipCodeCity').focus();
            document.getElementById('zipCode').focus();
          },
          onError: () => {
            getCities();
          }
        });
      } else {
        getCity(zipCodeDaata, {
          onSuccess: () => {
            getCities();
          },
          onError: () => {
            getCities();
          }
        });
      }
    }, 500);
  };

  const selectedCityChange = (e) => {
    document.getElementById('zipCode')?.focus();
    getZipCodeByCity(e.target[e.target.selectedIndex]?.getAttribute('datavalue'));
  };

  const selectedCity = (e) => {
    getZipCodeByCity(e.target[e.target.selectedIndex]?.getAttribute('datavalue'));
  };

  const renderLoader = () => {
    return (
      <div className="Loading">
        <LoaderComponent />
      </div>
    );
  };

  const emailValidation = async (type) => {
    const isValid = await trigger(type);
    const username = getValues('username');
    const password = getValues('password');
    const birthday = getValues('birthday');
    const email = getValues('email');
    const formattedDate = birthday ? format(new Date(birthday), 'yyyy-MM-dd') : '';
    const city = getValues('city');
    const zip_code = getValues('zip_code');
    const data = {
      username,
      password,
      email,
      password_confirmation: password,
      profile: {
        birthday: formattedDate,
        city: city,
        zip_code: zip_code,
        seeking: seeking,
        gender: gender
      }
    };
    isLoading(true);
    validateEmail(data, {
      onSuccess: (res) => {
        if (!isValid) {
          return;
        }

        handleAuthReactivation(res, email, navigate, () => {
          submitRef.current.click();
        });
      },
      onError: (err) => {
        isLoading(false);
        setError('email', {
          type: 'manual',
          message: err.data.errors.email[0]
        });
      }
    });
  };

  const redirectToLogin = () => {
    showRootPage();
    closeModal(modalRef);
  };

  return (
    <div className="SignUp">
      {(loading || zipCodeLoading) && renderLoader()}
      <div className="Title">Sign Up</div>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete={'one-time-code'}>
        <Input
          register={register}
          type="text"
          name="username"
          placeholder="Create a Username"
          errorMessage={errors?.username?.message}
          additionalError={usernameExist}
          className="SignUp-Input"
        />
        <Input
          register={register}
          type="password"
          name="password"
          placeholder="Create a Password"
          errorMessage={errors?.password?.message}
          className="SignUp-Input"
        />
        <div className="CheckBoxes-Container">
          <div>
            <div className="Checkbox-Container">
              <Radio
                onChange={radioChange}
                name="gender"
                value="male"
                gender=" I am a Man - Looking for a Woman"
              />
            </div>
            <div className="Checkbox-Container">
              <Radio
                onChange={radioChange}
                name="gender"
                value="female"
                gender="I am a Woman - Looking For a Man"
              />
            </div>
            {genderError && <div className="Error-Container">The gender field is required</div>}
          </div>
        </div>
        <div className="City-Container">
          <Input
            onChange={getZipCode}
            onKeyDown={preventCharacter}
            register={register}
            value={zipCode}
            type="number"
            name="zip_code"
            placeholder="Zip Code"
            errorMessage={errors?.zip_code?.message}
            maxLength={5}
            id="zipCode"
            className="SignUp-Input"
          />
          <Select
            onClick={selectedCity}
            onChange={selectedCityChange}
            register={register}
            ref={cityRef}
            name="city"
            errorMessage={errors?.city?.message}
            placeholder="City"
            emptyValue="First enter Zip code"
            options={cities}
            id="zipCodeCity"
            className="SignUp-Input Select"
          />
        </div>
        <Input
          register={register}
          type="email"
          name="email"
          placeholder="Email Address"
          errorMessage={errors?.email?.message}
          additionalError={emailExist}
          className="SignUp-Input"
        />
        <DatePicker
          control={control}
          name="birthday"
          placeholder="Birthdate"
          errorMessage={errors?.birthday?.message}
          className="SignUp-Input"
        />
        <input type="submit" ref={submitRef} hidden />
        <Button className="SubmitButton" type="submit" onClick={emailValidation} text="Join Now" />
      </Form>
      <div className="HaveAccountHolder">
        Have an account?
        <span onClick={redirectToLogin}> Sign in</span>
      </div>
    </div>
  );
};

export default SignUp;
