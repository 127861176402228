import { useNavigate } from '@reach/router';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';

import CONFETTI from '../../../assets/images/Modal/confetti.png';
import Button from '../../../components/Button/Button';
import { MEMBERS_ROUTES } from '../../../pages/MainView/components/MembersAreaMain/members_routes';
import { UserAboutMe } from '../../model/classes/UserAboutMe';
import { UserDetails } from '../../model/classes/UserDetails';

interface FreeCreditsType {
  openModalState: boolean;
  page: string;
  userDetails?: UserDetails;
  setModalPhotoOpen?: any;
  setOpenModalState?: any;
  userGeneralData?: UserAboutMe;
  redirectLocation?: string;
}

const FreeCreditsModal = ({
  openModalState,
  page,
  userDetails,
  setModalPhotoOpen,
  setOpenModalState,
  userGeneralData,
  redirectLocation
}: FreeCreditsType) => {
  const navigate = useNavigate();
  const [modalFreeCredits, setModalFreeCredits] = useState(openModalState);

  const closeFreeCreditsModal = () => {
    setModalFreeCredits(false);
    setOpenModalState && setOpenModalState(false);
    if (page === 'myProfile') {
      !userDetails?.completed_free_credit_actions.avatar && setModalPhotoOpen(true);
    }
    if (page === 'credits') {
      navigate(`${redirectLocation}`);
    }
  };

  const closeModalRedirect = () => {
    setModalFreeCredits(false);
    setOpenModalState && setOpenModalState(false);
    if (page !== 'myProfile') {
      navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MYPROFILEV2}`);
      localStorage.setItem('redirectedPage', page);
    }
    if (page === 'myProfile') {
      !userDetails?.completed_free_credit_actions.avatar && setModalPhotoOpen(true);
      localStorage.removeItem('redirectedPage');
    } else {
    }
  };

  useEffect(() => {
    setModalFreeCredits(openModalState);
  }, [openModalState]);

  return (
    <Modal centered open={modalFreeCredits} footer={null} onCancel={() => closeFreeCreditsModal()}>
      <div className="modal-free-credits-container">
        <h1>Free Credits</h1>
        <img src={CONFETTI} />
        <p>
          You will get <strong>10</strong> free credits when you complete your profile 100%. Start
          now!
        </p>
        <Button
          className="SubmitButton"
          type="submit"
          onClick={() => closeModalRedirect()}
          text="Ok"
        />
      </div>
    </Modal>
  );
};

export default FreeCreditsModal;
