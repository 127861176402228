import './ForgotPassword.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { forgotPassword } from '../../../services/user-service/user.service';
import Button from '../../Button/Button';
import { Form } from '../../Form/Form';
import Input from '../../Form/Input/Input';
import LoaderComponent from '../../LoaderCompoment/LoaderComponent';

const schema = yup.object().shape({
  email: yup.string().required('Email is required!').email('Invalid Email Address')
});

const ForgotPassword = ({ isResetSuccess, closeModal, modalRef }) => {
  const submitRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const forgotPasswordFailed = (err) => {
    setLoading(false);
    setError(err?.data?.message || err.message);
  };

  const forgotPasswordSuccess = (res) => {
    setLoading(false);
    if (res.data.status === 'passwords.user') {
      throw new Error('There is no account with this email address!');
    }
    if (res.data.status === 'passwords.throttled') {
      throw new Error(
        `Looks like you requested a password reset already. Please check your email for the instructions we sent you. If you didn't receive it, check your spam folder or try again a bit later`
      );
    } else {
      isResetSuccess(true);
      closeModal(modalRef);
    }
  };

  const onSubmit = (data) => {
    setLoading(true);
    forgotPassword(data.email).then(forgotPasswordSuccess).catch(forgotPasswordFailed);
  };

  const renderLoader = () => {
    return (
      <div className="Loading">
        <LoaderComponent />
      </div>
    );
  };

  return (
    <div className="ForgotPassword">
      {loading && renderLoader()}
      <div className="ResetPassword-Header">
        <h3>Forgot Password</h3>
        <p>
          Enter your email address you used at registration <br /> and we will send you a link to
          create a new password:
        </p>
      </div>
      <div className="ResetPassword-Form">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type="email"
            name="email"
            placeholder="Email Address"
            register={register}
            errorMessage={errors?.email?.message}
            additionalError={error}
            onChange={() => setError(null)}
          />
          <input type="submit" ref={submitRef} hidden />
          <Button
            className="SubmitButton"
            type="submit"
            onClick={() => {
              submitRef.current.click();
            }}
            text="SUBMIT"
          />
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
