import React from 'react';

import ArrowBack from '../../../../../assets/images/FunnelPage/arrow-back.svg';
import ManPlaceholder from '../../../../../assets/images/FunnelPage/man.svg';
import WomanPlaceholder from '../../../../../assets/images/FunnelPage/woman.svg';

const GenderInput = ({
  title,
  options,
  clickHandler = () => {},
  backArrow,
  prevView,
  fillRight
}) => {
  const values = {
    male: 'men',
    female: 'women'
  };

  return (
    <div className="gender">
      {backArrow && (
        <div className="arrow-back" onClick={prevView}>
          <img src={ArrowBack} alt="arrow back" />
        </div>
      )}
      <p>{title}</p>
      <div className="gender-select">
        {options.map((item) => {
          if (item !== 'prefer not to tell') {
            const value = values[item] || item;
            const isMale = value === 'men';
            const isFirstClassFill = (isMale && !fillRight) || (!isMale && fillRight);

            return (
              <div
                className={`placeholder ${isFirstClassFill ? 'fill' : 'outline'}`}
                onClick={() => clickHandler(item)}
                key={item}
              >
                <img
                  src={value === 'men' ? ManPlaceholder : WomanPlaceholder}
                  alt={`${item} placeholder`}
                  className="placeholder-img"
                />
                <span>{value.toUpperCase()}</span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default GenderInput;
