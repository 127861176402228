import { NewProfile } from '../../assets/images';

const NewProfileBadge = () => (
  <img
    src={NewProfile}
    style={{
      width: 65,
      height: 65,
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 5
    }}
    alt="is_new"
  />
);

export default NewProfileBadge;
