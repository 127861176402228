import './HeaderLayout.scss';

import React from 'react';

const HeaderLayout = ({ image, title, description, className }) => {
  const classes = ['Image', className];
  return (
    <div className="headerContainer" style={{ maxWidth: '100%' }}>
      <div className="HeaderImage">
        <img
          onContextMenu={(e) => e.preventDefault()}
          src={image}
          alt=""
          className={classes.join(' ')}
        />
      </div>
      <div className="Header">
        <h2 className="Header-Text">{title}</h2>
        <p className="Description">{description}</p>
      </div>
    </div>
  );
};

export default HeaderLayout;
