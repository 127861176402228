import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';
import { UserAboutMe } from '../../model/classes/UserAboutMe';

export const key = ['USER_ABOUT_ME_DATA'];

export const useGetUserAboutMe = (userId: number) =>
  useQuery(
    [...key],
    async (): Promise<UserAboutMe> => {
      const { data } = await axiosInstance.get(`api/profiles/${userId}`);
      return data.data;
    },
    {
      enabled: !!userId
    }
  );
