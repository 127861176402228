import './Email.scss';

import GoogleLoginButton from '../../../../../../../../components/GoogleLoginButton/GoogleLoginButton';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Email({
  hasSSO,
  setValue,
  errors,
  getValues,
  clearErrors,
  emailLoading,
  handleNextStep,
  setGoogleLogin,
  handleGoogleLogin,
  watch
}) {
  return (
    <>
      <div className="EmailContainer">
        <div className="EmailImage"></div>
        <p className="EmailText">
          One last step!
          <br />
          Enter your Email and match with this sexy ladies now!
        </p>
        <Input
          placeholder="Email address"
          name="email"
          type="email"
          value={getValues('email')}
          onChange={() => {
            setGoogleLogin(false);
          }}
          {...{
            errors,
            setValue,
            clearErrors
          }}
        />
        {hasSSO && (
          <GoogleLoginButton
            className="BlueGoogleLoginButton"
            text="Register with Google"
            handleGoogleLogin={handleGoogleLogin}
          />
        )}
        <Button
          handleNextStep={() => handleNextStep(['email'])}
          text="SUBMIT"
          loading={emailLoading}
          disabled={!watch('email')}
        ></Button>
      </div>
    </>
  );
}
