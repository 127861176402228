import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';
import { Subscription } from '../../model/classes/Subscription';

export const key = ['SUBSCRIPTIONS_LIST'];

export const useGetSubscriptionPackages = () =>
  useQuery([...key], async (): Promise<Array<Subscription>> => {
    const { data } = await axiosInstance.get('api/subscription-packages');
    return data.data;
  });
