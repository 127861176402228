import './GenderOfInterest.scss';

import { useEffect } from 'react';

import { useGetEnumPublic } from '../../../../../../../../core/APIHooks/useGetEnumPublic';
import Button from '../../UI/Button/Button';
import { CountdownSelect as Select } from '../../UI/Select/Select';

export default function GenderOfInterest({ onNextStep, control, setValue }) {
  const { data: enumPublic } = useGetEnumPublic();

  const genderOptions = [
    'Man looking for women',
    'Man looking for men',
    'Woman looking for men',
    'Woman looking for women'
  ].map((o) => ({ value: o, label: o }));

  useEffect(() => {
    setValue('gender', enumPublic?.gender[0]);
    setValue('seeking', enumPublic?.seeking[1]);
  }, []);

  const selectGenderSeeking = ({ value }) => {
    if (!enumPublic) {
      return;
    }

    const genderMapping = {};

    enumPublic.gender.forEach((gender) => {
      enumPublic.seeking.forEach((seeking) => {
        if (gender !== 'prefer not to tell' && seeking !== 'prefer not to tell') {
          const genderLabel = gender === 'female' ? 'Woman' : 'Man';
          const key = `${genderLabel} looking for ${seeking}`;
          genderMapping[key] = { gender, seeking };
        }
      });
    });

    const selectedGenderInterest = genderMapping[value];

    if (selectedGenderInterest) {
      setValue('gender', selectedGenderInterest.gender);
      setValue('seeking', selectedGenderInterest.seeking);
    }
  };

  return (
    <>
      <span className="CountdownLabel">I'm</span>
      <Select
        name="genderOfInterest"
        defaultValue={genderOptions[0].value}
        control={control}
        options={genderOptions}
        onSelect={selectGenderSeeking}
      />
      <Button text="Find Women" onClick={onNextStep} />
    </>
  );
}
