import './Warning.scss';

import WARNINGIMG from '../../../../../../../../assets/images/Lander18/warning.svg';
import Button from '../../UI/Button/Button';

export default function Warning({ onNextStep }) {
  return (
    <div className="WarningContainer">
      <div className="StepContent">
        <p className="WarningText">
          <img src={WARNINGIMG} alt="waarning" />
          <br /> This site is only for adults.
          <br /> Are You 18+?
        </p>
        <div className="ButtonContainer">
          <Button handleNextStep={() => onNextStep()} text="NO"></Button>
          <Button handleNextStep={() => onNextStep()} text="YES"></Button>
        </div>
      </div>
    </div>
  );
}
