import { CSSProperties, FC } from 'react';

const FeaturedIcon: FC<{ style?: CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="25.5" cy="25.5" r="25.5" fill="#FFB91F" />
      <g clipPath="url(#clip0_126_1977)">
        <path
          d="M37.5913 27.5432C37.2381 22.9441 35.0968 20.0621 33.2078 17.5189C31.4586 15.1644 29.9479 13.1312 29.9479 10.1319C29.9479 9.89103 29.813 9.67083 29.5991 9.56043C29.3845 9.44937 29.1266 9.46756 28.9315 9.60934C26.0945 11.6394 23.7274 15.061 22.9005 18.3256C22.3265 20.5985 22.2505 23.1536 22.2399 24.8412C19.6199 24.2816 19.0264 20.3626 19.0201 20.3199C18.9906 20.1166 18.8664 19.9397 18.6858 19.8431C18.5032 19.7478 18.2886 19.7409 18.1035 19.8325C17.9661 19.899 14.7313 21.5426 14.5431 28.1052C14.5299 28.3235 14.5293 28.5425 14.5293 28.7615C14.5293 35.1371 19.7171 40.3246 26.0933 40.3246C26.102 40.3252 26.1114 40.3265 26.119 40.3246C26.1215 40.3246 26.124 40.3246 26.1271 40.3246C32.4876 40.3064 37.6572 35.1259 37.6572 28.7615C37.6572 28.4409 37.5913 27.5432 37.5913 27.5432ZM26.0933 39.0398C23.9677 39.0398 22.2386 37.198 22.2386 34.9339C22.2386 34.8568 22.238 34.7789 22.2436 34.6836C22.2693 33.7288 22.4507 33.077 22.6495 32.6435C23.0222 33.444 23.6885 34.1798 24.7707 34.1798C25.1259 34.1798 25.4132 33.8925 25.4132 33.5374C25.4132 32.6228 25.4321 31.5676 25.6598 30.6153C25.8625 29.7709 26.3468 28.8725 26.9604 28.1523C27.2333 29.0871 27.7653 29.8436 28.2848 30.582C29.0283 31.6384 29.7968 32.7307 29.9317 34.5933C29.9398 34.7037 29.948 34.8147 29.948 34.9339C29.9479 37.1979 28.2189 39.0398 26.0933 39.0398Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_126_1977">
          <rect
            width="30.8372"
            height="30.8372"
            fill="white"
            transform="translate(10.6748 9.4884)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FeaturedIcon;
