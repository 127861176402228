import { adjectives as adjectivesList } from '../model/constants';

export const GenerateUsername = (email) => {
  const adjective = adjectivesList[Math.floor(Math.random() * adjectivesList.length)];
  const localPart = email.split('@')[0];

  // Remove any non-alphanumeric characters
  const cleanedLocalPart = localPart.replace(/[^a-zA-Z0-9]/g, '');

  const res =
    cleanedLocalPart.split('.')[0].charAt(0).toUpperCase() +
    cleanedLocalPart.split('.')[0].slice(1);
  const string = `${adjective}${res}`;

  const username = string.length > 16 ? string.substring(0, 16) : string;

  return username;
};
