import './AboutMeTab.scss';

import { Form, Input, Select, Slider, Tooltip } from 'antd';
import { SliderMarks } from 'antd/es/slider';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import Button from '../../../../components/Button/Button';
import LoaderComponent from '../../../../components/LoaderCompoment/LoaderComponent';
import { userData as userRecoil } from '../../../../recoil/atoms/userDataAtom';
import { TipInfoComponent } from '../../../components/tipInfoComponent/TipInfoComponent';
import { UserAboutMe } from '../../../model/classes/UserAboutMe';
import { AboutMeData } from '../../../model/interfaces/AboutMeData';
import { useGetUserAboutMe } from '../../../services/myProfile/useGetUserAboutMe';
import { useInterfaceAboutMeData } from '../../../services/myProfile/useInterfaceAboutMeData';
import { useUpdateUserData } from '../../../services/myProfile/useUpdateUserData';

const { TextArea } = Input;
interface SelectOptionsData {
  value: string | null;
  label: string;
  name: string;
}
interface AboutMeType {
  isMobile: boolean;
  renderTipMessage: any;
}

export const AboutMeTab = ({ isMobile, renderTipMessage }: AboutMeType) => {
  const [aboutForm] = Form.useForm();
  const [user] = useRecoilState(userRecoil);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //@ts-ignore
  const userId = user?.data?.profile.id;
  const { data: enumData } = useInterfaceAboutMeData();
  const { data: userAboutMeData, isLoading } = useGetUserAboutMe(userId);
  const { mutate: saveUserAboutMe } = useUpdateUserData();

  useEffect(() => {
    aboutForm.setFieldsValue(userAboutMeData);
    //@ts-ignore
    // userAboutMeData && setUsersPersonalInterests(userAboutMeData?.personal_interests);
  }, [userAboutMeData, aboutForm]);

  const selectData = (type: keyof AboutMeData) => {
    let options: SelectOptionsData[] = [];

    if (type !== 'interests' && type !== 'personal_interests') {
      options = [
        {
          value: '',
          label: '---',
          name: type
        }
      ];
    }
    enumData &&
      enumData[type]?.map((value: string) => {
        const arr = value.split('/');
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join('/');
        options.push({
          value: value,
          label: str2 === 'Bdsm' ? 'BDSM' : str2 === 'Tv' ? 'TV' : str2,
          name: type
        });
      });
    return options;
  };

  const onFinish = (value: UserAboutMe) => {
    const data = {
      body_build: value.body_build,
      personal_interests: value.personal_interests,
      interests: value.interests,
      civil_status: value.civil_status,
      hair_color: value.hair_color,
      eye_color: value.eye_color,
      height: value.height?.toString(),
      seeking: value.seeking,
      description: value.description,
      relationship_interest: value.relationship_interest
    };
    saveUserAboutMe({ userId: userId, payload: data });
  };

  const renderLoader = () => {
    return (
      <div className="about-me-loading">
        <LoaderComponent />
      </div>
    );
  };

  const marks: SliderMarks = {
    4.9: '4.9',
    6.9: '6.9'
  };

  return (
    <div className="about-me-tab">
      {isLoading ? (
        renderLoader()
      ) : (
        <Form
          className="form-container"
          form={aboutForm}
          name="user_about_me"
          layout="vertical"
          onFinish={onFinish}
        >
          <div className="form-left-side">
            <Form.Item name="body_build" label="Body Type">
              <Select placeholder="Body Type" size="large" options={selectData('body_build')} />
            </Form.Item>
            <Form.Item
              name="description"
              label={
                <Tooltip
                  placement="topLeft"
                  title="Personal contact sharing is prohibited for privacy."
                >
                  A few words about me
                </Tooltip>
              }
              rules={[
                {
                  max: 1024,
                  message: 'Max 1024 characters allowed'
                }
              ]}
            >
              <TextArea
                placeholder="About Me"
                autoSize={{ minRows: 7, maxRows: 7 }}
                maxLength={1024}
                onInput={(e) => {
                  const inputElement = e.target as HTMLTextAreaElement;
                  const regex = /[^a-zA-Z\s.,?!']/g; // Allows letters, spaces, and some punctuation
                  inputElement.value = inputElement.value.replace(regex, '');
                }}
              />
            </Form.Item>
            <Form.Item name="civil_status" label="Relationship Status">
              <Select placeholder="Status" size="large" options={selectData('civil_status')} />
            </Form.Item>
            <Form.Item name="interests" label="Sexual Interest (Pick up to 5 / min. 3)">
              <Select
                placeholder="Interests"
                mode="multiple"
                size="large"
                options={selectData('interests')}
              />
            </Form.Item>
          </div>
          <div className="form-right-side">
            <Form.Item
              name="personal_interests"
              label="My Personal Interest (Pick up to 5 / min. 3)"
            >
              <Select
                placeholder="Personal Interests"
                mode="multiple"
                size="large"
                options={selectData('personal_interests')}
              />
            </Form.Item>
            <Form.Item name="eye_color" label="Eye Color">
              <Select placeholder="Eye Color" size="large" options={selectData('eye_color')} />
            </Form.Item>
            <Form.Item name="hair_color" label="Hair Color">
              <Select placeholder="Hair Color" size="large" options={selectData('hair_color')} />
            </Form.Item>
            <Form.Item name="seeking" label="Interested In">
              <Select placeholder="Interested In" size="large" options={selectData('seeking')} />
            </Form.Item>
            <Form.Item name="relationship_interest" label="I’m Looking For">
              <Select
                placeholder="I’m Looking For"
                size="large"
                options={selectData('relationship_interest')}
              />
            </Form.Item>
            <Form.Item className="height-container" name="height" label="Height">
              <Slider marks={marks} className="height-slider" min={4.9} max={6.9} step={0.1} />
            </Form.Item>
            <Button className="SignUpButton" text="SAVE CHANGES" htmlType="submit" />
          </div>
        </Form>
      )}
      <div className="tip-container">
        {!isMobile ? <TipInfoComponent message={renderTipMessage()} /> : ''}
      </div>
    </div>
  );
};
