import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../axios/axiosInstance';
import { CreditsPackage } from '../../V2/model/classes/CreditsPackage';

const key = ['GIFT_PACKAGES'];
export const useGetGiftPackages = () =>
  useQuery([...key], async (): Promise<Array<Partial<CreditsPackage>>> => {
    const { data } = await axiosInstance('api/gifting-packages');
    return data.data;
  });
