export const addUserData = (data) => {
  return {
    type: 'ADD_USER_DATA',
    payload: data
  };
};

export const addUserEmail = (email) => {
  return {
    type: 'ADD_USER_EMAIL',
    payload: email
  };
};

export const addUserName = (name) => {
  return {
    type: 'ADD_USER_NAME',
    payload: name
  };
};

export const setUserLoading = (loading) => {
  return {
    type: 'SET_USER_LOADING',
    payload: loading
  };
};
