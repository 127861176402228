import * as actionTypes from '../types/profiles.js';

const initialState = {
  list: [],
  isLoading: false,
  pagination: {
    links: []
  },
  currentProfile: {}
};

const profiles = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILES_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case actionTypes.SET_PROFILES:
      return {
        ...state,
        list: action.payload,
        isLoading: false
      };
    case actionTypes.SET_PAGINATION_DATA:
      return {
        ...state,
        pagination: action.payload
      };
    case actionTypes.SET_CURRENT_PROFILE: {
      return {
        ...state,
        currentProfile: action.payload
      };
    }
    default:
      return { ...state };
  }
};

export default profiles;
