const initialState = {
  hairColors: [
    { id: 1, name: 'blonde' },
    { id: 2, name: 'red' },
    { id: 3, name: 'black' },
    { id: 4, name: 'brown' }
  ],
  bodyTypes: [
    { id: 1, name: 'lean' },
    { id: 2, name: 'big' },
    { id: 3, name: 'normal' },
    { id: 4, name: 'athletic' }
  ]
};

const staticData = (state = initialState, action) => {
  switch (action.type) {
    default:
      return { ...state };
  }
};

export default staticData;
