import Right1 from '../../../../assets/images/FunnelPage/Slide 1.1.png';
import Left1 from '../../../../assets/images/FunnelPage/Slide 1.png';
import Left2 from '../../../../assets/images/FunnelPage/Slide 2 NEW.jpg';
import Right2 from '../../../../assets/images/FunnelPage/Slide 2.1 NEW.jpg';
import Left3 from '../../../../assets/images/FunnelPage/Slide 3 NEW.jpg';
import Right3 from '../../../../assets/images/FunnelPage/Slide 3.1 NEW.jpg';
import Left4 from '../../../../assets/images/FunnelPage/Slide 4 NEW.jpg';
import Right4 from '../../../../assets/images/FunnelPage/Slide 4.1 NEW.jpg';
import Right5 from '../../../../assets/images/FunnelPage/Slide 5.1.png';
import Left5 from '../../../../assets/images/FunnelPage/Slide 5.png';
import Left6 from '../../../../assets/images/FunnelPage/Slide 6.1.png';
import Right6 from '../../../../assets/images/FunnelPage/Slide 6.png';

export const formStepsMapping = [
  {
    imageLeft: Left1,
    imageRight: Right1,
    messageLeft: 'Would you...',
    messageRight: 'fill her up?'
  },
  {
    imageLeft: Left2,
    imageRight: Right2,
    messageLeft: 'Will you...',
    messageRight: 'fuck the girl next door?'
  },
  {
    imageLeft: Left3,
    imageRight: Right3,
    messageLeft: 'Do you have time...',
    messageRight: 'for a work from home fuck?'
  },
  {
    imageLeft: Left4,
    imageRight: Right4,
    messageLeft: 'Can she...',
    messageRight: 'Sit on your face?'
  },
  {
    imageLeft: Left5,
    imageRight: Right5,
    messageLeft: 'Do you like...',
    messageRight: 'cute single moms?'
  },
  {
    imageLeft: Left6,
    imageRight: Right6,
    messageLeft: 'Are you ready...',
    messageRight: 'for no strings sex?'
  }
];
