import axios from 'axios';

export const urls = {
  payments: '/api/payments'
};

export const purchaseCredit = (data, userToken) => {
  return axios.post(`${urls.payments}/checkout`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const getPaymentSettings = () => {
  return axios.get('/api/settings');
}; //samo aza WP
