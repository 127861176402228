import './Play.scss';

import Button from '../../UI/Button/Button';

export default function Play({ onNextStep }) {
  return (
    <div className="PlayContainer">
      <div className="PlayImage"></div>
      <p className="PlayText">
        {'These women only want to play. \nAre you comfortable with this?'}
      </p>
      <Button handleNextStep={() => onNextStep()} text="NO"></Button>
      <Button handleNextStep={() => onNextStep()} text="YES"></Button>
    </div>
  );
}
