/* eslint-disable react/no-unescaped-entities */

import './WhyChooseFlirt.scss';

import React, { useRef } from 'react';

import DISCREET from '../../../../../../assets/images/LandingPage/icon_discreet.svg';
import EXCITING from '../../../../../../assets/images/LandingPage/icon_exciting.svg';
import IRRESISTIBLE from '../../../../../../assets/images/LandingPage/icon_irresistible.svg';
import MEMBERSONLY from '../../../../../../assets/images/LandingPage/icon_membersonly.svg';
import QUICKSECURE from '../../../../../../assets/images/LandingPage/icon_quicksecure.svg';
import WILD from '../../../../../../assets/images/LandingPage/icon_wild.svg';
import Button from '../../../../../../components/Button/Button';
import ImageComponent from '../../../../../../components/ImageComponent/ImageComponent';
import SignUpFlow from '../../../../../../components/SignUpFlow/SignUpFlow';

const WhyChooseFlirt = () => {
  const registerModalRef = useRef(null);

  const openModal = (ref) => {
    ref.current.openModal();
  };
  return (
    <div className="WhyChooseFlirt">
      <div className="WhyChooseFlirt-Container Container">
        <div className="Subtitle">It’s Never Too Late to Join the Fun</div>
        <div className="MainTitle">Is LocalFlirt the Right Community For You?</div>

        <div className="MainContent">
          <div className="Content-Box">
            <ImageComponent className="MainIcon" url={QUICKSECURE} />
            <div className="Title">Quick & Secure</div>
            <div className="Description">Sign up in seconds and starting flirting.</div>
          </div>
          <div className="Content-Box">
            <ImageComponent className="MainIcon" url={WILD} />
            <div className="Title">Wild</div>
            <div className="Description">
              Let your fantasies roam free with fast flowing chats and vibrant messages to
              like-minded adults.
            </div>
          </div>
          <div className="Content-Box">
            <ImageComponent className="MainIcon" url={MEMBERSONLY} />
            <div className="Title">Member's Only</div>
            <div className="Description">
              Only verified member’s can sign in but the good news is registration is fast and for
              the time being; free.
            </div>
          </div>
          <div className="Content-Box">
            <ImageComponent className="MainIcon" url={EXCITING} />
            <div className="Title">Exciting</div>
            <div className="Description">
              The hottest website you’ve never heard of. We’re like nothing you’ve ever tried
              before. Come inside and see.
            </div>
          </div>
          <div className="Content-Box">
            <ImageComponent className="MainIcon" url={IRRESISTIBLE} />
            <div className="Title">Irresistible</div>
            <div className="Description">
              Active community of regular members. Like the sweetest of fruits, once you take a bite
              you’ll want to come back for more.
            </div>
          </div>
          <div className="Content-Box">
            <ImageComponent className="MainIcon" url={DISCREET} />
            <div className="Title">Discreet</div>
            <div className="Description">
              Flirt in comfort knowing your discretion is always in mind. There’s no space for
              doubts, only desires.
            </div>
          </div>
        </div>
        <Button
          className="LandingPageButton"
          text="Sign up now"
          onClick={openModal.bind(null, registerModalRef)}
        ></Button>
        <SignUpFlow modalRef={registerModalRef} />
      </div>
    </div>
  );
};

export default WhyChooseFlirt;
