import './WelcomeSection.scss';

import React from 'react';

import VeriSignLogo from '../../../../../../assets/images/LandingPage/veri-sign-logo.png';
import BasicHeader from '../../../../../../components/BasicHeader/BasicHeader';
import Login from '../../../../../../components/Login/Login';

const WelcomeSection = () => {
  return (
    <div className="WelcomeSection">
      <div className="MainContent Container">
        <BasicHeader />
        <div className="MainContent Main-Content">
          <div className="WelcomeText">
            <div className="Title">You’re only one flirt away from a world of local fun!</div>
            <div className="Description">
              Start sharing your wildest fantasies with like minded adults looking for some friendly
              and flirtatious fun.
            </div>
          </div>
          <div className="Login-Container">
            <div className="SignUp-VeriSign">
              <Login />
              <img
                onContextMenu={(e) => e.preventDefault()}
                className="VeriSign-Logo"
                src={VeriSignLogo}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSection;
