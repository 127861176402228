import {
  CloseOutlined,
  HeartFilled,
  HeartOutlined,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';
import { useNavigate } from '@reach/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Button from '../../../components/Button/Button';
import { MEMBERS_ROUTES } from '../../../pages/MainView/components/MembersAreaMain/members_routes';
import { Triggers } from '../../model/Triggers';
import { useLikeProfile } from '../../services/profiles/useLikeProfile';

interface ImageCanvasProps {
  profile_image?: string;
  gallery_images?: Array<{
    id: number;
    name: string;
    type: string;
    url: string;
    thumbnail_url: string;
    created_at: string;
  }>;
  id?: number;
  user: any;
  favorite?: boolean;
}
export const ImageCanvas = ({
  profile_image,
  gallery_images,
  user,
  id,
  favorite
}: ImageCanvasProps) => {
  const navigate = useNavigate();
  const { mutate } = useLikeProfile();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const previewCanvas = useRef<HTMLCanvasElement>(null);
  const [currentAlbumPreview, setCurrentAlbumPreview] = useState<number>(0);
  const [preview, setPreview] = useState<boolean>(false);
  const [startX, setStartX] = useState<number | null>(null);
  const [liked, setLiked] = useState<boolean>(favorite ?? false);
  const [endX, setEndX] = useState<number | null>(null);
  const startOpacity = 0;
  const endOpacity = 1;
  const duration = 500;
  const images = useMemo(
    () => [
      { src: profile_image, premium: false, big: profile_image },
      ...(gallery_images ?? []).map((image) => {
        return {
          src: image.url,
          premium: true,
          big: image.url
        };
      })
    ],
    [gallery_images, profile_image]
  );
  const handleTouchStart = (event: TouchEvent) => {
    const touch = event.touches[0];
    if (touch) {
      setStartX(touch.clientX);
    }
  };
  const handleTouchEnd = (event: TouchEvent) => {
    const touch = event.changedTouches[0];
    if (touch) {
      setEndX(touch.clientX);
      if (endX && startX && endX - startX > 0 && Math.abs(endX - startX) > 25) {
        move(1);
      } else if (endX && startX && endX - startX < 0 && Math.abs(endX - startX) > 25) {
        move(-1);
      }
    }
  };

  useEffect(() => {
    const element = canvasRef.current;
    if (element) {
      element.addEventListener('touchstart', handleTouchStart);
      element.addEventListener('touchend', handleTouchEnd);
      return () => {
        element.removeEventListener('touchstart', handleTouchStart);
        element.removeEventListener('touchend', handleTouchEnd);
      };
    }
  });
  useEffect(() => {
    const element = previewCanvas.current;
    if (element) {
      element.addEventListener('touchstart', handleTouchStart);
      element.addEventListener('touchend', handleTouchEnd);
      return () => {
        element.removeEventListener('touchstart', handleTouchStart);
        element.removeEventListener('touchend', handleTouchEnd);
      };
    }
  });
  const move = (e: number) => {
    if (currentAlbumPreview + e < 0 || currentAlbumPreview + e >= images.length) {
      if (currentAlbumPreview + e < 0) setCurrentAlbumPreview(images.length - 1);
      if (currentAlbumPreview + e >= images.length) setCurrentAlbumPreview(0);
    } else setCurrentAlbumPreview((prev) => prev + e);
  };
  const handlePricing = () => {
    navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MEMBERSPRICING}`, {
      state: {
        trigger: Triggers.PROFILE,
        id: id
      }
    });
  };
  const like = () => {
    setLiked((prev) => !prev);
    id && mutate(id);
  };
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e && e.key === 'Escape') setPreview(false);
    if (e && e.keyCode === 39) move(1);
    if (e && e.keyCode === 37) move(-1);
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });
  useEffect(() => {
    const canvas = previewCanvas.current;
    const ctx = canvas?.getContext('2d');
    const img = new Image();

    const text = `${currentAlbumPreview + 1} / ${images.length}`;
    if (canvas) {
      canvas.oncontextmenu = function () {
        setPreview(false);
        return false;
      };
      canvas.onclick = () => {
        return false;
      };
      ctx && ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = canvas.clientWidth;
      img.src = images[currentAlbumPreview].big ?? '';
      img.onload = img.onerror = null;
    }

    if (ctx && canvas) {
      ctx.font = '24px Arial'; // Set the font size and family
      ctx.fillStyle = 'rgba(0,0,0,0.49)'; // Set the text color
      const textWidth = ctx.measureText(text).width;
      const textHeight = parseInt(ctx.font, 10); // Extract the font size as an integer

      img.onload = () => {
        const imgCtx = canvas?.getContext('2d');
        if (!imgCtx) return;
        const canvasAspectRatio = canvas.width / canvas.height;
        const imgAspectRatio = img.width / img.height;
        let newWidth: number, newHeight: number, x: number, y: number;
        if (canvasAspectRatio > imgAspectRatio) {
          newWidth = canvas.height * imgAspectRatio;
          newHeight = canvas.height;
          x = (canvas.width - newWidth) / 2;
          y = 0;
        } else {
          newWidth = canvas.width;
          newHeight = canvas.width / imgAspectRatio;
          x = 0;
          y = (canvas.height - newHeight) / 2;
        }
        if (images[currentAlbumPreview].premium && !user.data?.hasPremiumSubscription) {
          imgCtx.filter = 'blur(15px)';
          canvas.style.filter = 'blur(15px)';
        } else {
          canvas.style.filter = 'blur(0)';
        }
        imgCtx.drawImage(img, x, y, newWidth, newHeight);
        ctx.fillRect(
          canvas.width / 2 - textWidth - 20,
          canvas.height - 55 - textHeight,
          textWidth + 40,
          textHeight + 20
        );
        ctx.fillStyle = 'white';
        ctx.fillText(text, canvas.width / 2 - 48, canvas.height - 50); // Fill text
      };
    }
  }, [currentAlbumPreview, images, preview, user.data?.hasPremiumSubscription]);
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const img = new Image();
    const prevImg = new Image();
    if (canvas) {
      canvas.oncontextmenu = function () {
        return false;
      };
      canvas.onclick = () => {
        if (
          (images[currentAlbumPreview].premium && user.data?.hasPremiumSubscription) ||
          !images[currentAlbumPreview].premium
        )
          setPreview(true);
      };
      //ctx && ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = canvas.clientWidth;
      img.src = images[currentAlbumPreview].src ?? '';
      img.onload = img.onerror = null;
      prevImg.onload = prevImg.onerror = null;
      canvas.height = canvas.width * (4 / 3);
    }

    if (ctx && canvas) {
      ctx.fillStyle = '#000000';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      img.onload = () => {
        const imgCtx = canvas?.getContext('2d');
        if (!imgCtx) return;
        const startTime = performance.now();
        const canvasAspectRatio = canvas.width / canvas.height;
        const imgAspectRatio = img.width / img.height;
        let newWidth: number, newHeight: number, x: number, y: number;
        if (canvasAspectRatio > imgAspectRatio) {
          newWidth = canvas.height * imgAspectRatio;
          newHeight = canvas.height;
          x = (canvas.width - newWidth) / 2;
          y = 0;
        } else {
          newWidth = canvas.width;
          newHeight = canvas.width / imgAspectRatio;
          x = 0;
          y = (canvas.height - newHeight) / 2;
        }
        function animate(currentTime: number) {
          if (canvas && imgCtx) {
            imgCtx.clearRect(0, 0, canvas.width, canvas.height);
            if (images[currentAlbumPreview].premium && !user.data?.hasPremiumSubscription) {
              canvas.style.filter = 'blur(15px)';
              imgCtx.filter = 'blur(15px)';
            } else {
              imgCtx.filter = 'none';
              canvas.style.filter = 'blur(0px)';
            }
            const elapsedTime = currentTime - startTime;
            const currentProgress = Math.min(1, elapsedTime / duration);
            imgCtx.globalAlpha = startOpacity + (endOpacity - startOpacity) * currentProgress;
            imgCtx.drawImage(img, x, y, newWidth, newHeight);
            if (currentProgress < 1) {
              requestAnimationFrame(animate);
            }
          }
        }
        requestAnimationFrame(animate);
      };
      if (canvas) {
        const prevImgCtx = canvas?.getContext('2d');
        if (prevImgCtx) {
          prevImgCtx.globalAlpha = 1;
          prevImgCtx.fillStyle = 'black';
          prevImgCtx.fillRect(0, 0, canvas.width, canvas.height);
          prevImgCtx.fill();
        }
      }
    }
  }, [currentAlbumPreview, images, user.data?.hasPremiumSubscription]);
  return (
    <>
      <div style={{ position: 'relative' }}>
        {liked ? (
          <HeartFilled className={'like'} style={{ color: '#d41411' }} onClick={like} />
        ) : (
          <HeartOutlined className={'like'} style={{ color: '#d41411' }} onClick={like} />
        )}
        {gallery_images?.length && (
          <LeftOutlined className={'tools arrow left '} onClick={() => move(-1)} />
        )}
        <canvas ref={canvasRef} className={'radius main-canvas'} />
        {gallery_images?.length && (
          <RightOutlined className={'tools arrow right'} onClick={() => move(1)} />
        )}
        {images[currentAlbumPreview].premium && !user.data?.hasPremiumSubscription && (
          <Button
            onClick={handlePricing}
            text="Unlock the Photo"
            className={'get-premium-button'}
          />
        )}
      </div>
      {preview && (
        <div className={'preview'}>
          <LeftOutlined className={'tools arrow left lightbox'} onClick={() => move(-1)} />
          <canvas ref={previewCanvas} className={'preview'} height={800} />
          <RightOutlined className={'tools arrow right lightbox'} onClick={() => move(1)} />
          <CloseOutlined className={'tools close right'} onClick={() => setPreview(false)} />

          {images[currentAlbumPreview].premium && !user.data?.hasPremiumSubscription && (
            <Button
              onClick={handlePricing}
              text="Unlock the Photo"
              className={'get-premium-button'}
            />
          )}
        </div>
      )}
    </>
  );
};
