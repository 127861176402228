import './Email.scss';

import { useState } from 'react';

import GoogleLoginButton from '../../../../../../../../components/GoogleLoginButton/GoogleLoginButton';
import LanderChat from '../../LanderChat/LanderChat';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Email({
  setValue,
  errors,
  getValues,
  clearErrors,
  emailLoading,
  handleNextStep,
  setGoogleLogin,
  handleGoogleLogin,
  landerId,
  autogenerate
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const landerButtonClassName = `version${landerId}`;
  const landerGoogleClassName = `RedGoogleLoginButton version${landerId}`;

  const messages = {
    '12-1': [
      {
        content:
          'You’re ready to start meeting women, go wild! Just enter your email address so we can notify you when they start contacting you.'
      }
    ],
    '12-2': [
      {
        content:
          'It’s time to find some women. Have fun! Just let me know your email address so we can notify you when they start contacting you.'
      }
    ],
    '12-3': [
      {
        content:
          'One last thing before you’re set. Just let me know your email address so we can notify you when women start contacting you.'
      }
    ],
    '12-4': [
      {
        content:
          'Oh, the most important part! I need your email address so we can notify you when women reach out to you.'
      }
    ]
  };

  const submit = async () => {
    await handleNextStep(['email']);
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer12">
          <Input
            placeholder="Email"
            name="email"
            type="email"
            value={getValues('email')}
            onChange={() => {
              setGoogleLogin(false);
            }}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
        <p className="DividerText12-1">OR</p>
        <div className="ButtonsContianer">
          {!autogenerate && (
            <>
              <GoogleLoginButton
                className={landerGoogleClassName}
                text="Register with Google"
                handleGoogleLogin={handleGoogleLogin}
              />
              <Button
                className={landerButtonClassName}
                handleNextStep={() => submit()}
                text="NEXT"
                loading={emailLoading}
              />
            </>
          )}
          {autogenerate && (
            <>
              <GoogleLoginButton
                className={landerGoogleClassName}
                text="Register with Google"
                handleGoogleLogin={(res) =>
                  handleGoogleLogin(res, ['email', 'randUsr', 'randPass'])
                }
              />
              <Button
                className={landerButtonClassName}
                handleNextStep={() => handleNextStep(['email', 'randUsr', 'randPass'])}
                text="NEXT"
                loading={emailLoading}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
