import './chatTabs.scss';

import { useMatch, useNavigate, useParams } from '@reach/router';
import moment from 'moment/moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BsInbox } from 'react-icons/bs';

import { key as inboxKey, useGetInbox } from '../../../../../../../../core/messages/useGetInbox';
import { useGetLatestMessages } from '../../../../../../../../core/messages/useGetLatestMessages';
import useCheckMobileScreen from '../../../../../../../../hooks/useCheckMobileScreen';
import usePullToRefresh from '../../../../../../../../hooks/usePullToRefresh';
import { MEMBERS_ROUTES } from '../../../../members_routes';
import { InboxItem } from './InboxItem';

export const ChatTabs = () => {
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();
  const params = useParams();
  const [active, setActive] = useState(1);
  const match = useMatch(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MESSAGESNEW}/:profile`);
  useEffect(() => {
    !match?.profile && setActive(1);
  }, [match]);
  const profileId = match?.profile;
  const {
    data,
    hasNextPage: inboxHasNextPage,
    fetchNextPage: fetchNextInboxPage,
    isFetching
  } = useGetInbox(active === 1);
  const [activeConv, setActiveConv] = useState();
  const {
    data: latest,
    hasNextPage: latestHasNextPage,
    fetchNextPage: fetchNextLatestPage
  } = useGetLatestMessages(active === 2);
  const openConv = (e) => {
    setActiveConv(e.profile_id);
    navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MESSAGESNEW}/${e.profile_id}`);
  };
  const sortConversations = (data) => {
    const convos = data?.pages.map((item) => item.data).flat();
    const unread = convos
      .filter((a) => a.unread_messages > 0)
      .sort(
        (a, b) => new Date(b?.last_message?.created_at) - new Date(a?.last_message?.created_at)
      );
    const read = convos
      .filter((a) => a.unread_messages === 0)
      .sort(
        (a, b) => new Date(b?.last_message?.created_at) - new Date(a?.last_message?.created_at)
      );
    return [...unread, ...read];
  };
  useEffect(() => {
    Array.isArray(data?.pages.map((item) => item.data).flat()) &&
      active === 1 &&
      profileId &&
      setActiveConv(Number(profileId));
    Array.isArray(data?.pages.map((item) => item.data).flat()) &&
      active === 1 &&
      !isMobile &&
      !profileId &&
      navigate(
        `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MESSAGESNEW}/${
          sortConversations(data)[0]?.profile_id
        }`
      );
  }, [active, data, isMobile, navigate, profileId]);
  const renderInboxItems = (data) => {
    const chatItemsData = sortConversations(data);
    if (chatItemsData.length)
      return chatItemsData?.map((conv) => (
        <InboxItem key={conv.id} data={conv} onClick={openConv} active={activeConv} />
      ));
    else
      return (
        <div className={'inbox-empty'}>
          <h2>Inbox empty</h2>
          <BsInbox size={60} color={'rgba(0,0,0,0.2)'} />
        </div>
      );
  };
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '%d s',
      ss: '%d s',
      m: '1min',
      mm: '%dmin',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      w: '1w',
      ww: '%d2',
      M: '1m',
      MM: '%dm',
      y: '1y',
      yy: '%d y'
    }
  });
  const handleScroll = useCallback(() => {
    const element = elemRef.current;
    if (element) {
      const { scrollHeight, clientHeight, scrollTop } = element;
      if (
        scrollHeight - clientHeight === Math.ceil(scrollTop) ||
        scrollHeight - clientHeight === Math.floor(scrollTop)
      ) {
        if (active === 1) {
          inboxHasNextPage && fetchNextInboxPage();
        }
        if (active === 2) {
          latestHasNextPage && fetchNextLatestPage();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, fetchNextInboxPage, inboxHasNextPage]);
  const parentRef = useRef(null);
  const elemRef = useRef(null);

  const state = usePullToRefresh(elemRef, inboxKey, isFetching, isMobile);
  useEffect(() => {
    const element = elemRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [elemRef, handleScroll]);

  return (
    <div
      className={`conversations-container ${params === null ? 'hide' : undefined}`}
      ref={parentRef}
    >
      <div className={'tabs-list'}>
        <span className={`tabs-item ${active === 1 && 'active'}`} onClick={() => setActive(1)}>
          inbox
        </span>
        <span className={`tabs-item ${active === 2 && 'active'}`} onClick={() => setActive(2)}>
          conversations
        </span>
      </div>
      {active === 1 && (
        <div className={'conv-list'} ref={elemRef}>
          {data && renderInboxItems(data)}
        </div>
      )}
      {active === 2 && (
        <div className={'conv-list'} ref={elemRef}>
          {latest && renderInboxItems(latest)}
        </div>
      )}
    </div>
  );
};
