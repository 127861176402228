import './Password.scss';

import React, { useState } from 'react';

import LanderChat from '../../LanderChat/LanderChat';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Password({ setValue, errors, clearErrors, handleNextStep }) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = [
    { content: 'Great.' },
    { content: 'Now choose a password. It should be at least 6 characters long.' }
  ];

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat messages={messages} endChatAction={toggleElementVisibility} />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer">
          <Input
            placeholder="Enter your password"
            name="password"
            type="password"
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
        <div className="ButtonsContianer">
          <Button text="NEXT" handleNextStep={() => handleNextStep(['password'])} />
        </div>
      </div>
    </>
  );
}
