import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';
import { UserDetails } from '../../model/classes/UserDetails';

export const key = ['USER_DETAILS_DATA'];

export const useGetUserDetails = (userId: number) =>
  useQuery(
    [...key],
    async (): Promise<UserDetails> => {
      const { data } = await axiosInstance.get(`api/users/${userId}/details`);
      return data.data;
    },
    {
      enabled: !!userId
    }
  );
