import './Checkbox.scss';

import React, { useEffect, useState } from 'react';

const Checkbox = ({ onChange, name, initChecked }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    initChecked && setChecked(true);
  }, [initChecked]);

  const handleChange = () => {
    onChange(name, !checked);
    setChecked(!checked);
  };

  return (
    <div className="Checkbox" onClick={handleChange}>
      {checked ? <div className="Circle"></div> : null}
    </div>
  );
};

export default Checkbox;
