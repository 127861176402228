import { useMutation } from '@tanstack/react-query';

import { queryClient } from '../../../App';
import { axiosInstance } from '../../../axios/axiosInstance';
import { key as User } from './useGetUserData';

export const useConfirmUserLocation = () =>
  useMutation(
    async (id) => {
      const { data } = await axiosInstance.patch(`/api/users/${id}`, { location_confirmed: true });
      return data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries([...User])
    }
  );
