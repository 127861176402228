export const genderData = [
  { value: 'male', label: `Man` },
  { value: 'female', label: `Woman` }
];

export const heightData = [
  { value: '01', label: `Shorter than 4’7”` },
  { value: '02', label: `From 4’7” till 4’11”` },
  { value: '03', label: `From 4’11” till 5’3”` },
  { value: '04', label: `From 5’3” till 5’7”` },
  { value: '05', label: `From 5’7” till 5’11”` },
  { value: '06', label: `From 5’11” till 6’3”` },
  { value: '07', label: `From 6’3” till 6’7”` },
  { value: '08', label: `Taller than 6’7”` }
];
