import './Lander13.scss';

import { useEffect } from 'react';

import loaderImg from '../../../../../../assets/images/Lander10/loader.png';
import generateSteps from './data/steps';
import { FallbackProvider } from './FallbackProvider';

const hasOption = (slug) => {
  const regex = new RegExp(`/13-(1|2|3|4)/.*${slug}`);
  return regex.test(window.location.href);
};

export default function Lander13(props) {
  const { nextStep, currentFormField, submitForm, handleNextStep, landerId } = props;

  const options = {
    autogenerateUsername: hasOption('nousr'),
    autogeneratePassword: hasOption('nopswd'),
    hideLocation: hasOption('noloc'),
    hasSSO: hasOption('sso')
  };

  const steps = generateSteps(options);
  const isLastStep = currentFormField >= steps.length + 1;
  const landerVersionClassName = `LanderContainer13 Version${landerId}`;

  useEffect(() => {
    if (isLastStep) {
      submitForm();
    }
  }, [currentFormField]);

  const currentStep = steps[Math.min(currentFormField, steps.length) - 1];
  const { component: CurrentStep } = currentStep || {};

  return (
    <div className={landerVersionClassName}>
      <div className="BackgroundOverlay"></div>
      {isLastStep && (
        <div className="LoadingOverlay">
          <img src={loaderImg} alt="Loader" />
        </div>
      )}

      <form
        className="LanderContent"
        onSubmit={(e) => {
          e.preventDefault();

          if (currentStep.field) {
            handleNextStep([currentStep.field]);
          }
        }}
      >
        <div className="ProgressPoints">
          {steps.map((_, index) => (
            <div
              className={`ProgressPoint ${
                currentFormField >= index + 1 ? 'ProgressPointFilled' : ''
              }`}
              key={index}
            >
              <PointIcon />
              <span>{index + 1}</span>
            </div>
          ))}
        </div>

        <FallbackProvider>
          {CurrentStep && (
            <div className={'LanderContentInner'}>
              <div className={'LanderContentInnerLeft'}></div>
              <div className={'LanderContentInnerRight'}>
                <CurrentStep
                  onNextStep={nextStep}
                  handleNextStep={handleNextStep}
                  options={options}
                  {...props}
                />
              </div>
            </div>
          )}
        </FallbackProvider>
      </form>
    </div>
  );
}

const PointIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
    <circle cx="22.5" cy="22.5" r="22.5" />
  </svg>
);
