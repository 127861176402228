/* eslint-disable react/no-unescaped-entities */

import './SignUpAff.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@reach/router';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import ExcitingIcon from '../../../../assets/images/FunnelPage/icon_exciting.svg';
import MembersIcon from '../../../../assets/images/FunnelPage/icon_membersonly.svg';
import SecureIcon from '../../../../assets/images/FunnelPage/icon_quicksecure.svg';
import LogoComponent from '../../../../components/LogoComponent/LogoComponent';
import { useGetEnumPublic } from '../../../../core/APIHooks/useGetEnumPublic';
import { useEmailValidation } from '../../../../core/emailValidation/useEmailValidation';
import { authMe, memberLogin } from '../../../../services/auth-service/auth.service';
import { showEmailVerificationInstruction } from '../../../../services/navigation-service/navigation.service';
import {
  getToken,
  hasTokenExpired,
  saveToken
} from '../../../../services/token-service/token.service';
import { setUserLocalData } from '../../../../services/user-service/user.service';
import { handleAuthReactivation } from '../../helpers/authReactivation';
import BirthdayInput from './FormInputs/BirthdayInput';
import EmailInput from './FormInputs/EmailInput';
import GenderInput from './FormInputs/GenderInput';
import UsernamePasswordInput from './FormInputs/UsernamePasswordInput';
import ZipcodeInput from './FormInputs/ZipcodeInput';
import { formStepsMapping } from './FormStepsMapping';

const schema = yup.object().shape({
  gender: yup.string(),
  seeking: yup.string(),
  zip_code: yup
    .string()
    .required('Zip code is required!')
    .test('numbers', 'Zip code should only contain numbers', (val) => /^\d+$/.test(val))
    .test('len', 'Must be exactly 5 characters', (val) => val && val.trim().length === 5),
  city: yup.string(),
  username: yup
    .string()
    .required('Username is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  password: yup
    .string()
    .required('Password is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  age: yup
    .number()
    .typeError('Age is required!')
    .required('Age is required!')
    .test('len', 'You must be at least 18 years', (val) => val && val > 17),
  // birthday: yup
  //   .date()
  //   .max(new Date(Date.now() - 568025136000), "You must be at least 18 years")
  //   .required("Birthdate is required!"),
  email: yup.string().required('Email is required!').email('Invalid Email Address')
});

const SignUpAff = () => {
  const { data: enumPublic, isLoading: enumLoading } = useGetEnumPublic();
  const [currentFormField, setCurrentFormField] = useState(0);
  const [googleLogin, setGoogleLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
    trigger,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });
  const { mutate: validateEmail, isLoading } = useEmailValidation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const clickID = urlParams.get('clickId');
  const affiliate = urlParams.get('affiliate');
  const source = urlParams.get('source');
  const navigate = useNavigate();
  const prevView = () => {
    setCurrentFormField((prevState) => prevState - 1);
  };
  const nextView = () => {
    setCurrentFormField((prevState) => prevState + 1);
  };

  const emailValidation = () => {
    const username = getValues('username');
    const password = getValues('password');
    const age = getValues('age');
    const birthYear = new Date().getFullYear() - age;
    const gender = getValues('gender');
    const seeking = getValues('seeking');
    const city = getValues('city');
    const email = getValues('email');
    const zip_code = getValues('zip_code');
    const landerId = googleLogin ? 7 : '1typed_email';
    const data = {
      username,
      password,
      email,
      password_confirmation: password,
      click_id: clickID,
      affiliate_id: affiliate,
      lander_id: landerId,
      profile: {
        age: age,
        ...(age ? { birthday: `${birthYear}-01-01` } : {}),
        city: city,
        zip_code: zip_code,
        seeking: seeking,
        gender: gender
      },
      ...(source ? { source_id: source } : {})
    };
    validateEmail(data, {
      onSuccess: (res) => {
        handleAuthReactivation(res, email, navigate, () => {
          setValue('email', getValues('email'));
          clearErrors('email');
          submitForm();
        });
      },
      onError: (err) => {
        setError('email', {
          type: 'manual',
          message: err.data.errors.email[0]
        });
      }
    });
  };

  const onSubmit = (data) => {
    setLoading(true);
    const birthYear = new Date().getFullYear() - data.age;
    const landerId = googleLogin ? 1 : '1typed_email';
    let registerData = {};
    if (source) {
      registerData = {
        username: data.username,
        email: data.email,
        password: data.password,
        password_confirmation: data.password,
        terms_and_conditions: 1,
        click_id: clickID,
        affiliate_id: affiliate,
        source_id: source,
        lander_id: landerId,
        profile: {
          gender: data.gender,
          seeking: data.seeking,
          city: data.city,
          zip_code: data.zip_code,
          birthday: `${birthYear}-01-01`,
          age: data.age
        }
      };
    } else {
      registerData = {
        username: data.username,
        email: data.email.replace(/\s/g, ''),
        password: data.password,
        password_confirmation: data.password,
        terms_and_conditions: 1,
        click_id: clickID,
        affiliate_id: affiliate,
        lander_id: landerId,
        profile: {
          gender: data.gender,
          seeking: data.seeking,
          city: data.city,
          zip_code: data.zip_code,
          birthday: `${birthYear}-01-01`,
          age: data.age
        }
      };
    }

    axios
      .post('/api/auth/member/register', registerData, {
        headers: {
          doNotAddUserTokenAuthorization: true
        }
      })
      .then((res) => {
        // setLoading(false);
        if (res.status === 200) {
          localStorage.setItem('current-email', data.email);
          localStorage.setItem('affiliateSiteLockdown', new Date());
          // memberLogin(registerData).then((res) =>
          //   saveToken(res.data.access_token, res.data.expires_in)
          // );
          // showEmailVerificationInstruction();
          const loginData = {
            username: res.data.user.username,
            email: res.data.user.email,
            password: registerData.password
          };
          memberLogin(loginData)
            .then((res) => {
              setLoading(true);
              saveToken(res.data.access_token, res.data.expires_in);
              localStorage.setItem('userData', JSON.stringify(res.data.user));
              localStorage.setItem('affiliateSiteLockdown', new Date());
              googleLogin
                ? authMe(res.data.access_token)
                    .then((response) => {
                      setLoading(false);
                      setUserLocalData(response.data);
                      window.location.href = '/members-area';
                    })
                    .catch((err) => {
                      setLoading(false);
                    })
                : showEmailVerificationInstruction();
              // setUserLocalData(res.data.user);
            })
            .catch((err) => {
              setLoading(false);
            });
        } else {
          throw new Error('Something went wrong!');
        }
      })
      .catch((err) => {
        setLoading(false);
        setGoogleLogin(false);
        const data = err.response.data?.errors;
        Object.keys(err.response.data.errors)?.forEach((name) => {
          let message = data[name][0];
          setError(name, { type: 'manual', message });
        });
      });
  };

  const submitForm = (e) => {
    handleSubmit((data) => {
      onSubmit(data);
    })();
  };

  const responseGoogle = (res) => {
    setValue('email', res.profileObj?.email);
    setGoogleLogin(true);
    document.getElementById('submitFormRef').click();
  };

  useEffect(() => {
    const preloadImages = () => {
      const currentStep = formStepsMapping[currentFormField];
      const nextStep = formStepsMapping[currentFormField + 1];

      const imagesToPreload = [currentStep.imageLeft, currentStep.imageRight];

      if (nextStep) {
        imagesToPreload.push(nextStep.imageLeft, nextStep.imageRight);
      }

      imagesToPreload.forEach((imageSrc) => {
        const img = new Image();
        img.src = imageSrc;
      });
    };

    preloadImages();
  }, [currentFormField]);

  return (
    <>
      {getToken() && !hasTokenExpired() ? null : (
        <div className="funnel">
          <div className="container">
            <LogoComponent />
            <h1 className="title">These Women Near You Are Ready to Fuck!</h1>
            <div className="content">
              <div className="profiles">
                <div className="second-layer"></div>
                <div className="third-layer"></div>
                <div className="images-container">
                  <div className="image">
                    <img
                      src={formStepsMapping[currentFormField].imageLeft}
                      alt="Provocative girl"
                    />
                    <div className="text">
                      <p>{formStepsMapping[currentFormField].messageLeft}</p>
                    </div>
                  </div>
                  <div className="image">
                    <img
                      src={formStepsMapping[currentFormField].imageRight}
                      alt="Provocative girl"
                    />
                    <div className="text">
                      <p>{formStepsMapping[currentFormField].messageRight}</p>
                    </div>
                  </div>
                </div>
              </div>
              <form className="form" onSubmit={(e) => e.preventDefault(e)}>
                <h2 className="form-title">Free Sign Up In Just 30 Secs</h2>
                {currentFormField === 0 && enumPublic?.gender && (
                  <GenderInput
                    options={enumPublic?.gender}
                    title="What's your Gender?"
                    clickHandler={(e) => {
                      setValue('gender', e);
                      nextView();
                    }}
                  />
                )}
                {currentFormField === 1 && enumPublic?.seeking && (
                  <GenderInput
                    backArrow
                    options={enumPublic?.seeking}
                    title="I'm looking For a"
                    clickHandler={(e) => {
                      setValue('seeking', e);
                      setCurrentFormField((prevState) => prevState + 1);
                    }}
                    prevView={prevView}
                    fillRight
                  />
                )}
                {currentFormField === 2 && (
                  <ZipcodeInput
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    setError={setError}
                    clearErrors={clearErrors}
                    prevView={prevView}
                    errors={errors}
                    clickHandler={nextView}
                  />
                )}
                {currentFormField === 3 && (
                  <UsernamePasswordInput
                    register={register}
                    trigger={trigger}
                    errors={errors}
                    clearErrors={clearErrors}
                    prevView={prevView}
                    clickHandler={nextView}
                    setValue={setValue}
                  />
                )}
                {currentFormField === 4 && (
                  <BirthdayInput
                    type="number"
                    control={control}
                    trigger={trigger}
                    errors={errors}
                    getValues={getValues}
                    prevView={prevView}
                    clickHandler={nextView}
                    register={register}
                    setValue={setValue}
                    pattern="[0-9]*"
                    inputMode="numeric"
                  />
                )}
                {currentFormField === 5 && (
                  <EmailInput
                    register={register}
                    errors={errors}
                    prevView={prevView}
                    emailValidation={emailValidation}
                    loading={loading}
                    responseGoogle={responseGoogle}
                    setGoogleLogin={setGoogleLogin}
                    setValue={(name, e) => {
                      setValue(name, e);
                    }}
                    getValues={getValues}
                    clearErrors={clearErrors}
                    isLoading={isLoading}
                  />
                )}
              </form>
            </div>
            <div className="icons">
              <div className="icon-wrapper">
                <div className="icon-box">
                  <img src={SecureIcon} alt="Secure icon" />
                </div>
                <p>Quick &amp; Secure</p>
              </div>
              <div className="icon-wrapper">
                <div className="icon-box">
                  <img src={MembersIcon} alt="Member's icon" />
                </div>
                <p>Member's Only </p>
              </div>
              <div className="icon-wrapper">
                <div className="icon-box">
                  <img src={ExcitingIcon} alt="Exciting icon" />
                </div>
                <p>Exciting &amp; Discrete</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUpAff;
