import './Email.scss';

import { useEffect, useState } from 'react';

import GoogleLoginButton from '../../../../../../../../components/GoogleLoginButton/GoogleLoginButton';
import LanderChat from '../../LanderChat/LanderChat';
import Input from '../../UI/Input/Input';

export default function Email({
  setValue,
  errors,
  getValues,
  clearErrors,
  setGoogleLogin,
  emailLoading,
  handleGoogleLogin,
  landerId,
  options,
  watch,
  setIsValid
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = {
    '14-1': [
      {
        content:
          'You are so cool! Thank you! One more thing, let me  know your email, so I can update you about new matches!'
      }
    ],
    '14-2': [
      {
        content:
          'You are so cool! Thank you! One more thing, let me  know your email, so I can update you about new matches!'
      }
    ],
    '14-3': [
      {
        content:
          'You are so cool! Thank you! One more thing, let me  know your email, so I can update you about new matches!'
      }
    ],
    '14-4': [
      {
        content:
          'You are so cool! Thank you! One more thing, let me  know your email, so I can update you about new matches!'
      }
    ]
  };

  const email = watch('email');

  useEffect(() => {
    setIsValid(!!email);
  }, [email, setIsValid]);

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat
        landerId={landerId}
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
      />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer">
          <Input
            placeholder="Email"
            name="email"
            type="email"
            value={getValues('email')}
            onChange={() => {
              setGoogleLogin(false);
            }}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
      </div>
      <div className="GoogleContainer">
        {options.hasSSO && <p className="DividerText">or</p>}
        <div className="EmailButtonsContainer">
          {options.hasSSO && (
            <GoogleLoginButton
              icon={GoogleIcon}
              text="Register with Google"
              handleGoogleLogin={(res) =>
                handleGoogleLogin(
                  res,
                  [
                    'email',
                    options.autogeneratePassword ? 'randPass' : null,
                    options.autogenerateUsername ? 'randUsr' : null
                  ].filter(Boolean)
                )
              }
            />
          )}
        </div>
      </div>
    </>
  );
}

const GoogleIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="white" />
    <path
      d="M21.7812 15C21.7823 16.5958 21.2201 18.1408 20.1938 19.3628C19.1675 20.5848 17.7428 21.4054 16.1708 21.68C14.5988 21.9546 12.9803 21.6656 11.6005 20.8639C10.2206 20.0622 9.16798 18.7993 8.62797 17.2977L4.74927 20.4006C5.95533 22.6932 7.89478 24.5156 10.2579 25.5769C12.6211 26.6381 15.2717 26.8769 17.7865 26.2551C20.3013 25.6334 22.5353 24.187 24.1317 22.1469C25.7281 20.1067 26.5949 17.5905 26.5937 15"
      fill="#00AC47"
    />
    <path
      d="M21.7813 15C21.7804 16.0789 21.5218 17.1419 21.0269 18.1006C20.532 19.0593 19.8152 19.8858 18.9362 20.5114L22.7708 23.579C23.9732 22.4964 24.9348 21.1734 25.5934 19.6955C26.252 18.2177 26.5928 16.618 26.5938 15"
      fill="#4285F4"
    />
    <path
      d="M8.21875 15.0002C8.22043 14.2163 8.35892 13.4388 8.62799 12.7025L4.74929 9.59961C3.86736 11.2631 3.40625 13.1173 3.40625 15.0002C3.40625 16.883 3.86736 18.7373 4.74929 20.4008L8.62799 17.2979C8.35892 16.5616 8.22043 15.7841 8.21875 15.0002Z"
      fill="#FFBA00"
    />
    <path
      d="M15 8.21876C16.4349 8.21916 17.8321 8.6777 18.9882 9.52758L22.5407 6.21195C21.222 5.07493 19.6635 4.25042 17.9816 3.79994C16.2996 3.34946 14.5377 3.28464 12.8272 3.61031C11.1167 3.93599 9.50181 4.64375 8.10309 5.68079C6.70437 6.71783 5.55791 8.05736 4.74927 9.59942L8.62797 12.7023C9.10101 11.391 9.96638 10.2572 11.1065 9.45503C12.2465 8.65284 13.606 8.22118 15 8.21876Z"
      fill="#EA4435"
    />
    <path
      d="M26.5938 14.125V15L24.625 18.0625H15.4375V13.25H25.7188C25.9508 13.25 26.1734 13.3422 26.3375 13.5063C26.5016 13.6704 26.5938 13.8929 26.5938 14.125Z"
      fill="#4285F4"
    />
  </svg>
);
