import { Typography } from 'antd';
import React from 'react';

import { interestsObject } from './interestsImage';

const { Title } = Typography;
export const InterestsList = ({ interests }: { interests: Array<string> }) => {
  const renderInterests = () =>
    interests?.map((item) =>
      item.length ? (
        <div className={'interest-item'} key={item}>
          <img
            src={
              interestsObject[item as keyof typeof interestsObject]?.image ||
              interestsObject['safe sex'].image
            }
            alt={item}
          />
          <div>{interestsObject[item as keyof typeof interestsObject]?.title ?? item}</div>
        </div>
      ) : null
    );
  return (
    <div style={{ marginTop: '2rem' }}>
      <Title level={4}>My interests</Title>
      <div className={'interests'}>{renderInterests()}</div>
    </div>
  );
};
