import './ImageN.scss';

import { Skeleton } from 'antd';
import React, { useRef } from 'react';

import { useImageLoaded } from '../../hooks/useImageLoaded';

const ImageN = (props) => {
  const { onClick, name, className, classNameImg, onLoad } = props;
  const classes = ['ImageComponent', className];
  const ref = useRef();
  const [loaded] = useImageLoaded(ref);

  return (
    <div className={classes.join(' ')}>
      {!loaded ? (
        <Skeleton>
          <Skeleton.Image active={!loaded} />
        </Skeleton>
      ) : (
        <img
          ref={ref}
          onLoad={onLoad}
          className={classNameImg}
          loading={'lazy'}
          width={'100%'}
          onClick={onClick}
          src={name}
          alt={name}
          onContextMenu={(e) => e.preventDefault()}
        />
      )}
    </div>
  );
};

export default ImageN;
