import './LanderChat.scss';

import React, { useEffect, useState } from 'react';

import AvatarV1 from '../../../../../../../assets/images/Lander15/version1/chat-avatar.png';
import AvatarV2 from '../../../../../../../assets/images/Lander15/version2/chat-avatar.png';
import AvatarV3 from '../../../../../../../assets/images/Lander15/version3/chat-avatar.png';
import AvatarV4 from '../../../../../../../assets/images/Lander15/version4/chat-avatar.png';

export default function LanderChat({
  messages,
  landerId,
  endChatAction = null,
  currentFormField,
  isLastStep
}) {
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    setCurrentMessageIndex(0);
  }, [currentFormField, isLastStep]);
  useEffect(() => {
    const addMessage = () => {
      // Display a typing message
      const typingMessage = { typing: true, content: '' };
      setDisplayedMessages((prevMessages) => {
        const updatedMessages =
          prevMessages.length >= 2
            ? [...prevMessages.slice(1), typingMessage]
            : [...prevMessages, typingMessage];
        return updatedMessages;
      });

      // Simulate delay before showing the actual message
      if (messages) {
        setTimeout(() => {
          setDisplayedMessages((prevMessages) => {
            const updatedMessages =
              prevMessages.length >= 3 ? [...prevMessages.slice(1)] : [...prevMessages];
            updatedMessages.pop(); // Remove the typing message
            updatedMessages.push(messages[currentMessageIndex]); // Add the actual message
            return updatedMessages;
          });
          setCurrentMessageIndex((prevIndex) => prevIndex + 1);

          // Check if it's the last message and perform endChatAction if defined
          if (currentMessageIndex + 1 === messages.length) {
            endChatAction && endChatAction();
          }
        }, 600);
      }
    };

    if (currentMessageIndex == 0) {
      addMessage();
    }

    const timer = setInterval(() => {
      if (messages && currentMessageIndex < messages.length) {
        addMessage();
      } else {
        clearInterval(timer);
      }
    }, 900);

    return () => {
      clearInterval(timer);
    };
  }, [currentMessageIndex, currentFormField, messages]);

  const avatars = {
    '15-1': AvatarV1,
    '15-2': AvatarV2,
    '15-3': AvatarV3,
    '15-4': AvatarV4
  };

  return (
    <div className={currentFormField != 10 ? 'ChatContainer' : 'Hidden'}>
      {displayedMessages.map((message, index) => (
        <div
          className="MessageContainer ToggleElement"
          style={{ animationDelay: `${index * 0.2}s` }}
          key={index}
        >
          <div className="AvatarContainer">
            {index === displayedMessages.length - 1 && (
              <img src={avatars[`${landerId}`]} alt="Avatar" />
            )}
          </div>
          <div className="Message">
            {message.typing && (
              <div className="typing is-typing-init is-typing-active">
                <span className="typing__bullet"></span>
                <span className="typing__bullet"></span>
                <span className="typing__bullet"></span>
              </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: message.content }} />
          </div>
        </div>
      ))}
    </div>
  );
}
