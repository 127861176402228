/* eslint-disable react/no-unescaped-entities */
import './TermsAndConditions.scss';

import React from 'react';

import HEADERIMAGE from '../../../../../assets/images/HeadersImages/TermsAndCond-Header.png';
import HeaderLayout from '../../../../../components/HeaderLayout/HeaderLayout';
import { showPrivacyPolicyPage } from '../../../../../services/navigation-service/navigation.service';

const TermsAndConditions = () => {
  const title = 'Terms and Conditions';
  const description = 'Effective date: 15th January 2022';
  const image = HEADERIMAGE;
  return (
    <div className="Terms-And-Conditions">
      <HeaderLayout title={title} description={description} image={image} />
      <div className="Terms-Body-Container">
        <p>Effective date: 15th January 2022</p>
        <h3>
          The following Terms and Conditions (along with any documents referenced herein, inclusive
          of our{' '}
          <span className="redirectLink" onClick={showPrivacyPolicyPage}>
            Privacy Policy
          </span>
          ) establish a contracted agreement ("Agreement") between you and Us, under which you may
          use this Website and its associated services offered through this Website or application
          (together the "Services").
        </h3>
        <p>
          Please review these Terms and Conditions carefully before you begin to access the
          Services.
        </p>
        <p>
          By using this Website, you express your acceptance of these Terms and Conditions and our{' '}
          <span className="redirectLink" onClick={showPrivacyPolicyPage}>
            Privacy Policy
          </span>{' '}
          and your agreement to act in accordance with them. If you do not accept and agree to these
          Terms and Conditions and our{' '}
          <span className="redirectLink" onClick={showPrivacyPolicyPage}>
            Privacy Policy
          </span>
          , or you are under 18 years old, or under the age of consent in your country of residence
          if the age of consent is greater than 18-years old, please do not use this Website. By
          ticking the designated box and clicking on the "I Agree" button on the Sign Up page form
          you give us your acceptance of and agreement to these Terms & Conditions, even if you do
          not read and review them. If you do not agree to these Terms & Conditions, you cannot use
          this Website.
        </p>
        <p>Please note, there are some important website items, that require your attention:</p>
        <ul>
          <li>
            This is a flirting website for adults. This website is not directed at minors. If you
            are under 18 years of age or under the age of consent in your country of residence if
            the age of consent is greater than 18-years old, you must not use our Website.
          </li>
          <li>
            We are not responsible for your actions, anything you may do or say, on our Website. The
            use of certain words are restricted and content of the Website is randomly checked. If
            we receive a complaint regarding something you post, we have the right to remove it if
            we believe it is in violation of any law or in breach of these Terms and Conditions, or
            our{' '}
            <span className="redirectLink" onClick={showPrivacyPolicyPage}>
              Privacy Policy
            </span>
            . Please see clause 5,G for details about restrictions regarding use of our service.
          </li>
          <li>
            BACKGROUND CHECKS, CRIMINAL CHECKS OR IDENTITY CHECKS ARE NOT CONDUCTED ON ANY OF OUR
            WEBSITE'S USERS.
          </li>
          <li>
            We do not get involved in disputes between you and any other user. However, we do remove
            content, if the content requires removal. You take full responsibility and liability for
            all of your posts and content. If your posts and content is in breach of a third party's
            rights, you remain liable.
          </li>
          <li>
            All paid Membership subscriptions (Premium Memberships) automatically renew for the
            period and price for which you signed up. To avoid recurring charges, you may cancel the
            auto-renew feature, but you must act at least 24 hours before the next renewal date to
            avoid receiving the next charge.
          </li>
          <li>
            The Website offers a communication service that aims to allow the Users and Premium
            Members to flirt with the Profiles, using a certain credit for each message. The Website
            is a platform for Users and Premium Members to communicate electronically with the
            Profiles and is not intended for physical meetings.
          </li>
          <li>
            WE RESERVE THE RIGHT TO AMEND THESE TERMS AND ANY DOCUMENT REFERRED TO HEREIN AT ANY
            TIME. YOU ARE EXPECTED TO OCCASIONALLY CHECK THIS PAGE AND ITS LINKED DOCUMENTS TO TAKE
            NOTE OF ANY CHANGES WE MAKE AS THEY ARE LEGALLY BINDING. THESE TERMS AND CONDITIONS AND
            THEIR INCLUDED PROVISIONS MAY ALSO BE OVERRULED BY PROVISIONS OR NOTICES PUBLISHED
            ELSEWHERE ON OUR WEBSITE.
          </li>
        </ul>
        <p>
          Any user or Member in violation of our Terms and Conditions or that we believe was
          spuriously created to participate in prohibited activities may be deleted at our sole
          discretion.
        </p>
        <ul>
          <li id="Bolded-HeaderLink">
            <h4>DEFINITIONS</h4>
            <p>
              <span className="Bolded-Quotes">“Content”</span> is all information, in the form of
              but not limited to text, images, graphical elements and technical codes, present on
              the WWW.LOCALFLIRT.COM.
            </p>
            <p>
              <span className="Bolded-Quotes">“Credit”</span> is what the User must purchase in
              order to send messages to the Profiles.
            </p>
            <p>
              <span className="Bolded-Quotes">“Premium Membership”</span> shall mean the exclusive
              service of WWW.LOCALFLIRT.COM that allows the User to access Profiles and view their
              photo galleries and provides viewing access of Profiles that have visited the User’s
              profile.
            </p>
            <p>
              <span className="Bolded-Quotes">“Profile”</span> shall mean a profile displayed on
              WWW.LOCALFLIRT.COM that contains an image, username and account information relating
              to a certain person.
            </p>
            <p>
              <span className="Bolded-Quotes">“Service” </span>shall mean the features provided by
              WWW.LOCALFLIRT.COM which the User can access with a valid username and password, and
              thereof pertains to all features and amenities that the Website provides, including
              but not limited to its messaging service.
            </p>
            <p>
              <span className="Bolded-Quotes">“User”</span> shall mean a person with a valid
              username and password for this Website.
            </p>
            <p>
              <span className="Bolded-Quotes">“Website”</span> is WWW.LOCALFLIRT.COM operated and
              managed by Gochamie Inc that is accessible to the User via an internet-connected
              device be it a computer, mobile, tablet or other device.
            </p>
          </li>
          <li id="Bolded-HeaderLink">
            <h4>THE PARTIES TO THIS AGREEMENT</h4>
            <p>
              This Website is owned, operated and managed by Gochamie Inc. The Website is operated
              only as an online flirting service.
            </p>
            <p>You are entering into this binding agreement with Gochamie Inc.</p>
            <p>Our contact details are:</p>
            <p>Gochamie Inc, 20 North Orange Avenue Suite, 1100 11th Floor, Orlando, FL 32801</p>
            <p>Phone: +1 877-381-0324 </p>
            <p>Email for Customer service: support@localflirt.com</p>
          </li>
          <li id="Bolded-HeaderLink">
            <h4>ABOUT THE WEBSITE</h4>
            <ul>
              <li>
                <h4> SERVICES PROVISIONS</h4>
                <p>
                  We provide online entertainment and flirting services through our Website which
                  enable and allow you to contact, engage and interact with the Profiles. This is a
                  flirting website for adults designed as a discreet yet exciting online community
                  to exchange messages with other like-minded people and/or share photos, stories,
                  and other information. It is intended to be a fun website to visit and it is
                  important to us (and for you as well as all members) that it remains a safe and
                  friendly environment. Therefore, you agree to only use our Website in a manner
                  consistent with its purpose and according to these terms.
                </p>
                <p>
                  We are continually developing the features we offer users and we may offer
                  additional services or revise the scope of the Services at our discretion, with or
                  without notice to you. These Terms and Conditions will then apply to all
                  additional or revised Services.
                </p>
                <p>We also reserve the right to discontinue the Services which are offered.</p>
              </li>
              <li>
                <h4>WARRANTIES BY YOU IN USING THE SERVICES</h4>
                <ul className="Push-Bot" type="disc">
                  <li>
                    By logging in and accessing the Website or purchasing our services, you certify
                    to us that:
                  </li>
                  <li>
                    You are of the age of consent where you live and that you have the legal
                    capacity to agree to these terms;
                  </li>
                  <li>
                    You own (or have permission to use by its owner) any credit card you used to
                    purchase our Services with and have authorized us (or our authorized payment
                    processing agent) to charge the credit card according to the membership you
                    chose (which may include automatically recurring renewal charges until
                    canceled);
                  </li>
                  <li>
                    You are aware of the adult nature of the materials included, you agree to
                    receive them, and you are not offended by such subject matter and materials;
                  </li>
                  <li>
                    You access the Website voluntarily and for your own personal use and enjoyment.
                    You will not seek to obtain commercial gain, or obtain financial or other
                    benefits from other users or profiles, fraudulently or otherwise;
                  </li>
                  <li>
                    You have no felony convictions and that you are not required to register as a
                    sex offender with any government entity;
                  </li>
                  <li>
                    Your use of the Website is not prohibited or restricted by your jurisdiction or
                    access location;
                  </li>
                  <li>
                    You have complied and will continue to comply with the laws and regulations of
                    the jurisdiction and location from where you access this Website;
                  </li>
                  <li>
                    All information that you submit when you register is accurate and truthful and
                    that you will promptly delete and/or update any information which has been
                    provided that later becomes inaccurate, disingenuous, misleading, or false; and
                  </li>
                  <li>
                    You will not share this Website or its contents with a minor or otherwise make
                    the Website or its contents available to a minor;
                  </li>
                  <li>
                    You state that you have the right, authority, and capacity to enter into this
                    agreement and be bound by these Terms and Conditions and that by using our
                    Website you will not be in violation of any law or regulation of the country in
                    which you reside. You are solely responsible for your compliance with any and
                    all applicable local laws and regulations;
                  </li>
                  <li>
                    You additionally state that you have not been convicted of and are not subject
                    to any court orders relating to assault, violence, sexual misconduct, or
                    harassment.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li id="Bolded-HeaderLink">
            <h4>HOW YOU CAN ACCESS OUR SERVICES</h4>
            <p>
              Our Website is an online meeting place for adults that features a robust collection of
              online resources, including various communication tools, chat services, match game,
              browse and filter Profiles features. You may only use and become a registered user or
              member of our Website if you are aged 18 years or older (or the age of consent in the
              country in which you reside if that happens to be greater than persons aged 18 years
              old.
            </p>
            <p>This Website can only be used by signing up as a User.</p>
          </li>
          <li>
            <h4>ABOUT SUBSCRIPTIONS AND BILLING</h4>
            <ul type="disc">
              <li>
                <h4>ABOUT PAID SERVICES</h4>
                <p>
                  We offer you the opportunity to pay for certain services offered on the Website.
                  These services include Credit to use as messages, Premium Membership with photo
                  gallery access and ability to view visitors to the User’s profile
                </p>
                <p>
                  You are responsible for all costs associated with the use of your selected payment
                  method including, for example, exchange rates, transaction fees, interest, and
                  other fees applied by your credit/debit card companies and banks. We reserve the
                  right to offer free membership to any person of our choice at any given time, for
                  any duration, while also charging other members, at the same time. We also reserve
                  the right to discontinue membership at any time, for any and all of our members.
                </p>
                <p>
                  The fee payable for the Premium Membership is presented on the payment page when
                  you are making the payment. Please review the payment page very carefully when you
                  make the payment.
                </p>
                <p>
                  Please be aware that if you subscribe to the Premium Membership, you will become
                  liable for automatic renewal billing. THIS MEANS THAT THE MINIMUM DURATION OF YOUR
                  CONTRACT WILL BE FOR THE INITIAL PERIOD, AND YOUR MEMBERSHIP WILL AUTOMATICALLY
                  RENEW UNTIL YOUR SERVICE IS CANCELED.
                </p>
                <p>
                  We reserve the right to alter our subscription package at any time (in accordance
                  with our process as explained below in section 5, C. You will have the option to
                  cancel your membership if you do not agree with the changes being offered.)
                </p>
              </li>
              <li>
                <h4>AUTOMATIC RENEWAL BILLING – PREMIUM MEMBERSHIP</h4>
                <p>
                  We operate an automatic renewal billing policy on our Premium Membership
                  subscription.
                </p>
                <p>
                  This means that once your initial membership period has expired, for your
                  convenience, your membership will automatically renew, using the original payment
                  method, at the same rate for which you signed up. For example, if you pay by
                  credit card, we will continue to charge this same credit card as each subscription
                  period expires. We will only charge these repeat payments when your current
                  subscription period expires and not before. Occasionally, we enroll our loyal
                  customers in a loyalty program in which randomly selected customers will be
                  granted a discounted membership subscription price on only the next payment.
                </p>
                <p>
                  You must provide current, complete, and accurate information for your billing
                  account. You agree to promptly update all billing information to keep your billing
                  account current, complete, and accurate (for example, updating your billing
                  information when a change in billing address, card number, or expiration date
                  occurs), and you will promptly notify us if your payment method is cancelled
                  (including if your credit card is lost or stolen), or if you become aware of a
                  potential security breach (such as an unauthorized disclosure or use of your name
                  or password). If you fail to provide us any of this information, you will be held
                  responsible for the fees accrued under your billing account. Additionally, you
                  authorize us to obtain updated or replacement expiration dates and card numbers
                  for your credit or debit card as provided by your credit or debit card issuer.
                </p>
              </li>
              <li>
                <h4>ACCOUNT MAINTENANCE</h4>
                <p>
                  All active (paying) customers are charged a monthly Account Maintenance Fee of
                  $21.99, which can be cancelled at any time. This fee ensures: Your account is kept
                  active. Your credits will be valid for 6 months. Your chat history is maintained
                  in full. This will be automatically rebilled every month until cancelled.
                </p>
              </li>
              <li>
                <h4>PRICE CHANGES</h4>
                <p>
                  We reserve the right to update our prices at any time including any change in your
                  subscription price. You will have the option to cancel your membership if you do
                  not agree to any of the changes.
                </p>
              </li>
              <li>
                <h4>BILLING AND REFUND DISPUTES</h4>
                <p>
                  In the case that you disagree with any charge made to your account, you agree to
                  contact us with an intention to resolve the dispute before making a formal
                  notification to your credit card company. You must contact us via email or
                  telephone, explaining your reasons for contesting the charge. This will enable us
                  to accurately and promptly assess your complaint and, when justified, and in order
                  to avoid further inconvenience to you, credit your card with the contested amount
                  in a timely manner. All disputes must be raised within three (3) months of the
                  contested transaction occurring.
                </p>
                <p>
                  Refunds are not issued unless extenuating circumstances exist that we feel warrant
                  a refund. For example, if the Website is down or not working properly. In such
                  cases, we will issue refunds after performing an investigation on a case-by-case
                  basis. Certain considerations will be taken such as other complaints received or
                  other customer comments. Satisfaction is not guaranteed and therefore we will not
                  consider customer dissatisfaction with the Website or simply buyer’s remorse as
                  justifiable reasons to issue a refund.
                </p>
                <p>
                  Refunds of credit are only offered within 14 days of purchase, and refunds are not
                  applicable to already used credits. Purchased credits are valid for 6 months
                  before they expire.
                </p>
                <p>
                  Cash refunds are not provided; refunds are issued back to you by our authorized
                  billing agent for the requested transaction.
                </p>
                <p>
                  Refund requests must be submitted to our customer service department via our
                  customer support channels–email or telephone. Upon the issuance of a refund, the
                  Website reserves the right to include you on the negative databases of our
                  associated authorized billing agents, and to any third-party fraud prevention
                  agents with which our products are associated.
                </p>
              </li>
              <li>
                <h4>PERSONAL USE</h4>
                <p>
                  Your User profile is only and solely for your personal use. Use of your User
                  profile by others or attempting to use another User’s profile is strictly
                  prohibited. You may not and must not assign or otherwise transfer your User
                  profile to any other person(s) or entity.
                </p>
                <p>
                  Your own actions and actions of anyone using your User profile are your sole
                  responsibility and you assume all liability regarding, (i) the content &
                  information posted by you or anyone using your User profile on your behalf; (ii)
                  and the content & information posted, transmitted, published or made otherwise
                  available through the Services by you or anyone using your User profile.
                </p>
              </li>
              <li>
                <h4>YOUR USE OF OUR SERVICES: INFORMATION YOU SUBMIT</h4>
                <p>
                  By registering with us to use our Services, you attest and affirm that you will:
                </p>
                <ul className="Push-Bot" type="disc">
                  <li>
                    Provide all the required information in order to complete the registration forms
                    for our Services; and
                  </li>
                  <li>
                    Make certain the information you provide is complete, accurate and up to date
                    and you will promptly update all information to keep your User profile and
                    billing information/account complete and accurate upon any change (for example
                    in case of change of billing address, credit card number or expiration date);
                    and
                  </li>
                  <li>
                    The Services have not and will not be accessed or used from any place or
                    jurisdiction where such use is prohibited or contrary to applicable laws, rules,
                    regulations, ordinances, edicts or customs of your jurisdiction; and
                  </li>
                  <li>
                    The Services and your use of them is and will be in compliance with all
                    applicable laws, rules, regulations, ordinances, edicts or customs; and
                  </li>
                  <li>
                    By creating a User Profile, you confirm you have never (i) been convicted of a
                    felony; and (ii) are not required by any government entity or agency to register
                    as a sex offender; and
                  </li>
                  <li>
                    You will only post information and content that is relevant and in compliance
                    with the purposes of our Service (for further explanations regarding the type of
                    information and content you can post, please refer to the following section 5, G
                    "Restrictions On Your Website Use"); and
                  </li>
                  <li>
                    You will be fully responsible for all use of, and for any actions that take
                    place using your User profile, regardless of whether it be by yourself or any
                    other person using your User profile.
                  </li>
                </ul>
              </li>
              <li>
                <h4>RESTRICTIONS ON YOUR WEBSITE USE</h4>
                <p>
                  You agree that you will not post, transmit to others, communicate in any way, or
                  provide links to any content on or through the Services, or otherwise engage in of
                  any activity through the Services that in our sole judgement:
                </p>
                <ul type="disc">
                  <li>
                    impersonates, imitates or otherwise falsely represents affiliation, connection
                    and/or association with any person or entity;
                  </li>
                  <li>
                    discloses any personal and/or confidential information of any person or entity
                    without first securing their consent or solicits, requests or attempts to obtain
                    another person's personal information for commercial or unlawful purposes;
                  </li>
                  <li>
                    discloses, solicits or attempts to obtain personal information from anyone under
                    the age of 18 or discloses, solicits or attempts to obtain passwords for
                    unlawful purposes;
                  </li>
                  <li>
                    includes and/or contains any material which is technically or digitally harmful
                    (such as but not limited to computer viruses, logic bombs, Trojan horses, worms,
                    harmful components, corrupted data or other malicious software or harmful data);
                  </li>
                  <li>
                    encourages racism, bigotry, hatred or physical harm against any group or
                    individual;
                  </li>
                  <li>encourages or enables illegal or unlawful activities;</li>
                  <li>
                    aims to harass, annoy, threaten or intimidate any other members or other people,
                    entities or organizations;
                  </li>
                  <li>
                    is defamatory, inaccurate, obscene, indecent, seditious, offensive,
                    pornographic, abusive, liable to incite racial hatred, discriminatory,
                    inflammatory, blasphemous, in breach of confidence, in breach of privacy, harms
                    minors in any way (a minor being a person aged under 18 years old or under the
                    age of consent in the country in which you reside if the age of consent is over
                    18 years old ) or which may cause annoyance or inconvenience;
                  </li>
                  <li>
                    is related to in any way child pornography, bestiality, rape (or other
                    non-consensual sexual behavior), mutilation of a person or body parts, any act
                    or depiction of parties being harmed or forced (including but not limited to
                    rape, drugs, violence and alcohol), any anti- or exploitive religious, gender or
                    age specific acts (sexual exploitation);
                  </li>
                  <li>is deliberately deceptive, misleading or fraudulent;</li>
                  <li>
                    contains others' copyrighted content (such as music, videos, photographs, images
                    etc.) trademarks, patents, trade secret or other proprietary information,
                    without first securing the original owner’s consent;
                  </li>
                  <li>
                    uses the Services for chain letters, junk, spam and other unsolicited e-mails;
                  </li>
                  <li>
                    interferes or disrupts networks and/or servers connected to the Services or
                    attempts to interfere with the proper functioning of the Services (including
                    performing excessive and unreasonable information loads on the servers and
                    infrastructure used for Website operations);
                  </li>
                  <li>
                    contains any material or information which may infringe or violate any third
                    party's rights;
                  </li>
                  <li>
                    violates any applicable jurisdictional, local, state, national or international
                    law;
                  </li>
                  <li>
                    provides material support or resources (or conceals or disguises the nature,
                    location, source, or ownership of material support or resources) to any entities
                    or organization(s) designated by governments as a foreign terrorist
                    organization;
                  </li>
                  <li>stalks or otherwise harasses another person, profile or user;</li>
                  <li>is a computer generated image, such as AI generated images;</li>
                </ul>
                <br />
                <p>You will not and must not:</p>
                <ul className="Push-Bot" type="disc">
                  <li>
                    upload, post, email, transmit, communicate or otherwise disclose another
                    person’s private information;
                  </li>
                  <li>
                    access data not intended for such user or log into a server or User account
                    which the user does not have authorized access to;
                  </li>
                  <li>
                    attempt to probe, scan or test the vulnerability of the system or network or to
                    breach the security or authentication measures of the Website in an unauthorized
                    manner;
                  </li>
                  <li>
                    attempt to interfere with service to any user, host, serve or network, including
                    but not limited to infecting the Services with a virus, overloading, flooding,
                    spamming, mail bombing, crashing or otherwise attempting to enact a denial of
                    service to the Website, network, server or the server of any user;
                  </li>
                  <li>
                    edit headers or otherwise manipulate identifiers in order to conceal the origin
                    of any content transmitted through the Services;
                  </li>
                  <li>
                    send unsolicited messages, including promotions and/or advertising of products
                    or services;
                  </li>
                  <li>
                    edit any TCP/IP packet header or any part of the header information in any
                    e-mail or newsgroup posting for any reason;
                  </li>
                  <li>
                    use any information obtained from the Services in order to contact, advertise
                    to, solicit, or sell to any user or member without securing their expressed
                    prior consent.
                  </li>
                </ul>
                <p>
                  In the event you breach the terms of this subsection, we reserve all rights,
                  claims and causes of action we may have, statutory or otherwise, including but not
                  limited to, the right to seek statutory penalties for each such unsolicited and
                  unauthorized communication you send through the Services.
                </p>
                <p>
                  Further, in the event you breach the terms of this subsection, we reserve all
                  rights, on our sole judgment, to discontinue your User profile immediately without
                  any notification, and to contact and inform the local authorities in your
                  jurisdiction.
                </p>
              </li>
              <li>
                <h4>BACKGROUND AND CRIMINAL CHECKS OF USERS</h4>
                <p>
                  Your interactions with other Profiles of this Website is your sole responsibility.
                  You understand that we do not in any way: (1) track the offline or online activity
                  of our users; (2) screen or interview our users; (3) investigate the background of
                  our users (including conducting criminal background checks); or (4) attempt to
                  certify the accuracy or statements made by users. We make no representations or
                  guarantees regarding the conduct of users or those acting on their behalf. You
                  will take all practical and sensible precautions necessary in all communications,
                  meetings, or interactions with other Profiles of the Website. You should not
                  disclose any personal or financial information to strangers.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <h4>OUR RIGHTS</h4>
            <ul type="disc">
              <li>
                <h4>OUR PROPRIETARY RIGHTS</h4>
                <p>
                  You recognize and accept that the copyrights, trademarks and other intellectual
                  property rights including registered and unregistered trademarks, across the
                  Services and the content provided as part of the Services belong to us or are used
                  by us under exclusive license. No rights in respect to the Services or to the
                  content provided as part of the Services are granted to you other than those
                  expressly set out in these Terms and Conditions.
                </p>
              </li>
              <li>
                <h4>TO USE YOUR CONTENT FOR OUR OWN PURPOSES</h4>
                <p>
                  By posting information or content including but not limited to copyrighted
                  content, name and likeness, and images to your User profile pages, you
                  automatically authorize, and claim and attest, that you have the right to grant
                  us, a non-exclusive, royalty free, worldwide license to use, copy, publish,
                  display, reformat, translate and distribute, and broadcast, such information or
                  content and in addition, authorize and grant us the sub-licenses of the same. You
                  further cede any moral or potential infringement rights you may have in regard to
                  any such information or content. We may allocate or sublicense the above license
                  to our affiliates and successors without any further approval by you.
                </p>
                <p>
                  A full explanation and further details on how we use your information can be found
                  in our{' '}
                  <span className="redirectLink" onClick={showPrivacyPolicyPage}>
                    Privacy Policy
                  </span>
                  .
                </p>
              </li>
              <li>
                <h4>PROFILE DATA TRANSFERS FOR RE-BRANDING AND OTHER CORPORATE PURPOSES</h4>
                <p>
                  In line with our{' '}
                  <span className="redirectLink" onClick={showPrivacyPolicyPage}>
                    Privacy Policy
                  </span>
                  , and in order to ensure a smooth transition of the Service to another company
                  within our corporate group, or to a third party, in the event of a re-branding
                  within our group or in the event of a merger, acquisition, reorganization, sale of
                  any or all of our assets, as explained in our{' '}
                  <span className="redirectLink" onClick={showPrivacyPolicyPage}>
                    Privacy Policy
                  </span>
                  , as your data is considered to be an asset belonging to Us and as such may be
                  transferred. In such cases you will be informed about how your data is being
                  handled, used, transferred and/or processed via electronic messaging or via a
                  general posting announcement on the website.
                </p>
                <p>
                  Unless you are notified in such communications that a service is ending, for all
                  services that are transferred, it is to be assumed that your subscription and
                  automatic renewal billing will continue unchanged until canceled on the new
                  website or by contacting the new website operator(s) or owner(s).
                </p>
              </li>
              <li>
                <h4>THIRD PARTY RIGHTS</h4>
                <p>
                  By submitting content to our Website, you are affirming and verifying that you are
                  the exclusive author and owner of said content (and that our use will not infringe
                  upon or violate the rights of any third party, including privacy rights, publicity
                  rights, intellectual property rights, contract rights, or any other applicable
                  rights) and you agree that you cede any moral rights relating to that content
                  (including the right to be identified as the author). We have the right to
                  disclose your identity to any third party who is claiming that any content posted
                  or uploaded by you to our Website is in violation of their intellectual property
                  rights or of their right to privacy or any other law.
                </p>
              </li>
              <li>
                <h4>USER PROFILE CANCELLATION & CUSTOMER COMPLAINT HANDLING </h4>
                <ul type="disc">
                  <li>
                    <h4>TERM AND TERMINATION</h4>
                    <p>
                      These Terms and Conditions will become effective from the moment at which you
                      use the Service as a registered User or Premium Member and will be in effect
                      and enforce unless and until they are terminated.
                    </p>
                    <p>
                      The Premium Membership subscription will be automatically renewed for
                      continuous subscription periods of the same duration as the original
                      subscription term selected, at the then current non-promotional subscription
                      rate in accordance with the Automatic Renewal Billing process explained in
                      these Terms and Conditions.
                    </p>
                    <p>
                      Please note that you must cancel your subscription at least 24 hours prior to
                      the end of the subscription period for which you paid in order to avoid being
                      charged for the following subscription period. To change or cancel your
                      subscription at any time, please contact us via email or via telephone. Either
                      you, or us, may cancel your User or Membership profile at any time and for any
                      reason by sending notice to the other.
                    </p>
                    <p>
                      We reserve the right to immediately suspend or discontinue your access to any
                      of the Services, without notice, for any reason. We also reserve the right to
                      remove your User or Membership profile information or data from our Services,
                      and any other records, at any time and at our sole discretion.
                    </p>
                    <p>
                      Any notices from you addressed to the Website and/or in the name of
                      WWW.LOCALFLIRT.COM for anything unrelated to membership, user management or
                      technical support should, unless otherwise specified in these Terms and
                      Conditions be sent via post to the company’s mailing address;
                    </p>
                    Gochamie Inc
                    <p>20 North Orange Avenue Suite, 1100 11th Floor, Orlando, FL 32801</p>
                  </li>
                </ul>
              </li>
              <ul type="disc">
                <li>
                  <h4>YOUR RIGHTS TO CANCEL AT ANY TIME</h4>
                  <p>
                    You may cancel your membership or subscription at any time by giving us notice
                    of your request to cancel by contacting us via telephone or email:
                  </p>
                  <p>
                    <span id="Bolded-HeaderLink">Telephone:</span> +1 877-381-0324 <br />
                    <span id="Bolded-HeaderLink">Email:</span> support@localflirt.com
                  </p>
                  <p>
                    When emailing us you must include your profile information (username and email
                    address) in order for us to verify your identity as the User or Membership
                    profile account holder and therefore authorized to amend the account.
                  </p>
                  <p>
                    Please be aware that any notice of intention to cancel shall take effect upon
                    receipt by us. Providing your User or Membership profile details to any other
                    person or third-party company is a breach of these Terms and Conditions.
                    Therefore, only cancellations requested by you as the rightful User or Member of
                    the Services will be effective. A cancellation initiated by any other party or
                    entity, will not be recognized nor considered a valid cancellation and you will
                    continue to be charged.
                  </p>
                </li>
                <li>
                  <h4>DATA PROTECTION</h4>
                  <p>
                    We do not sell, rent or otherwise disclose data or personal information
                    collected by our Site to any third party. Site has invested considerably in
                    security hardware, software and protocols to ensure all member information
                    remains secure. Members can exercise their right to be forgotten or to see what
                    information is being held on them. Any entitled party making a valid request for
                    data, data modification or data deletion shall receive it. Valid requests should
                    be made through the contact form on Site.
                  </p>
                </li>
                <li>
                  <h4>CUSTOMER COMPLAINT HANDLING POLICY</h4>
                  <p>
                    If you have any questions regarding these Terms and Conditions, the Services or
                    if you wish to lodge a formal complaint then please contact us via the
                    following:
                  </p>
                  <p>
                    <p>
                      <span id="Bolded-HeaderLink">Telephone:</span> +1 877-381-0324 <br />
                      <span id="Bolded-HeaderLink">Email:</span> support@localflirt.com
                    </p>
                  </p>
                  <p>
                    When emailing us you must include your profile information (username and email
                    address) in order for us to verify your identity as the User or Membership
                    profile account holder and therefore authorized to amend the account.
                  </p>
                </li>
                <li>
                  <h4>LEGAL DISCLAIMERS, WARRANTIES & INDEMNITIES</h4>
                  <ul type="disc">
                    <li>
                      <h4>DISCLAIMER OF WARRANTIES</h4>
                      <p>
                        This section applies to the maximum extent permitted by applicable law. We
                        do not set forth nor recognize (and hereby exclude) any warranties,
                        representations, conditions or terms of any kind, whether express, implied,
                        statutory or otherwise with respect to the Services (including all
                        information contained therein), including but not limited to any implied
                        warranties of merchantability, satisfactory quality, fitness for a
                        particular purpose or non-infringement.
                      </p>
                      <p>
                        We do not warrant that your use of the Services will be protected,
                        uninterrupted, continually available, error-free or will meet your
                        requirements, or that any defects in the Services will be corrected. We deny
                        liability for, and no warranty, representation, condition or other term is
                        made with respect to, the connectivity and availability of the Services.
                        Services provided by the Website are done so on an "as is" and "as
                        available" basis. It is your sole responsibility for making the necessary
                        preparations to ensure your access to the Website (including Internet
                        provider and mobile data provider fees and any other charges associated with
                        such access.) We are not and will not be held responsible for any reduced
                        functionality you may experience because of or due to your accessing of the
                        Website through mobile services or any similar service currently known or in
                        the future developed.
                      </p>
                      <p>
                        Satisfactory quality, performance, accuracy, and effort is a risk entirely
                        assumed by you. We make no warranty or guarantee that the Website or any
                        content will meet your needs, standards or requirements. We deny all
                        warranties—express, statutory, or implied—including warranties of
                        merchantability, fitness for a particular purpose, skilled effort, accuracy,
                        completeness, reliability, security, title, exclusivity, quiet enjoyment,
                        non-infringement, and guarantees that your access to the Website will be
                        uninterrupted, free or error, or that content reductions will not occur.
                        There are no warranties of any kind that reach out beyond the face of these
                        terms or that emerge due to course of performance, course of dealing, or
                        usage of trade.
                      </p>
                      <p>
                        The Website may contain mistakes, errors, flaws, omissions, inaccuracies, or
                        out-of-date information. We cannot guarantee and do not promise any specific
                        outcomes from use of the Website or its services. We do not warrant the
                        reliability of any statement or other information presented or distributed
                        through the Website. We reserve the right to correct any mistakes, errors or
                        omissions in any part of the Website.
                      </p>
                      <p>
                        Opinions, advice, statements, offers, or other information or content made
                        available through the Services, but not expressly stated by us, are those of
                        their respective authors, and should not be relied upon undoubtedly. We do
                        not: (i) warrant or guarantee the accuracy, completeness or usefulness of
                        any information provided on the Services, or (ii) adopt, endorse, back or
                        accept responsibility for the accuracy or authenticity of any opinion,
                        suggestion, or statement made by any party other than us. Under no
                        circumstance will we be responsible for any loss or damage resulting from
                        anyone's acceptance of information or other content posted on the Services,
                        provided through the Services, or transmitted to or by any Users, Profiles
                        or Members.
                      </p>
                    </li>
                    <li>
                      <h4>LIMITATION OF LIABILITY</h4>
                      <p>
                        To the maximum extent permitted by law, in no event will we be liable for
                        any direct, indirect or consequential loss or damage arising out of or
                        relating, directly or indirectly, to the use or inability to use the
                        Services, including but not limited to, financial loss including income or
                        earnings, technical or digital loss including data, program and/or system
                        corruption, loss of goodwill and loss of anticipated savings, even if we
                        know or have been alerted to the possibility of such loss arising.
                      </p>
                      <p>
                        To the maximum extent permitted by applicable law, in no event will we be
                        liable for any claims, losses or damages whatsoever, whether direct,
                        indirect, general, special, compensatory, consequential, and/or incidental,
                        arising out of or relating to the conduct of you or anyone else in
                        connection with the use of the Services, including but not limited to
                        physical harm including bodily injury, emotional distress, and/or any other
                        claims, losses or damages resulting from communications fostered by the
                        Services.
                      </p>
                      <p>
                        Our total liability to you relating to and in connection with these Terms
                        and Conditions or the use of the Services shall be limited to the fees paid
                        by you to us for access and use of the Services as a User or Member.
                      </p>
                      <p>
                        Not anything in these Terms and Conditions will limit or exclude our
                        liability for: (i) death or personal injury or harm arising from our proven
                        negligence; or (ii) fraud or fraudulent misrepresentations made by us.
                      </p>
                    </li>{' '}
                    <li>
                      <h4>INDEMNITY</h4>
                      <p>
                        You agree to indemnify us, our officers, directors, employees, agents and
                        third parties for any claims, causes of action, debts, losses, costs,
                        liabilities and expenses (including reasonable legal fees) relating to or
                        arising from, directly or indirectly, out of: (i) your use or anyone using
                        your User or Membership profile account or your and/or their inability to
                        use the Services, (ii) any content, details or information posted by you or
                        anyone using your User or Membership profile account, (iii) the violation of
                        these Terms and Conditions by you or anyone using your User or Membership
                        profile account or the violation of any rights of a third party by you or
                        anyone using your User or Membership profile account or (iv) the violation
                        of any applicable laws, rules, or regulations by you anyone using your User
                        or Membership profile account.
                      </p>
                      <p>
                        We reserve the right, at our own cost, to assume the exclusive defense and
                        control of any matter otherwise subject to indemnification by you. If we so
                        choose to exercise this right, you agree to provide us with your full
                        cooperation notwithstanding.
                      </p>
                    </li>{' '}
                    <li>
                      <h4>LINKS TO OTHER THIRD PARTY WEBSITES</h4>
                      <p>
                        The links to other third-party websites and resources contained on our
                        Website are provided for your informational purposes only. We have no
                        control over the content and information relayed by those websites and
                        resources and accept no responsibility for them, or for any loss or damage
                        that may emerge from your use of them.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>ENTIRE AGREEMENT, GOVERNING LAW AND CLASS ACTION WAIVER</h4>
                  <ul type="disc">
                    <li>
                      <h4>ENTIRE AGREEMENT</h4>
                      <p>
                        These Terms and Conditions, and the pages throughout this Website and
                        Services to which these terms refer, constitute a contract that governs the
                        relationship between us and you. As such, these terms and conditions nullify
                        and replace any conditions that may have been agreed to in the past, even if
                        not directly withdrawn or amended, and constitute the entirety of the rights
                        and obligations in place between both parties.{' '}
                      </p>
                    </li>
                    <li>
                      <h4>GOVERNING LAW</h4>
                      <p>
                        You agree that this Agreement and any issue or dispute arising out of or
                        otherwise related to this Agreement or with Your use of our Website,
                        Intellectual Property, the Terms of Use, the Privacy Statement, or any
                        matter concerning this Website shall be governed exclusively by the laws of
                        the United States, excluding its conflict of law provisions.
                      </p>
                    </li>
                    <li>
                      <h4>CLASS ACTION WAIVER</h4>
                      <p>
                        IN ANY DISPUTE, NEITHER YOU NOR ANY OTHER PERSON SHALL BE ENTITLED TO JOIN
                        OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER AFFILIATES OR PERSONS/ENTITIES OR
                        ARBITRATE ANY CLAIM AS A REPRESENTATIVE OR CLASS ACTION OR IN A PRIVATE
                        ATTORNEY GENERAL CAPACITY. YOU ACKNOWLEDGE THAT YOU ARE GIVING UP YOUR
                        RIGHTS TO TAKE PART AND ENGAGE IN A CLASS ACTION OR REPRESENTATIVE ACTION
                        WITH RESPECT TO ANY SUCH CLAIM.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TermsAndConditions;
