import './ThankYou.scss';

import { useEffect } from 'react';

import AvatarV1 from '../../../../../../../../assets/images/Lander15/version1/chat-avatar.png';
import AvatarV2 from '../../../../../../../../assets/images/Lander15/version2/chat-avatar.png';
import AvatarV3 from '../../../../../../../../assets/images/Lander15/version3/chat-avatar.png';
import AvatarV4 from '../../../../../../../../assets/images/Lander15/version4/chat-avatar.png';

export default function ThankYou({
  onNextStep,
  handleNextStep,
  currentStep,
  setIsValid,
  submitForm,
  landerId
}) {
  const ThankYouVersion = `ThankYouContainer Version${landerId}`;

  useEffect(() => {
    const timer = setTimeout(() => {
      submitForm();
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const avatars = {
    '15-1': AvatarV1,
    '15-2': AvatarV2,
    '15-3': AvatarV3,
    '15-4': AvatarV4
  };

  return (
    <div className={ThankYouVersion}>
      <img className="ThankYouAvatar" src={avatars[`${landerId}`]} alt="Avatar" />
      <span className="ThankYouText">Thank You!</span>
      <div className="ThankYouLoader typing is-typing-init is-typing-active">
        <span className="typing__bullet"></span>
        <span className="typing__bullet"></span>
        <span className="typing__bullet"></span>
      </div>
    </div>
  );
}
