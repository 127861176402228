import { useMutation } from '@tanstack/react-query';

import { queryClient } from '../../App';
import { axiosInstance } from '../../axios/axiosInstance';
import { key as UserDataKey } from './userData/useGetUserData';

export const key = ['VISITORS_NOTIFICATIONS'];
export const useMarkVisitorsNotificationAsRead = () => {
  return useMutation(
    [key],
    async () => {
      const { data } = await axiosInstance.post('/api/member/notifications/visitors/read/');
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...UserDataKey]);
      }
    }
  );
};
