import './Button.scss';

import { Button as AntdButton } from 'antd';

const Button = (props) => {
  const { text, onClick, className, icon, disabled, type = 'primary', ...rest } = props;
  const classes = ['Button', className];

  return (
    <AntdButton
      className={classes.join(' ')}
      onClick={() => (!disabled && onClick ? onClick() : null)}
      disabled={disabled}
      icon={icon}
      {...rest}
    >
      {props.children || text}
    </AntdButton>
  );
};

export default Button;
