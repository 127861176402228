import './LocationModalNew.scss';

import { Input, Modal, notification, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import MODALLOGO from '../../assets/images/LandingPage/LocalFlirt-red-logo-black.svg';
import MAP from '../../assets/images/Modal/map_wheredoyoulive.jpg';
import MAPMOBILE from '../../assets/images/Modal/map_wheredoyoulive_mobile.jpg';
import { useConfirmUserLocation } from '../../core/APIHooks/userData/useConfirmUserLocation';
import { useGetCityByZipCode } from '../../core/APIHooks/userData/useGetCityByZipCode';
import { useGetUserData } from '../../core/APIHooks/userData/useGetUserData';
import { useSaveUserProfile } from '../../core/APIHooks/useSaveUserProfile';
import Button from '../Button/Button';

const LocationModalNew = (props) => {
  const { openModal, setOpen } = props;

  const { data } = useGetUserData();
  const { mutate: confirm } = useConfirmUserLocation();
  const { mutate: getCity } = useGetCityByZipCode();
  const { mutate: updateProfile, isLoading } = useSaveUserProfile();

  const profileData = data?.data.profile;
  const [zipCode, setZipCode] = useState(profileData?.zip_code ? profileData?.zip_code : '');
  const [cities, setCities] = useState([{ label: '', value: '' }]);
  const [city, setCity] = useState(profileData?.city ? profileData?.city : '');
  const [zipCodeError, setZipCodeError] = useState();

  useEffect(() => {
    setZipCode(profileData?.zip_code);
    const data = {
      zip_code: profileData?.zip_code
    };
    profileData?.zip_code &&
      getCity(data, {
        onSuccess: (data) => {
          setCities([{ label: data.data[0].city, value: data.data[0].zip_code }]);
          if (data.data.length > 0) {
            setCity(data.data[0].city);
          } else if (data.data.length === 0) {
            setCity('');
            setZipCodeError('Non-existent zipcode');
          }
        },
        onError: (err) => {
          setCity('');
          setZipCodeError(err.response.data.errors.zip_code);
        }
      });
  }, [profileData]);

  const preventCharacter = (e) => {
    const regExp = /[a-zA-Z()+-/*.,]/g;
    if (e.key.length === 1 && regExp.test(e.key)) {
      e.preventDefault();
    }
  };

  const getZipCode = (e) => {
    setZipCode(e.target.value);
    setZipCodeError('');
    const data = {
      zip_code: e.target.value
    };
    getCity(data, {
      onSuccess: (data) => {
        console.log('CITIES  on change==> ', data.data);
        if (data.data.length > 0) {
          console.log('ON CHANGE CITY ===> ', data.data[0].city);
          const arrayOfCities = [];
          data.data.map((city) => {
            console.log('city -> ', city);
            arrayOfCities.push({ label: city.city, value: city.zip_code });
          });
          setCities(arrayOfCities);
          setCity(data.data[0].city);
        } else if (data.data.length === 0) {
          setZipCodeError('Non-existent zipcode');
        }
      },
      onError: (err) => {
        setZipCodeError(err.response.data.errors.zip_code);
      }
    });
  };

  const selectedCity = (value, option) => {
    setZipCode(value);
    setCity(option.label);
    setZipCodeError('');
  };

  const renderInputs = () => {
    return (
      <div className="CityContainer">
        <Input
          list="data"
          onChange={getZipCode}
          onKeyDown={preventCharacter}
          id="zipCode"
          type="text"
          name="zip_code"
          placeholder="Zip Code"
          value={zipCode ? zipCode : ''}
          maxLength={5}
        />
        <Select
          onChange={selectedCity}
          value={city}
          name="city"
          placeholder="Choose City"
          options={cities}
        />
      </div>
    );
  };

  const renderCityContainer = () => {
    return (
      <div className="CitySingleContainer">
        <div className="CityHolder">{profileData.city}</div>
      </div>
    );
  };

  const userDataRequest = () => {
    confirm(data?.data.id, {
      onSuccess: () => {
        setOpen(false);
      },
      onError: () => notification.error({ message: 'Error confirming location!' })
    });
  };

  const onSubmit = () => {
    zipCode.length !== 5 ? setZipCodeError('5 digit required') : null;
    const finalData = {
      zip_code: zipCode,
      city: city
    };
    if ((zipCode.length === 5) & !zipCodeError) {
      updateProfile(
        { id: profileData.id, data: finalData },
        {
          onSuccess: () => {
            setOpen(false);
            setZipCodeError('');
            userDataRequest();
          },
          onError: (e) => {
            console.log(e.response.data.errors.zip_code);
            setZipCodeError(e.response.data.errors.zip_code);
          }
        }
      );
    }
  };

  const closeWhereDoYouLive = () => {
    if (data?.data.click_id) {
      onSubmit();
    } else {
      setOpen(false);
      userDataRequest();
    }
  };

  return (
    <div>
      <Modal
        className="LocationModalNew"
        centered={true}
        closable={false}
        open={openModal}
        width={900}
        footer={null}
      >
        <div className="ModalContent">
          <div className="MapModalContainer">
            <img onContextMenu={(e) => e.preventDefault()} className="MapImage" src={MAP} alt="" />
            <img
              onContextMenu={(e) => e.preventDefault()}
              className="MapImageMobile"
              src={MAPMOBILE}
              alt="map"
            />
          </div>
          <div className="DataModalContainer">
            <div className="LogoModalContainer">
              <img className="LogoImage" src={MODALLOGO} />
            </div>
            <div className="TitleModalContainer">Where do you live?</div>
            <div className="TextModalContainer">
              Confirm your zip code to display the users that are closest to you first!
            </div>
            <div className="InputModalContainer">
              {data ? renderInputs() : <Spin />}
              {zipCodeError ? <div className="ErrorMessage"> {zipCodeError} </div> : ''}
            </div>
            <div className="ButtonModalContainer">
              <Button
                className="WhereDoYouLiveButton"
                text="SUBMIT"
                disabled={!city}
                onClick={closeWhereDoYouLive}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LocationModalNew;
