import './GalleryUpload.scss';

import { DeleteOutlined, FileImageOutlined } from '@ant-design/icons';
import { Image, Modal, notification, UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useState } from 'react';
//@ts-ignore
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { queryClient } from '../../../../../App';
import Button from '../../../../../components/Button/Button';
import LoaderComponent from '../../../../../components/LoaderCompoment/LoaderComponent';
import { DragUpload } from '../../../../components/upload/DragUpload';
import { useDeleteUserPhotos } from '../../../../services/myProfile/useDeleteUserPhotos';
import { key as UserDetailsKey } from '../../../../services/myProfile/useGetUserDetails';
import {
  key as UserGalleryKey,
  useGetUserGallery
} from '../../../../services/myProfile/useGetUserGallery';
import { useUpdateUserPhotos } from '../../../../services/myProfile/useUpdateUserPhotos';

interface GalleryUploadType {
  userId: number;
}

const GalleryUpload = ({ userId }: GalleryUploadType) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteImage, setDeleteImage] = useState<number>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { mutate: saveUserGalleryPhotos, isLoading } = useUpdateUserPhotos();
  const { mutate: deleteUserPhoto } = useDeleteUserPhotos();
  const { data: userGeneralData } = useGetUserGallery(userId);
  const userGalleryImages = userGeneralData?.gallery_images;
  let galleryPhotosNumber = userGeneralData ? userGeneralData?.gallery_images?.length : 0;
  const galleryPhotoLimit = 30;

  const showModal = (image: number) => {
    setIsModalOpen(true);
    setDeleteImage(image);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    deleteImage &&
      deleteUserPhoto(deleteImage, {
        onSuccess: () => {
          queryClient.invalidateQueries(UserGalleryKey);
        }
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const uploadGalleryImages = (data: any) => {
    saveUserGalleryPhotos(
      { userId, file: data.file, type: 'gallery_images' },
      {
        onSuccess: () => {
          setFileList([]);
          queryClient.invalidateQueries(UserGalleryKey);
        }
      }
    );
  };

  const beforeUploadImage = (file: RcFile) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'image/bmp' ||
      file.type === 'image/gif';
    galleryPhotosNumber && galleryPhotosNumber++;
    if (!isJpgOrPng) {
      galleryPhotosNumber && galleryPhotosNumber--;
      notification.error({
        message: 'You can only upload JPG/PNG/BMP/GIF file!'
      });
    }

    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      galleryPhotosNumber && galleryPhotosNumber--;
      notification.error({
        message: `Image ${file.name} must be smaller than 10MB!`
      });
    }

    let limitNumber = true;
    if (galleryPhotosNumber && galleryPhotosNumber > galleryPhotoLimit) {
      limitNumber = false;
      galleryPhotosNumber && galleryPhotosNumber--;
      notification.error({
        message: `${file.name} sorry, you reached the gallery limit (30). Please delete an existing photo to upload this image.`
      });
    }

    return isJpgOrPng && isLt2M && limitNumber;
  };

  return (
    <div className="gallery-upload">
      <DragUpload
        name={'image'}
        beforeUpload={beforeUploadImage}
        customRequest={uploadGalleryImages}
        showUploadList={false}
        listType="picture"
        defaultFileList={fileList}
        multiple={true}
        icon={<FileImageOutlined />}
        queryKeyArray={[UserGalleryKey, UserDetailsKey]}
        disabled={!!(userGalleryImages && userGalleryImages?.length >= galleryPhotoLimit)}
      />
      <div className="gallery-images-container">
        {isLoading ? (
          <div className="gallery-loader">
            <LoaderComponent />
          </div>
        ) : null}

        <Image.PreviewGroup>
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 3 }}>
            <Masonry gutter="10px">
              {userGalleryImages?.map((image) => {
                return (
                  <div className="gallery-image-container" key={image.id}>
                    <Image src={image.url} />
                    <div className="delete-image-container" onClick={() => showModal(image.id)}>
                      <DeleteOutlined />
                    </div>
                  </div>
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </Image.PreviewGroup>
      </div>
      <Modal
        className="delete-image-modal"
        footer={null}
        title="Delete Photo"
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <p>Are You sure You want to delete this photo? </p>
        <div className="modal-button-container">
          <Button className="SubmitButton" onClick={() => handleCancel()} text="No" />
          <Button className="SubmitButton" onClick={() => handleOk()} text="Yes" />
        </div>
      </Modal>
    </div>
  );
};

export default GalleryUpload;
