import './PageNotFound.scss';

import React from 'react';

import LogoText from '../../assets/images/MembersAreaNavbar/LogoText.svg';
import { showMembersAreaPage } from '../../services/navigation-service/navigation.service';

const PageNotFound = () => {
  return (
    <div className="not-found">
      <div className="header">
        <img
          onContextMenu={(e) => e.preventDefault()}
          onClick={() => showMembersAreaPage()}
          src={LogoText}
          alt="logo-icon"
        />
      </div>
      <div className="page-not-found">
        <h1>Page Not Found</h1>
      </div>
    </div>
  );
};

export default PageNotFound;
