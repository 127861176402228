import './HomePageProfiles.scss';

import { Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import ProfileAvatar from '../../../../../../assets/images/Temp/ProfileImage.jpg';
import Button from '../../../../../../components/Button/Button';
import ImageComponent from '../../../../../../components/ImageComponent/ImageComponent';
import ImageHomepage from '../../../../../../components/ImageHomepage/ImageHomepage';
import SignUpFlow from '../../../../../../components/SignUpFlow/SignUpFlow';
import { getPublicProfiles } from '../../../../../../services/profile-service/profile.service';

const HomePageProfiles = () => {
  const [userData, getUserData] = useState();
  const registerModalRef = useRef(null);

  useEffect(() => {
    const getProfileSuccess = (response) => {
      const { data } = response.data;
      getUserData(data);
    };

    getPublicProfiles().then(getProfileSuccess);
  }, []);

  const openModal = (ref) => {
    ref.current.openModal();
  };

  const scrollToTop = () => {
    var elmnt = document.getElementById('scrollTop');
    elmnt.scrollIntoView();
  };

  const ProfileThumb = ({ profile }) => {
    return (
      <div className="ProfileInfo-Container" onClick={scrollToTop}>
        <div className="ProfileInfo">
          <div>
            {profile?.username}, {new Date().getFullYear() - profile?.birthday?.slice(0, 4)}
          </div>
        </div>
        <div className="Overlay" />
        {profile.on}
        {profile?.avatar_image ? (
          <ImageHomepage name={profile?.avatar_image} />
        ) : (
          <ImageComponent url={ProfileAvatar} />
        )}
      </div>
    );
  };

  const renderProfile = (profile, index) => {
    if (window.innerWidth < 720) {
      return (
        index < 8 && (
          <Tooltip
            placement="top"
            title={profile.username}
            color="#681A20"
            key={`profile-${index}`}
          >
            <div className="Profile-Container">
              <ProfileThumb profile={profile} />
            </div>
          </Tooltip>
        )
      );
    } else {
      return (
        <Tooltip placement="top" title={profile.username} color="#681A20" key={`profile-${index}`}>
          <div className="Profile-Container">
            <ProfileThumb profile={profile} />
          </div>
        </Tooltip>
      );
    }
  };

  const renderProfileList = () => {
    return <div className="Profiles-Container">{userData?.map(renderProfile)}</div>;
  };

  return (
    <div className="HomePageProfiles">
      <div className="HomePageProfiles-Container ">
        <div className="Subtitle">thousands of local profiles</div>
        <div className="MainTitle">Check Out Our Latest Selection</div>
        {renderProfileList()}
        <Button
          onClick={openModal.bind(null, registerModalRef)}
          text="View More"
          className={'LandingPageButton'}
        />
      </div>
      <SignUpFlow modalRef={registerModalRef} />
    </div>
  );
};

export default HomePageProfiles;
