import './Warning.scss';

import { Button } from 'antd';
import React from 'react';

import warningBgImage from '../../../../../../../../assets/images/Lander10/warning.png';
import HorizontalOption from '../../HorizontalOption/HorizontalOption';

export default function Gender({ onNextStep }) {
  return (
    <>
      <HorizontalOption title="Warning!" backgroundImage={warningBgImage} disabled />
      <p className="WarningDescription">
        The women you’re about to see are real and might live near you, you might even know them,
        you must agree to be discrete and private when using this site. Do you agree?
      </p>
      <div className="WarningOptions">
        <Button className="WarningButton" type="primary" onClick={() => onNextStep()}>
          NO, I’M NOT READY
        </Button>
        <Button className="WarningButton Danger" type="primary" onClick={() => onNextStep()}>
          YES, I AM READY
        </Button>
      </div>
    </>
  );
}
