import * as actionTypes from '../types/newMessages';

const initialState = {};

const latestMessage = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NEW_MESSAGE:
      return action.payload;
    default:
      return state;
  }
};

export default latestMessage;
