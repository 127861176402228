import { FrownOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from '@reach/router';
import { Pagination, Result } from 'antd';
import React, { useEffect } from 'react';

import Button from '../../../../../../components/Button/Button';
import LoaderComponent from '../../../../../../components/LoaderCompoment/LoaderComponent';
import { ProfileCard } from '../../../../../../components/ProfileCard/ProfileCard';
import { useGetFavourites } from '../../../../../../core/APIHooks/useGetFavourites';
import { useGetUserData } from '../../../../../../core/APIHooks/userData/useGetUserData';
import { Triggers } from '../../../../../../V2/model/Triggers';
import { MEMBERS_ROUTES } from '../../members_routes';

const MyFavorites = () => {
  const perPage = 30;
  const location = useLocation();
  const navigate = useNavigate();
  const { data: user, isLoading: gettingUser, dataUpdatedAt, isPreviousData } = useGetUserData();
  const query = new URLSearchParams(location.search);
  const { data, isLoading, error } = useGetFavourites({ page: query.get('page') ?? '1', perPage });
  const premium = user?.data?.hasPremiumSubscription;
  const handleQuery = (param, value) => {
    query.set(param, value);
    navigate(`${location.pathname}?${query.toString()}`);
  };

  console.log(data);
  useEffect(() => {
    if (!isPreviousData && dataUpdatedAt > 0 && !gettingUser && !premium && user?.data?.id) {
      navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MEMBERSPRICING}`, {
        state: {
          trigger: Triggers.FAVORITES,
          id: data?.id
        }
      });
    }
  }, [data?.id, gettingUser, premium, user?.data?.id]);

  const renderProfiles = () => {
    return (
      data?.data.length > 0 &&
      premium &&
      data.data.map((item) => (
        <div className={'container'} key={item.id}>
          <ProfileCard profile={item} key={item.id} />
        </div>
      ))
    );
  };

  return (
    <>
      <div className="title"> Favorites </div>
      {isLoading || gettingUser ? (
        <div className={'loader'}>
          <LoaderComponent />
        </div>
      ) : (
        <>
          {error ? (
            <div className={'no-result'}>
              <Result
                icon={<FrownOutlined style={{ color: '#9f0220' }} />}
                title="Something is not ok"
                subTitle={error?.response.data.message}
                extra={
                  <Button
                    text="Browse profiles?"
                    onClick={() => navigate(`${MEMBERS_ROUTES.MEMBERSAREA}`)}
                  />
                }
              />
            </div>
          ) : (
            <div className={'grid-container'}>{renderProfiles()}</div>
          )}
          <div className={'Pages'}>
            <Pagination
              className={'pagination'}
              defaultCurrent={1}
              current={data?.meta?.current_page}
              total={data?.meta?.total}
              pageSize={perPage}
              onChange={(page) => handleQuery('page', page)}
              showSizeChanger={false}
              hideOnSinglePage={true}
            />
          </div>
        </>
      )}
    </>
  );
};

export default MyFavorites;
