import './NotEnoughCredits.scss';

import { Button } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HiShoppingCart } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';

import ICONWARNING from '../../../assets/images/Modal/icon_warning.svg';
import USERIMAGE from '../../../assets/images/Temp/ProfileImageSmall.jpg';
import ImageN from '../../../components/ImageN/ImageN';
import { useSocket, wsEvents } from '../../../core/sockets/useSocket';
import { setCredits as setCreditsAction } from '../../../redux/actions/credits';
import { purchaseCredit } from '../../../services/payment-services/payment.service';
import { getBillingCredits } from '../../../services/prcing-service/pricing.service';
import { getToken } from '../../../services/token-service/token.service';
import { getUserData } from '../../../services/user-service/user.service';
import ImageComponent from '../../ImageComponent/ImageComponent';
import ChangeCC from '../ChangeCC/ChangeCC';
import ModalDialog from '../ModalDialog/ModalDialog';

const NotEnoughCredits = (props) => {
  const { username, modalRef, purchaseCreditsRef, openModal, closeModal, photo, auto } = props;
  const dispatch = useDispatch();
  const currentCredits = useSelector((state) => state.credits);
  const userData = getUserData();
  const changeCCRef = useRef(null);
  const [paymentData, setPaymentData] = useState();
  const [loader, setLoader] = useState(true);

  const token = getToken();
  const openCreditBundle = () => {
    openModal(purchaseCreditsRef);
    closeModal(modalRef);
  };

  const getCurrentPricing = useCallback(async () => {
    try {
      const response = await getBillingCredits();
      dispatch(setCreditsAction(response.data.data));
      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch]);

  useEffect(() => {
    getCurrentPricing();
  }, [getCurrentPricing]);

  useSocket({
    type: wsEvents.PAYMENT_SUCCESS,
    callBack: () => {
      console.log('should close');
      auto && closeModal(modalRef);
    }
  });
  const isCharge = () => {
    return userData?.credit_history.length > 0;
  };
  const charge = isCharge();
  const [disabled, setDisabled] = useState(false);
  const handleClick = (title) => {
    const windowReference = window.open('about:blank', '_blank');
    setDisabled(true);
    const bundle = title.toLowerCase();
    const currentUrl = window.location.toString();
    const redirect_url = currentUrl.split('?')[0];

    const data = {
      type: currentCredits?.creditBundles.type,
      bundle: bundle,
      redirect_url: redirect_url,
      charge: charge
    };
    setPaymentData(data);

    if (charge) {
      purchaseCredit(data, token)
        .then((res) => {
          const url = res.data.redirect_url;
          windowReference.location = window.location.href === url ? url + '?paymentstatus=0' : url;
        })
        .then(() => closeModal(modalRef))
        .catch((err) => {
          console.log(err);
        });
    } else {
      purchaseCredit(data, token)
        .then((res) => {
          windowReference.location = res.data.redirect_url;
        })
        .catch((err) => console.log(err));
    }
  };

  const changeCCModal = () => {
    return (
      <ModalDialog className="ChangeCCWrapper" ref={changeCCRef} hideLogo={true}>
        <ChangeCC paymentData={paymentData} />
      </ModalDialog>
    );
  };
  const renderText = () => {
    return `Buy 5
        messages for only $ ${!loader ? currentCredits?.creditBundles.bundles[0]?.price : ''}`;
  };

  return (
    <div className="NotEnoughCredits">
      <div className="ImageContainer">
        {photo ? <ImageN name={photo} className="UserPhoto" /> : <ImageComponent url={USERIMAGE} />}
      </div>
      <div className="TitleContainer">
        <ImageN name={ICONWARNING} className="WarningImage" />
        Ooops, you don’t have enough credits...
      </div>
      <div className="SubtitleContainer">
        To send {username} a message, you need to purchase credits.
      </div>
      <div className="ButtonContainer">
        <Button
          className={'SubmitButton Button'}
          htmlType="submit"
          disabled={disabled}
          icon={<HiShoppingCart />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClick('STARTER');
          }}
        >
          {renderText()}
        </Button>
      </div>
      <div className="SelectBundleContainer">
        or <span onClick={openCreditBundle}>Select a bigger bundle</span>
      </div>
      {changeCCModal()}
    </div>
  );
};

export default NotEnoughCredits;
