import { selector } from 'recoil';

import { userData } from '../atoms/userDataAtom';

export const userProfile = selector({
  key: 'UserProfile',
  get: ({ get }) => {
    const data = get(userData);
    return data.data?.profile;
  }
});
