import './Email.scss';

import GoogleLoginButton from '../../../../../../../../components/GoogleLoginButton/GoogleLoginButton';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Email({
  getValues,
  handleNextStep,
  watch,
  errors,
  setValue,
  emailLoading,
  clearErrors,
  setGoogleLogin,
  handleGoogleLogin
}) {
  return (
    <div className="EmailContainer">
      <div className="StepContent">
        <p className="EmailText">Type your email address</p>
        <div className="InputMainContainer">
          <Input
            placeholder="Email address"
            name="email"
            type="email"
            value={getValues('email')}
            onChange={() => {
              setGoogleLogin(false);
            }}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
          <GoogleLoginButton
            className="GreyGoogleLoginButton"
            text="Sign up with Google"
            handleGoogleLogin={handleGoogleLogin}
          />
        </div>
        <div className="ButtonContainer">
          <Button
            handleNextStep={() => handleNextStep(['email'])}
            text="CONTINUE"
            loading={emailLoading}
            disabled={!watch('email')}
          ></Button>
        </div>
      </div>
    </div>
  );
}
