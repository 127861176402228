import './GenderOfInterest.scss';

import {
  interestedInManBgImageV1,
  interestedInManBgImageV1Mobile,
  interestedInWomanBgImageV1,
  interestedInWomanBgImageV1Mobile
} from '../../../../../../../../assets/images/Lander15/version1';
import {
  interestedInManBgImageV2,
  interestedInManBgImageV2Mobile,
  interestedInWomanBgImageV2,
  interestedInWomanBgImageV2Mobile
} from '../../../../../../../../assets/images/Lander15/version2';
import {
  interestedInManBgImageV3,
  interestedInManBgImageV3Mobile,
  interestedInWomanBgImageV3,
  interestedInWomanBgImageV3Mobile
} from '../../../../../../../../assets/images/Lander15/version3';
import {
  interestedInManBgImageV4,
  interestedInManBgImageV4Mobile,
  interestedInWomanBgImageV4,
  interestedInWomanBgImageV4Mobile
} from '../../../../../../../../assets/images/Lander15/version4';
import { useGetEnumPublic } from '../../../../../../../../core/APIHooks/useGetEnumPublic';
import VerticalOption from '../../VerticalOption/VerticalOption';

export default function GenderOfInterest({
  watch,
  setValue,
  setIsValid,
  landerId,
  handleNextStep,
  currentStep,
  isElementVisible,
  setIsElementVisible,
  getResponsiveImage
}) {
  const { data: enumPublic } = useGetEnumPublic();

  const gender = watch('seeking');
  const avatars = {
    '15-1': {
      male: getResponsiveImage(interestedInManBgImageV1Mobile, interestedInManBgImageV1),
      female: getResponsiveImage(interestedInWomanBgImageV1Mobile, interestedInWomanBgImageV1)
    },
    '15-2': {
      male: getResponsiveImage(interestedInManBgImageV2Mobile, interestedInManBgImageV2),
      female: getResponsiveImage(interestedInWomanBgImageV2Mobile, interestedInWomanBgImageV2)
    },
    '15-3': {
      male: getResponsiveImage(interestedInManBgImageV3Mobile, interestedInManBgImageV3),
      female: getResponsiveImage(interestedInWomanBgImageV3Mobile, interestedInWomanBgImageV3)
    },
    '15-4': {
      male: getResponsiveImage(interestedInManBgImageV4Mobile, interestedInManBgImageV4),
      female: getResponsiveImage(interestedInWomanBgImageV4Mobile, interestedInWomanBgImageV4)
    }
  };

  const handleSeekingSelect = (gender) => {
    setValue('seeking', gender);
    handleNextStep([currentStep.field]);
    setIsValid(false);
    setIsElementVisible(false);
  };

  return (
    <>
      <div
        className={
          isElementVisible
            ? 'Options SelectOptions Interests ToggleElement'
            : 'Hidden Options SelectOptions Interests'
        }
      >
        {enumPublic?.seeking.map((value) => {
          return (
            <VerticalOption
              key={value}
              title={value}
              backgroundImage={
                value === 'men' ? avatars[`${landerId}`].male : avatars[`${landerId}`].female
              }
              onClick={() => handleSeekingSelect(value)}
              isActive={gender === value}
            />
          );
        })}
      </div>
    </>
  );
}
