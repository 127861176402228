/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

import { useUpdateUserFreshChatRestoreId } from '../services/myProfile/useUpdateUserFreshChatRestoreId';

declare global {
  interface Window {
    fcWidget: any;
    fcWidgetMessengerConfig: any;
  }
}
export const useFreshChat = () => {
  const rawUserData = localStorage.getItem('userData');
  const userData = rawUserData ? JSON.parse(rawUserData) : null;
  const { mutate: saveRestoreId } = useUpdateUserFreshChatRestoreId();

  const restoreId = userData?.freshchat_restore_id ?? null;

  useEffect(() => {
    if (userData?.id && window.fcWidget) {
      window.fcWidget.init({
        token: '98a0a2f9-e360-495e-9f36-c88b0addb4ee',
        host: 'https://cdncloud-help.freshchat.com',
        widgetUuid: 'd4767699-f7df-417d-8031-22f6e2cad441',
        externalId: userData?.id ?? 'Unknown ID',
        restoreId,
        firstName: userData?.profile?.username ?? 'Unknown name',
        email: userData?.email ?? 'Unknown email'
      });

      window.fcWidget.on('user:created', async (resp: any) => {
        const { status, data } = resp;
        console.log({ resp });
        if (status === 200 && data.restoreId) {
          saveRestoreId(data.restoreId);
        }
      });
    }
    window.fcWidgetMessengerConfig = {
      externalId: userData?.id,
      restoreId
    };

    return () => window?.fcWidget?.destroy();
  }, []);

  return null;
};
