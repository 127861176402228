/* eslint-disable react/no-unescaped-entities */

import './SignUpAff7nosso.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@reach/router';
import { Progress } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Form/Input/Input';
import { useGetEnumPublic } from '../../../../core/APIHooks/useGetEnumPublic';
import { useEmailValidation } from '../../../../core/emailValidation/useEmailValidation';
import { authMe, memberLogin } from '../../../../services/auth-service/auth.service';
import { showEmailVerificationInstruction } from '../../../../services/navigation-service/navigation.service';
import { saveToken } from '../../../../services/token-service/token.service';
import { setUserLocalData } from '../../../../services/user-service/user.service';
import { handleAuthReactivation } from '../../helpers/authReactivation';
import { InputNumber } from '../Inputs';
import AutomatedMessages from './AnimatedMessages';
import ZipcodeInput from './FormInputs/ZipcodeInput';

const schema = yup.object().shape({
  username: yup
    .string()
    .required('Username is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  password: yup
    .string()
    .required('Password is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  age: yup
    .number()
    .typeError('Age is required!')
    .required('Age is required!')
    .test('len', 'You must be at least 18 years', (val) => val && val > 17),
  zip_code: yup
    .string()
    .required('Zip code is required!')
    .test('numbers', 'Zip code should only contain numbers', (val) => /^\d+$/.test(val))
    .test('len', 'Must be exactly 5 characters', (val) => val && val.trim().length === 5)
});

const SignUpAff7nosso = () => {
  const [currentFormField, setCurrentFormField] = useState(1);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [gender, setGender] = useState('');
  const [seeking, setSeeking] = useState('');
  const [loadingZipCode, setLoadingZipCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCard, setShowCard] = useState(null);
  const [stepPercentage, setStepPercentage] = useState(0);
  const [googleLogin, setGoogleLogin] = useState(false);
  const { mutate: validateEmail, isLoading } = useEmailValidation();
  const { data: enumPublic } = useGetEnumPublic();
  const navigate = useNavigate();

  const reversedSeeking = enumPublic?.seeking.map((item) => item).reverse();
  const clickID = urlParams.get('clickId');
  const affiliate = urlParams.get('affiliate');
  const source = urlParams.get('source');

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    switch (currentFormField) {
      case 2:
        return setStepPercentage(10);
      case 3:
        return setStepPercentage(20);
      case 4:
        return setStepPercentage(30);
      case 5:
        return setStepPercentage(40);
      case 6:
        return setStepPercentage(50);
      case 7:
        return setStepPercentage(60);
      case 8:
        return setStepPercentage(70);
      case 9:
        return setStepPercentage(80);
      case 10:
        return setStepPercentage(88);
      case 11:
        return setStepPercentage(95);
      default:
        return setStepPercentage(0);
    }
  }, [currentFormField]);

  const handleSeekingValue = (value, number) => {
    setCurrentFormField(number);
    setSeeking(value);
  };

  const handleGenderValue = (value) => {
    setCurrentFormField(3);
    setGender(value);
  };

  const handleNextStep = async (type, view) => {
    const isValid = await trigger(type);
    if (isValid) {
      setCurrentFormField(view);
    }
  };
  const renderStepOne = () => {
    return (
      <div className="StepsContainer">
        <div className="Steps">
          <AutomatedMessages
            setShowCard={setShowCard}
            text="Hey, I’m Ema, here to help you find best matches."
            text2="What kind of relationships are you looking for?"
            stepNo={1}
          />
          <div className="Aff7FormContainer">
            <div
              className={`ClickCard ${showCard === 1 && 'show'}`}
              onClick={() => handleNextStep('casual', 2)}
            >
              Casual
            </div>
            <div
              className={`ClickCard kind-of-relationship-2 ${showCard === 1 && 'show'}`}
              onClick={() => handleNextStep('couple of dates', 2)}
            >
              Couple of dates{' '}
            </div>
            <div
              className={`ClickCard kind-of-relationship-3 ${showCard === 1 && 'show'}`}
              onClick={() => handleNextStep('serious relationships', 2)}
            >
              Serious relationships{' '}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStepTwo = () => {
    return (
      <div className="StepsContainer">
        <div className="Steps">
          <AutomatedMessages
            setShowCard={setShowCard}
            text="Are you a man or a woman?"
            stepNo={2}
          />
          <div className="Aff7FormContainer">
            {enumPublic?.gender.map((value) => {
              const genderClass = value === 'male' ? 'a-man' : 'a-woman';
              const classCard = [`ClickCard big ${showCard === 2 && 'show'} ${genderClass}`];
              if (value !== 'prefer not to tell') {
                return (
                  <div
                    key={value}
                    className={classCard}
                    onClick={() => handleGenderValue(value, 3)}
                  >
                    {value === 'male' ? `I'm a man` : `I'm a woman`}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderStepThree = () => {
    return (
      <div className="StepsContainer">
        <div className="Steps">
          <AutomatedMessages setShowCard={setShowCard} text="Who are you looking for?" stepNo={3} />
          <div className="Aff7FormContainer">
            {reversedSeeking.map((value) => {
              const genderClass = value === 'men' ? 'seeking-man' : 'seeking-woman';
              const classCard = [`ClickCard big ${showCard === 3 && 'show'} ${genderClass}`];
              return (
                <div
                  key={value}
                  className={classCard.join()}
                  onClick={() => handleSeekingValue(value, 4)}
                >
                  {value === 'men' ? 'Seeking a man' : 'Seeking a woman'}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderStepFour = () => {
    return (
      <div className="StepsContainer">
        <div className="Steps AutoHeight">
          <AutomatedMessages setShowCard={setShowCard} text="How old are you?" stepNo={4} />
          <div className="Aff7FormContainer">
            <div className={`InputMainContainer ${showCard === 4 && 'show'}`}>
              <div className="InputContainers">
                <InputNumber
                  name="age"
                  {...register('age')}
                  placeholder="Age"
                  errorMessage={errors?.age?.message}
                />
              </div>
              <Button onClick={() => handleNextStep('age', 5)} text="Continue" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStepFive = () => {
    return (
      <div className="StepsContainer">
        <div className="Steps">
          <AutomatedMessages
            setShowCard={setShowCard}
            text="What woman's body type do you prefer?"
            stepNo={5}
          />
          <div className="Aff7FormContainer">
            <div
              className={`ClickCard body-type-1 ${showCard === 5 && 'show'}`}
              onClick={() => handleNextStep('athletic', 6)}
            >
              Athletic
            </div>
            <div
              className={`ClickCard body-type-2 ${showCard === 5 && 'show'}`}
              onClick={() => handleNextStep('curvy', 6)}
            >
              Curvy{' '}
            </div>
            <div
              className={`ClickCard body-type-3 ${showCard === 5 && 'show'}`}
              onClick={() => handleNextStep('plump', 6)}
            >
              Plump{' '}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStepSix = () => {
    return (
      <div className="StepsContainer">
        <div className="Steps">
          <AutomatedMessages
            setShowCard={setShowCard}
            text="Great. I would like to look for the most suitable matches"
            text2="What woman's age do you prefer?"
            stepNo={6}
          />
          <div className="Aff7FormContainer">
            <div
              className={`ClickCard age-1 ${showCard === 6 && 'show'}`}
              onClick={() => handleNextStep('18-29', 7)} //update with backend
            >
              18-29
            </div>
            <div
              className={`ClickCard age-2 ${showCard === 6 && 'show'}`}
              onClick={() => handleNextStep('30-45', 7)} //update with backend
            >
              30-45{' '}
            </div>
            <div
              className={`ClickCard age-3 ${showCard === 6 && 'show'}`}
              onClick={() => handleNextStep('45+', 7)} //update with backend
            >
              45+{' '}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStepSeven = () => {
    return (
      <div className="StepsContainer">
        <div className="Steps MobileAdjust">
          <AutomatedMessages
            setShowCard={setShowCard}
            messageBig2
            text="In which radius would you like to search?"
            text2="Just enter the Zip Code of your city. You can set the radius later."
            text3="Where do you live?"
            stepNo={7}
          />

          <div className="Aff7FormContainer stepSeven">
            <div className={`InputMainContainer ${showCard === 7 && 'show'}`}>
              <div className="InputContainers">
                <ZipcodeInput
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                  clearErrors={clearErrors}
                  errors={errors}
                  setLoadingZipCode={setLoadingZipCode}
                />
              </div>
              <Button
                onClick={() => handleNextStep('zip_code', 8)}
                text={loadingZipCode ? 'Loading...' : 'Continue'}
                className={loadingZipCode ? 'DisabledButton' : ''}
                disabled={loadingZipCode}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStepEight = () => {
    return (
      <div className="StepsContainer">
        <div className="Steps AutoHeight">
          <AutomatedMessages
            setShowCard={setShowCard}
            text="Please enter your username"
            stepNo={8}
          />
          <div className="Aff7FormContainer">
            <div className={`InputMainContainer ${showCard === 8 && 'show'}`}>
              <div className="InputContainers">
                <Input
                  register={register}
                  name="username"
                  placeholder="Username"
                  errorMessage={errors?.username?.message}
                  onChange={(e) => {
                    setValue('username', e.target.value);
                    clearErrors('username');
                    clearErrors('email');
                  }}
                />
              </div>
              <Button onClick={() => handleNextStep('username', 9)} text="Continue" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStepNine = () => {
    return (
      <div className="StepsContainer">
        <div className="Steps">
          <AutomatedMessages
            setShowCard={setShowCard}
            messageBig2
            text="Great!"
            text2="Now choose a pasword. It should be at least 6 characters long"
            stepNo={9}
          />
          <div className="Aff7FormContainer">
            <div className={`InputMainContainer ${showCard === 9 && 'show'}`}>
              <div className="InputContainers">
                <Input
                  register={register}
                  name="password"
                  type="password"
                  placeholder="Password"
                  errorMessage={errors?.password?.message}
                  onChange={(e) => {
                    setValue('password', e.target.value);
                    clearErrors('password');
                    clearErrors('email');
                  }}
                />
              </div>
              <Button onClick={() => handleNextStep('password', 10)} text="Continue" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const jumpToNextStep = (step) => {
    setTimeout(() => setCurrentFormField(step), 1500);
  };

  const renderStepTen = () => {
    return (
      <div className="StepsContainer">
        <div className="Steps AutoHeight">
          <AutomatedMessages
            setShowCard={setShowCard}
            text="Wonderful. I have already found a lot of matches for you!"
            stepNo={10}
          />
          <div className="Aff7FormContainer">
            <div className={`InputMainContainer ${showCard === 10 && 'show'}`}>
              <div className="InputContainers">
                <div className="NumberCounter" id="the_count">
                  <CountUp start={0} end={22234} delay={3} onEnd={() => jumpToNextStep(11)} />
                </div>
                <div className="CounterSubtext">potential matches are waiting</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const emailValidation = () => {
    const username = getValues('username');
    const password = getValues('password');
    const age = getValues('age');
    const birthYear = new Date().getFullYear() - age;
    const city = getValues('city');
    const email = getValues('email');
    const zip_code = getValues('zip_code');
    const landerId = googleLogin ? 7 : '7typed_email';
    const data = {
      email,
      username,
      password,
      password_confirmation: password,
      click_id: clickID,
      affiliate_id: affiliate,
      lander_id: landerId,
      profile: {
        age: age,
        ...(age ? { birthday: `${birthYear}-01-01` } : {}),
        city: city,
        zip_code: zip_code,
        seeking: seeking,
        gender: gender
      },
      ...(source ? { source_id: source } : {})
    };
    validateEmail(data, {
      onSuccess: (res) => {
        handleAuthReactivation(res, email, navigate, () => {
          setValue('email', getValues('email'));
          clearErrors('email');
          submitForm();
        });
      },
      onError: (err) => {
        setError('email', {
          type: 'manual',
          message: err.data.errors.email[0]
        });
      }
    });
  };

  const renderStepEleven = () => {
    return (
      <div className="StepsContainer">
        <div className="Steps AutoHeight">
          <AutomatedMessages
            setShowCard={setShowCard}
            messageBig
            text="Please fill in your email, so I could keep you in touch about new matches."
            stepNo={11}
          />
          <div className="Aff7FormContainer">
            <div className={`InputMainContainer ${showCard === 11 && 'show'}`}>
              <div className="InputContainers">
                <Input
                  register={register}
                  name="email"
                  placeholder="Email"
                  errorMessage={errors?.email?.message}
                  onChange={(e) => {
                    setValue('email', e.target.value);
                    clearErrors('password');
                    clearErrors('email');
                  }}
                  onKeyDown={(e) => {
                    setGoogleLogin(false);
                    if (e.keyCode === 32) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <Button
                id="submitFormRef"
                onClick={emailValidation}
                text={loading || isLoading ? 'Loading...' : 'Continue'}
                className={loading || isLoading ? 'DisabledButton' : ''}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const onSubmit = (data) => {
    setLoading(true);
    setValue('email', data.email);
    const birthYear = new Date().getFullYear() - data.age;
    let submitData = { ...data, seeking, gender };
    let registerData = {};
    const landerId = googleLogin ? 7 : '7typed_email';
    if (source) {
      registerData = {
        username: submitData.username,
        email: data.email,
        password: submitData.password,
        password_confirmation: submitData.password,
        terms_and_conditions: 1,
        click_id: clickID,
        affiliate_id: affiliate,
        source_id: source,
        lander_id: landerId,
        profile: {
          city: submitData.city,
          zip_code: submitData.zip_code,
          birthday: `${birthYear}-01-01`,
          age: data.age,
          seeking: submitData.seeking,
          gender: submitData.gender
        }
      };
    } else {
      registerData = {
        username: submitData.username,
        email: submitData.email,
        password: submitData.password,
        password_confirmation: submitData.password,
        terms_and_conditions: 1,
        click_id: clickID,
        affiliate_id: affiliate,
        lander_id: landerId,
        profile: {
          city: submitData.city,
          zip_code: submitData.zip_code,
          birthday: `${birthYear}-01-01`,
          age: data.age,
          seeking: submitData.seeking,
          gender: submitData.gender
        }
      };
    }

    console.log('registerData ==> ', registerData);

    axios
      .post('/api/auth/member/register', registerData, {
        headers: {
          doNotAddUserTokenAuthorization: true
        }
      })
      .then((res) => {
        // setLoading(false);
        if (res.status === 200) {
          localStorage.setItem('current-email', data.email);
          const loginData = {
            username: res.data.user.username,
            email: res.data.user.email,
            password: registerData.password
          };
          memberLogin(loginData)
            .then((res) => {
              setLoading(true);
              saveToken(res.data.access_token, res.data.expires_in);
              localStorage.setItem('userData', JSON.stringify(res.data.user));
              localStorage.setItem('affiliateSiteLockdown', new Date());
              googleLogin
                ? authMe(res.data.access_token)
                    .then((response) => {
                      setLoading(false);
                      setUserLocalData(response.data);
                      window.location.href = '/members-area';
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err);
                    })
                : showEmailVerificationInstruction();
              // setUserLocalData(res.data.user);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        } else {
          throw new Error('Something went wrong!');
        }
      })
      .catch((err) => {
        setLoading(false);
        setGoogleLogin(false);
        const data = err.response.data.errors;
        Object.keys(err.response.data.errors).forEach((name) => {
          let message = data[name][0];
          setError(name, { type: 'manual', message });
          // if (name === "username") {
          //   setCurrentFormField(8);
          // }
          if (name === 'email') {
            setCurrentFormField(11);
          }
        });
      });
  };

  const submitForm = (e) => {
    console.log('dsadadadasdsdasdas', e);
    handleSubmit((data) => onSubmit(data))(e);
  };
  return (
    <div className="SignUpAff7nosso">
      <div className="ProgressBarContainer">
        <Progress
          percent={stepPercentage}
          showInfo={false}
          strokeColor={{
            '0%': '#9f0220',
            '100%': '#9f0220'
          }}
        />
      </div>
      <form className="form" onSubmit={(e) => e.preventDefault(e)}>
        {currentFormField === 1 && renderStepOne()}
        {currentFormField === 2 && renderStepTwo()}
        {currentFormField === 3 && renderStepThree()}
        {currentFormField === 4 && renderStepFour()}
        {currentFormField === 5 && renderStepFive()}
        {currentFormField === 6 && renderStepSix()}
        {currentFormField === 7 && renderStepSeven()}
        {currentFormField === 8 && renderStepEight()}
        {currentFormField === 9 && renderStepNine()}
        {currentFormField === 10 && renderStepTen()}
        {currentFormField === 11 && renderStepEleven()}
      </form>
    </div>
  );
};

export default SignUpAff7nosso;
