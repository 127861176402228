import './style.scss';

import { Modal, ModalProps } from 'antd';
import React, { FC } from 'react';

import ModalClose from '../../assets/images/modalClose.svg';
import NoUser from '../../assets/images/NoUser.svg';
import Button from '../Button/Button';

interface NotFoundModalProps extends ModalProps {}
export const NotFoundModal: FC<NotFoundModalProps> = ({ open, onCancel }) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      maskClosable
      closeIcon={<img src={ModalClose} alt={'close'} />}
      footer={
        <Button
          type={'primary'}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            onCancel?.(e as React.MouseEvent<HTMLButtonElement, MouseEvent>)
          }
        >
          BACK TO BROWSE PROFILES
        </Button>
      }
    >
      <div className={'modal-body'}>
        <img src={NoUser} alt={'no user'} className={'avatar-icon'} />
        <h3>The user does not exist!</h3>
        <p>This user removed account, or there is some other issue</p>
      </div>
    </Modal>
  );
};
