import './SignUpAff8.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@reach/router';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import * as yup from 'yup';

import WOMANGIF from '../../../../assets/images/FunnelPage/lander8woman.gif';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Form/Input/Input';
import LoaderPulse from '../../../../components/LoaderPulse/LoaderPulse';
import { useEmailValidation } from '../../../../core/emailValidation/useEmailValidation';
import { userData as userDataNew } from '../../../../recoil/atoms/userDataAtom';
import { authMe, memberLogin } from '../../../../services/auth-service/auth.service';
import { showEmailVerificationInstruction } from '../../../../services/navigation-service/navigation.service';
import { saveToken } from '../../../../services/token-service/token.service';
import { setUserLocalData } from '../../../../services/user-service/user.service';
import { handleAuthReactivation } from '../../helpers/authReactivation';
import { InputNumber } from '../Inputs';
import { stepList } from './FlowData';
import ZipcodeInput from './FormInputs/ZipcodeInput';

const schema = yup.object().shape({
  username: yup
    .string()
    .required('Username is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  password: yup
    .string()
    .required('Password is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  // email: yup.string().required('Email is required!').email('Invalid Email Address'),
  age: yup
    .number()
    .typeError('Age is required!')
    .required('Age is required!')
    .test('len', 'You must be at least 18 years', (val) => val && val > 17)
    .test('len', 'Invalid age', (val) => val && val < 100),
  // birthday: yup
  //   .date()
  //   .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
  //   .required("Birthdate is required!"),
  zip_code: yup
    .string()
    .required('Zip code is required!')
    .test('numbers', 'Zip code should only contain numbers', (val) => /^\d+$/.test(val))
    .test('len', 'Must be exactly 5 characters', (val) => val && val.trim().length === 5)
  // age_from: yup.string().required("Please select age from option"),
  // age_to: yup.string().required("Please select age to option"),
});

const SignUpAff8 = () => {
  const [currentFormField, setCurrentFormField] = useState(0);
  const [googleLogin, setGoogleLogin] = useState(false);
  const [loadingZipCode, setLoadingZipCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const clickID = urlParams.get('clickId');
  const affiliate = urlParams.get('affiliate');
  const source = urlParams.get('source');
  const setUserData = useSetRecoilState(userDataNew);
  const { mutate: validateEmail, isLoading } = useEmailValidation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
    trigger,
    formState: { errors },
    control
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const renderModal = (data) => {
    return (
      <div className="StepsModalContainer">
        <div className="StepsModalHeader"> {data.header}</div>
        <div className="StepsModalBody">
          {data.image && (
            <div className="StepsModalImage">
              {' '}
              <img className="AnimatedImage" src={WOMANGIF} alt="" />
            </div>
          )}
          {data.body && <div className="StepsModalText"> {data.body}</div>}
          {data.input &&
            data.input.map((input, index) => {
              return (
                <div className="StepsModalInput" key={index}>
                  <span>{input.label}</span>{' '}
                  {input.type !== 'zipCode' ? (
                    input.type !== 'tel' ? (
                      <Input
                        register={register}
                        name={input.name}
                        type={input.type}
                        value={getValues(input.name) ? getValues(input.name) : ''}
                        placeholder={input.placeholder}
                        maxLength={input.name === 'age' ? 2 : ''}
                        errorMessage={
                          Object.keys(errors).length !== 0 && errors[input.name]
                            ? errors[input.name].message
                            : ''
                        }
                        pattern={input.name === 'age' ? '[0-9]*' : undefined}
                        inputMode={input.name === 'age' ? 'numeric' : undefined}
                        onChange={(e) => {
                          if (input.name === 'age') {
                            setValue(input.name, e.target.value.slice(0, 2));
                          } else {
                            setValue(input.name, e.target.value);
                          }
                          clearErrors(input.name);
                        }}
                        onKeyDown={(e) => {
                          if (input.name === 'email') {
                            setGoogleLogin(false);
                            if (e.keyCode === 32) {
                              e.preventDefault();
                            }
                          }
                        }}
                      />
                    ) : (
                      <InputNumber
                        name="age"
                        {...register('age')}
                        placeholder="Age"
                        errorMessage={errors?.age?.message}
                      />
                    )
                  ) : (
                    <ZipcodeInput
                      register={register}
                      setValue={setValue}
                      getValues={getValues}
                      setError={setError}
                      clearErrors={clearErrors}
                      value={getValues(input.name) ? getValues(input.name) : ''}
                      errors={errors}
                      setLoadingZipCode={setLoadingZipCode}
                    />
                  )}
                </div>
              );
            })}

          {data.inputInfo && <div className="InputInfo">{data.inputInfo}</div>}
          <div className="ButtonsContainer">
            {data.buttons &&
              data.buttons.map((button, index) => {
                return button.text !== 'GOOGLE' ? (
                  <Button
                    className={button.second ? 'Second' : loadingZipCode ? 'DisabledButton' : ''}
                    disabled={loadingZipCode}
                    onClick={() =>
                      button.function === 'submit'
                        ? submitForm()
                        : button.type[0] === 'nextStep'
                        ? nextStep()
                        : button.type[0] === 'email'
                        ? emailValidation(button.type)
                        : handleNextStep(button.type)
                    }
                    text={button.text}
                    key={index}
                    loading={isLoading}
                  />
                ) : (
                  <GoogleLogin
                    text="Register with Google"
                    width="100%"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    className="GoogleLoginButton"
                  />
                );
              })}
          </div>
        </div>
        {loading ? (
          <div className="LoaderContainer">
            <LoaderPulse />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  const responseGoogle = (res) => {
    let userObject = jwt_decode(res.credential);
    setValue('email', userObject?.email);
    setGoogleLogin(true);
    // handleNextStep(['email']);
    emailValidation('email', true);
  };

  const handleNextStep = async (type, view) => {
    const isValid = await trigger(type);
    if (isValid) {
      nextStep();
    }
  };

  const emailValidation = async (type, isSSO = false) => {
    const isValid = await trigger(type);
    const landerId = isSSO ? 8 : '8typed_email';
    const username = getValues('username');
    const password = getValues('password');
    const age = getValues('age');
    const birthYear = new Date().getFullYear() - age;
    const gender = getValues('gender');
    const seeking = getValues('seeking');
    const city = getValues('city');
    const email = getValues('email');
    const zip_code = getValues('zip_code');
    const data = {
      email,
      username,
      password,
      password_confirmation: password,
      click_id: clickID,
      affiliate_id: affiliate,
      lander_id: landerId,
      profile: {
        age: age,
        ...(age ? { birthday: `${birthYear}-01-01` } : {}),
        city: city,
        zip_code: zip_code,
        seeking: seeking,
        gender: gender
      },
      ...(source ? { source_id: source } : {})
    };
    validateEmail(data, {
      onSuccess: (res) => {
        if (!isValid) {
          return;
        }

        handleAuthReactivation(res, email, navigate, () => {
          setValue('email', getValues('email'));
          clearErrors('email');
          nextStep();
        });
      },
      onError: (err) => {
        setError('email', {
          type: 'manual',
          message: err.data.errors.email[0]
        });
      }
    });
  };

  const submitForm = (e) => {
    handleSubmit((data) => onSubmit(data))(e);
  };

  const nextStep = () => {
    setCurrentFormField((prev) => prev + 1);
  };

  const onSubmit = (data) => {
    setLoading(true);
    const birthYear = new Date().getFullYear() - data.age;
    let submitData = { ...data };
    let registerData = {};
    const landerId = googleLogin ? 8 : '8typed_email';
    if (source) {
      registerData = {
        username: submitData.username,
        email: submitData.email,
        password: submitData.password,
        password_confirmation: submitData.password,
        terms_and_conditions: 1,
        phone: submitData.phone,
        click_id: clickID,
        affiliate_id: affiliate,
        source_id: source,
        lander_id: landerId,
        profile: {
          city: submitData.city,
          zip_code: submitData.zip_code,
          birthday: `${birthYear}-01-01`,
          age: data.age
        }
      };
    } else {
      registerData = {
        username: submitData.username,
        email: submitData.email,
        password: submitData.password,
        password_confirmation: submitData.password,
        terms_and_conditions: 1,
        phone: submitData.phone,
        click_id: clickID,
        affiliate_id: affiliate,
        lander_id: landerId,
        profile: {
          city: submitData.city,
          zip_code: submitData.zip_code,
          birthday: `${birthYear}-01-01`,
          age: data.age
        }
      };
    }

    axios
      .post('/api/auth/member/register', registerData, {
        headers: {
          doNotAddUserTokenAuthorization: true
        }
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          localStorage.setItem('current-email', data.email);
          const loginData = {
            username: res.data.user.username,
            email: res.data.user.email,
            password: registerData.password
          };
          memberLogin(loginData)
            .then((res) => {
              setLoading(true);
              saveToken(res.data.access_token, res.data.expires_in);
              localStorage.setItem('userData', JSON.stringify(res.data.user));
              localStorage.setItem('affiliateSiteLockdown', new Date());
              googleLogin
                ? authMe(res.data.access_token)
                    .then((response) => {
                      setLoading(false);
                      setUserLocalData(response.data);
                      setUserData(response.data);
                      window.location.href = '/members-area';
                    })
                    .catch(() => {
                      setLoading(false);
                    })
                : showEmailVerificationInstruction();
            })
            .catch(() => {
              setLoading(false);
            });
        } else {
          throw new Error('Something went wrong!');
        }
      })
      .catch((err) => {
        setLoading(false);
        setGoogleLogin(false);
        const errData = err.response !== 'undefined' ? err?.response?.data?.errors : '';
        Object.keys(err?.response?.data?.errors).forEach((name) => {
          let message = errData[name][0];
          setError(name, { type: 'manual', message });
          // if (name === "username") {
          //   setCurrentFormField(8);
          // }
          if (name === 'email') {
            setCurrentFormField(5);
          }
        });
      });
  };

  return (
    <div className="SignUpAff8">
      <form className="form" onSubmit={(e) => e.preventDefault(e)}>
        {renderModal(stepList[currentFormField])}
      </form>
    </div>
  );
};

export default SignUpAff8;
