import './Email.scss';

import { useState } from 'react';

import GoogleLoginButton from '../../../../../../../../components/GoogleLoginButton/GoogleLoginButton';
import LanderChat from '../../LanderChat/LanderChat';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Email({
  setValue,
  errors,
  getValues,
  clearErrors,
  emailLoading,
  handleNextStep,
  setGoogleLogin,
  handleGoogleLogin,
  landerId
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = [
    { content: 'Let me know your email so I can keep you updated about new matches!' }
  ];

  const submit = async () => {
    await handleNextStep(['email']);
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat messages={messages} endChatAction={toggleElementVisibility} />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer">
          <Input
            placeholder="Email"
            name="email"
            type="email"
            value={getValues('email')}
            onChange={() => {
              setGoogleLogin(false);
            }}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
        {landerId.includes('10sso') && <p className="DividerText">OR</p>}
        <div className="EmailButtonsContainer">
          {landerId.includes('10sso') && (
            <GoogleLoginButton text="Register with Google" handleGoogleLogin={handleGoogleLogin} />
          )}
          <Button handleNextStep={() => submit()} text="NEXT" loading={emailLoading} />
        </div>
      </div>
    </>
  );
}
