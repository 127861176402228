import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createPromise } from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import rootReducer from './reducers/rootReducer.js';

export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(createPromise(), thunk))
);

// export default createStore(
//   rootReducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//   // composeWithDevTools(applyMiddleware, thunk))
// );
