import './MessagePopup.scss';

import { notification } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import sound from '../../assets/audio/notification.mp3';
import NOTIFICATIONICON from '../../assets/images/notification_icon.png';
import { showMessagesPage } from '../../pages/MainView/components/MembersAreaMain/navigation.service';
import { setNewMessage } from '../../redux/actions/newMessages';
import { getProfileById } from '../../services/profile-service/profile.service';
import ImageComponent from '../ImageComponent/ImageComponent';

const MessagePopup = (props) => {
  const { className } = props;
  const { latestMessage } = useSelector((state) => state);
  const classes = ['messagePopup', className];

  const dispatch = useDispatch();

  useEffect(() => {
    let senderData;
    if (
      latestMessage?.popUp === true &&
      !window.location.pathname.includes('/members-area/messages')
    ) {
      getProfileById(latestMessage?.sender_profile_id)
        .then((res) => {
          senderData = res;
          const key = `open${Date.now()}`;
          const yearsOld = new Date().getFullYear() - senderData.data.data.birthday.slice(0, 4);
          const handleClick = (latestMessage) => {
            showMessagesPage(senderData?.data.data.id);
            notification.destroy(key);
            dispatch(setNewMessage({ ...latestMessage, popUp: false }));
          };

          const message = (
            <div className="popupMessageText" onClick={handleClick}>
              You received a New Message!
            </div>
          );

          const description = (
            <div className="popupMessageTextProfile" onClick={handleClick}>
              <div className="iconContainer" onClick={handleClick}>
                <ImageComponent url={NOTIFICATIONICON} />
              </div>
              <div className="messageContainer" onClick={handleClick}>
                <div className="popupMessageText">You received a New Message!</div>
                From {senderData.data.data.username}, {yearsOld}
                <span onClick={handleClick}>Go to your inbox &rarr; </span>
              </div>
            </div>
          );
          const messageNotification = {
            // message,
            key,
            description,
            placement: 'topRight',
            className: 'custom-class',
            duration: 6.5,
            onClick: handleClick
          };

          notification.open(messageNotification);

          const audio = new Audio(sound);
          const playPromise = audio.play();

          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                console.log('audio played auto');
              })
              .catch((error) => {
                console.log('playback prevented');
              });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [latestMessage]);

  return <div className={classes.join(' ')}></div>;
};

export default MessagePopup;
