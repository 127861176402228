import './HorizontalOption.scss';

import React from 'react';

export default function HorizontalOption({ title, backgroundImage, onClick, disabled, landerId }) {
  const optionClassName = `HorizontalOption${disabled ? ' HorizontalOptionDisabled' : ''}`;
  const horizontalOverlayClassName = `BackgroundOverlay12 version${landerId}`;

  return (
    <div
      className={optionClassName}
      onClick={onClick}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={horizontalOverlayClassName}></div>
      <span>{title}</span>
    </div>
  );
}
