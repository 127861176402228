const initialState = {
  welcomeMessage: true,
  mobileView: false
};

const welcomeMessage = (state = initialState, action) => {
  if (action.type === 'SHOW_WELCOME_MESSAGE') {
    return { ...state, welcomeMessage: action.payload };
  }
  if (action.type === 'SET_MOBILE_VIEW') {
    return { ...state, mobileView: action.payload };
  }
  return state;
};

export default welcomeMessage;
