import './VerticalOption.scss';

export default function VerticalOption({ title, backgroundImage, onClick, isActive }) {
  return (
    <div className={`VerticalOption18 ${isActive ? 'VerticalOptionActive' : ''}`} onClick={onClick}>
      {backgroundImage && (
        <div className="ThumbImageContainer">
          <img src={backgroundImage} />
        </div>
      )}
      <span className={!backgroundImage && 'FullText'}>{title}</span>
    </div>
  );
}
