import './MessageInput.scss';

import { Emoji, Picker } from 'emoji-mart';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as AttchImage } from '../../../../../../../../assets/images/attachment_icon.svg';
import { ReactComponent as RemoveImage } from '../../../../../../../../assets/images/Modal/Close-Mobile.svg';
import SENDICON from '../../../../../../../../assets/images/send_icon.svg';
import ImageComponent from '../../../../../../../../components/ImageComponent/ImageComponent';
import { setNewConversationId } from '../../../../../../../../redux/actions/messageConversationId';
import { setNewConversationStatus } from '../../../../../../../../redux/actions/messageConversationStatus';
import { CURRENT_USER_CREDITS } from '../../../../../../../../redux/types/credits';
import {
  getNewConversationsInbox,
  getSingleConversationByID,
  saveFailedMessages,
  sendMessage
} from '../../../../../../../../services/message-services/message.services';
import { getToken } from '../../../../../../../../services/token-service/token.service';
import { getUserData } from '../../../../../../../../services/user-service/user.service';

let stylesButton = {
  opacity: '0.5',
  pointerEvents: 'none'
};

const MessageInput = (props) => {
  const {
    getMessage,
    starter,
    getStarter,
    openModal,
    notEnoughCredistRef,
    purchaseCreditsRef,
    setNotEnoughCredits,
    profileID,
    conversationID,
    getMesages,
    setConversation,
    selectedProfileData,
    getProfileID,
    getScrollToTopRefresh,
    scrollToTopRefresh
  } = props;
  const messageInput = useRef();
  const uploadImage = useRef();
  const userData = getUserData();
  const [myMessageValue, getMyMessage] = useState('');
  const [convMessages, setConvMessages] = useState();
  const [emojiPickerState, SetEmojiPicker] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [sendImage, setSendImage] = useState();
  const { credits } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { newConversationId } = useSelector((state) => state);

  useEffect(() => {
    getMyMessage(starter);
  }, [starter]);

  const sendMessageFailed = (err) => {
    if (!convMessages) {
      getNewConversationsInbox(getToken)
        .then(getConversationsSuccess)
        .catch(getConversationsFailed);
    }
  };

  const getConversationsFailed = (err) => {
    console.log(err);
  };

  const getConversationsSuccess = (res) => {
    getScrollToTopRefresh(scrollToTopRefresh + 1);
    if (window.innerWidth < 1024) {
      const getConversationFailed = (err) => {
        console.log(err);
      };

      const getConversationSuccess = (res) => {
        getMesages(res.data.messages.data);
        setConversation(res.data.messages.data);
        // dispatch(setNewConversationStatus(true));
        setConvMessages(res.data.messages.data);
      };
      getSingleConversationByID(newConversationId?.conversationId, getToken)
        .then(getConversationSuccess)
        .catch(getConversationFailed);
    }
    getProfileID(profileID);
  };

  const updateCredits = () => {
    const currentCredits = userData?.credits;
    const dataFromLocalStorage = JSON.parse(localStorage.getItem('userData'));
    const newDataFromLocalStorage = {
      ...dataFromLocalStorage,
      credits: currentCredits > 0 && currentCredits - 1
    };
    localStorage.setItem('userData', JSON.stringify(newDataFromLocalStorage));
  };

  const sendNewMessageSuccess = (res) => {
    removeSendImage();
    getMyMessage('');
    getStarter('');
    updateCredits();
    dispatch({
      type: CURRENT_USER_CREDITS,
      payload: credits.currentUserCredits > 0 && credits.currentUserCredits - 1
    });
    //getRefreshConvList(refreshConvList + 1);

    const getConversationFailed = (err) => {
      console.log(err);
    };

    const getConversationSuccess = (res) => {
      setConversation(res.data.messages.data);
      dispatch(setNewConversationStatus(true));
      setConvMessages(res.data.messages.data);
    };
    dispatch(setNewConversationId(res.data.data.conversation_id));
    getSingleConversationByID(res.data.data.conversation_id, getToken)
      .then(getConversationSuccess)
      .catch(getConversationFailed);
  };
  const isCharge = () => {
    // const purchaseCredit = userData?.credit_history.filter(
    //   (credit) => credit.action === 'purchase',
    // );
    // return purchaseCredit?.length > 0;
    return userData?.credit_history.length > 0;
  };
  const charge = isCharge();

  const sendMessageData = () => {
    var today = new Date();
    getMessage('');
    const redirectUrl = window.location.toString();
    localStorage.setItem('redirect_url', redirectUrl + localStorage.getItem('userId'));

    if (credits.currentUserCredits > 0) {
      messageInput.current.value = '';
      getMyMessage('');
      setNotEnoughCredits && setNotEnoughCredits(false);
      sendMessage(selectedProfileData?.id, myMessageValue, sendImage, getToken)
        .then(sendNewMessageSuccess)
        .catch(sendMessageFailed);
    } else {
      messageInput.current.value = '';
      setNotEnoughCredits && setNotEnoughCredits(true);
      getMessage(myMessageValue);
      // getTime(today);
      charge ? openModal(purchaseCreditsRef) : openModal(notEnoughCredistRef);

      saveFailedMessages(myMessageValue, today);
      sendMessage(selectedProfileData?.id, myMessageValue, sendImage, getToken)
        .then(sendNewMessageSuccess)
        .catch(sendMessageFailed);
    }
  };

  const myMessage = (e) => {
    var keyCode = e.hasOwnProperty('which') ? e.which : e.keyCode;
    if (keyCode !== 13) {
      getMyMessage(e.target.value);
    } else {
      e.preventDefault();
      sendMessageData();
      // getMyMessage();
    }
  };

  const handleChange = (e) => {
    let strings = /^\D{0,}\d{0,2}(\.\d{0,0})?\D{0,}$/;
    if (strings.test(e.target.value)) {
      getMyMessage(e.target.value);
    }
  };

  const triggerPicker = () => {
    SetEmojiPicker(!emojiPickerState);
  };

  const selectEmoji = (emoji) => {
    getMyMessage(myMessageValue ? myMessageValue + emoji.native : '' + emoji.native);
    SetEmojiPicker(!emojiPickerState);
  };

  let emojiPicker;
  if (emojiPickerState) {
    emojiPicker = (
      <Picker
        title="Pick your emoji…"
        emoji="point_up"
        onSelect={(emoji) => selectEmoji(emoji)}
        style={{ position: 'absolute', bottom: '20px', left: '70px' }}
      />
    );
  }

  const uploadImageToChat = () => {
    uploadImage.current.click();
  };

  const imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);

    const formData = new FormData();
    const fileArray = Array.from(e.target.files);
    formData.append('image', fileArray[0]);
    setSendImage(formData);
  };

  const removeSendImage = () => {
    setSendImage();
    setPreviewImage();
  };

  useEffect(() => {
    if (starter !== '' && window.innerWidth > 500) {
      messageInput.current.focus();
    }
  }, [starter]);

  return (
    <div id="MsgInput" className="MessageInput">
      {previewImage ? (
        <div className="UploadImagePreviewContainer">
          <ImageComponent url={previewImage} />
          <RemoveImage className="removeImage" onClick={removeSendImage} />
        </div>
      ) : (
        ''
      )}
      <div className="InputMessage">
        <textarea
          autoFocus={false}
          onKeyDown={myMessage}
          ref={messageInput}
          value={myMessageValue}
          onChange={handleChange}
          placeholder="Type your message"
          className="TextArea"
        />
      </div>
      <div className="ButtonsContainer">
        <div className="AttachmentContainer">
          <input
            ref={uploadImage}
            type="file"
            name="imageUpload"
            accept="image/jpeg, image/png"
            onChange={imageHandler}
            hidden
          />
          <AttchImage className="AttachmentIcon" onClick={uploadImageToChat} />
          <Emoji
            className="EmojiIcon"
            emoji={{ id: 'grinning', skin: 3 }}
            size={20}
            onClick={triggerPicker}
          />
          {emojiPicker}
        </div>
        <div style={!myMessageValue ? stylesButton : {}} className="SendMessageButtonContainer">
          <ImageComponent url={SENDICON} onClick={sendMessageData} />
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
