import { Controller } from 'react-hook-form';
import Select from 'react-select';

export function CountdownSelect({ defaultValue, options, control, name, onSelect }) {
  const selectClassNames = {
    control: () => 'CountdownSelect',
    menu: () => 'CountdownSelectMenu',
    valueContainer: () => 'CountdownSelectValue',
    option: ({ isFocused, isSelected }) =>
      `CountdownSelectOption ${isFocused ? 'CountdownSelectOptionFocused' : ''} 
      ${isSelected ? 'CountdownSelectOptionSelected' : ''}`
  };

  const selectComponents = {
    IndicatorSeparator: () => null
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Select
          components={selectComponents}
          classNames={selectClassNames}
          options={options}
          value={options.find((c) => c.value === value)}
          onChange={(val) => {
            onChange(val.value);
            onSelect && onSelect(val);
          }}
        />
      )}
    />
  );
}
