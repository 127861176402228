import './Button.scss';

import BaseButton from '../../../../../../../../components/Button/Button';

export default function Button({ text, loading, handleNextStep, disabled = false }) {
  return (
    <BaseButton
      className="Button RoundButton15"
      text={text}
      loading={loading}
      onClick={handleNextStep}
      disabled={disabled}
    />
  );
}
