import './Age.scss';

import React, { useEffect, useState } from 'react';

import { InputNumber } from '../../../../../Inputs';
import LanderChat from '../../LanderChat/LanderChat';

export default function Age({
  typeOnlyNumbers,
  errors,
  setValue,
  clearErrors,
  setIsValid,
  watch,
  landerId,
  register
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = {
    '14-1': [{ content: 'That’s really great. How old are you?' }],
    '14-2': [{ content: 'That’s really great. How old are you?' }],
    '14-3': [{ content: 'That’s really great. How old are you?' }],
    '14-4': [{ content: 'That’s really great. How old are you?' }]
  };

  const age = watch('age');

  useEffect(() => {
    setIsValid(!!age);
  }, [age, setIsValid]);

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat
        landerId={landerId}
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
      />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer">
          <div className="InputContainer">
            <InputNumber
              name="age"
              {...register('age')}
              placeholder="Age"
              errorMessage={errors?.age?.message}
            />
          </div>
        </div>
      </div>
    </>
  );
}
