/* eslint-disable react/no-unescaped-entities */

import './EmailVerificationInstruction.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import DEFAULTINBOX from '../../../../assets/images/EmailInstruction/email_gmail_inbox.png';
import DEFAULTSPAM from '../../../../assets/images/EmailInstruction/email_gmail_spam.png';
import HOTMAILTINBOX from '../../../../assets/images/EmailInstruction/email_outlook_inbox.png';
import HOTMAILSPAM from '../../../../assets/images/EmailInstruction/email_outlook_spam.png';
import YAHOOINBOX from '../../../../assets/images/EmailInstruction/email_yahoo_inbox.png';
import YAHOOSPAM from '../../../../assets/images/EmailInstruction/email_yahoo_spam.png';
import HEADERIMAGE from '../../../../assets/images/HeadersImages/Contact-Header.png';
import Button from '../../../../components/Button/Button';
import { Form } from '../../../../components/Form/Form';
import Input from '../../../../components/Form/Input/Input';
import ImageComponent from '../../../../components/ImageComponent/ImageComponent';
import SuccessMessage from '../../../../components/SuccessMessage/SuccessMessage';
import { useAuthMeQuery } from '../../../../services/authHooks/useAuthMe';
import { getToken } from '../../../../services/token-service/token.service';
import { resendVerificationEmail } from '../../../../services/user-service/user.service';

const schema = yup.object().shape({
  email: yup.string().required('Email is required!').email('Invalid Email Address')
});

const EmailVerificationInstruction = () => {
  const { data: user } = useAuthMeQuery();
  const resendEmail = useRef(null);
  const [inboxImage, getInboxImage] = useState();
  const [spamImage, getSpamImage] = useState();
  const [showSuccesMessage, setShowSuccesMessage] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get('userToken');
  const emailFromQuery = queryParameters.get('current-email');
  const encodedEmail = atob(token).includes('email') ? JSON.parse(atob(token)).email : '';
  const userEmail = emailFromQuery ?? localStorage.getItem('current-email') ?? encodedEmail;
  const userUsername = null; //getUserData().username;
  const address = userEmail?.split('@').pop();
  const [additionalErr, setAdditionalErr] = useState();
  const [mailProvider, setMailProvider] = useState();

  useEffect(() => {
    const pattern = /@(.+)$/;

    const match = userEmail?.match(pattern);

    if (match) {
      const domain = match[1];
      setMailProvider(domain);
    } else {
    }
  }, [userEmail]);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    localStorage.removeItem('current-email');
    localStorage.setItem('current-email', userEmail);
  });

  useEffect(() => {
    switch (address) {
      case 'hotmail.com':
      case 'outlook.com':
        getInboxImage(HOTMAILTINBOX);
        return getSpamImage(HOTMAILSPAM);
      case 'yahoo.com':
        getInboxImage(YAHOOINBOX);
        return getSpamImage(YAHOOSPAM);
      default:
        getInboxImage(DEFAULTINBOX);
        return getSpamImage(DEFAULTSPAM);
    }
  }, [address]);

  const resendEmailFailed = (err) => {
    setShowSuccesMessage(false);
    setAdditionalErr(err.response.data.message);
  };

  const resendEmailSuccess = () => {
    setShowSuccesMessage(true);
    setAdditionalErr('');
  };

  const onSubmit = (data) => {
    setAdditionalErr('');
    setShowSuccesMessage(false);
    resendVerificationEmail(data, getToken()).then(resendEmailSuccess).catch(resendEmailFailed);
  };
  const getUserMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    // Check for Android
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    return 'Unknown';
  };
  const redirectToEmail = () => {
    switch (mailProvider) {
      case 'gmail.com':
        return `https://mail.google.com/mail/u/0/#search/from%3ALocalFlirt+in%3Aall+OR+in%3Aspam+subject%3Averification`;
      case 'yahoo.com':
        return `https://www.${address}`;
      case 'hotmail.com':
        return `https://www.${address}`;
      case 'outlook.com':
        return `https://www.${address}`;
      default:
        return undefined;
    }
  };
  const renderButton = () => {
    return redirectToEmail() ? (
      <Button
        className="CheckYourEmailButton"
        type="button"
        onClick={() => window.open(redirectToEmail(), '_blank')}
        text="Check Your Email"
      />
    ) : (
      <p className="EmailVerification-Description">Remember to check your spam folder</p>
    );
  };

  return (
    <div className="EmailVerificationInstruction">
      <div className="EmailVerification-HeaderContainer">
        <ImageComponent url={HEADERIMAGE} className="EmailVerification-HeaderImage" />
        <div className="Header-Text">
          <div className="EmailVerification-Header">Email Verification Instructions</div>
          <p className="EmailVerification-Description">
            You are just one step away from having full acces to LocalFlirt. An email has been sent
            to
            <span> {userEmail} </span>with a verification link. Please click the link in order to
            complete the registration.
          </p>
          <div className="CheckYourEmailButton-Container">{renderButton()}</div>
        </div>
      </div>
      <div className="EmailVerification-Container">
        <div className="HowToVerify">
          <div className="HowToVerify-Container Container">
            <div className="ImageContainer">
              <ImageComponent url={inboxImage} className="EmailVerification-Image" />
            </div>
            <div className="InfoContainer">
              <div className="InfoTitle">How to Verify Your Account</div>
              <div className="InfoDescription">
                Open your email client and there you will find an email from us containing a link.
              </div>
              <div className="InfoDescription">
                Click on the link within the email and your account will then get verified within a
                few seconds!
              </div>
            </div>
          </div>
        </div>
        <div className="HaventReceivedEmail">
          <div className="HaventReceivedEmail-Container Container">
            <div className="InfoContainer">
              <div className="InfoTitle">Haven't received the verification email?</div>
              <div className="InfoDescription">
                Make sure to double check if it has ended up in the junk folder. You can find it in
                this folder by checking here.
              </div>
            </div>
            <div className="ImageContainer">
              <ImageComponent url={spamImage} className="EmailVerification-Image" />
            </div>
          </div>
        </div>
        <div className="StillHaventReceivedEmail-Container">
          <div className="StillHaventReceivedEmail Container">
            <div className="StillHaventReceivedEmailTitle">Still no email?</div>
            <div className="StillHaventReceivedEmailDescription">
              Then drop us an email at{' '}
              <a
                className="Support-Text"
                href="mailto:support@localflirt.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@localflirt.com
              </a>{' '}
              and we will make sure to help you!
            </div>
            <div className="StillHaventReceivedEmailDescription">
              Have you entered the wrong email address? No worries, just enter the correct email
              address below and we will send you a new verification email.
            </div>
            <div className="SendEmailAgain">
              <Form className="SendEmailAgainForm" onSubmit={handleSubmit(onSubmit)}>
                <Input
                  register={register}
                  className="SendEmailAgainInput"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  errorMessage={errors?.email?.message}
                  additionalError={additionalErr}
                />
                <input type="submit" ref={resendEmail} hidden />
                <Button
                  className="SendEmailAgainButton"
                  type="submit"
                  onClick={() => {
                    resendEmail.current.click();
                  }}
                  text="Send"
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
      <SuccessMessage
        showMessage={showSuccesMessage}
        setShowMessage={setShowSuccesMessage}
        message="A verification email has been sent to you"
      />
    </div>
  );
};

export default EmailVerificationInstruction;
