import { CSSProperties, FC } from 'react';

const LimitedOfferIcon: FC<{ style?: CSSProperties; fill?: string; className?: string }> = ({
  style,
  fill = '#1CB23D',
  className
}) => {
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <svg
        width="51"
        height="51"
        viewBox="0 0 51 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="25.5" cy="25.5" r="25.5" fill={fill} />
        <rect x="8.30176" y="22.887" width="8.3522" height="1.78976" rx="0.894879" fill="white" />
        <rect x="8.30176" y="30.046" width="8.3522" height="1.78976" rx="0.894879" fill="white" />
        <rect x="11.2842" y="26.4666" width="5.36927" height="1.78976" rx="0.894879" fill="white" />
        <path
          d="M32.4378 11.8606H28.6878C28.192 11.8606 27.7891 12.2635 27.7891 12.7594C27.7891 13.2552 28.192 13.6581 28.6878 13.6581H32.4378C32.9337 13.6581 33.3366 13.2552 33.3366 12.7594C33.3366 12.2635 32.9337 11.8606 32.4378 11.8606Z"
          fill="white"
        />
        <path
          d="M42.4493 17.7799L40.0009 15.3316C39.66 14.9907 39.1022 14.9907 38.7303 15.3316C38.3894 15.6725 38.3894 16.2304 38.7303 16.6023L41.1477 19.0196C41.3336 19.2056 41.5506 19.2676 41.7675 19.2676C41.9844 19.2676 42.2324 19.1746 42.3873 19.0196C42.7902 18.6787 42.7902 18.1209 42.4493 17.7799Z"
          fill="white"
        />
        <path
          d="M30.5472 15.6104C24.3488 15.6104 19.3281 20.631 19.3281 26.8294C19.3281 33.0278 24.3488 38.0485 30.5472 38.0485C36.7456 38.0485 41.7662 33.0278 41.7662 26.8294C41.7662 20.631 36.7456 15.6104 30.5472 15.6104ZM35.1959 28.0381H30.5472C30.0513 28.0381 29.6484 27.6352 29.6484 27.1393V21.3129C29.6484 20.817 30.0513 20.4141 30.5472 20.4141C31.0431 20.4141 31.446 20.817 31.446 21.3129V26.2716H35.1959C35.6918 26.2716 36.0947 26.6745 36.0947 27.1703C36.0947 27.6352 35.6918 28.0381 35.1959 28.0381Z"
          fill="white"
        />
      </svg>
    </svg>
  );
};
export default LimitedOfferIcon;
