import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import { axiosInstance } from '../../axios/axiosInstance';
import { userData } from '../../recoil/atoms/userDataAtom';
// import useCookie from '../../util/useCookie';

const url = '/api/users/me';

export const useAuthMe = () => {
  const [, setData] = useRecoilState(userData);

  const mutation = useMutation(async () => {
    const { data } = await axiosInstance.get(url);
    setData(data);
    return data;
  });

  return { ...mutation, isLoading: mutation.isLoading };
};

export const useAuthMeQuery = () => {
  const [, setData] = useRecoilState(userData);
  // const [cookie, setCookie, updateCookie] = useCookie('platform-data');
  return useQuery(
    ['ME'],
    async () => {
      const { data } = await axiosInstance.get(url);
      setData(data.data);
      // TODO CANARY SWITCH
      // if (!cookie) {
      //   setCookie(data.data.platform_version, 30);
      //   location.reload();
      // } else if (cookie !== data.data.platform_version.toString()) {
      //   updateCookie(data.data.platform_version, 30);
      //   location.reload();
      // }
      return data.data;
    },
    {
      staleTime: Infinity
    }
  );
};
