import './PasswordReset.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { resetUserPassword } from '../../services/user-service/user.service';
import Button from '../Button/Button';
import { Form } from '../Form/Form';
import Input from '../Form/Input/Input';
import LoaderComponent from '../LoaderCompoment/LoaderComponent';
import SuccessMessage from '../SuccessMessage/SuccessMessage';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required!')
    .min(4, 'Must be between 4 to 16 characters')
    .max(16, 'Must be between 4 to 16 characters'),
  password_confirmation: yup
    .string()
    .required('Confirm password is required!')
    .min(4, 'Must be between 4 to 16 characters')
    .max(16, 'Must be between 4 to 16 characters')
});

const PasswordReset = (props) => {
  const { token, username, email, openModal, closeModal, modalRef, successMessageRef } = props;
  const submitRef = useRef(null);
  const [passwordDontMatch, getLoginInvalidError] = useState('');
  const [loading, isLoading] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const resetPassword = (data) => {
    isLoading(true);
    resetUserPassword(data, token, email).then(resetPasswordSuccess).catch(resetPasswordFailed);
  };

  const resetPasswordFailed = (error) => {
    isLoading(false);
    console.log(error);
    setShowFailureMessage(true);
  };

  const resetPasswordSuccess = () => {
    isLoading(false);
    closeModal(modalRef);
    openModal(successMessageRef);
  };

  const onSubmit = (data) => {
    getLoginInvalidError();
    data.password === data.password_confirmation
      ? resetPassword(data)
      : getLoginInvalidError('Passwords don`t match');
  };

  const renderLoader = () => {
    return (
      <div className="Loading">
        <LoaderComponent />
      </div>
    );
  };

  return (
    <div className="PasswordReset">
      {showFailureMessage ? (
        <SuccessMessage
          className="Failure-Message"
          message="To change your password again please request the Reset Password email once more"
          showMessage={showFailureMessage}
          setShowMessage={setShowFailureMessage}
        />
      ) : null}
      {loading && renderLoader()}
      <div className="Title">Password Reset</div>
      <div className="Subtitle">Hello {username}, enter your new password below:</div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          register={register}
          type="password"
          name="password"
          placeholder="New Password"
          errorMessage={errors?.password?.message}
        />
        <Input
          register={register}
          type="password"
          name="password_confirmation"
          placeholder="Confirm password"
          errorMessage={errors?.password_confirmation?.message}
          additionalError={passwordDontMatch}
        />
        <input type="submit" ref={submitRef} hidden />
        <Button
          className="SubmitButton"
          type="submit"
          onClick={() => {
            submitRef.current.click();
          }}
          text="Submit"
        />
      </Form>
    </div>
  );
};

export default PasswordReset;
