import * as actionTypes from '../types/subscriptionTriggers.js';

const initialState = {
  subscription_trigger: '',
  profile_id: null,
  redirect_url: ''
};

const subscriptionTriggers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TRIGGER:
      return {
        ...state,
        ...action.payload
      };
    case actionTypes.RESET_TRIGGER:
      return { ...initialState };
    default:
      return { ...state };
  }
};

export default subscriptionTriggers;
