import './ModalDialog.scss';

import React, { forwardRef, useImperativeHandle, useState } from 'react';

import CLOSE from '../../../assets/images/Modal/Close.png';
import CLOSE_DARK from '../../../assets/images/Modal/Close-Mobile.svg';
import ImageComponent from '../../ImageComponent/ImageComponent';
import ModalLogo from '../ModalLogo/ModalLogo';

const ModalDialog = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(props.active || false);
  const [actions, setActions] = useState(true);

  useImperativeHandle(ref, () => {
    return {
      openModal: () => open(),
      closeModal: () => close(),
      disableActions: (loading) => disableActions(loading)
    };
  });

  const disableActions = (loading) => {
    setActions(!loading);
  };

  const open = () => {
    setDisplay(true);
  };

  const close = () => {
    !props.disableClose && setDisplay(false);
    if (props.handleClose) {
      props.handleClose();
    }
  };

  if (display) {
    const classes = ['ModalContainer'];
    const backgroundClasses = ['Background'];
    if (props.className) classes.push(props.className);

    if (!actions) {
      classes.push('disabled');
      backgroundClasses.push('disabled');
    }

    const closeImg = props.className === 'PaymetnConfirmWrapper' ? CLOSE_DARK : CLOSE;

    return (
      <div className="ModalDialog">
        <div className={backgroundClasses.join(' ')} onClick={close}></div>
        <div className={classes.join(' ')} style={props.style}>
          {!props.disableClose && (
            <ImageComponent className="CloseButton" url={closeImg} onClick={close} />
          )}
          {!props.hideLogo && <ModalLogo />}
          {props.children}
        </div>
      </div>
    );
  }

  return null;
});

export default ModalDialog;
