import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';
import { PreferenceData } from '../../model/interfaces/PreferenceData';

export const key = ['ENUM_PREFERENCE'];

export const useInterfacePreferenceData = () =>
  useQuery([...key], async (): Promise<PreferenceData> => {
    const { data } = await axiosInstance.get('api/users/enum/preferences');
    return data;
  });
