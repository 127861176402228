import { CSSProperties, FC } from 'react';

const StarIcon: FC<{ style?: CSSProperties }> = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      style={style}
    >
      <g clipPath="url(#clip0_1157_7120)">
        <path
          d="M33.1978 12.5016L22.185 11.4802L17.8105 1.32215C17.5042 0.610877 16.4957 0.610877 16.1894 1.32215L11.8149 11.4803L0.802205 12.5016C0.0310968 12.5731 -0.280547 13.5322 0.301237 14.0433L8.61037 21.3428L6.17871 32.1322C6.00844 32.8876 6.82431 33.4804 7.49016 33.085L17 27.4382L26.5098 33.085C27.1757 33.4804 27.9916 32.8876 27.8213 32.1322L25.3896 21.3428L33.6987 14.0433C34.2805 13.5322 33.9689 12.5731 33.1978 12.5016Z"
          fill="#EFB025"
        />
        <path
          d="M17.8105 1.32215C17.5042 0.610877 16.4957 0.610877 16.1894 1.32215L11.8149 11.4803L0.802205 12.5016C0.0310968 12.5731 -0.280547 13.5322 0.301237 14.0433L8.61037 21.3428L6.17871 32.1322C6.00844 32.8876 6.82431 33.4804 7.49016 33.085L9.6127 31.8247C9.90648 19.7321 15.5251 11.2163 19.9735 6.34485L17.8105 1.32215Z"
          fill="#EFB025"
        />
      </g>
      <defs>
        <clipPath id="clip0_1157_7120">
          <rect width="34" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StarIcon;
