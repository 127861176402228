import './Input.scss';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

const Input = ({ register, name, errorMessage, additionalError, className, type, ...rest }) => {
  const classes = ['Input', className];
  const [seePassword, setSeePassword] = useState(type !== 'password');

  return (
    <div className="InputField">
      <div className="see-password">
        {type === 'password' && (
          <span className="eye-icon" onClick={() => setSeePassword(!seePassword)}>
            {seePassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          </span>
        )}
        <input
          name={name}
          className={classes.join(' ')}
          {...register(name)}
          {...rest}
          type={seePassword && type === 'password' ? `text` : `${type}`}
        />
      </div>
      <div className={errorMessage ? 'Error-Container Active' : 'Error-Container'}>
        {errorMessage ? errorMessage : additionalError}
      </div>
    </div>
  );
};

export default Input;
