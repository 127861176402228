import { useQuery } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';

import { axiosInstance } from '../../../axios/axiosInstance';
import { userData } from '../../../recoil/atoms/userDataAtom';
import { urls } from '../../../services/auth-service/auth.service';
import { setUserLocalData } from '../../../services/user-service/user.service';
import { ApiResponseBasicProfile } from '../../../V2/model/interfaces/MeUserData';

export interface UserProfile {
  id: number;
  username: string;
  email: string;
  terms_and_conditions: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  email_verified_at: string | null;
  auto_verified_at: string | null;
  click_id: number | null;
  freshchat_restore_id: string | null;
  has_spent_before: boolean;
  user_has_card_removed: boolean;
  profile: {
    id: number;
    username: string;
    real_name: string | null;
    gender: string;
    seeking: string;
    city: string;
    zip_code: string;
    birthday: string;
    user_id: number;
    description: string | null;
    profile_image: string | null;
    body_build: string | null;
    hair_color: string | null;
    eye_color: string | null;
    interests: string[];
    personal_interests: string[];
    relationship_interest: string | null;
    ethnicity: string | null;
    civil_status: string | null;
    height: string | null;
    online_status: number;
    show_online_status: number;
    private_location: number;
    avatar_image: string | null;
  };
  favorite_profiles: any[]; // Define the type for favorite_profiles as needed
  credits: number;
  totalCreditsBought: number;
  location_confirmed: number;
  activity_score: number;
  last_active_at: string;
  premium: boolean;
  hasPremiumSubscription: boolean;
  roles: {
    id: number;
    name: string;
  }[];
  billing_date: string | null;
  credit_history: {
    amount: number;
    action: string;
    issuer: string | null;
    created_at: string;
  }[];
  unread_visitor_notifications: number;
  unread_message_notifications: number;
}
export const key = ['USER_DATA'];
export const useGetUserData = () => {
  // const [cookie, setCookie, updateCookie] = useCookie('platform-version');
  const setUserData = useSetRecoilState(userData);
  return useQuery(
    key,
    async (): Promise<ApiResponseBasicProfile> => {
      const { data } = await axiosInstance.get(urls.me);
      return data;
    },
    {
      onSuccess: (data) => {
        setUserLocalData(data);
        setUserData(data);
        // TODO CANARY SWITCH
        // if (!cookie) {
        //   setCookie(data.data.platform_version.toString(), 30);
        //   location.reload();
        // } else if (cookie !== data.data.platform_version.toString()) {
        //   setCookie(data.data.platform_version.toString(), 30);
        //   location.reload();
        // }
      }
    }
  );
};
