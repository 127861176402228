import './Username.scss';

import React, { useEffect, useState } from 'react';

import LanderChat from '../../LanderChat/LanderChat';
import Input from '../../UI/Input/Input';

export default function Username({
  setValue,
  errors,
  clearErrors,
  getValues,
  watch,
  landerId,
  setIsValid
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = {
    '14-1': [{ content: 'Thank you, handsome! Now enter your Username:' }],
    '14-2': [{ content: 'Thank you, handsome! Now enter your Username:' }],
    '14-3': [{ content: 'Thank you, handsome! Now enter your Username:' }],
    '14-4': [{ content: 'Thank you, handsome! Now enter your Username:' }]
  };

  const username = watch('username');

  useEffect(() => {
    setIsValid(!!username);
  }, [username, setIsValid]);

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat
        landerId={landerId}
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
      />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer">
          <Input
            placeholder="Username"
            name="username"
            value={getValues('username')}
            status={errors.username?.message ? 'error' : ''}
            onChange={(e) => {
              setValue('username', e.target.value);
              clearErrors('username');
            }}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
      </div>
    </>
  );
}
