import './Protection.scss';

import React, { useState } from 'react';

import LanderChat from '../../LanderChat/LanderChat';

export default function Protection({ setIsValid, landerId }) {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const [activeOption, setActiveOption] = useState(null);

  const messages = {
    '14-1': [{ content: 'Do you agree to use a condom when having sex?' }],
    '14-2': [{ content: 'Do you agree to use a condom when having sex?' }],
    '14-3': [{ content: 'Do you agree to use a condom when having sex?' }],
    '14-4': [{ content: 'Do you agree to use a condom when having sex?' }]
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(true);
  };

  const handleOptionClick = (option) => {
    setActiveOption(option);
    setIsValid(true);
  };

  return (
    <div className="RelationshipContainer">
      <LanderChat
        messages={messages[`${landerId}`]}
        landerId={landerId}
        endChatAction={toggleElementVisibility}
      />
      <div
        className={
          isElementVisible
            ? 'ToggleElement SelectOptions ProtectionOptions'
            : 'Hidden SelectOptions ProtectionOptions'
        }
      >
        <button
          className={`ProtectionOptionButton ${
            activeOption === 'No' ? 'ProtectionOptionButtonActive' : ''
          }`}
          onClick={() => handleOptionClick('No')}
        >
          No
        </button>
        <button
          className={`ProtectionOptionButton ${
            activeOption === 'Yes' ? 'ProtectionOptionButtonActive' : ''
          }`}
          onClick={() => handleOptionClick('Yes')}
        >
          Yes
        </button>
        {/* <VerticalOption
          title="Casual Sex"
          backgroundImage={casualSexBg}
          onClick={() => handleOptionClick('Casual Sex')}
          isActive={activeOption === 'Casual Sex'}
        />
        <VerticalOption
          title="Couple of dates"
          backgroundImage={coupleOfDates}
          onClick={() => handleOptionClick('Couple of dates')}
          isActive={activeOption === 'Couple of dates'}
        />
        <VerticalOption
          title="Serious Relationships"
          backgroundImage={seriousRelationship}
          onClick={() => handleOptionClick('Serious Relationships')}
          isActive={activeOption === 'Serious Relationships'}
        /> */}
      </div>
    </div>
  );
}
