import './MyProfile.scss';

import { Modal, notification, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import Button from '../../../components/Button/Button';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import { userData as userRecoil } from '../../../recoil/atoms/userDataAtom';
import FreeCreditsModal from '../../components/freeCreditsModal/FreeCreditsModal';
import { TipInfoComponentMobile } from '../../components/tipInfoComponentMobile/TipInfoComponentMobile';
import { useGetUserDetails } from '../../services/myProfile/useGetUserDetails';
import { useGetUserGallery } from '../../services/myProfile/useGetUserGallery';
import { useUpdateMyProfileFIrstView } from '../../services/myProfile/useUpdateMyProfileFIrstView';
import { AboutMeTab } from './aboutMeTab/AboutMeTab';
import { GalleryTab } from './galleryTab/GalleryTab';
import { ImgCroper } from './galleryTab/imgCroper/ImgCroper';
import { GeneralTab } from './generalTab/GeneralTab';
import { PreferencesTab } from './preferencesTab/PreferencesTab';
import { SettingsTab } from './settingsTab/SettingsTab';

export const MyProfile = () => {
  const isMobile = useCheckMobileScreen();
  const [activeTab, setactiveTab] = useState<string>('1');
  const [tipMessage, setTipMessage] = useState<string>();
  const [modalPhotoOpen, setModalPhotoOpen] = useState(false);
  const [creditsNotifyAboutMe, setCreditsNotifyAboutMe] = useState<boolean>();
  const [creditsNotifyAvatar, setCreditsNotifyAvatar] = useState<boolean>();
  const [creditsNotifyGallery, setCreditsNotifyGallery] = useState<boolean>();
  const [creditsPreferences, setCreditsPreferences] = useState<boolean>();
  const [modalFreeCredits, setModalFreeCredits] = useState<boolean>(false);
  const [modalsPopup, setModalsPopup] = useState<boolean>(true);
  const [user] = useRecoilState(userRecoil);
  const userId = user?.data?.id;
  const userProfileId = user?.data?.profile.id;
  const { data: userGeneralData, isLoading } = useGetUserGallery(userProfileId);
  const { data: userDetails, isFetched } = useGetUserDetails(userId);
  const { mutate: updateProfileFirstView } = useUpdateMyProfileFIrstView();
  const userProfileImage = userGeneralData?.profile_image;
  const userGalleryImages = userGeneralData?.gallery_images;
  const redirectedPage = localStorage.getItem('redirectedPage');

  const renderTipMessage = () => {
    return <span>{tipMessage}</span>;
  };

  useEffect(() => {
    if (userDetails) {
      setCreditsNotifyAboutMe(userDetails?.completed_free_credit_actions.about_me);
      setCreditsNotifyAvatar(userDetails?.completed_free_credit_actions.avatar);
      setCreditsNotifyGallery(userDetails?.completed_free_credit_actions.gallery);
      setCreditsPreferences(userDetails?.completed_free_credit_actions.preferences);
    }
  });

  const modalPopup = () => {
    if (!redirectedPage) {
      userDetails &&
      (!userDetails.completed_free_credit_tabs.about_me ||
        !userDetails?.completed_free_credit_tabs.gallery ||
        !userDetails?.completed_free_credit_tabs.preferences)
        ? setModalFreeCredits(true)
        : setModalFreeCredits(false);
    } else if (redirectedPage && !userDetails?.completed_free_credit_actions.avatar) {
      setModalPhotoOpen(true);
    }
  };

  useEffect(() => {
    creditsNotifyAboutMe === false &&
      userDetails?.completed_free_credit_actions.about_me !== creditsNotifyAboutMe &&
      notification.success({ message: `3 Free Credits Issued Successfully` });

    creditsNotifyAvatar === false &&
      userDetails?.completed_free_credit_actions.avatar !== creditsNotifyAvatar &&
      notification.success({ message: `1 Free Credit Issued Successfully` });

    creditsNotifyGallery === false &&
      userDetails?.completed_free_credit_actions.gallery !== creditsNotifyGallery &&
      notification.success({ message: `3 Free Credits Issued Successfully` });

    creditsPreferences === false &&
      userDetails?.completed_free_credit_actions.preferences !== creditsPreferences &&
      notification.success({ message: `3 Free Credits Issued Successfully` });

    isFetched && modalsPopup && modalPopup();
  }, [userDetails, userGeneralData]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('redirectedPage');
    };
  }, []);

  useEffect(() => {
    switch (activeTab) {
      case '1':
        userDetails?.is_complete_my_profile
          ? setTipMessage(
              `Good job handsome, your profile is looking great. Keep it updated to get more attention!`
            )
          : setTipMessage(
              `Complete the About, Preferences and Gallery tabs to earn up to 10 free credits!`
            );
        break;
      case '2':
        userDetails?.completed_free_credit_actions.preferences
          ? setTipMessage(
              `Good job handsome, your profile is looking great. Keep it updated to get more attention!`
            )
          : setTipMessage(
              `Tell me all your preferences and you’ll get 3 credits for free to chat to all your favorite profiles`
            );
        break;
      case '3':
        userDetails?.completed_free_credit_actions.about_me
          ? setTipMessage(
              `Good job handsome, your profile is looking great. Keep it updated to get more attention!`
            )
          : setTipMessage(
              `We want to know more about you! Complete this page and get 3 credits to message your matches`
            );
        break;
      case '4':
        !userDetails?.completed_free_credit_actions.avatar
          ? setTipMessage(
              `Upload your profile photo and show us how you look and I'll give you 1 free credit`
            )
          : userDetails?.completed_free_credit_actions.avatar &&
            !userDetails?.completed_free_credit_actions.gallery
          ? setTipMessage(
              `Hey handsome! Add 3 or more photos to your gallery and you'll get 3 more credits!`
            )
          : setTipMessage(` Good job handsome, your profile is looking great. Keep it updated to get more
        attention`);
        break;
      case '5':
        userDetails?.is_complete_my_profile
          ? setTipMessage(
              `Good job handsome, your profile is looking great. Keep it updated to get more attention!`
            )
          : setTipMessage(
              `Complete the About, Preferences and Gallery tabs to earn up to 10 free credits!`
            );
        break;
      default:
        setTipMessage(
          `Amber's Tip: Fill out your profile 100% and I'll give you 10 free credits to have  some fun.`
        );
    }
  }, [activeTab, userDetails, userProfileImage, userGalleryImages]);

  const tabChange = (activeKey: string) => {
    setactiveTab(activeKey);
  };

  const closeUploadPhotoModal = () => {
    const data = {
      has_visited_my_profile: true
    };
    //@ts-ignore ima tip ali nece da prepozna
    updateProfileFirstView({ userId: userId, payload: data });
    setModalsPopup(false);
    setModalPhotoOpen(false);
    // localStorage.removeItem('redirectedPage');
  };

  return (
    <div className="my-profile">
      {!isLoading ? (
        isMobile ? (
          <TipInfoComponentMobile message={isLoading ? 'Loading...' : renderTipMessage()} />
        ) : (
          ''
        )
      ) : (
        ''
      )}
      <Tabs className="my-profile-tabs" type="card" onChange={tabChange}>
        <TabPane key={'1'} tab={<div className="gallery-tab-title tab1 completed">General</div>}>
          <GeneralTab isMobile={isMobile} renderTipMessage={renderTipMessage} />
        </TabPane>
        <TabPane
          key={'2'}
          tab={
            <div
              className={
                userDetails?.completed_free_credit_tabs.preferences
                  ? 'gallery-tab-title tab2 completed'
                  : 'gallery-tab-title tab2'
              }
            >
              Preferences
            </div>
          }
        >
          <PreferencesTab isMobile={isMobile} renderTipMessage={renderTipMessage} />
        </TabPane>
        <TabPane
          key={'3'}
          tab={
            <div
              className={
                userDetails?.completed_free_credit_tabs.about_me
                  ? 'gallery-tab-title tab3 completed'
                  : 'gallery-tab-title tab3'
              }
            >
              About Me
            </div>
          }
        >
          <AboutMeTab isMobile={isMobile} renderTipMessage={renderTipMessage} />
        </TabPane>
        <TabPane
          key={'4'}
          tab={
            <div
              className={
                userDetails?.completed_free_credit_tabs.gallery
                  ? 'gallery-tab-title tab4 completed'
                  : 'gallery-tab-title tab4'
              }
            >
              Gallery
            </div>
          }
        >
          <GalleryTab isMobile={isMobile} renderTipMessage={renderTipMessage} />
        </TabPane>
        <TabPane key={'5'} tab={<div className="gallery-tab-title tab5 completed">Settings</div>}>
          <SettingsTab isMobile={isMobile} renderTipMessage={renderTipMessage} />
        </TabPane>
      </Tabs>
      <Modal centered open={modalPhotoOpen} footer={null} onCancel={() => closeUploadPhotoModal()}>
        <div className="modal-image-cropper-container">
          <h1>Add Profile Photo</h1>
          <p>
            Show us what you look like and get <strong>1 free </strong> credit to send a message to
            your favorite girl
          </p>
          <ImgCroper setModalPhotoOpen={setModalPhotoOpen} />
          <Button
            className="SubmitButton"
            type="submit"
            onClick={() => closeUploadPhotoModal()}
            text="Close"
          />
        </div>
      </Modal>
      <FreeCreditsModal
        openModalState={modalFreeCredits}
        page="myProfile"
        userDetails={userDetails}
        setModalPhotoOpen={setModalPhotoOpen}
        userGeneralData={userGeneralData}
      />
    </div>
  );
};
