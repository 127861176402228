const currencySymbol = (currency: Currency) => {
  switch (currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    default:
      return currency;
  }
};

export { currencySymbol };
