import React from 'react';

import ArrowBack from '../../../../../assets/images/FunnelPage/arrow-back.svg';
import Button from '../../../../../components/Button/Button';
import Input from '../../../../../components/Form/Input/Input';

const EmailInput = ({
  register,
  errors,
  prevView,
  emailValidation,
  loading,
  responseGoogle,
  setValue,
  getValues,
  clearErrors,
  setGoogleLogin,
  isLoading
}) => {
  return (
    <div className="email">
      <div className="arrow-back" onClick={prevView}>
        <img src={ArrowBack} alt="arrow back" />
      </div>
      <h2>Last Step! What’s Your Email?</h2>
      <p>In order to avoid fake profiles, we send you an email to confirm your profile</p>
      <Input
        register={register}
        name="email"
        type={'email'}
        placeholder="johndoe@gmail.com"
        errorMessage={errors?.email?.message}
        onChange={(e) => {
          setValue('email', e.target.value);
          clearErrors('email');
        }}
        onKeyDown={(e) => {
          e.key === 'Enter' && emailValidation();
          setGoogleLogin(false);
          if (e.keyCode === 32) {
            e.preventDefault();
          }
        }}
      />
      <Button
        id="submitFormRef"
        className={loading ? 'DisabledButton' : ''}
        text={loading ? 'Loading...' : 'Confirm Email'}
        type="button"
        onClick={emailValidation}
        loading={isLoading}
      />
    </div>
  );
};

export default EmailInput;
