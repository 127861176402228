import './UsernamePassword.scss';

import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function UsernamePassword({ handleNextStep, watch, errors, setValue, clearErrors }) {
  return (
    <div className="UsernamePasswordContainer">
      <div className="StepContent">
        <p className="UsernamePasswordText"> Create account</p>

        <div className="InputMainContainer">
          <Input
            placeholder="Username"
            name="username"
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />

          <Input
            placeholder="Password"
            name="password"
            type="password"
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
        <div className="ButtonContainer">
          <Button
            className={`ButtonDark`}
            text="CONTINUE"
            handleNextStep={() => handleNextStep(['username', 'password'])}
            disabled={!watch('username') || !watch('password')}
          />
        </div>
      </div>
    </div>
  );
}
