import './SignInProceed.scss';

import Button from '../../UI/Button/Button';

export default function SignInProceed({ onNextStep }) {
  return (
    <div className="SignInProceedContainer">
      <div className="StepContent">
        <p className="SignInProceedText">
          Nice! Sign in and have fun with these girls! Click the button below to continue.
        </p>
        <div className="ButtonContainer">
          <Button handleNextStep={() => onNextStep()} text="PROCEED"></Button>
        </div>
      </div>
    </div>
  );
}
