import { useInfiniteQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import { axiosInstance } from '../../axios/axiosInstance';
import { queueMessage } from '../../recoil/atoms/queueMessageAtom';

export const key = ['SINGLE_CONVERSATION'];
const url = 'api/member/inbox/conversations';
export const useGetConversationById = (userId, profileId) => {
  const [queue, setQueue] = useRecoilState(queueMessage);
  return useInfiniteQuery(
    [...key, profileId],
    async ({ pageParam = 1 }) => {
      const { data } = await axiosInstance.get(`${url}/${userId}/${profileId}/messages`, {
        params: {
          page: pageParam,
          per_page: 10
        }
      });
      return data;
    },
    {
      getNextPageParam: (nextPage) => {
        if (nextPage?.next_page_url) return nextPage.current_page + 1;
      },
      onSuccess: () => {
        if (queue[profileId]?.length > 0)
          setQueue({
            ...queue,
            [profileId]: []
          });
      },
      enabled: !!profileId && !!userId && profileId !== 'undefined'
    }
  );
};
