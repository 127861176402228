import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';
import { UserAboutMe } from '../../model/classes/UserAboutMe';

export const key = ['USER_GALLERY_DATA'];

export const useGetUserGallery = (userId: number) =>
  useQuery(
    [...key, userId],
    async (): Promise<UserAboutMe> => {
      const { data } = await axiosInstance.get(`api/profiles/${userId}?include=gallery_images`);
      return data.data;
    },
    {
      enabled: !!userId,
      staleTime: 1000 * 60 * 5
    }
  );
