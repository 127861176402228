import { useMutation } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';
import { UserDetails } from '../../model/classes/UserDetails';

interface DetailsData {
  userId: number;
  payload: UserDetails;
}
interface ErrorResponse {
  message: string;
  errors: string[];
}

function transformErrorData(data: any): ErrorResponse {
  const result: ErrorResponse = {
    message: data.message,
    errors: []
  };

  if (data.errors)
    for (const [, value] of Object.entries(data.errors)) {
      // @ts-ignore
      result.errors.push(value[0]);
    }

  return result;
}

export const useUpdateMyProfileFIrstView = () =>
  useMutation(
    async ({ userId, payload }: DetailsData) => {
      const { data } = await axiosInstance.post(`api/users/${userId}/details`, {
        ...payload,
        _method: 'PATCH'
      });
      return data;
    }
    // {
    //   onSuccess: () => notification.success({ message: 'Update success' }),
    //   onError: (err: AxiosError) => {
    //     if (err.response) {
    //       const data = transformErrorData(err.response.data);
    //       notification.error({
    //         message: 'Update error ' + err.response?.status,
    //         description: data.errors.map((error: string) => {
    //           return (
    //             <p key={error} style={{ marginBottom: '5px' }}>
    //               {error}
    //             </p>
    //           );
    //         }),
    //       });
    //     }
    //   },
    // },
  );
