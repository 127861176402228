import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axiosInstance } from '../../axios/axiosInstance';

export const key = ['FAVOURITE_PROFILES'];
export const useGetFavourites = ({ page, perPage }: { page: number; perPage: number }) =>
  useQuery<any, AxiosError>(
    [key, page],
    async () => {
      const { data } = await axiosInstance.get('/api/v2/profiles/favorites', {
        params: { page, per_page: perPage }
      });
      return data;
    },
    {
      retry: 0
    }
  );
