import './VerticalOption.scss';

export default function VerticalOption({ title, backgroundImage, onClick, isActive }) {
  return (
    <div
      className={`VerticalOption15 ${isActive ? 'VerticalOptionActive' : ''}`}
      onClick={onClick}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="BackgroundOverlay"></div>
      <span>{title}</span>
    </div>
  );
}
