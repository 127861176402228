import './MatchGame.scss';

import React, { useState } from 'react';

import { queryClient } from '../../../../../../App';
import { CircleX, Eye, Fire, Loader, Placeholder } from '../../../../../../assets/images/MatchGame';
import ImageComponent from '../../../../../../components/ImageComponent/ImageComponent';
import NewProfileBadge from '../../../../../../components/NewProfileBadge/NewProfileBadge';
import { key as MatchKey, useGetMatch } from '../../../../../../V2/services/matchGame/useGetMatch';
import { useLikeMatchProfile } from '../../../../../../V2/services/matchGame/useLikeMatchProfile';
import { MatchModal } from './MatchModal';
import { SettingsModal } from './SettingsModal';

const MatchGame = () => {
  const [isMatch, setIsMatch] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [filter, setFilter] = useState({});
  const invalidate = () => queryClient.invalidateQueries([...MatchKey]);
  /*** new API ***/
  const { data, isLoading, isFetching } = useGetMatch({ filter });
  const { mutate, isLoading: liking } = useLikeMatchProfile();

  const yearsOld = new Date().getFullYear() - data?.birthday?.slice(0, 4);
  const closeMatchModal = () => {
    invalidate().then(() => setIsMatch(false));
  };

  const continuePlaying = () => {
    closeMatchModal();
    invalidate();
  };

  const viewProfile = () => {
    const url = window.location.origin + '/members-area/member-profile/' + data.id;
    window.open(url, '_blank');
  };

  return (
    <div className="MatchGame">
      <div className="MatchGameTitleWrapper">
        <div className="MatchGameTitle">Match game</div>
        <button className="MatchGameSubtitle" onClick={() => setIsSettings(true)}>
          Update your match game settings
        </button>
      </div>
      <div
        className="PhotoContainer"
        key={(!isLoading && !isFetching) || liking ? data?.id : Math.random()}
      >
        <div className="PhotoContainerBefore"></div>
        <div className="PhotoContainerBefore1"></div>
        {!isLoading && !isFetching && !liking && data && (
          <>
            {!!data?.is_new && <NewProfileBadge />}
            <div className="GalleryProfileContainer" onClick={viewProfile}>
              <div className="ViewProfileWrapper">
                <img className="ViewProfileIcon eyeIcon" src={Eye} alt={'eye'} />
                <p className="ViewProfileText">VIEW PROFILE</p>
              </div>
            </div>
          </>
        )}
        {isLoading || isFetching || liking ? (
          <div className="loadingContainer">
            <img src={Loader} alt={'loader'} className={'loader'} />
          </div>
        ) : data ? (
          data?.public_avatar_url ? (
            <img
              key={data.public_avatar_url}
              src={data.public_avatar_url}
              className="ImageShadow"
              alt={data.username}
            />
          ) : (
            <ImageComponent url={Placeholder} imgClassName="ImageShadow" />
          )
        ) : (
          <div className="NoProfile">
            There are no profiles that match your filters. Please update the filters and play again!{' '}
          </div>
        )}
        {!isLoading && !isFetching && !liking && data && (
          <>
            <p className="userName">
              {data?.username}, {yearsOld}
            </p>
          </>
        )}
      </div>
      <div className="IconContainer">
        <img
          className={`MatchIcon ${isFetching || liking || isLoading || !data ? 'disabled' : ''}`}
          src={CircleX}
          onClick={() => {
            mutate({ id: data.id, action: 'dislike' }, { onSuccess: () => invalidate() });
          }}
          alt={'dislike'}
        />
        <img
          className={`MatchIcon ${isFetching || liking || isLoading || !data ? 'disabled' : ''}`}
          src={Fire}
          onClick={() => {
            mutate(
              { id: data.id, action: 'like' },
              {
                onSuccess: (res) => {
                  res.match ? setIsMatch(true) : invalidate();
                }
              }
            );
          }}
          alt={'like'}
        />
      </div>
      <MatchModal
        open={isMatch}
        onClose={closeMatchModal}
        data={data}
        keepPlaying={continuePlaying}
      />
      <SettingsModal
        open={isSettings}
        onClose={() => setIsSettings(false)}
        onFilterSet={(e) => setFilter(e)}
      />
    </div>
  );
};
export default MatchGame;
