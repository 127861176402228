import { useRecoilState } from 'recoil';

import { userData as userRecoil } from '../../../../recoil/atoms/userDataAtom';
import { CreditsPackage } from '../../../model/classes/CreditsPackage';
import { CreditsCardV1 } from './CreditsCardV1';
import { CreditsCardV2 } from './CreditsCardV2';

interface CreditsCardProps {
  packageDef: CreditsPackage;
  redirectUrl?: string;
  onSuccess?: () => void;
  trigger?: 'credits_page' | 'credits_modal';
  noBuy?: boolean;
  profileId?: number;
  lastMessageId?: number;
  version?: number;
  starterPackage?: boolean;
}
export const CreditsCard = ({
  packageDef,
  redirectUrl,
  onSuccess,
  trigger,
  noBuy,
  profileId,
  lastMessageId,
  version,
  starterPackage
}: CreditsCardProps) => {
  const [user] = useRecoilState(userRecoil);
  const creditCardVersion = version || user?.data?.package_layout;

  return creditCardVersion === 1 ? (
    <CreditsCardV1
      {...{
        packageDef,
        redirectUrl,
        onSuccess,
        trigger,
        noBuy,
        profileId,
        lastMessageId,
        starterPackage
      }}
    />
  ) : (
    <CreditsCardV2
      {...{
        packageDef,
        redirectUrl,
        onSuccess,
        trigger,
        noBuy,
        profileId,
        lastMessageId,
        starterPackage
      }}
    />
  );
};
