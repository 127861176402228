import axios from 'axios';

export const urls = {
  notificationsNew: 'api/member/inbox/conversations/read/',
  visitorsNotification: 'api/member/notifications/visitors/read/'
};

export const markNotificationAsRead = (conversationId, userToken) => {
  return axios.get(`${urls.notificationsNew}${conversationId}`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const markVisitorsNotificationAsRead = (userToken) => {
  return axios.post(`${urls.visitorsNotification}`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

// export const urls = {
//   notifications: "api/member/notifications"
// };

// export const markNotificationAsRead = (notificationsData, userToken) => {
//   const notificationsArr = {
//     notifications: notificationsData,
//   };
//   return axios.post(`${urls.notifications}`, notificationsArr, {
//     headers: {
//       Authorization: `Bearer ${userToken}`,
//       Accept: "application/json",
//       "Content-Type": "application/json"
//     }
//   });
// };
