import './PaymentNote.scss';

import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { queryClient } from '../../../App';
import CHECK from '../../../assets/images/Modal/Check_Purple.svg';
import CIRCLE from '../../../assets/images/Modal/circle.png';
import CLOSE from '../../../assets/images/Modal/Close_Purple.png';
import { key as UserProfileKey } from '../../../core/APIHooks/userData/useGetUserData';
import { cancelSpecial } from '../../../recoil/atoms/cancelSpecial';
import { key as PackagesKey } from '../../../V2/services/packages/useGetAllPackages';

const PaymentNote = (props) => {
  const [, setShouldCancelSpecial] = useRecoilState(cancelSpecial);
  const { status, text, note } = props;
  useEffect(() => {
    queryClient.invalidateQueries(UserProfileKey);
    queryClient.invalidateQueries(PackagesKey);
    const specialOffer = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('special'))));
    localStorage.setItem('special', JSON.stringify({ ...specialOffer, special: false }));
    setShouldCancelSpecial(true);
  });
  return (
    <div className="PaymentConfirm">
      {status === 'confirm' ? (
        <img className="PaymentConfirmSign" src={CHECK} alt="check sign" />
      ) : (
        <>
          <img className="PaymentConfirmClose" src={CLOSE} alt="check sign" />
          <img className="PaymentConfirmSign" src={CIRCLE} alt="check sign" />
        </>
      )}
      <h2 className="PaymentConfirmText">{text}</h2>
      <p className="PaymentConfirmNote">{note}</p>
    </div>
  );
};

export default PaymentNote;
