import './LanderChat.scss';

import React, { useEffect, useState } from 'react';

import AVATARBLUE from '../../../../../../../assets/images/Lander12/blue/avatar.png';
import AVATARORANGE from '../../../../../../../assets/images/Lander12/orange/avatar.png';
import AVATARPURPLE from '../../../../../../../assets/images/Lander12/purple/avatar.png';
import AVATARRED from '../../../../../../../assets/images/Lander12/red/avatar.png';

export default function LanderChat({ messages, endChatAction = null, landerId }) {
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const landerMessageClassName = `Message12 version${landerId}`;

  const avatars = {
    '12-1': AVATARRED,
    '12-2': AVATARORANGE,
    '12-3': AVATARPURPLE,
    '12-4': AVATARBLUE
  };

  useEffect(() => {
    const addMessage = () => {
      // Display a typing message
      const typingMessage = { typing: true, content: '' };
      setDisplayedMessages((prevMessages) => [...prevMessages, typingMessage]);

      // Simulate delay before showing the actual message
      setTimeout(() => {
        setDisplayedMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages.pop(); // Remove the typing message
          updatedMessages.push(messages[currentMessageIndex]); // Add the actual message
          return updatedMessages;
        });
        setCurrentMessageIndex((prevIndex) => prevIndex + 1);

        // Check if it's the last message and perform endChatAction if defined
        if (currentMessageIndex + 1 === messages.length) {
          endChatAction && endChatAction();
        }
      }, 600);
    };

    if (currentMessageIndex == 0) {
      addMessage();
    }

    const timer = setInterval(() => {
      if (currentMessageIndex < messages.length) {
        addMessage();
      } else {
        clearInterval(timer);
      }
    }, 900);

    return () => {
      clearInterval(timer);
    };
  }, [currentMessageIndex]);

  return (
    <div className="ChatContainer12">
      {displayedMessages.map((message, index) => (
        <div
          className="MessageContainer ToggleElement"
          style={{ animationDelay: `${index * 0.2}s` }}
          key={index}
        >
          <div className="AvatarContainer12-1">
            {index === displayedMessages.length - 1 && (
              <img src={avatars[`${landerId}`]} alt="Avatar" />
            )}
          </div>
          <div className={landerMessageClassName}>
            {message.typing && (
              <div className="typing is-typing-init is-typing-active">
                <span className="typing__bullet"></span>
                <span className="typing__bullet"></span>
                <span className="typing__bullet"></span>
              </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: message.content }} />
          </div>
        </div>
      ))}
    </div>
  );
}
