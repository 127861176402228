import * as actionTypes from '../types/zendesk.js';

const initialState = {
  zendeskWidget: false
};

export const zendeskWidget = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ZENDESK_WIDGET:
      return {
        ...state,
        zendeskWidget: action.payload
      };
    default:
      return { ...state };
  }
};
