import React, { useState } from 'react';

import middleAgeBg from '../../../../../../../../assets/images/Lander10/middle-age.png';
import oldBg from '../../../../../../../../assets/images/Lander10/old.png';
import youngBg from '../../../../../../../../assets/images/Lander10/young.png';
import HorizontalOption from '../../HorizontalOption/HorizontalOption';
import LanderChat from '../../LanderChat/LanderChat';

export default function Relationship({ onNextStep }) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = [
    { content: 'Great. I would like to look for the most suitable matches.' },
    { content: 'What woman’s age do you prefer?' }
  ];

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat messages={messages} endChatAction={toggleElementVisibility} />
      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <HorizontalOption title="18 - 29" backgroundImage={youngBg} onClick={onNextStep} />
        <HorizontalOption title="30 - 45" backgroundImage={middleAgeBg} onClick={onNextStep} />
        <HorizontalOption title="45+" backgroundImage={oldBg} onClick={onNextStep} />
      </div>
    </>
  );
}
