import './SignUpFlow.scss';

import React, { useRef, useState } from 'react';

import ModalDialog from '../modals/ModalDialog/ModalDialog';
import SignUp from '../SignUp/SignUp';
import VerifyAccount from '../VerifyAccount/VerifyAccount';

const SignUpFlow = (props) => {
  const { modalRef } = props;
  const [accessToken, getAccessToken] = useState('');
  const [registerData, getRegisterData] = useState();
  const verifyModalRef = useRef(null);

  const closeModal = (ref) => {
    ref.current.closeModal();
  };

  const openModal = (ref) => {
    ref.current.openModal();
  };

  return (
    <div>
      <ModalDialog ref={modalRef}>
        <SignUp
          closeModal={closeModal}
          openModal={openModal}
          modalRef={modalRef}
          verifyModalRef={verifyModalRef}
          getAccessToken={getAccessToken}
          registerData={registerData}
          getRegisterData={getRegisterData}
        />
      </ModalDialog>
      <ModalDialog ref={verifyModalRef}>
        <VerifyAccount
          openModal={openModal}
          closeModal={closeModal}
          verifyModalRef={verifyModalRef}
          modalRef={modalRef}
          accessToken={accessToken}
          registerData={registerData}
        />
      </ModalDialog>
    </div>
  );
};

export default SignUpFlow;
