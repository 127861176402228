import './Gender.scss';

import { useState } from 'react';

import Button from '../../UI/Button/Button';
import VerticalOption from '../../UI/VerticalOption/VerticalOption';

export default function Gender({ handleNextStep, setValue, watch }) {
  const [activeOption, setActiveOption] = useState(null);

  const handleOptionClick = (option) => {
    setValue('gender', option);
    setActiveOption(option);
  };
  return (
    <div className="GenderContainer">
      <div className="StepContent">
        <p className="GenderText">Are you a man or a woman?</p>
        <div className="VerticalOptionMainContainer">
          <VerticalOption
            title="I'm a man"
            onClick={() => handleOptionClick('male')}
            isActive={activeOption === 'male'}
          />
          <VerticalOption
            title="I'm a woman"
            onClick={() => handleOptionClick('female')}
            isActive={activeOption === 'female'}
          />
        </div>
        <div className="ButtonContainer">
          <Button
            handleNextStep={() => handleNextStep(['gender'])}
            text="CONTINUE"
            disabled={!watch('gender')}
          ></Button>
        </div>
      </div>
    </div>
  );
}
