import './ConversationContainer.scss';

import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DISMISS_NOTIFICATION } from '../../../../../../../../redux/types/notifications';
import {
  getFailedMessages,
  getSingleConversationByID
} from '../../../../../../../../services/message-services/message.services';
import { markNotificationAsRead } from '../../../../../../../../services/notification-service/notification-service';
import { getToken } from '../../../../../../../../services/token-service/token.service';
import { getUserData } from '../../../../../../../../services/user-service/user.service';
import ReceivedMessage from './ReceivedMessage/ReceivedMessage';
import SentMessage from './SentMessage/SentMessage';

const ConversationContainer = (props) => {
  const {
    notEnoughCredits,
    messages,
    setConversation,
    conversationActiveId,
    loadingConversation,
    openModal,
    notEnoughCredistRef,
    purchaseCreditsRef
  } = props;
  const startLoad = useRef();
  const conversationRef = useRef();
  const userData = getUserData();
  const failedMessages = getFailedMessages();
  const token = getToken();
  const dispatch = useDispatch();
  let { notifications } = useSelector((state) => state);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const { newConversationId } = useSelector((state) => state);

  const notificationsIdArr = notifications;
  // const conversationsIdArr = notifications.map(
  //   (note) => note?.notifiable?.conversation_id
  // );
  // const isActive = conversationsIdArr.includes(conversationActiveId);
  // console.log(
  //   notificationsIdArr.length,
  //   conversationsIdArr,
  //   isActive,
  //   conversationActiveId
  // );
  const updateNotificationsLocal = () => {
    const notifications = userData?.unread_message_notifications;
    console.log('updateNotificationsLocal ==> CCONVERSATION CONTAINER !!!!!!!! ');
    const dataFromLocalStorage = JSON.parse(localStorage.getItem('userData'));
    const newDataFromLocalStorage = {
      ...dataFromLocalStorage,
      unread_message_notifications: Number(notifications) > 0 && Number(notifications) - 1
    };
    localStorage.setItem('userData', JSON.stringify(newDataFromLocalStorage));
  };

  useEffect(() => {
    if (notificationsIdArr > 0 && conversationActiveId) {
      markNotificationAsRead(conversationActiveId, token)
        .then(() => {
          dispatch({
            type: DISMISS_NOTIFICATION,
            payload: conversationActiveId
          });
          updateNotificationsLocal(conversationActiveId);
        })
        .catch((err) => console.log(err));
    }
  }, [dispatch, conversationActiveId]);

  useEffect(() => {
    // getNotEnoughCredits(false);
  }, [messages]);

  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({
  //     behavior: "smooth",
  //     block: "end",
  //     inline: "start",
  //   });
  // };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);

  const dateToYMD = (date) => {
    var d = date.getDate();
    var m = date.getMonth() + 1; //Month from 0 to 11
    var y = date.getFullYear();
    return '' + (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
  };

  const renderDate = (time) => {
    var date = new Date(time);
    var today = new Date();
    var dateFormated = moment(date).format('yyy-MM-DD');
    var todayFormated = moment(today).format('yyy-MM-DD');
    return (
      <div className="DayShowContainer" key={time}>
        <span> {dateFormated === todayFormated ? 'Today' : dateToYMD(date)} </span>
      </div>
    );
  };

  const showDate = (index, item) => {
    const currentCreatedAt = new Date(item?.created_at);
    const currentCreatedAtDate = dateToYMD(currentCreatedAt);

    const nextCreatedAt = new Date(reversed[index - 1]?.created_at);
    const nextCreatedAtDate = dateToYMD(nextCreatedAt);

    return currentCreatedAtDate !== nextCreatedAtDate || !index;
  };

  const showFailedMessages = () => {
    if (failedMessages) {
      return failedMessages[0]?.map((message, index) => {
        return (
          <SentMessage
            text={message?.messageBody}
            messageTime={message?.messageTime}
            notEnoughCredits={notEnoughCredits}
            key={index}
            openModal={openModal}
            notEnoughCredistRef={notEnoughCredistRef}
            purchaseCreditsRef={purchaseCreditsRef}
          />
        );
      });
    }
  };

  // const showTime = (index, item) => {
  //   if (
  //     new Date(item.created_at).getMinutes() !==
  //       new Date(messages[index + 1]?.created_at).getMinutes() ||
  //     new Date(item.created_at).getHours() !==
  //       new Date(messages[index + 1]?.created_at).getHours()
  //   )
  //     return true;
  // };

  // const itemListHandler = useCallback(
  //   (data) => {
  //     setInboxList((prev) => {
  //       return [
  //         ...new Set([
  //           ...prev,
  //           ...data.data.filter(
  //             (value) => !prev.some((prevValue) => value.id === prevValue.id)
  //           ),
  //         ]),
  //       ];
  //     });
  //     setHasMore(page < data.last_page ? true : false);
  //   },
  //   [page]
  // );

  useEffect(() => {
    page > 1 &&
      getSingleConversationByID(newConversationId?.conversationId, getToken(), page)
        .then((res) => {
          messages && setConversation([...messages, ...res.data.messages.data]);
          setHasMore(page < res.data.messages.last_page);
        })
        .catch((err) => console.log(err));
  }, [page]);

  const onScroll = () => {
    if (conversationRef.current) {
      const { scrollTop } = conversationRef.current;
      if (scrollTop < 200) {
        if (hasMore) {
          setPage((prevPage) => prevPage + 1);
          setHasMore(false);
        }
      }
    }
  };

  const chatScrollToBottom = () => {
    var scroll = document.getElementById('ConversationContainerScroll');
    const {} = conversationRef.current;
    page > 1 ? (scroll.scrollTop = 900) : (scroll.scrollTop = scroll.scrollHeight);
    page > 1
      ? scroll.animate({ scrollTop: 900 })
      : scroll.animate({ scrollTop: scroll.scrollHeight });
  };

  useEffect(() => {
    chatScrollToBottom();
  }, [messages]);
  // console.log("messages ====> ", messages);

  const reversed = messages && [...messages].reverse();

  return (
    <div
      className="ConversationContainer"
      id="ConversationContainerScroll"
      onScroll={onScroll}
      ref={conversationRef}
    >
      {loadingConversation &&
        reversed?.map((item, index) => {
          var time = item?.created_at;
          if (userData?.profile?.id === item.sender_profile_id) {
            return (
              <span key={index}>
                {showDate(index, item) && renderDate(time)}
                {index === 2 && <span ref={startLoad}></span>}
                <SentMessage
                  text={item.body}
                  image={item?.image ? item?.image : ''}
                  messageTime={time}
                  openModal={openModal}
                  notEnoughCredistRef={notEnoughCredistRef}
                  purchaseCreditsRef={purchaseCreditsRef}
                />
              </span>
            );
          } else {
            return (
              (item?.body || item?.attachments) && (
                <span key={index}>
                  {index === 2 && <span ref={startLoad}></span>}
                  {showDate(index, item) && renderDate(time)}
                  <ReceivedMessage
                    text={item.body}
                    messageTime={time}
                    image={
                      item?.image
                        ? item?.image
                        : item?.attachments?.length > 0
                        ? item?.attachments[0]
                        : ''
                    }
                  />
                </span>
              )
            );
          }
        })}
      {showFailedMessages()}
      {/* {profileData &&
      sentMessage &&
      messages?.length <= 0 &&
      !notEnoughCredits ? (
        <SentMessage text={sentMessage+ '@@@@@@@@@@@@@@@@@@@@@@@'} messageTime={sentMessageTime} />
      ) : (
        ""
      )} */}
      {/* <div ref={messagesEndRef} /> */}
    </div>
  );
};

export default ConversationContainer;
