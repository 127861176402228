const parseError = (e) => {
  const data = e?.response?.data;
  const errorArray = [];
  const errors = data?.errors;
  for (const message in errors) {
    errors[message].map((msg) => errorArray.push(msg));
  }
  if (errorArray.length > 0) return errorArray.join('\n');
  else return e.message;
};

export { parseError };
