import './LanderConfirmationPage.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@reach/router';
import { Checkbox, Tag } from 'antd';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import Button from '../../../../components/Button/Button';
import { useConfirmLandingStep } from '../../../../core/APIHooks/useConfirmLandingStep';

const validationSchema = Yup.object().shape({
  terms_and_conditions: Yup.boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.')
});
const RECAPTCHA_SITE_KEY = '6LfiSCQoAAAAADuRqkIXmxoQBYaLDgEwbWF3k-7I';

export default function LanderConfirmationPage() {
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('userToken');
  const tokenData = token && JSON.parse(window.atob(token));
  const { mutate: confirmLogin, isLoading } = useConfirmLandingStep();
  const navigate = useNavigate();
  const [captcha, setCaptcha] = useState();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    localStorage.setItem('current-email', tokenData?.email);
  }, [tokenData]);

  const submitForm = (e) => {
    handleSubmit((data) => onSubmit(data))(e);
  };

  const onSubmit = (val) => {
    const confirmData = {
      token: token,
      terms_and_conditions: val.terms_and_conditions,
      ...(tokenData?.recaptcha ? { recaptcha_token: captcha } : {})
    };
    setCaptcha();
    recRef.current && recRef.current.reset();
    confirmLogin(
      { confirmData },
      {
        onSuccess: (res) => {
          navigate(res.redirect_url);
        }
      }
    );
  };
  const recRef = useRef();
  return (
    <div className="LanderConfirmationContainer">
      <div className="ConfirmationBackgroundOverlay"></div>

      <div className="LanderConfirmationContent">
        <div className="LanderContentInner">
          <div className="InnerHeader">
            <span>Sign up now for FREE!</span>
            <span>No credit card required</span>
          </div>
          <div className="InnerContainer">
            <div className="ConfirmationContainer">
              <form>
                <div className="StepSubTitle">
                  Please check your details and confirm your profile
                </div>
                <div className="CheckInfoContainer">
                  <p>
                    <Checkbox checked={true} />{' '}
                    <span className="CheckboxLabel">
                      <b>E-mail:</b> {tokenData?.email}
                    </span>
                  </p>
                  <p>
                    <Checkbox checked={true} />{' '}
                    <span className="CheckboxLabel">
                      <b>Username:</b> {tokenData?.username}
                    </span>
                  </p>
                  <p>
                    <Controller
                      name="terms_and_conditions"
                      control={control}
                      render={({ field }) => <Checkbox {...field} />}
                    />
                    <span className="TermsLabel">
                      By becoming a member I agree to the <b>Terms & Conditions</b> &{' '}
                      <b>Privacy Policy</b>
                    </span>
                  </p>

                  {errors.terms_and_conditions && (
                    <Tag color="red">{errors.terms_and_conditions.message}</Tag>
                  )}
                </div>
                {tokenData?.recaptcha && (
                  <div className="recaptcha">
                    <ReCAPTCHA
                      ref={recRef}
                      className={'RECAPTCHA'}
                      sitekey={RECAPTCHA_SITE_KEY}
                      theme={'dark'}
                      onChange={(e) => setCaptcha(e)}
                    />
                  </div>
                )}
                <div className="ButtonsContianer">
                  <Button
                    onClick={submitForm}
                    className="ConfirmationButton"
                    text="CONFIRM"
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading || (tokenData?.recaptcha && !captcha)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
