import React, { useState } from 'react';

import ArrowBack from '../../../../../assets/images/FunnelPage/arrow-back.svg';
import Button from '../../../../../components/Button/Button';
import Input from '../../../../../components/Form/Input/Input';
import { getCityZipCode } from '../../../../../services/city-service/city.service';

const preventCharacter = (e) => {
  const regExp = /[a-zA-Z()+-/*.,]/g;
  if (e.key.length === 1 && regExp.test(e.key)) {
    e.preventDefault();
  }
};

const ZipcodeInput = ({
  setValue = () => {},
  errors,
  setError,
  clickHandler,
  register,
  clearErrors,
  prevView,
  getValues
}) => {
  const [additionalError, setAdditionalError] = useState('');
  const [validationFinished, setValidationFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const getZipCode = (e) => {
    setValue('zip_code', e.target.value);
    if (getValues('zip_code')?.trim().length === 5) {
      setLoading(true);
      getCityZipCode(e.target.value)
        .then((res) => {
          if (res.data.data[0].zip_code && res.data.data[0].city && res.data.data.length > 0) {
            setValue('city', res.data.data[0].city);
            setLoading(false);
          }
        })
        .catch((err) => {
          setValue('city', '');
          setAdditionalError('Non-existent zipcode');
        })
        .finally(() => setValidationFinished(true));
    }
    // getValues("zip_code")?.length === 5 &&
  };

  const handleNextStep = () => {
    const zipCodeValue = getValues('zip_code');

    if (!zipCodeValue) {
      setError('zip_code', {
        type: 'manual',
        message: 'Zip code is required!'
      });

      return;
    }

    if (!/^\d+$/.test(zipCodeValue)) {
      setError('zip_code', {
        type: 'manual',
        message: 'Zip code should only contain numbers'
      });

      return;
    }

    if (zipCodeValue?.trim().length !== 5) {
      setError('zip_code', {
        type: 'manual',
        message: 'Must be exactly 5 characters'
      });

      return;
    }

    if (zipCodeValue?.trim().length === 5 && !additionalError && validationFinished) {
      clickHandler();
    }
  };
  const handleEnterClicked = (e) => {
    if (e.key === 'Enter') {
      handleNextStep();
    }
  };

  return (
    <div className="zipcode">
      <div className="arrow-back" onClick={prevView}>
        <img src={ArrowBack} alt="arrow back" />
      </div>
      <h2>Enter Your Zip Code</h2>
      <p>To see the milfs that are closest to you</p>
      <Input
        list="data"
        maxLength={5}
        onChange={(e) => {
          setAdditionalError('');
          clearErrors('zip_code');
          setValidationFinished(false);
          getZipCode(e);
        }}
        onKeyDown={preventCharacter}
        onKeyPress={handleEnterClicked}
        register={() => {}}
        type="text"
        name="zip_code"
        placeholder="Zip Code"
        errorMessage={errors.zip_code?.message}
        additionalError={additionalError}
      />
      <Button
        className={loading ? 'Disabled' : ''}
        text={loading ? 'Loading...' : 'next'}
        onClick={handleNextStep}
      />
    </div>
  );
};

export default ZipcodeInput;
