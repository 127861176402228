import axios from 'axios';
import Echo from 'laravel-echo';

export function createSocketConnection(token) {
  if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: false,
      bearerToken: `Bearer ${token}`,
      wsHost: process.env.REACT_APP_PUSHER_WS_HOST,
      wssHost: process.env.REACT_APP_PUSHER_WSS_HOST,
      wsPort: process.env.REACT_APP_PUSHER_PORT,
      wssPort: process.env.REACT_APP_PUSHER_WSS_PORT,
      enabledTransports: ['ws', 'wss'],
      authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        }
      },
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, register) => {
            axios
              .post(
                process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
                {
                  socket_id: socketId,
                  channel_name: channel.name
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json'
                  }
                }
              )
              .then((response) => {
                register(false, response.data);
              })
              .catch((error) => {
                register(true, error);
              });
          }
        };
      },
      // encrypted: true,
      transports: ['websocket', 'polling', 'flashsocket'],
      disableStats: true
    });
  }
}
