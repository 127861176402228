import './HomePageDescription.scss';

import React, { useRef } from 'react';

import PHONEIMAGE from '../../../../../../assets/images/LandingPage/pins.png';
import Button from '../../../../../../components/Button/Button';
import ImageComponent from '../../../../../../components/ImageComponent/ImageComponent';
import SignUpFlow from '../../../../../../components/SignUpFlow/SignUpFlow';

const HomePageDescription = () => {
  const registerModalRef = useRef(null);

  const openModal = (ref) => {
    ref.current.openModal();
  };

  return (
    <div className="HomePageDescription">
      <div className="HomePageDescription-Container Container">
        <div className="HomePageDescription-Content">
          <div className="Images-Container">
            <ImageComponent url={PHONEIMAGE} />
          </div>
          <div className="Text-Container">
            <div className="FlirtNearYou-Text">
              LocalFlirt <span className="FlirtNearYou-Text-light">- Free Sign Up</span>
            </div>
            <div className="SignUp-HeaderText">
              Sign up now for FREE and start exploring profiles instantly!
            </div>
            <div className="FlirtNearYou-Description">
              Right now new members can sign up for free. Register to join an exclusive community
              and start adding some excitement to your life. We can’t wait to meet you!
            </div>
            <div className="HomeSignUpButton-Container">
              <Button
                className="LandingPageButton"
                text="SIGN UP NOW"
                onClick={openModal.bind(null, registerModalRef)}
              ></Button>
            </div>
          </div>
        </div>
      </div>
      <SignUpFlow modalRef={registerModalRef} />
    </div>
  );
};

export default HomePageDescription;
