import './chatTabs.scss';

import { Badge } from 'antd';
import moment from 'moment';
import Moment from 'react-moment';

import PROFILE from '../../../../../../../../assets/images/Temp/ProfileImageSmall.jpg';
import ImageComponent from '../../../../../../../../components/ImageComponent/ImageComponent';
import ImageN from '../../../../../../../../components/ImageN/ImageN';
import { useReadMessage } from '../../../../../../../../core/messages/useReadMessage';

export const InboxItem = (props) => {
  const MONTH_MILLISECONDS = 2500000000;
  const displayUnreadMessages = (messages) => {
    if (messages < 0) return null;
    if (messages > 0 && messages < 100) return messages;
    if (messages >= 100) return '99+';
  };
  const { onClick, active } = props;
  const { username, last_message, unread_messages, profile_image, profile_id, online_status, id } =
    props.data;
  const { body, created_at } = last_message ?? { body: null, created_at: null };
  const isOlderThanMonth = moment(new Date()).diff(moment(created_at)) / MONTH_MILLISECONDS >= 0.88;
  const { mutate: read } = useReadMessage(unread_messages > 0 && active);
  const getStatus = () => (online_status ? 'success' : 'default');
  return (
    <div
      role={'button'}
      className={`inbox-item ${active === profile_id && 'active'}`}
      onClick={() => {
        onClick(props.data);
        unread_messages > 0 && active && read(id);
      }}
    >
      <div className="UserPhotoContainer">
        <Badge status={getStatus()} className={'status'} />
        {profile_image ? <ImageN name={profile_image} /> : <ImageComponent url={PROFILE} />}
      </div>
      <div>
        <div className={'username'}>{username}</div>
        <div className={'message-body'} dangerouslySetInnerHTML={{ __html: body }} />
      </div>
      <div className={'meta'}>
        <div className={'time'}>
          {isOlderThanMonth ? (
            moment.utc(last_message?.created_at).local().format('yyyy-MM-DD')
          ) : (
            <Moment fromNow ago>
              {moment.utc(last_message?.created_at).local().format()}
            </Moment>
          )}
        </div>
        <div
          className={'badge'}
          style={{ background: unread_messages ? '#9f0220' : 'transparent' }}
        >
          <span>{displayUnreadMessages(unread_messages)}</span>
        </div>
      </div>
    </div>
  );
};
