import { useEffect, useState } from 'react';

const useZoomLevel = () => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(100);

  useEffect(() => {
    const checkZoomLevel = () => {
      const outerWidth = window.outerWidth;
      const innerWidth = window.innerWidth;
      const zoomLevel = (outerWidth / innerWidth) * 100;

      setIsZoomed(zoomLevel !== 100);
      setZoomLevel(zoomLevel.toFixed(0));
    };

    window.addEventListener('resize', checkZoomLevel);
    window.addEventListener('load', checkZoomLevel);

    checkZoomLevel();

    return () => {
      window.removeEventListener('resize', checkZoomLevel);
      window.removeEventListener('load', checkZoomLevel);
    };
  }, []);

  return { isZoomed, zoomLevel };
};

export default useZoomLevel;
