import './HorizontalOption.scss';

import React from 'react';

export default function HorizontalOption({ title, backgroundImage, onClick, disabled }) {
  const optionClassName = `HorizontalOption${disabled ? ' HorizontalOptionDisabled' : ''}`;

  return (
    <div
      className={optionClassName}
      onClick={onClick}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="BackgroundOverlay"></div>
      <span>{title}</span>
    </div>
  );
}
