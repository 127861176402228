import { useMutation } from '@tanstack/react-query';

import { queryClient } from '../../../App';
import { axiosInstance } from '../../../axios/axiosInstance';
import { key as Me } from '../../../core/APIHooks/userData/useGetUserData';

export const useRemoveProfilePhoto = () =>
  useMutation(
    async () => {
      await axiosInstance.delete('/api/v2/profiles/avatar');
    },
    {
      onSuccess: () => queryClient.invalidateQueries([...Me])
    }
  );
