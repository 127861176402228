import './Warning.scss';

import Button from '../../UI/Button/Button';

export default function Warning({ onNextStep }) {
  return (
    <div className="WarningContainer">
      <div className="WarningImage"></div>
      <p className="WarningText">
        <span>WARNING!</span> This is not a dating site.
        <br /> Turn back if you don’t want pussy.
      </p>
      <Button handleNextStep={() => onNextStep()} text="I AM READY TO FUCK"></Button>
    </div>
  );
}
