import './ImageHomepage.scss';

import React from 'react';

const ImageHomepage = (props) => {
  const { onClick, name, className, classNameImg } = props;
  const classes = ['ImageComponent', className];

  return (
    <div className={classes.join(' ')}>
      <img
        className={classNameImg}
        onClick={onClick}
        src={name}
        alt={name}
        onContextMenu={(e) => e.preventDefault()}
      />
    </div>
  );
};
export default ImageHomepage;
