import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';

const key = ['HAS_SPECIAL'];
export const useHasSpecialOffer = () =>
  useQuery(
    [...key],
    async (): Promise<{ show_special_offer_popup: boolean }> => {
      const isSpecial = await axiosInstance.get('api/users/has-special-offer');
      if (
        isSpecial.data.show_special_offer_popup &&
        ((localStorage.getItem('special') &&
          JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('special')))).showAgain <
            Date.now()) ||
          !localStorage.getItem('special'))
      )
        localStorage.setItem(
          'special',
          `{"special": ${isSpecial.data.show_special_offer_popup}, "showAgain": ${
            Date.now() + 86400000
          }}`
        );
      return isSpecial.data.show_special_offer_popup;
    },
    {
      //staleTime: 1000 * 60 * 60 * 24,
    }
  );
