import * as actionTypes from '../types/messageConversationId';

const initialState = {};

const newConversationId = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NEW_CONVERSATION_ID:
      const conversationId = action.payload;
      return {
        conversationId
      };
    default:
      return state;
  }
};

export default newConversationId;
