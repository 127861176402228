import './Lander10.scss';

import { useEffect } from 'react';

import loaderImg from '../../../../../../assets/images/Lander10/loader.png';
import steps from './data/steps';
import { FallbackProvider } from './FallbackProvider';

export default function Lander10(props) {
  const { nextStep, currentFormField, submitForm, handleNextStep } = props;
  const isLastStep = currentFormField >= steps.length + 1;

  useEffect(() => {
    if (isLastStep) {
      submitForm();
    }
  }, [currentFormField]);

  const currentStep = steps[Math.min(currentFormField, steps.length) - 1];
  const { component: CurrentStep } = currentStep || {};

  return (
    <div className="LanderContainer">
      <div className="BackgroundOverlay"></div>
      {isLastStep && (
        <div className="LoadingOverlay">
          <img src={loaderImg} alt="Loader" />
        </div>
      )}

      <form
        className="LanderContent"
        onSubmit={(e) => {
          e.preventDefault();

          if (currentStep.field) {
            handleNextStep([currentStep.field]);
          }
        }}
      >
        <div className="ProgressBar">
          <div className="Percentage" style={{ width: `${currentStep?.percent || 0}%` }}>
            <span>{currentStep?.percent}%</span>
          </div>
        </div>
        <FallbackProvider>
          {CurrentStep && (
            <div className="LanderContentInner">
              <CurrentStep onNextStep={nextStep} handleNextStep={handleNextStep} {...props} />
            </div>
          )}
        </FallbackProvider>
      </form>
    </div>
  );
}
