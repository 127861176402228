import { useNavigate } from '@reach/router';
import { useQuery } from '@tanstack/react-query';
import { notification } from 'antd';
import { useRecoilState } from 'recoil';

import { axiosInstance } from '../../axios/axiosInstance';
import { userData } from '../../recoil/atoms/userDataAtom';

export const key = ['PROFILE'];
const url = 'api/member/inbox/profile';
export const useGetProfile = (id) => {
  const navigate = useNavigate();
  const [user] = useRecoilState(userData);
  const loggedUser = user?.data;

  return useQuery(
    [...key, id],
    async () => {
      const { data } = await axiosInstance.get(`${url}/${id}`);
      return data;
    },
    {
      //enabled: !!id && id !== 'undefined',
      staleTime: Infinity,
      retry: 1,
      onError: (err) => {
        notification.error({
          message: 'No conversations',
          description: 'Pick profile to start flirting!'
        });

        if (err.response.status !== 404) {
          return;
        }

        if (loggedUser) {
          navigate('/members-area');
          return;
        }

        navigate('/');
      }
    }
  );
};
