import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';

// import { queryClient } from '../../App';
import { axiosInstance } from '../../axios/axiosInstance';

export const useConfirmLandingStep = () =>
  useMutation(
    async ({ confirmData }) => {
      const { data } = await axiosInstance.post(`/api/auth/member/confirm`, confirmData);
      return data;
    },
    {
      onError: (err) => {
        if (err.response) {
          notification.error({
            message: 'Confirm error ' + err.response?.status,
            description: <p>{err?.message}</p>
          });
        }
      }
    }
  );
