import './PasswordResetConfirmationMessage.scss';

import React from 'react';

import CHECK from '../../assets/images/Modal/checkmark_red.svg';
import CIRCLE from '../../assets/images/Modal/circle.png';
import { showRootPage } from '../../services/navigation-service/navigation.service';
import ImageComponent from '../ImageComponent/ImageComponent';

const PasswordResetConfirmationMessage = (props) => {
  const { closeModal, modalRef } = props;

  const redirectToHomepage = () => {
    closeModal(modalRef);
    showRootPage();
  };
  return (
    <div className="PasswordResetConfirmationMessage">
      <div className="ImageContainer">
        <ImageComponent url={CIRCLE} />
        <ImageComponent className="CheckImage" url={CHECK} />
      </div>
      <div className="Title">Reset Successful</div>
      <div className="Subtitle">Your password has been successfully reset</div>
      <div className="Subtitle">
        <span onClick={redirectToHomepage}>Login here</span> to start flirting now!
      </div>
    </div>
  );
};

export default PasswordResetConfirmationMessage;
