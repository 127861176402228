import React, { useState } from 'react';

import casualSexBg from '../../../../../../../../assets/images/Lander10/casual-sex.png';
import coupleOfDates from '../../../../../../../../assets/images/Lander10/couple-of-dates.png';
import seriousRelationship from '../../../../../../../../assets/images/Lander10/serious-relationships.png';
import HorizontalOption from '../../HorizontalOption/HorizontalOption';
import LanderChat from '../../LanderChat/LanderChat';

export default function Relationship({ onNextStep }) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = [
    { content: 'Hey, I’m Alice, let’s find you some women.' },
    { content: 'What kind of relationship are you looking for?' }
  ];

  const toggleElementVisibility = () => {
    setIsElementVisible(true);
  };

  return (
    <>
      <LanderChat messages={messages} endChatAction={toggleElementVisibility} />
      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <HorizontalOption title="Casual Sex" backgroundImage={casualSexBg} onClick={onNextStep} />
        <HorizontalOption
          title="Couple of dates"
          backgroundImage={coupleOfDates}
          onClick={onNextStep}
        />
        <HorizontalOption
          title="Serious Relationships"
          backgroundImage={seriousRelationship}
          onClick={onNextStep}
        />
      </div>
    </>
  );
}
