import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../axios/axiosInstance';

const key = ['CHAT_STARTERS'];
const url = '/api/settings/chat-starters';
export const useChatStarters = () =>
  useQuery([...key], async () => {
    const { data } = await axiosInstance.get(url);
    return data.data.texts;
  });
