import './Button.scss';

import React from 'react';

import BaseButton from '../../../../../../../../components/Button/Button';

export default function Button({ text, loading, handleNextStep, className, disabled }) {
  return (
    <BaseButton
      text={text}
      loading={loading}
      onClick={handleNextStep}
      className={className}
      disabled={disabled}
    />
  );
}
