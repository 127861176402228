import * as actionTypes from '../types/credits.js';

const initialState = {
  creditBundles: [],
  currentUserCredits: null
};

export const credits = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CREDIT_BUNDLES:
      return {
        ...state,
        creditBundles: action.payload
      };
    case actionTypes.CURRENT_USER_CREDITS:
      return {
        ...state,
        currentUserCredits: action.payload
      };
    default:
      return { ...state };
  }
};

// export const currentCcredits = (state = initialStateCurrentCredits, action) => {
//   switch (action.type) {
//     case actionTypes.SET_CURRENT_CREDITS:
//       return action.payload;
//     default:
//       return state;
//   }
// };
