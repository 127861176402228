import './VerticalOption.scss';

import React from 'react';

export default function VerticalOption({ title, backgroundImage, onClick, landerId }) {
  const verticalOverlayClassName = `BackgroundOverlay13 version${landerId}`;
  return (
    <div
      className="VerticalOption13"
      onClick={onClick}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={verticalOverlayClassName}></div>
      <span>{title}</span>
    </div>
  );
}
