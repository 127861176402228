import './Location.scss';

import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Location({
  handleNextStep,
  typeOnlyNumbers,
  errors,
  setValue,
  clearErrors,
  cityLoading,
  watch
}) {
  return (
    <>
      <div className="LocationContainer">
        <p className="LocationText">Tell me your location</p>
        <Input
          placeholder="Enter Zip Code"
          name="zip_code"
          maxLength={5}
          onKeyDown={typeOnlyNumbers}
          {...{
            errors,
            setValue,
            clearErrors
          }}
        />
        <Button
          handleNextStep={() => handleNextStep(['zip_code'])}
          disabled={!watch('zip_code')}
          loading={cityLoading}
          text="SUBMIT"
        ></Button>
      </div>
    </>
  );
}
