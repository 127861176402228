import { Divider } from 'antd';

import { PaymentFooterCompact, Premium1, Premium2, Premium3 } from '../../../../assets/images';

export const PricingFooter = () => {
  return (
    <>
      <p className="pricing-info">
        By becoming a <span>PREMIUM</span> member you will be able to:
      </p>
      <div className="benefits-wrapper">
        <div className="benefit">
          <img src={Premium1} alt={'Premium 1'} />
          <p>
            Access ALL photos of every <br /> profile
          </p>
        </div>
        <div className="benefit">
          <img src={Premium2} alt={'premium 2'} />
          <p>
            See who is visiting your <br /> profile
          </p>
        </div>
        <div className="benefit">
          <img src={Premium3} />
          <p>
            Access to My Favorites page <br /> where you can save all other <br /> profiles you like
          </p>
        </div>
      </div>
      <Divider dashed={true} />
      <p className="pricing-info">100% secure and discreet payments</p>
      <img src={PaymentFooterCompact} />
    </>
  );
};
