import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../axios/axiosInstance';

export const key = ['ENUM_PUBLIC'];
export const useGetEnumPublic = () =>
  useQuery([key], async () => {
    const { data } = await axiosInstance.get('/api/profiles/enum-public');
    return data;
  });
