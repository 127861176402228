import { List } from 'antd';
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { useOutsideClick } from '../../hooks/useOutsideClick';

interface ContextMenu {
  context: Array<{ action: () => void; text: string }>;
  elem: RefObject<any>;
}
export const useContextMenu = ({ context, elem }: ContextMenu) => {
  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  useOutsideClick({ ref: menuRef, action: () => setShowMenu(false) });
  const handleContextMenu = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target && e.type === 'contextmenu') {
      setXPos(e.pageX);
      setYPos(e.pageY);
      setShowMenu(true);
    }
  }, []);
  const handleClick = useCallback(() => {
    showMenu && setShowMenu(false);
  }, [showMenu]);

  useEffect(() => {
    const doc = elem.current;
    if (doc) {
      doc.addEventListener('click', handleClick);
      doc.addEventListener('contextmenu', handleContextMenu);
      return () => {
        document.addEventListener('click', handleClick);
        doc.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, [elem, handleClick, handleContextMenu]);
  const menu = (
    <div
      ref={menuRef}
      style={{
        backgroundColor: 'white',
        position: 'fixed',
        left: xPos,
        top: yPos,
        zIndex: 999,
        minWidth: '130px',
        transformOrigin: '0 0',
        background: 'transparent'
      }}
    >
      <List
        style={{ background: 'white' }}
        bordered
        dataSource={context}
        renderItem={(item) => (
          <List.Item
            key={1}
            onClick={() => {
              item.action();
              setShowMenu(false);
            }}
          >
            <div style={{ cursor: 'pointer' }}>{item.text}</div>
          </List.Item>
        )}
      />
    </div>
  );

  return { xPos, yPos, showMenu, menu };
};
