import './ZipCode.scss';

import React, { useEffect } from 'react';

import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function ZipCode({
  watch,
  typeOnlyNumbers,
  errors,
  setValue,
  clearErrors,
  setIsValid,
  landerId,
  isValid,
  handleNextStep,
  currentStep,
  isElementVisible,
  setIsElementVisible
}) {
  const zip_code = watch('zip_code');

  useEffect(() => {
    setIsValid(!!zip_code);
  }, [zip_code, setIsValid]);

  return (
    <>
      <div className={isElementVisible ? 'ToggleElement SelectOptions' : 'Hidden SelectOptions'}>
        <div className="InputsContainer">
          <Input
            placeholder="Enter your ZIP Code here..."
            name="zip_code"
            maxLength={5}
            onKeyDown={typeOnlyNumbers}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
          <Button
            handleNextStep={() => {
              handleNextStep([currentStep.field]);
            }}
            text="Submit"
            disabled={!isValid || Object.keys(errors).length > 0}
          />
        </div>
      </div>
    </>
  );
}
