import './Input.scss';

import { Input as AInput, Tag } from 'antd';
import React from 'react';

export default function Input({
  placeholder,
  name,
  errors,
  setValue,
  clearErrors,
  onChange,
  ...rest
}) {
  return (
    <div className="InputContainer18">
      <AInput
        placeholder={placeholder}
        name={name}
        status={errors[name]?.message ? 'error' : ''}
        onChange={(e) => {
          setValue(name, e.target.value);
          clearErrors(name);
          onChange && onChange(e);
        }}
        {...rest}
      />
      {errors[name]?.message && <Tag type="danger">{errors[name]?.message}</Tag>}
    </div>
  );
}
