import React, { useState } from 'react';

import curvyBgBlue from '../../../../../../../../assets/images/Lander14/blue/body-type/curvy.png';
import largeBgBlue from '../../../../../../../../assets/images/Lander14/blue/body-type/large.png';
import normalBgBlue from '../../../../../../../../assets/images/Lander14/blue/body-type/normal.png';
import curvyBgLight from '../../../../../../../../assets/images/Lander14/light/body-type/curvy.png';
import largeBgLight from '../../../../../../../../assets/images/Lander14/light/body-type/large.png';
import normalBgLight from '../../../../../../../../assets/images/Lander14/light/body-type/normal.png';
import curvyBgPink from '../../../../../../../../assets/images/Lander14/pink/body-type/curvy.png';
import largeBgPink from '../../../../../../../../assets/images/Lander14/pink/body-type/large.png';
import normalBgPink from '../../../../../../../../assets/images/Lander14/pink/body-type/normal.png';
import curvyBgRed from '../../../../../../../../assets/images/Lander14/red/body-type/curvy.png';
import largeBgRed from '../../../../../../../../assets/images/Lander14/red/body-type/large.png';
import normalBgRed from '../../../../../../../../assets/images/Lander14/red/body-type/normal.png';
import LanderChat from '../../LanderChat/LanderChat';
import VerticalOption from '../../VerticalOption/VerticalOption';

export default function BodyType({ setIsValid, landerId }) {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const [activeOption, setActiveOption] = useState(null);

  const messages = {
    '14-1': [{ content: 'Neat! What woman’s body type do you prefer?' }],
    '14-2': [{ content: 'Neat! What woman’s body type do you prefer?' }],
    '14-3': [{ content: 'Neat! What woman’s body type do you prefer?' }],
    '14-4': [{ content: 'Neat! What woman’s body type do you prefer?' }]
  };

  const avatars = {
    '14-1': {
      normal: normalBgRed,
      curvy: curvyBgRed,
      large: largeBgRed
    },
    '14-2': {
      normal: normalBgBlue,
      curvy: curvyBgBlue,
      large: largeBgBlue
    },
    '14-3': {
      normal: normalBgPink,
      curvy: curvyBgPink,
      large: largeBgPink
    },
    '14-4': {
      normal: normalBgLight,
      curvy: curvyBgLight,
      large: largeBgLight
    }
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  const handleOptionClick = (option) => {
    setActiveOption(option);
    setIsValid(true);
  };

  return (
    <>
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />
      <div
        className={
          isElementVisible ? 'ToggleElement SelectOptions Body' : 'Hidden SelectOptions Body'
        }
      >
        <VerticalOption
          title="Normal"
          backgroundImage={avatars[`${landerId}`].normal}
          onClick={() => handleOptionClick('Normal')}
          isActive={activeOption === 'Normal'}
        />
        <VerticalOption
          title="Curvy"
          backgroundImage={avatars[`${landerId}`].curvy}
          onClick={() => handleOptionClick('Curvy')}
          isActive={activeOption === 'Curvy'}
        />
        <VerticalOption
          title="Large"
          backgroundImage={avatars[`${landerId}`].large}
          onClick={() => handleOptionClick('Large')}
          isActive={activeOption === 'Large'}
        />
      </div>
    </>
  );
}
