import Age from '../Steps/Age/Age';
import BodyType from '../Steps/BodyType/BodyType';
import Email from '../Steps/Email/Email';
import Gender from '../Steps/Gender/Gender';
import Location from '../Steps/Location/Location';
import Password from '../Steps/Password/Password';
import Play from '../Steps/Play/Play';
import Protection from '../Steps/Protection/Protection';
import Username from '../Steps/Username/Username';
import Warning from '../Steps/Warning/Warning';

function generateSteps() {
  const data = [
    {
      component: Warning
    },
    {
      component: Gender
    },
    {
      component: Play
    },
    {
      component: Protection
    },
    {
      component: Age
    },
    {
      component: BodyType
    },
    {
      component: Location
    },
    {
      component: Username
    },
    {
      component: Password
    },
    {
      component: Email
    }
  ];

  return data;
}

export default generateSteps;
