import { useMutation } from '@tanstack/react-query';

import { queryClient } from '../../../App';
import { axiosInstance } from '../../../axios/axiosInstance';
import { key as MemberKey } from './useGetProfile';

export const useLikeProfile = () =>
  useMutation(
    async (id: number): Promise<void> => {
      await axiosInstance.post(`api/v2/profiles/favorite/${id}`);
    },
    {
      onSuccess: (data, variables) =>
        queryClient.invalidateQueries([...MemberKey, variables.toString()])
    }
  );
