import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../axios/axiosInstance';

export const key = ['VISITORS_PROFILES'];
export const useGetVisitors = ({ page, perPage }: { page: string; perPage: number }) =>
  useQuery([key, page], async () => {
    const { data } = await axiosInstance.get('/api/v2/profiles/get-visitor-profiles', {
      params: { page, per_page: perPage }
    });
    return data;
  });
