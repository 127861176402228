import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useSetRecoilState } from 'recoil';

import { queryClient } from '../../App';
import { key as UserData } from '../../core/APIHooks/userData/useGetUserData';
import { userData } from '../../recoil/atoms/userDataAtom';
import { saveToken } from '../token-service/token.service';

const url = '/api/auth/member/login';
export const useLogin = () => {
  const setUserData = useSetRecoilState(userData);
  return useMutation(
    async ({ credentials, rememberMe }) => {
      const { data } = await axios.post(url, {
        ...credentials,
        remember_me: rememberMe
      });
      saveToken(data.access_token, data.expires_in);
      setUserData(data);
      return data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries([...UserData])
    }
  );
};
