import * as actionTypes from '../types/newMessages';

const initialState = {};

const newMessages = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NEW_MESSAGE:
      const senderId = action.payload?.conversation_id;
      return {
        [senderId]: action.payload
      };
    default:
      return state;
  }
};

export default newMessages;
