import './Info.scss';

import Button from '../../UI/Button/Button';

export default function Info({ onNextStep }) {
  return (
    <div className="InfoContainer">
      <div className="StepContent">
        <p className="InfoText">
          I want to make sure you get the ultimate sexy party experience. I just need to ask you a
          few questions about what you like.
        </p>
        <div className="ButtonContainer">
          <Button handleNextStep={() => onNextStep()} text="CONTINUE"></Button>
        </div>
      </div>
    </div>
  );
}
