import './TypeOfGirl.scss';

import { useState } from 'react';

import bbw from '../../../../../../../../assets/images/Lander18/thumbs/bbw.png';
import ebony from '../../../../../../../../assets/images/Lander18/thumbs/ebony.png';
import milf from '../../../../../../../../assets/images/Lander18/thumbs/milf.png';
import teen from '../../../../../../../../assets/images/Lander18/thumbs/teen.png';
import Button from '../../UI/Button/Button';
import VerticalOption from '../../UI/VerticalOption/VerticalOption';

export default function TypeOfGirl({ onNextStep }) {
  const [activeOption, setActiveOption] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleOptionClick = (option) => {
    setActiveOption(option);
    setButtonDisabled(false);
  };

  return (
    <div className="TypeOfGirlContainer">
      <div className="StepContent">
        <p className="TypeOfGirlText">Which type of girl do you prefer?</p>
        <div className="VerticalOptionMainContainer">
          <VerticalOption
            title="Teen"
            backgroundImage={teen}
            onClick={() => handleOptionClick('Teen')}
            isActive={activeOption === 'Teen'}
          />
          <VerticalOption
            title="Milf"
            backgroundImage={milf}
            onClick={() => handleOptionClick('Milf')}
            isActive={activeOption === 'Milf'}
          />
          <VerticalOption
            title="BBW"
            backgroundImage={bbw}
            onClick={() => handleOptionClick('BBW')}
            isActive={activeOption === 'BBW'}
          />
          <VerticalOption
            title="Ebony"
            backgroundImage={ebony}
            onClick={() => handleOptionClick('Ebony')}
            isActive={activeOption === 'Ebony'}
          />
        </div>
        <div className="ButtonContainer">
          <Button
            handleNextStep={() => onNextStep()}
            text="CONTINUE"
            disabled={buttonDisabled}
          ></Button>
        </div>
      </div>
    </div>
  );
}
