import './Lander9NoPswd.scss';

import { GoogleLogin } from '@react-oauth/google';
import { Checkbox, Input, Select, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import BACKGROUND1 from '../../../../../../assets/images/FunnelPage/lander9_bg1.png';
import BACKGROUND2 from '../../../../../../assets/images/FunnelPage/lander9_bg2.png';
import BACKGROUND3 from '../../../../../../assets/images/FunnelPage/lander9_bg3.png';
import LOGO from '../../../../../../assets/images/MembersAreaNavbar/LogoText.svg';
import Button from '../../../../../../components/Button/Button';
import ImageComponent from '../../../../../../components/ImageComponent/ImageComponent';
import ValidateEmailTypo from '../../../../../../components/ValidateEmail/ValidateEmailTypo';
import { InputNumber } from '../../../Inputs';
import { genderData, heightData } from '../../data/inputData';

const RECAPTCHA_SITE_KEY = '6LcinpYoAAAAAIB2663EgkZDzhBUq4MLSEf0Ayj8';

const Lander9NoPswd = (props) => {
  const {
    landerId,
    currentFormField,
    setValue,
    getValues,
    errors,
    watch,
    clearErrors,
    nextStep,
    handleNextStep,
    submitForm,
    loading,
    cityLoading,
    emailLoading,
    setStartAge,
    toAges,
    fromAges,
    typeOnlyNumbers,
    setGoogleLogin,
    responseGoogle,
    recaptcha,
    register
  } = props;

  const reCaptchaRef = React.createRef();

  const shouldRenderGoogleLogin = landerId.includes('sso');

  const images = [BACKGROUND1, BACKGROUND2, BACKGROUND3];
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleRecaptchaChange = (value) => {
    if (!value) {
      return;
    }

    setValue('g-recaptcha-response', value);
    submitForm();
  };

  const handleSubmitForm = () => {
    if (recaptcha && reCaptchaRef) {
      reCaptchaRef.current.execute();
      return;
    }

    submitForm();
  };

  let sliderInterval = useRef();
  let switchImages = () => {
    if (currentSlide < images.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(0);
    }
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    sliderInterval = setInterval(() => {
      switchImages();
    }, 5000);
    return () => {
      clearInterval(sliderInterval);
    };
  });

  const renderStepOne = () => {
    return (
      <div className="StepContainer">
        <div className="StepTitle">This is NOT a Dating site</div>
        <div className="StepText">
          Before we can show you a list and photos of women who are <br />
          ready to have sexual contact right now, we need to ask a few <br /> quick questions.
        </div>
        <div className="ButtonsContianer">
          <Button onClick={() => nextStep()} text="OK" />
        </div>
      </div>
    );
  };

  const renderStepTwo = () => {
    return (
      <div className="StepContainer">
        <div className="StepText">
          These women want to share their nude private pictures with <br />
          you. Do you accept?
        </div>
        <div className="ButtonsContianer">
          <Button className="Secondary" onClick={() => nextStep()} text="NO" />
          <Button onClick={() => nextStep()} text="YES" />
        </div>
      </div>
    );
  };

  const renderStepThree = () => {
    return (
      <div className="StepContainer">
        <div className="StepText">
          Many of these women are desperate single moms and <br />
          cheating wives looking for some fun. They could be your <br />
          neighbors or someone you know. Do you agree to keep the <br />
          identity of these women a secret?
        </div>
        <div className="ButtonsContianer">
          <Button className="Secondary" onClick={() => nextStep()} text="NO" />
          <Button onClick={() => nextStep()} text="YES" />
        </div>
      </div>
    );
  };

  const renderStepFour = () => {
    return (
      <div className="StepContainer">
        <div className="StepText">
          These women have asked us to not allow men who are <br />
          seeking a ‘relationship’. They only want to play. Are you <br />
          comfortable with this?
        </div>
        <div className="ButtonsContianer">
          <Button className="Secondary" onClick={() => nextStep()} text="NO" />
          <Button onClick={() => nextStep()} text="YES" />
        </div>
      </div>
    );
  };

  const renderStepFive = () => {
    return (
      <div className="StepContainer">
        <div className="StepText">Do you agree to use a condom when having sex?</div>
        <div className="ButtonsContianer">
          <Button className="Secondary" onClick={() => nextStep()} text="NO" />
          <Button onClick={() => nextStep()} text="YES" />
        </div>
      </div>
    );
  };

  const renderStepSix = () => {
    return (
      <div className="StepContainer">
        <div className="StepText">
          Are you at least 24 years old? These women have requested <br />
          contacts from older men only.
        </div>
        <div className="ButtonsContianer">
          <Button className="Secondary" onClick={() => nextStep()} text="NO" />
          <Button onClick={() => nextStep()} text="YES" />
        </div>
      </div>
    );
  };

  const renderStepSeven = () => {
    return (
      <div className="StepContainer">
        <div className="StepText">I'm a:</div>
        <div className="InputsContainer">
          <div className="InputContainer">
            <Select
              name="gender"
              placeholder="Choose gender"
              options={genderData}
              status={errors.gender?.message ? 'error' : ''}
              onChange={(value) => {
                setValue('gender', value);
                clearErrors('gender');
              }}
            />
            {errors.gender?.message && <Tag color="red">{errors.gender?.message}</Tag>}
          </div>
        </div>
        <div className="ButtonsContianer">
          <Button onClick={() => handleNextStep(['gender'])} text="NEXT" />
        </div>
      </div>
    );
  };

  const renderStepEight = () => {
    return (
      <div className="StepContainer">
        <div className="StepText">What is the ideal age of your new match?</div>
        <div className="InputsContainer">
          <div className="InputContainer">
            <Select
              placeholder="Age From"
              options={fromAges}
              onChange={(value) => {
                setValue('age_from', value);
                setStartAge(value);
                clearErrors('age_from');
              }}
            />
            {errors.age_from?.message && <Tag color="red">{errors.age_from?.message}</Tag>}
          </div>
          <div className="InputContainer">
            <Select
              placeholder="Age To"
              options={toAges}
              onChange={(value) => {
                setValue('age_to', value);
                clearErrors('age_to');
              }}
            />
            {errors.age_to?.message && <Tag color="red">{errors.age_to?.message}</Tag>}
          </div>
        </div>
        <div className="ButtonsContianer">
          <Button onClick={() => handleNextStep(['age_from', 'age_to'])} text="NEXT" />
        </div>
      </div>
    );
  };

  const renderStepNine = () => {
    return (
      <div className="StepContainer">
        <div className="StepText">Your age:</div>
        <div className="InputsContainer">
          <div className="InputContainer">
            <InputNumber name="age" {...register('age')} placeholder="Age" />
            {errors.age?.message && <Tag color="red">{errors.age?.message}</Tag>}
          </div>
        </div>
        <div className="ButtonsContianer">
          <Button onClick={() => handleNextStep(['age'])} text="NEXT" />
        </div>
      </div>
    );
  };

  const renderStepTen = () => {
    return (
      <div className="StepContainer">
        <div className="StepText">Where do you live?</div>
        <div className="InputsContainer">
          <div className="InputContainer">
            <Input
              placeholder="Enter Zip Code"
              name="zip_code"
              maxLength={5}
              onKeyDown={typeOnlyNumbers}
              status={errors.zip_code?.message ? 'error' : ''}
              onChange={(e) => {
                setValue('zip_code', e.target.value);
                clearErrors('zip_code');
              }}
            />
            {errors.zip_code?.message && <Tag color="red">{errors.zip_code?.message}</Tag>}
          </div>
        </div>
        <div className="ButtonsContianer">
          <Button onClick={() => handleNextStep(['zip_code'])} text="NEXT" loading={cityLoading} />
        </div>
      </div>
    );
  };

  const renderStepEleven = () => {
    return (
      <div className="StepContainer">
        <div className="StepText">How tall are you?</div>
        <div className="InputsContainer">
          <div className="InputContainer">
            <Select
              placeholder="Choose"
              options={heightData}
              status={errors.height?.message ? 'error' : ''}
              onChange={(value) => {
                setValue('height', value);
                clearErrors('height');
              }}
            />
            {errors.height?.message && <Tag color="red">{errors.height?.message}</Tag>}
          </div>
        </div>
        <div className="ButtonsContianer">
          <Button onClick={() => handleNextStep(['height'])} text="NEXT" />
        </div>
      </div>
    );
  };

  const renderStepTwelve = () => {
    return (
      <div className="StepContainer">
        <div className="StepText">What is your E-mail address?</div>
        <div className={`InputsContainer ${shouldRenderGoogleLogin ? 'GoogleLogin' : ''}`}>
          <div className="InputContainer">
            {shouldRenderGoogleLogin ? (
              <>
                <ValidateEmailTypo email={getValues('email')} setValue={setValue} />
                <Input
                  placeholder="example.mail@domain.com"
                  name="email"
                  value={getValues('email')}
                  status={errors.email?.message ? 'error' : ''}
                  onChange={(e) => {
                    watch('email');
                    setValue('email', e.target.value);
                    clearErrors('email');
                    setGoogleLogin(false);
                  }}
                />
                {errors.email?.message && <Tag color="red">{errors.email?.message}</Tag>}
              </>
            ) : (
              <Input
                placeholder="example.mail@domain.com"
                name="email"
                status={errors.email?.message ? 'error' : ''}
                onChange={(e) => {
                  setValue('email', e.target.value);
                  clearErrors('email');
                }}
              />
            )}
          </div>
          {shouldRenderGoogleLogin && (
            <div className="InputContainer">
              <GoogleLogin
                text="Register with Google"
                width="100%"
                onSuccess={(res) => responseGoogle(res, ['email', 'randUsr', 'randPass'])}
                onFailure={(res) => responseGoogle(res, ['email', 'randUsr', 'randPass'])}
                className="GoogleLoginButton"
              />
            </div>
          )}
        </div>
        <div className="ButtonsContianer">
          <Button
            onClick={() => handleNextStep(['email', 'randUsr', 'randPass'])}
            text="NEXT"
            loading={emailLoading}
          />
        </div>
      </div>
    );
  };

  const renderStepThirteen = () => {
    return (
      <div className="StepContainer">
        <div className="StepText">What nickname do you want?</div>
        <div className="InputsContainer">
          <div className="InputContainer">
            <Input
              placeholder="Enter your nickname"
              name="username"
              value={getValues('username')}
              status={errors.username?.message ? 'error' : ''}
              onChange={(e) => {
                setValue('username', e.target.value);
                clearErrors('username');
              }}
            />
            {errors.username?.message && <Tag color="red">{errors.username?.message}</Tag>}
            <span className="PasswordInfo">Your Welcome E-mail includes Your password!</span>
          </div>
        </div>
        <div className="ButtonsContianer">
          <Button onClick={() => handleNextStep(['username'])} text="NEXT" />
        </div>
      </div>
    );
  };

  const renderStepFourteen = () => {
    return (
      <div className="StepContainer">
        <div className="StepText">Enter your password</div>
        <div className="InputsContainer">
          <div className="InputContainer">
            <Input
              placeholder="Enter your password"
              name="password"
              type="password"
              status={errors.password?.message ? 'error' : ''}
              onChange={(e) => {
                setValue('password', e.target.value);
                clearErrors('password');
              }}
            />
            {errors.password?.message && <Tag color="red">{errors.password?.message}</Tag>}
          </div>
        </div>
        <div className="ButtonsContianer">
          <Button onClick={() => handleNextStep(['password'])} text="NEXT" />
        </div>
      </div>
    );
  };

  const renderStepFifteen = () => {
    return (
      <div className="StepContainer">
        <div className="StepSubTitle">Check your data</div>
        <div className="CheckInfoContainer">
          <p>
            <Checkbox checked={true} /> Username: {getValues('username')}
          </p>
          <p>
            <Checkbox checked={true} /> Email: {getValues('email')}
          </p>
          <p>
            <Checkbox name="terms_and_conditions" />
            <span> My data is correct & </span> I agree to the terms, privacy <br />
            policy and the use of cookies.
          </p>
          {errors.terms_and_conditions?.message && (
            <Tag color="red">{errors.terms_and_conditions?.message}</Tag>
          )}
        </div>
        <div className="ButtonsContianer">
          {recaptcha && (
            <ReCAPTCHA
              size="invisible"
              ref={reCaptchaRef}
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={handleRecaptchaChange}
            />
          )}
          <Button onClick={handleSubmitForm} text="CONFIRM" type="submit" loading={loading} />
        </div>
      </div>
    );
  };

  return (
    <div className="Lander9">
      <div className="imgWrapper">
        {images.map((img, index) => (
          <img
            key={index}
            src={img}
            className={index === currentSlide ? 'imageActive homeImage' : 'image'}
          />
        ))}
      </div>
      <div className="LanderHeader">
        <ImageComponent url={LOGO} />
      </div>
      <div className="StepsContainer">
        <form className="form" onSubmit={(e) => e.preventDefault(e)}>
          {currentFormField === 1 && renderStepOne()}
          {currentFormField === 2 && renderStepTwo()}
          {currentFormField === 3 && renderStepThree()}
          {currentFormField === 4 && renderStepFour()}
          {currentFormField === 5 && renderStepFive()}
          {currentFormField === 6 && renderStepSix()}
          {currentFormField === 7 && renderStepSeven()}
          {currentFormField === 8 && renderStepEight()}
          {currentFormField === 9 && renderStepNine()}
          {currentFormField === 10 && renderStepTen()}
          {currentFormField === 11 && renderStepEleven()}
          {currentFormField === 12 && renderStepTwelve()}
          {currentFormField === 13 && renderStepThirteen()}
          {/* {currentFormField === 14 && renderStepFourteen()} */}
          {currentFormField === 14 && renderStepFifteen()}
        </form>
      </div>
    </div>
  );
};

export default Lander9NoPswd;
