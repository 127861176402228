import './Username.scss';

import arrowLeftIcon from '../../../../../../../../assets/images/Lander11/arrow-left.svg';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Username({
  setValue,
  errors,
  prevStep,
  clearErrors,
  handleNextStep,
  getValues
}) {
  return (
    <>
      <div className="UsernameForm">
        <div className="UsernameField">
          <div className="CountdownLabelWithBack">
            <span className="CountdownLabel">Nickname</span>
            <span className="CountdownBack" onClick={prevStep}>
              <img src={arrowLeftIcon} />
              Back
            </span>
          </div>
          <Input
            placeholder="Username"
            name="username"
            value={getValues('username')}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
      </div>

      <Button text="Next" onClick={() => handleNextStep(['username'])} />
    </>
  );
}
