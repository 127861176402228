export const ROUTES = {
  ROOT: '/',
  ABOUT: '/about',
  PRICING: '/pricing',
  VERIFYACCOUNT: '/verify-account',
  FAQ: '/faq',
  CONTACT: '/contact',
  PRIVACYPOLICY: '/privacy-policy',
  TERMS: '/terms-and-conditions',
  EMAILVERIFICATIONINSTRUCTION: '/email-verification-instruction',
  SIGNUP: '/signup',
  SIGNUP2: '/signup2',
  SIGNUP3: '/signup3',
  SIGNUP6NOZIP: '/signup6nozp',
  SIGNUP4: '/signup4',
  SIGNUP5: '/signup5',
  SIGNUP5SSO: '/signup5sso',
  SIGNUP7: '/signup7',
  SIGNUP7NOSSO: '/signup7nosso',
  SIGNUP8: '/signup8',
  LANDERS: '/signup',
  MEMBERSAREA: '/members-area',
  NOTFOUND: '/not-found'
};
