import './Matches.scss';

import React, { useEffect } from 'react';

import firstRightImage from '../../../../../../../../assets/images/Lander10/matches/fifth.png';
import thirdLeftImage from '../../../../../../../../assets/images/Lander10/matches/first.png';
import centerImage from '../../../../../../../../assets/images/Lander10/matches/fourth.png';
import secondLeftImage from '../../../../../../../../assets/images/Lander10/matches/second.png';
import thirdRightImage from '../../../../../../../../assets/images/Lander10/matches/seventh.png';
import secondRightImage from '../../../../../../../../assets/images/Lander10/matches/sixth.png';
import firstLeftImage from '../../../../../../../../assets/images/Lander10/matches/third.png';
import LanderChat from '../../LanderChat/LanderChat';

export default function Matches({ onNextStep }) {
  const messages = [{ content: 'Wonderful. I have already founded a lot of \n matches for you!' }];

  useEffect(() => {
    const timer = setTimeout(() => {
      onNextStep();
    }, 2000);

    return () => clearTimeout(timer);
  }, [onNextStep]);

  return (
    <>
      <LanderChat messages={messages} />

      <div className="avatar-container">
        <img src={centerImage} alt="Center Avatar" className="avatar center-avatar" />
        <img src={firstLeftImage} alt="Left Avatar 1" className="avatar left-avatar" />
        <img src={secondLeftImage} alt="Left Avatar 2" className="avatar left-avatar" />
        <img src={thirdLeftImage} alt="Left Avatar 3" className="avatar left-avatar" />
        <img src={firstRightImage} alt="Right Avatar 1" className="avatar right-avatar" />
        <img src={secondRightImage} alt="Right Avatar 2" className="avatar right-avatar" />
        <img src={thirdRightImage} alt="Right Avatar 3" className="avatar right-avatar" />
      </div>

      <p className="MatchesText">2345 potential matches are waiting for you!</p>
    </>
  );
}
