import { combineReducers } from 'redux';

import authentication from '../redux/reducers/authentication';
import { credits } from '../redux/reducers/credits';
import latestMessage from '../redux/reducers/latestMessage';
import newConversationId from '../redux/reducers/messageConversationId';
import newConversationStatus from '../redux/reducers/messageConversationStatus';
import newMessages from '../redux/reducers/newMessages';
import notifications from '../redux/reducers/notifications';
import paymentNotification from '../redux/reducers/paymentNotification';
import pricing from '../redux/reducers/pricing.js';
import profile from '../redux/reducers/profile';
import profileAvatar from '../redux/reducers/profileAvatar';
import profiles from '../redux/reducers/profiles.js';
import sidebar from '../redux/reducers/sidebar.js';
import staticData from '../redux/reducers/staticData.js';
import subscriptionTriggers from '../redux/reducers/subscriptionTriggers.js';
import welcomeMessage from '../redux/reducers/welcomeMessage';
import { zendeskWidget } from '../redux/reducers/zendesk';

export default combineReducers({
  authentication,
  sidebar,
  profile,
  staticData,
  profiles,
  pricing,
  credits,
  newMessages,
  subscriptionTriggers,
  latestMessage,
  paymentNotification,
  notifications,
  profileAvatar,
  welcomeMessage,
  zendeskWidget,
  newConversationId,
  newConversationStatus
});
