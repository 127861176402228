import React from 'react';

import ArrowBack from '../../../../../assets/images/FunnelPage/arrow-back.svg';
import Button from '../../../../../components/Button/Button';
import Input from '../../../../../components/Form/Input/Input';

const UsernamePasswordInput = ({
  register,
  trigger,
  errors,
  clearErrors,
  clickHandler,
  prevView,
  setValue
}) => {
  const handleNextStep = () => {
    trigger('username')
      .then(() => trigger('password'))
      .then(() => {
        const errorPassUsername = Object.keys(errors).filter(
          (err) => err === 'username' || err === 'password'
        );
        if (errorPassUsername.length === 0) {
          clickHandler();
        }
      });
  };
  const handleEnterClicked = (e) => {
    if (e.key === 'Enter') {
      handleNextStep();
    }
  };

  return (
    <div className="user-pass">
      <div className="arrow-back" onClick={prevView}>
        <img src={ArrowBack} alt="arrow back" />
      </div>
      <h2>Create Your Username &amp; Password</h2>
      <Input
        register={register}
        name="username"
        placeholder="Username"
        errorMessage={errors?.username?.message}
        onChange={(e) => {
          setValue('username', e.target.value);
          clearErrors('username');
          clearErrors('email');
        }}
        onKeyPress={handleEnterClicked}
      />
      <Input
        register={register}
        name="password"
        placeholder="Password"
        errorMessage={errors?.password?.message}
        onChange={(e) => {
          setValue('password', e.target.value);
          clearErrors('password');
        }}
        onKeyPress={handleEnterClicked}
        type="password"
      />
      <Button text="next" onClick={handleNextStep} />
    </div>
  );
};

export default UsernamePasswordInput;
