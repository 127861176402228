import { Modal } from 'antd';
import React from 'react';

import MatchGameFilters from '../../../../../../components/MatchGameFilters/MatchGameFilters';

interface SettingsModalProps {
  open: boolean;
  onClose: () => void;
  onFilterSet: (e: any) => void;
}
export const SettingsModal = ({ open = true, onClose, onFilterSet }: SettingsModalProps) => {
  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <MatchGameFilters
        updateFilters={(
          filter: Partial<{
            age_between: [number, number];
            hair_color: string;
            body_body_build: string;
          }>
        ) => {
          onFilterSet(filter);
          onClose();
        }}
      />
    </Modal>
  );
};
