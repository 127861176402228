import './Radio.scss';

import React, { useState } from 'react';

const Radio = ({ onChange, name, value, gender }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    onChange(name, !checked, value);
    setChecked(!checked);
  };

  return (
    <div className="Radio">
      <input
        onChange={handleChange}
        className="FormRadioInput"
        type="radio"
        name={name}
        id={value + name}
      />
      <label className="FormRadioLabel" htmlFor={value + name}>
        <span className="FormRadioButton"></span>
        <span>{gender}</span>
      </label>
    </div>
  );
};

export default Radio;
