import './Select.scss';

import React from 'react';

const Select = ({ register, name, errorMessage, options, emptyValue, ...rest }) => {
  return (
    <div className="SelectField">
      <select className="Select" {...register(name)} {...rest}>
        {options ? (
          options?.map((option) => (
            // eslint-disable-next-line react/no-unknown-property
            <option key={option.id} value={option.city} datavalue={option.zip_code}>
              {option.city}
            </option>
          ))
        ) : (
          <option value={emptyValue}>{emptyValue}</option>
        )}
      </select>
      <div className="Error-Container">{errorMessage}</div>
    </div>
  );
};

export default Select;
