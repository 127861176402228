import './Button.scss';

import React from 'react';

import BaseButton from '../../../../../../../../components/Button/Button';

export default function Button({ text, loading, handleNextStep, className }) {
  return (
    <BaseButton text={text} loading={loading} onClick={handleNextStep} className={className} />
  );
}
