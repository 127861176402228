import {
  SET_CURRENT_PROFILE,
  SET_PAGINATION_DATA,
  SET_PROFILES,
  SET_PROFILES_LOADING
} from '../types/profiles';

export const setProfiles = (profiles) => {
  return {
    type: SET_PROFILES,
    payload: profiles
  };
};

export const setProfilesLoading = (loading) => {
  return {
    type: SET_PROFILES_LOADING,
    payload: loading
  };
};

export const setPaginationData = (paginationData) => {
  return {
    type: SET_PAGINATION_DATA,
    payload: paginationData
  };
};

export const setCurrentProfile = (payload) => {
  return {
    type: SET_CURRENT_PROFILE,
    payload
  };
};
