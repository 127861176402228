import { useCallback } from 'react';

import { queryClient } from '../App';
import { authLogout } from '../services/auth-service/auth.service';
import { showRootPage } from '../services/navigation-service/navigation.service';
import { cleanupToken } from '../services/token-service/token.service';
import { cleanupUserData } from '../services/user-service/user.service';
import { key as googleApiKey } from '../V2/services/auth/useGetGoogleApiKey';

export const useLogout = () => {
  const logout = useCallback(() => {
    const logoutSuccess = () => {
      queryClient.invalidateQueries(googleApiKey);
      cleanupToken();
      cleanupUserData();
      localStorage.clear();
      showRootPage();
    };

    const logoutFailure = (error) => {
      if (error?.response?.status === 401) {
        localStorage.clear();
      }
      showRootPage();
    };

    authLogout().then(logoutSuccess).catch(logoutFailure);
  }, []);

  return logout;
};
