import { GET_CREDIT_BUNDLES } from '../types/credits';

export const setCredits = (credits) => {
  return {
    type: GET_CREDIT_BUNDLES,
    payload: credits
  };
};

// export const setCurrentCredits = (credits) => {
//   return {
//     type: CURRENT_USER_CREDITS,
//     payload: credits,
//   };
// };
