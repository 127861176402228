import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';
import { ShowPopUnderPayload } from '../../model/interfaces/ShowPopUnderPayload';

export const key = ['SHOW_POP_UNDER'];

export const useShowPopUnder = () =>
  useQuery(
    [...key],
    async (): Promise<ShowPopUnderPayload> => {
      const { data } = await axiosInstance.get('api/users/show-pop-under');
      return data;
    },
    {
      staleTime: 0,
      enabled: false
    }
  );
