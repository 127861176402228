import './Lander15.scss';

import { useEffect, useState } from 'react';

import useCheckMobileScreen from '../../../../../../hooks/useCheckMobileScreen';
// import loaderImg from '../../../../../../assets/images/Lander14/loader.gif';
// import Button from '../Lander15/UI/Button/Button';
import generateSteps from './data/steps';
import { FallbackProvider } from './FallbackProvider';
import LanderChat from './LanderChat/LanderChat';
import { messages } from './LanderChat/messages';

const hasOption = (slug) => {
  const regex = new RegExp(`/15-(1|2|3|4)/.*${slug}`);
  return regex.test(window.location.href);
};

export default function Lander15(props) {
  const { nextStep, currentFormField, submitForm, handleNextStep, errors, landerId, emailLoading } =
    props;
  const [isValid, setIsValid] = useState(false);
  const [isElementVisible, setIsElementVisible] = useState(false);
  const isMobile = useCheckMobileScreen();

  const options = {
    autogenerateUsername: hasOption('nousr'),
    autogeneratePassword: hasOption('nopswd'),
    hideLocation: hasOption('noloc'),
    hasSSO: hasOption('sso')
  };

  const steps = generateSteps(options);
  const isLastStep = currentFormField >= steps.length + 1;
  const landerVersionClassName = `LanderContainer15 Version${landerId}`;
  const landerVersionContent = `LanderContent Version${landerId}`;

  console.log('currentFormField ==> ', currentFormField);

  const toggleElementVisibility = () => {
    setIsElementVisible(true);
  };

  const getResponsiveImage = (mobileImage, image) => {
    return isMobile ? mobileImage : image;
  };

  useEffect(() => {
    console.log('isLastStep _----> ', isLastStep);
    if (isLastStep) {
      console.log('submit form');
      submitForm();
    }
    setIsElementVisible(false);
  }, [currentFormField]);

  const currentStep = steps[Math.min(currentFormField, steps.length) - 1];
  const { component: CurrentStep } = currentStep || {};

  return (
    <div className={landerVersionClassName}>
      {/* {isLastStep && (
        <div className="LoadingOverlay">
          <img src={loaderImg} alt="Loader" />
        </div>
      )} */}
      <div className={currentFormField != 10 ? 'ProgressBar' : 'Hidden'}>
        <div className="Percentage" style={{ width: `${currentStep?.percent || 0}%` }}>
          <span>{currentFormField}/9</span>
        </div>
      </div>
      <form
        className={landerVersionContent}
        onSubmit={(e) => {
          e.preventDefault();

          if (currentStep.field) {
            handleNextStep([currentStep.field]);
          }
        }}
      >
        <LanderChat
          messages={messages[landerId][`${currentFormField}`]}
          endChatAction={toggleElementVisibility}
          landerId={landerId}
          currentFormField={currentFormField}
          isLastStep={isLastStep}
        />
        <div className="ContentContainer">
          <FallbackProvider>
            {CurrentStep && (
              <>
                <div className="LanderContentInner15">
                  <CurrentStep
                    onNextStep={nextStep}
                    handleNextStep={handleNextStep}
                    setIsValid={setIsValid}
                    isValid={isValid}
                    options={options}
                    currentStep={currentStep}
                    isElementVisible={isElementVisible}
                    setIsElementVisible={setIsElementVisible}
                    submitForm={submitForm}
                    emailLoading={emailLoading}
                    getResponsiveImage={getResponsiveImage}
                    {...props}
                  />
                  {/* <Button
                    handleNextStep={() => {
                      handleNextStep([currentStep.field]);
                      setIsValid(false);
                    }}
                    text="Submit"
                    disabled={!isValid || Object.keys(errors).length > 0}
                  /> */}
                </div>
              </>
            )}
          </FallbackProvider>
        </div>
      </form>
    </div>
  );
}
