import './Gender.scss';

import React, { useState } from 'react';

import FEMALEBLUE from '../../../../../../../../assets/images/Lander12/blue/female.png';
import MALEBLUE from '../../../../../../../../assets/images/Lander12/blue/male.png';
import FEMALEORANGE from '../../../../../../../../assets/images/Lander12/orange/female.png';
import MALEORANGE from '../../../../../../../../assets/images/Lander12/orange/male.png';
import FEMALEPURPLE from '../../../../../../../../assets/images/Lander12/purple/female.png';
import MALEPURPLE from '../../../../../../../../assets/images/Lander12/purple/male.png';
import FEMALERED from '../../../../../../../../assets/images/Lander12/red/female.png';
import MALERED from '../../../../../../../../assets/images/Lander12/red/male.png';
import LanderChat from '../../LanderChat/LanderChat';
import VerticalOption from '../../VerticalOption/VerticalOption';

export default function Gender({ onNextStep, setValue, landerId }) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = {
    '12-1': [
      {
        content:
          'Hey handsome, I’m Hannah. You’re looking for a local hookup? Please tell me, are you:'
      }
    ],
    '12-2': [
      {
        content: 'Hey gorgeous, I’m Gemma. I hear you’re looking for a local fuck? Are you:'
      }
    ],
    '12-3': [
      {
        content: 'I’m Sydney, your guide to local sex in minutes. Please tell me, are you:'
      }
    ],
    '12-4': [
      {
        content:
          'Hello, I’m Lucy. Let me help you find some local love. Can you please tell me, are you:'
      }
    ]
  };

  const avatars = {
    '12-1': {
      male: MALERED,
      female: FEMALERED
    },
    '12-2': {
      male: MALEORANGE,
      female: FEMALEORANGE
    },
    '12-3': {
      male: MALEPURPLE,
      female: FEMALEPURPLE
    },
    '12-4': {
      male: MALEBLUE,
      female: FEMALEBLUE
    }
  };

  const handleGenderSelect = (gender) => {
    setValue('gender', gender);
    onNextStep();
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />
      <div className={isElementVisible ? 'Options ToggleElement' : 'Options Hidden'}>
        <VerticalOption
          title="MALE"
          backgroundImage={avatars[`${landerId}`].male}
          onClick={() => handleGenderSelect('male')}
          landerId={landerId}
        />
        <VerticalOption
          title="FEMALE"
          backgroundImage={avatars[`${landerId}`].female}
          onClick={() => handleGenderSelect('female')}
          landerId={landerId}
        />
      </div>
    </>
  );
}
