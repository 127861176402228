import { Skeleton, Space } from 'antd';

export const HeaderSkeleton = ({ active }) => {
  return (
    <div>
      <Space>
        <Skeleton.Avatar active={active} shape={'circle'} size={'large'} />
        <Space direction={'vertical'}>
          <div>
            <Skeleton.Input active={active} size={'small'} />
          </div>
          <div>
            {active ? (
              <Skeleton.Input active={active} size={'small'} />
            ) : (
              <div>No profile loaded</div>
            )}
          </div>
        </Space>
      </Space>
    </div>
  );
};
