import { useNavigate } from '@reach/router';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo } from 'react';

import { useGetUserData } from '../../core/APIHooks/userData/useGetUserData';
import { PurchaseType } from '../../V2/model/enum/PurchaseType';
import { useCheckOutPayment } from '../../V2/services/payment/useCheckOutPayment';

const useEmailOffer = (): void => {
  const navigate = useNavigate();
  const url = useMemo(() => new URL(window.location.href), []);
  const queryString = url.search;
  const urlParams = useMemo(() => new URLSearchParams(queryString), [queryString]);
  const { data } = useGetUserData();

  const EMAIL_OFFER = localStorage.getItem('EMAIL_OFFER');
  const ACCESS_TOKEN = localStorage.getItem('token');
  const emailOfferParams = new URLSearchParams(EMAIL_OFFER?.split('?')[1]);
  const subscription_trigger_local_storage = emailOfferParams.get('subscription_trigger');
  const package_id_local_storage = emailOfferParams.get('package');

  const package_id = parseInt(urlParams.get('package') ?? package_id_local_storage ?? '0');
  const subscription_trigger =
    urlParams.get('subscription_trigger') ?? subscription_trigger_local_storage;

  const { mutate: buy } = useCheckOutPayment(subscription_trigger);

  const handleEmailOffer = useCallback(() => {
    if (subscription_trigger?.includes('email_offer') && ACCESS_TOKEN) {
      const redirectUrl = `${url.origin}${url.pathname}`;
      buy(
        {
          package_id,
          type: PurchaseType.PURCHASE,
          redirect_url: redirectUrl,
          subscription_trigger
        },
        {
          onSuccess: ({ redirect_url }) => {
            localStorage.removeItem('EMAIL_OFFER');
            if (redirectUrl != redirect_url) {
              notification.error({ message: 'Invalid card' });
              return;
            }

            navigate(redirect_url);
            notification.success({ message: '20 Free Credits Issued Successfully' });
          },
          onError: (error: unknown) => {
            localStorage.removeItem('EMAIL_OFFER');
            const axiosError = error as AxiosError;
            notification.error({ message: axiosError?.response?.data.message });
          }
        }
      );
    }
  }, [subscription_trigger, urlParams, buy, navigate, url]);

  useEffect(() => {
    if (data?.data?.id) {
      handleEmailOffer();
    }
  }, [data?.data?.id, handleEmailOffer]);
};

export default useEmailOffer;
