import axios from 'axios';

import { showRootPage } from '../services/navigation-service/navigation.service';
import { cleanupToken, getToken } from '../services/token-service/token.service';
import { cleanupUserData } from '../services/user-service/user.service';

const setAuthorizationHeader = (config) => {
  config.headers['Authorization'] = `Bearer ${getToken()}`;
  config.headers['Cache-Control'] = `no-cache, max-age = 0, no-store, must-revalidate`;
  config.headers['Pragma'] = `no-cache`;
  config.headers['Expires'] = `0`;

  return config;
};

const cleanup = () => {
  cleanupToken();
  cleanupUserData();
};

export const createRequestInterceptor = () => {
  axios.interceptors.request.use((config) => {
    if (!config.headers.doNotAddUserTokenAuthorization) {
      config = setAuthorizationHeader(config);
    }
    delete config.headers.doNotAddUserTokenAuthorization;
    return config;
  });
};

export const createResponseInterceptor = (store) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401 && error.response?.config.url !== '/auth') {
        cleanup(store);
        showRootPage();
      }
      return Promise.reject(error);
    }
  );
};
