import './ListOfConversations.scss';

import React, { useRef } from 'react';

import TabsComponent from '../../../../../../../components/TabsComponent/TabsComponent';
import ConversationUserListAll from './ConversationUserListAll/ConversationUserListAll';

const ListOfConversations = (props) => {
  const { setMobileComponentActive, mobileComponentActive } = props;
  const conversationRef = useRef(null);
  const inboxRef = useRef(null);

  const tabsData = [
    {
      id: 1,
      title: 'INBOX',
      content: (
        <ConversationUserListAll name="inbox" setMobileComponentActive={setMobileComponentActive} />
      ),
      ref: inboxRef
    },
    {
      id: 2,
      title: 'CONVERSATIONS',
      content: (
        <ConversationUserListAll
          name="conversation"
          setMobileComponentActive={setMobileComponentActive}
        />
      ),
      ref: conversationRef
    }
  ];
  return (
    <div
      className={
        mobileComponentActive === 0 ? 'ListOfConversations' : 'ListOfConversations MobileClosed'
      }
    >
      <TabsComponent tabsData={tabsData} />
    </div>
  );
};

export default ListOfConversations;
