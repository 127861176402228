import '../../../MessagesNew/ListOfConversations/ConversationUserListAll/ConversationUserListAll.scss';

import { Badge } from 'antd';
import React from 'react';

import BACK from '../../../../../../../../assets/images/back_arrow.png';
import PROFILE from '../../../../../../../../assets/images/Temp/ProfileImageSmall.jpg';
import ImageComponent from '../../../../../../../../components/ImageComponent/ImageComponent';
import ImageN from '../../../../../../../../components/ImageN/ImageN';

export const ConversationHeader = (props) => {
  const { isMobile, onBack, onClick } = props;
  const { profile_image, username, online_status } = props.profile;
  const getStatus = () => (online_status ? 'success' : 'default');
  return (
    <>
      {isMobile && (
        <div className="BackArrowContainer" onClick={() => onBack && onBack()}>
          <ImageComponent url={BACK} />
        </div>
      )}
      <div className={'user'} onClick={() => onClick && onClick()}>
        <div className={'UserPhotoContainer'}>
          {profile_image ? <ImageN name={profile_image} /> : <ImageComponent url={PROFILE} />}
        </div>
        <div>
          <div className={'username'}>{username}</div>
          <div>
            {' '}
            <Badge status={getStatus()} className={'status'} />
            <span className={'status-text'}>{online_status ? 'Online' : 'Offline'}</span>
          </div>
        </div>
      </div>
    </>
  );
};
