import './ButtonWithIcon.scss';

import React from 'react';

const ButtonWithIcon = (props) => {
  const { text, onClick, className, iconSrc } = props;
  const classes = ['Button', className];

  return (
    <div className={classes.join(' ')} onClick={onClick}>
      <img src={iconSrc} className="iconSrc" />
      <p className="buttonText">{text}</p>
    </div>
  );
};

export default ButtonWithIcon;
