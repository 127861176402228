/* eslint-disable react/no-unescaped-entities */
import './PrivacyPolicy.scss';

import React from 'react';

import HEADERIMAGE from '../../../../../assets/images/HeadersImages/PrivacyPolicy-Header.png';
import HeaderLayout from '../../../../../components/HeaderLayout/HeaderLayout';
import { showTermsAndConditionPage } from '../../../../../services/navigation-service/navigation.service';

const PrivacyPolicy = () => {
  const title = 'Privacy Policy';
  const description = 'Effective date: 15th January 2022';
  const image = HEADERIMAGE;
  return (
    <div className="Privacy-Policy">
      <HeaderLayout title={title} description={description} image={image} />

      <div className="Privacy-Container">
        <p>Effective date: 15th January 2022</p>
        <p>
          Gochamie Inc ("us", "we", or "our") operates the WWW.LOCALFLIRT.COM website (the
          "Service").
        </p>
        <p>
          This section is to inform you of our policies regarding the collection, use, and
          disclosure of personal data when you use our Service and the choices you have associated
          with that data.
        </p>
        <p>
          Your data is used by us to provide and improve the Service. By using the Service, you
          agree to the collection and use of information in accordance with this policy. Unless
          otherwise detailed in this Privacy Policy, terms in this Privacy Policy have the same
          meanings as used in our Terms and Conditions which can be accessed here{' '}
          <span className="redirectLink" onClick={showTermsAndConditionPage}>
            Terms and Conditions{' '}
          </span>
        </p>
        <p>Definitions</p>
        <ul>
          <li>
            <h3>Service</h3>
            <p>Service is the WWW.LOCALFLIRT.COM website operated by Gochamie Inc</p>
          </li>
          <li>
            <h3>Personal Data</h3>
            <p>
              Personal Data is referred to data about a living individual who can be identified from
              those data (or from those and other information which is already in our possession or
              likely to come into our possession).
            </p>
          </li>
          <li>
            <h3>Usage Data</h3>
            <p>
              Usage Data is data collected automatically and generated by the use of the Service or
              from the Service infrastructure itself (for example, the time period/duration of a
              page visit).
            </p>
          </li>
          <li>
            <h3>Cookies</h3>
            <p>
              Cookies are referred to small pieces of data stored that is on your device (computer
              or mobile device).
            </p>
          </li>
          <li>
            <h3>Data Controller</h3>
            <p>
              Data Controller is referred to the natural or legal person who (either alone or in a
              group or in common with other persons) decides the purposes for which and the manner
              in which any personal information are, or are to be, processed.For the purpose of this
              Privacy Policy, we are the Data Controller of your Personal Data.
            </p>
          </li>
          <li>
            <h3>Data Processors (or Service Providers)</h3>
            <p>
              Data Processor (or Service Provider) refers to any natural or legal person who
              processes the data on behalf of the Data Controller. In order to process your data
              more efficiently we may use the services of various other Service Providers.
            </p>
          </li>
          <li>
            <h3>Data Subject (or User)</h3>
            <p>
              Data Subject refers to any living individual who uses our Service and is the subject
              of Personal Data.
            </p>
          </li>
        </ul>
        <h3>Information Collection and Use</h3>
        <p>
          To improve and provide our service to you, we collect several various types of information
          for different purposes.
        </p>
        <h3>Types of Data Collected</h3>
        <h3>Personal Data</h3>
        <p>
          When our Service is being used, you may be asked to provide us with certain personally
          identifiable information that could be used to contact or identify you ("Personal Data").
          Personally identifiable information can include, but is not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Cookies and Usage Data</li>
        </ul>
        <h3>Usage Data</h3>
        <p>
          Information may also be collected on how the Service is accessed and used ("Usage Data").
          This Usage Data may include information relating to your computer's Internet Protocol
          address (e.g. IP address), browser type, browser version, the pages of our Service that
          you visit, the time and date of your visit, the time spent on those pages, unique device
          identifiers and other diagnostic data.
        </p>
        <h3>Tracking & Cookies Data</h3>
        <p>
          Cookies and similar tracking technologies may be used to track the activity on our Service
          and hold certain information.
        </p>
        <p>
          Cookies are referred to files that hold small amount of data which might include
          information of an anonymous unique identifier. Cookies work by being sent to your browser
          from a website and stored on your device. Tracking technologies such as beacons, tags, and
          scripts are used to collect and track information as well as to analyze and improve our
          Service.
        </p>
        <p>
          You can command your browser to refuse all cookies or to alert when a cookie is being
          sent. But if you do not accept cookies, some portions of our Service may be inaccessible
          to you.
        </p>
        <p>Examples of Cookies we use:</p>
        <ul>
          <li>
            <h4>Session Cookies</h4>
            <p>Session Cookies are used to operate our Service.</p>
          </li>
          <li>
            <h4>Preference Cookies</h4>
            <p>
              Preference Cookies are used for remembering your preferences and various settings.
            </p>
          </li>
          <li>
            <h4>Security Cookies</h4>
            <p>Security Cookies are used for security reasons.</p>
          </li>
        </ul>
        <h3>Use of Data</h3>
        <p>Gochamie Inc uses the collected data for several purposes:</p>
        <ul>
          <li>To provide and maintain our Service</li>
          <li>To notify you if there are any changes to our Service</li>
          <li>
            To allow you to participate in interactive features of our Service when you choose to do
            so
          </li>
          <li>To provide customer support</li>
          <li>To improve our Service by gathering analysis or valuable information</li>
          <li>To monitor the usage of our Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>
        <h3>Retention of Data</h3>
        <p>
          Your personal data will be retained by Gochamie Inc only for the necessary time period for
          the purposes mentioned in this Privacy Policy. We will retain and use your Personal Data
          to the extent necessary to comply with our legal obligations (for example, if we are
          required to retain your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p>
          Gochamie Inc will also retain Usage Data for internal analysis purposes. Usage Data is
          generally retained for a shorter period of time, except when this data is used to
          strengthen the security or to improve the functionality of our Service, or we are legally
          obligated to retain this data for longer time periods.
        </p>
        <h3>Transfer of Data</h3>
        <p>
          Your information, including Personal Data, may be transferred to — and maintained on —
          computers located outside of your state, province, country or other governmental
          jurisdiction where the data protection laws may differ than those from your jurisdiction.
        </p>
        <p>
          If you choose to provide information to us while being located outside, please note that
          we will transfer the data, including Personal Data, to and process it there.
        </p>
        <p>
          Your represent your agreement to the transfer by consenting to this Privacy Policy
          followed by your submission of such information.
        </p>
        <p>
          Gochamie Inc will take all steps reasonably necessary to ensure that your data is treated
          securely and in accordance with this Privacy Policy and no transfer of your Personal Data
          will take place to an organization or a country unless there are adequate controls in
          place including the security of your data and other personal information.
        </p>
        <h3 className="Underlined">Disclosure of Data</h3>
        <p>
          We do not sell, rent or otherwise disclose data or personal information collected by our
          Site to any third party. Site has invested considerably in security hardware, software and
          protocols to ensure all member information remains secure. Members can exercise their
          right to be forgotten or to see what information is being held on them. Any entitled party
          making a valid request for data, data modification or data deletion shall receive it.
          Valid requests should be made through the contact details below.
        </p>
        <h3>Business Transaction</h3>
        <p>
          If Gochamie Inc is involved in a merger, acquisition or asset sale, your Personal Data may
          be transferred. You will be notified before your Personal Data is transferred and becomes
          subject to a different Privacy Policy.
        </p>
        <h3>Disclosure for Law Enforcement</h3>
        <p>
          Under certain circumstances, Gochamie Inc may be required to disclose your Personal Data
          if requested by the law or in response to valid requests by public authorities (e.g. a
          court or a government agency).
        </p>
        <h3>Legal Requirements</h3>
        <p>
          Gochamie Inc may disclose your Personal Data in the good faith belief that such action is
          necessary to:
        </p>
        <ul>
          <li>To comply with a legal obligation</li>
          <li>To protect and defend the rights or property of Gochamie Inc</li>
          <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>To protect the personal safety of users of the Service or the public</li>
          <li>To protect against legal liability</li>
        </ul>
        <h3>Security of Data</h3>
        <p>
          The security of your data is important to us, but bear in mind that no method of
          transmission over the Internet, or any method of electronic storage is promised to be 100%
          secure. While we strive to use commercially acceptable means to protect your Personal Data
          as best as we can, we cannot guarantee its absolute security.
        </p>
        <h3>Service Providers</h3>
        <p>
          We may employ third party companies and individuals to facilitate our Service ("Service
          Providers"), to provide the Service on our behalf, to assist us in analyzing how our
          Service is used or to perform Service-related services.
        </p>
        <p>
          These third parties will have access to your Personal Data only to perform these tasks on
          our behalf and are not permitted to disclose or use it for any other reasons.
        </p>
        <h3>Analytics</h3>
        <p>
          We may use third-party Service Providers to analyze and monitor the use of our Service.
        </p>
        <ul>
          <li>
            <p>Google Analytics</p>
            <p>
              Google Analytics is a web analytics service offered by Google that helps to track and
              report website traffic. Google uses the collected data to track and monitor the use of
              our Service and this specific data is shared with other Google services. Google may
              use the collected data for its own advertising network to contextualize and
              personalize the ads.By installing the Google Analytics opt-out browser add-on you will
              be allowed to opt-out of having made your activity on the Service available to Google
              Analytics. This particular add-on prevents the Google Analytics JavaScript (ga.js,
              analytics.js, and dc.js) from sharing information with Google Analytics about activity
              related to visits.To know more about the privacy practices of Google, please visit the
              Google Privacy & Terms web page:{' '}
              <a rel="noreferrer" target="_blank" href="https://policies.google.com/privacy?hl=en">
                https://policies.google.com/privacy?hl=en
              </a>
            </p>
          </li>
        </ul>
        <h3>Payments</h3>
        <p>
          We may provide paid products and/or services within the Service. We use third-party
          services for payment processing (e.g. payment processors) for that purpose.
        </p>
        <p>
          We will not store or collect your payment card details. That information is provided
          directly to our third-party payment processors who has their own set of Privacy Policy
          rules to use your personal information.
        </p>
        <p>
          These payment processors adhere to the standards set by PCI-DSS as managed by the PCI
          Security Standards Council, which is a joint effort of brands like Visa, Mastercard,
          American Express and Discover. PCI-DSS requirements help ensure the secure handling of
          payment information.
        </p>
        <h3>Links to Other Sites</h3>
        <p>
          Our Service may contain links to other sites which are not operated by us. If you click on
          a third party link, you will be directed to that third party's site. We strongly recommend
          that you review the Privacy Policy of every site you visit.
        </p>
        <p>
          Privacy policy practices and content of any third party sites or services are not in our
          control and we take no responsibility for it.
        </p>
        <h3>Children's Privacy</h3>
        <p>Our Service does not address anyone under the age of 18 ("Children").</p>
        <p>
          We do not knowingly collect personally identifiable information from anyone under the age
          of 18. Please contact us if you are a parent or guardian and you are aware that your child
          has provided us with Personal Data. If we become aware that we have collected Personal
          Data from children without verification of parental consent, we take steps to remove that
          information from our servers as soon as possible.
        </p>
        <h3>Scope of this Privacy Policy</h3>
        <p>
          This privacy policy applies to your use of our services owned or operated by the Gochamie
          Inc on WWW.LOCALFLIRT.COM.This policy does not apply to your use of unaffiliated sites to
          which we only link to.
        </p>
        <h3>General digital content policy</h3>
        <p>
          Gochamie Inc is legally authorized to offer this service on WWW.LOCALFLIRT.COM. The
          content offered on WWW.LOCALFLIRT.COM does not contain any sexually-oriented adult
          (pornographic) content. WWW.LOCALFLIRT.COM does not include software that would damage
          anyone’s computer, which could be used for malicious purposes like sending spam emails or
          spreading a computer virus, or would violate anyone’s privacy (spyware or cookies, for
          example).
        </p>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update our Privacy Policy from time to time. If any changes occur, you will be
          notified by us through the postings of the new Privacy Policy on this page.
        </p>
        <p>We will update the "effective date" at the top of this Privacy Policy.</p>
        <p>
          We advise you to review this Privacy Policy periodically for any potential changes. Any
          changes to this Privacy Policy are effective when they are posted on this page.
        </p>
        <h3>Contact Us</h3>
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <ul>
          <li>
            By email:{' '}
            <a
              className="Support-Text"
              href="mailto:support@localflirt.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              support@localflirt.com{' '}
            </a>{' '}
          </li>
          <li>By phone number: +1 877-381-0324</li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
