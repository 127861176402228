import './ProfileInfo.scss';

import parse from 'html-react-parser';
import React from 'react';

import BACK from '../../../../../../../assets/images/back_arrow.png';
import EYECIRCLE from '../../../../../../../assets/images/MembersArea/eye_circle.png';
import EYESHAPE from '../../../../../../../assets/images/MembersArea/eye_shape.png';
import ImageComponent from '../../../../../../../components/ImageComponent/ImageComponent';
import ImageN from '../../../../../../../components/ImageN/ImageN';
import { showMemberProfilePage } from '../../../navigation.service';

const ProfileInfo = (props) => {
  const { selectedProfileData, mobileComponentActive, setMobileComponentActive } = props;

  const stringToHTML = () => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(selectedProfileData?.description, 'text/html');
    return <div className="DescriptionContainer">{parse(doc.body.innerHTML)}</div>;
  };

  const renderOnlineStatus = () => {
    return (
      <div className="OnlineStatusContainer">
        <span className="OnlineStatus"></span>
        <span> Online</span>
      </div>
    );
  };

  const renderOfflineStatus = () => {
    return (
      <div className="OfflineStatusContainer">
        <span className="OfflineStatus"></span>
        <span> Offline</span>
      </div>
    );
  };

  const renderBackArrow = () => {
    return (
      <div className="BackArrowContainer">
        <ImageComponent url={BACK} onClick={setMobileComponentActive.bind(null, 1)} />
      </div>
    );
  };

  const renderProfileHeader = () => {
    return (
      <div className="ProfileHeader">
        {renderBackArrow()}
        <div className="UserPhotoContainer">
          {selectedProfileData?.avatar_image?.thumbnail_url && (
            <ImageN name={selectedProfileData?.avatar_image?.thumbnail_url} />
          )}
        </div>
        <div className="UserNameContainer">
          <div className="Username">{selectedProfileData?.username}</div>
          <div className="UserStatusPreview">
            {selectedProfileData?.online_status ? renderOnlineStatus() : renderOfflineStatus()}
          </div>
        </div>
      </div>
    );
  };

  const renderUserProfile = () => {
    return (
      <div
        // className="ProfileInfoContainer"
        className={
          mobileComponentActive === 2 ? 'ProfileInfoContainer' : 'ProfileInfoContainer MobileClosed'
        }
        // onTouchMove={handleTouchMove}
        // onTouchStart={handleTouchStart}
        // onTouchEnd={handleTouchEnd}
      >
        {window.innerWidth < 501 && renderProfileHeader()}
        <div className="ProfileInfo">
          <div
            // className={
            //   sideBar.sideBarValue
            //     ? 'ProfileImageContainer-Sidebar'
            //     : 'ProfileImageContainer'
            // }
            className="ProfileImageContainer"
            onClick={showMemberProfilePage.bind(null, selectedProfileData?.id)}
          >
            {
              selectedProfileData?.avatar_url && (
                <ImageN name={selectedProfileData?.avatar_url} className="profile-img" />
              )
              //  : <ImageComponent url={PROFILE} className="profile-img"/>
            }

            <div
              className="ViewProfileIconContainer"
              onClick={showMemberProfilePage.bind(null, selectedProfileData?.id)}
            >
              <ImageComponent className="eye-shape" url={EYESHAPE} />
              <ImageComponent className="eye-shape" url={EYECIRCLE} />
            </div>
            <div
              // className={sideBar.sideBarValue ? 'UserInfo-Sidebar' : 'UserInfo'}
              className="UserInfo"
            >
              {selectedProfileData ? (
                <>
                  {selectedProfileData?.username},{' '}
                  {new Date().getFullYear() - selectedProfileData?.birthday?.slice(0, 4)}{' '}
                </>
              ) : (
                ''
              )}
            </div>
            <div className="GradientBackground"></div>
          </div>
          <div className="ProfileDescriptionContainer">
            {selectedProfileData ? stringToHTML() : ''}
          </div>
        </div>
      </div>
    );
  };

  return renderUserProfile();
};

export default ProfileInfo;
