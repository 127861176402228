export const stepList = [
  {
    id: 0,
    header: (
      <div className="MainHeader">
        The <span>ONLY</span> site that gets you online flirts!
      </div>
    ),
    body: (
      <div className="TextBox">
        <div className="WarningText">WARNING!</div>
        <div className="StandardText Centered">There are lots of gorgeous women on this site.</div>
        <div className="SecondaryText">Please be discret.</div>
      </div>
    ),
    image: 'image',
    buttons: [
      {
        text: 'OK',
        function: 'nextStep',
        type: ['nextStep']
      }
    ]
  },
  {
    id: 1,
    header: <div>Attention</div>,
    body: (
      <div className="StandardText">
        Before we can show you a list and photos of members who are ready to have a sexual
        experience right now, we need to ask a few quick questions.
      </div>
    ),
    buttons: [
      {
        text: 'CONTINUE',
        function: 'nextStep',
        type: ['nextStep']
      }
    ]
  },
  {
    id: 2,
    header: (
      <div className="QuestionHeader">
        <div>Question</div> <div className="Active QuestionNumber">1</div>
        <div className="QuestionNumber">2</div>
        <div className="QuestionNumber">3</div>
        <div className="QuestionNumber">4</div>
        <div className="QuestionNumber">5</div>
        <div className="QuestionNumber">6</div>
        {/* <div className="QuestionNumber">7</div> */}
      </div>
    ),
    body: (
      <div className="StandardText">
        Many of these women are desperate single moms and cheating wives looking for some fun. They
        could be one of your neighbors or someone you know. Do you agree to keep the identity of
        these women a secret?
      </div>
    ),
    buttons: [
      {
        text: 'NO',
        second: true,
        function: 'nextStep',
        type: ['nextStep']
      },
      {
        text: 'YES',
        function: 'nextStep',
        type: ['nextStep']
      }
    ]
  },
  {
    id: 3,
    header: (
      <div className="QuestionHeader">
        <div>Question</div> <div className="QuestionNumber">1</div>
        <div className="Active QuestionNumber">2</div>
        <div className="QuestionNumber">3</div>
        <div className="QuestionNumber">4</div>
        <div className="QuestionNumber">5</div>
        <div className="QuestionNumber">6</div>
        {/* <div className="QuestionNumber">7</div> */}
      </div>
    ),
    body: (
      <div className="StandardText">
        These women only want to play. Are you comfortable with this?
      </div>
    ),
    buttons: [
      {
        text: 'NO',
        second: true,
        function: 'nextStep',
        type: ['nextStep']
      },
      {
        text: 'YES',
        function: 'nextStep',
        type: ['nextStep']
      }
    ]
  },
  {
    id: 4,
    header: (
      <div className="QuestionHeader">
        <div>Question</div> <div className="QuestionNumber">1</div>
        <div className="QuestionNumber">2</div>
        <div className="Active QuestionNumber">3</div>
        <div className="QuestionNumber">4</div>
        <div className="QuestionNumber">5</div>
        <div className="QuestionNumber">6</div>
        {/* <div className="QuestionNumber">7</div> */}
      </div>
    ),
    body: (
      <div className="StandardText">
        Are you at least 24 years old? These women have requested that we don’t let those anyone
        younger than 24 contact them, due to previous experiences.
      </div>
    ),
    buttons: [
      {
        text: 'NO',
        second: true,
        function: 'nextStep',
        type: ['nextStep']
      },
      {
        text: 'YES',
        function: 'nextStep',
        type: ['nextStep']
      }
    ]
  },
  {
    id: 5,
    header: (
      <div className="QuestionHeader">
        <div>Question</div> <div className="QuestionNumber">1</div>
        <div className="QuestionNumber">2</div>
        <div className="QuestionNumber">3</div>
        <div className="Active QuestionNumber">4</div>
        <div className="QuestionNumber">5</div>
        <div className="QuestionNumber">6</div>
        {/* <div className="QuestionNumber">7</div> */}
      </div>
    ),
    body: <div className="StandardText">Do you agree to practice safe sex?</div>,
    input: [
      {
        type: 'email',
        name: 'email',
        label: 'Email',
        placeholder: 'email@exampledomain.com'
      }
    ],
    buttons: [
      {
        text: 'GOOGLE',
        function: 'nextStep',
        type: ['google']
      },
      {
        text: 'AGREE',
        function: 'nextStep',
        type: ['email']
      }
    ]
  },
  // {
  //   id: 6,
  //   header: (
  //     <div className="QuestionHeader">
  //       <div>Question</div> <div className="QuestionNumber">1</div>
  //       <div className="QuestionNumber">2</div>
  //       <div className="QuestionNumber">3</div>
  //       <div className="QuestionNumber">4</div>
  //       <div className="Active QuestionNumber">5</div>
  //       <div className="QuestionNumber">6</div>
  //       <div className="QuestionNumber">7</div>
  //     </div>
  //   ),
  //   body: (
  //     <div className="StandardText">Do you want to make your profile stand out from the crowd?</div>
  //   ),
  //   input: [
  //     {
  //       type: 'text',
  //       name: 'phone',
  //       label: 'Enter your phone number:',
  //       placeholder: 'Enter your phone',
  //     },
  //   ],
  //   inputInfo:
  //     '*Your phone number will only be used for verification purposes and will not be shared with any 3rd parties.',
  //   buttons: [
  //     {
  //       text: 'YES, PLEASE',
  //       function: 'nextStep',
  //       type: ['nextStep'],
  //     },
  //     {
  //       text: 'SKIP FOR NOW',
  //       second: true,
  //       function: 'nextStep',
  //       type: ['nextStep'],
  //     },
  //   ],
  // },
  {
    id: 6,
    header: (
      <div className="QuestionHeader">
        <div>Question</div> <div className="QuestionNumber">1</div>
        <div className="QuestionNumber">2</div>
        <div className="QuestionNumber">3</div>
        <div className="QuestionNumber">4</div>
        <div className="Active QuestionNumber">5</div>
        <div className="QuestionNumber">6</div>
        {/* <div className="QuestionNumber">7</div> */}
      </div>
    ),
    body: <div className="StandardText">Almost there!</div>,
    input: [
      {
        type: 'tel',
        name: 'age',
        label: 'Your Age:',
        placeholder: 'Enter your age'
      },
      {
        type: 'zipCode',
        name: 'zip_code',
        label: 'ZIP code:',
        placeholder: 'Enter your ZIP code'
      }
    ],
    buttons: [
      {
        text: 'TO FINAL STEP',
        function: 'nextStep',
        type: ['age', 'zip_code']
      }
    ]
  },
  {
    id: 7,
    header: (
      <div className="QuestionHeader">
        <div>Question</div> <div className="QuestionNumber">1</div>
        <div className="QuestionNumber">2</div>
        <div className="QuestionNumber">3</div>
        <div className="QuestionNumber">4</div>
        <div className="QuestionNumber">5</div>
        <div className="Active QuestionNumber">6</div>
        {/* <div className="QuestionNumber">7</div> */}
      </div>
    ),
    body: <div className="StandardText">Please choose Username and Password</div>,
    input: [
      {
        type: 'text',
        name: 'username',
        label: 'Username:',
        placeholder: 'Enter username'
      },
      {
        type: 'password',
        name: 'password',
        label: 'Password:',
        placeholder: 'Enter password'
      }
    ],
    buttons: [
      {
        text: 'AGREE & COMPLETE',
        function: 'submit',
        type: ['username', 'password']
      }
    ]
  }
];
