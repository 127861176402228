import './creditsModal.scss';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { PaymentFooter } from '../../../../assets/images';
import { userData } from '../../../../recoil/atoms/userDataAtom';
import { useGetAllPackages } from '../../../services/packages/useGetAllPackages';
import { CreditsCard } from '../../shared/CreditsCard/CreditsCard';

interface CreditsModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  profileId?: number;
  lastMessageId?: number;
}
export const CreditsModal = ({
  isModalOpen,
  closeModal,
  profileId,
  lastMessageId
}: CreditsModalProps) => {
  const [user] = useRecoilState(userData);
  const { data: packages, isSuccess } = useGetAllPackages(Boolean(user?.data?.profile?.id));
  const [isStarter, setIsStarter] = useState(false);

  const renderPackages = (isSpecial: PhpBool) => {
    return packages
      ?.filter((item) => item.is_special_offer === isSpecial && item.starter === 0)
      .map((pkg) => (
        <CreditsCard
          redirectUrl={location.href}
          trigger={'credits_modal'}
          packageDef={pkg}
          key={pkg.id}
          profileId={profileId}
          lastMessageId={lastMessageId}
          onSuccess={() => {
            closeModal();
          }}
        />
      ));
  };
  const renderStarterPackage = () => {
    return packages?.map((pkg) =>
      pkg.starter ? (
        <div className={'ModalStarterPackage'} key={pkg.id}>
          <CreditsCard
            trigger={'credits_modal'}
            redirectUrl={location.href}
            packageDef={pkg}
            key={pkg.id}
            profileId={profileId}
            lastMessageId={lastMessageId}
            starterPackage={true}
            onSuccess={() => {
              closeModal();
            }}
          />
          <div className="SelectBundleContainer">
            or <span onClick={() => setIsStarter(false)}>Select a bigger bundle</span>
          </div>
        </div>
      ) : null
    );
  };

  useEffect(() => {
    if (packages) {
      const starter = packages.some((pkg) => pkg.starter);
      isSuccess && setIsStarter(starter);
    }
  }, [isSuccess, packages]);

  return (
    <Modal
      width={window.innerWidth * 0.5}
      open={isModalOpen}
      footer={
        <div className={'modal-footer'}>
          <img src={PaymentFooter} alt={'footer'} />
        </div>
      }
      title={
        <>
          {' '}
          {!isStarter && packages?.some((pkg) => pkg.starter) && (
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
              onClick={() => setIsStarter(true)}
            >
              <ArrowLeftOutlined />
              Go back
            </div>
          )}
        </>
      }
      maskClosable
      mask
      className={'credits-modal'}
      onCancel={closeModal}
    >
      <div className="purchase-modal-title">
        <div className={'warning'}>Oops! You ran out of credits.</div>
        <div>
          <span className={'buy'}>Buy now</span> to keep flirting
        </div>
      </div>
      <div className={'credits-modal-body'}>
        {isStarter ? (
          <div>
            <div style={{ marginBottom: 10 }}>{renderPackages(1)}</div>
            <div>{renderStarterPackage()}</div>
          </div>
        ) : (
          renderPackages(0)
        )}
      </div>
    </Modal>
  );
};
