import './SignUpAff4.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@reach/router';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import LOGO from '../../../../assets/images/FunnelPage/Aff4LogoText.svg';
import PROFILEONE from '../../../../assets/images/FunnelPage/aff4prof.png';
import Button from '../../../../components/Button/Button';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Dropdown from '../../../../components/Form/Dropdown/Dropdown';
import Input from '../../../../components/Form/Input/Input';
import ImageComponent from '../../../../components/ImageComponent/ImageComponent';
import { useGetEnumPublic } from '../../../../core/APIHooks/useGetEnumPublic';
import { useEmailValidation } from '../../../../core/emailValidation/useEmailValidation';
import { authMe, memberLogin } from '../../../../services/auth-service/auth.service';
import { showEmailVerificationInstruction } from '../../../../services/navigation-service/navigation.service';
import { saveToken } from '../../../../services/token-service/token.service';
import { setUserLocalData } from '../../../../services/user-service/user.service';
import { handleAuthReactivation } from '../../helpers/authReactivation';
import { InputNumber } from '../Inputs';
import ZipcodeInput from './FormInputs/ZipcodeInput';

const schema = yup.object().shape({
  username: yup
    .string()
    .required('Username is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  password: yup
    .string()
    .required('Password is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  // email: yup.string().required('Email is required!').email('Invalid Email Address'),
  age: yup
    .number()
    .typeError('Age is required!')
    .required('Age is required!')
    .test('len', 'You must be at least 18 years', (val) => val && val > 17),
  zip_code: yup
    .string()
    .required('Zip code is required!')
    .test('numbers', 'Zip code should only contain numbers', (val) => /^\d+$/.test(val))
    .test('len', 'Must be exactly 5 characters', (val) => val && val.trim().length === 5),
  age_from: yup.string().required('Please select age from option'),
  age_to: yup.string().required('Please select age to option')
});

const SignUpAff4 = () => {
  const [currentFormField, setCurrentFormField] = useState(1);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [gender, setGender] = useState('');
  const [seeking, setSeeking] = useState('');
  const [loadingZipCode, setLoadingZipCode] = useState(false);
  const [googleLogin, setGoogleLogin] = useState(false);
  const [fromAges, setFromAges] = useState([]);
  const [toAges, setToAges] = useState([]);
  const [startAge, setStartAge] = useState();
  const [loading, setLoading] = useState(false);
  const clickID = urlParams.get('clickId');
  const affiliate = urlParams.get('affiliate');
  const source = urlParams.get('source');
  const { mutate: validateEmail, isLoading } = useEmailValidation();
  const { data: enumPublic } = useGetEnumPublic();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    let days = [];
    for (let i = 100; i >= 18; i--) {
      days.push({
        id: i,
        value: i
      });
    }
    setFromAges(days);
  }, []);
  useEffect(() => {
    let days = [];
    if (startAge)
      for (let i = 100; i >= startAge; i--) {
        days.push({
          id: i,
          value: i
        });
      }
    setToAges(days);
  }, [startAge]);

  const handleSeekingValue = (value) => {
    setCurrentFormField(2);
    setSeeking(value);
  };

  const handleGenderValue = (value) => {
    setCurrentFormField(6);
    setGender(value);
  };

  const handleNextStep = async (type, view) => {
    const isValid = await trigger(type);
    if (isValid) {
      setCurrentFormField(view);
    }
  };

  const handleNextStepTwoInput = (typeOne, typeTwo, view) => {
    trigger(typeOne)
      .then(() => trigger(typeTwo))
      .then(() => {
        if (Object.keys(errors).length === 0) {
          setCurrentFormField(view);
        }
      });
  };
  const checkboxChange = () => {};

  const renderMediaContainer = () => {
    return (
      <>
        <div className="NavbarAff">
          <ImageComponent url={LOGO} />
        </div>
        <div className="SignUpFree">
          <p>
            Sign up is free!
            <br />
            Find us at <a href="https://localflirt.com/">LocalFlirt.com</a>
          </p>
        </div>
      </>
    );
  };

  const renderStepOne = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="Steps">
          <div className="Aff4Message">
            <img src={PROFILEONE} alt="" />
            <div className="RectMessage">
              <p>
                Hey, I’m Amber and I’m the site admin for localflirt.com{' '}
                <span style={{ color: '#9F0220' }}>How can I help you?</span>
              </p>
            </div>
          </div>
          <div className="Aff4FormContainer">
            {enumPublic?.seeking.map((value) => (
              <Button
                key={value}
                className={value === 'men' ? 'Inverted' : ''}
                onClick={() => handleSeekingValue(value)}
                text={`I AM LOOKING FOR A ${value.toUpperCase()}`}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderStepTwo = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="Steps">
          <div className="Aff4Message">
            <img src={PROFILEONE} alt="" />
            <div className="RectMessage">
              <p>
                Thanks. I’m going to find you the best matches, can you tell me your{' '}
                <span style={{ color: '#9F0220' }}>date of birth?</span>
              </p>
            </div>
          </div>
          <div className="Aff4FormContainer">
            <div className="InputContainers">
              {/* <DatePicker
                control={control}
                name="birthday"
                placeholder="Birthday"
                errorMessage={errors?.birthday?.message}
                className="AffFlowInput"
                placement="top-end"
              /> */}
              <InputNumber
                name="age"
                {...register('age')}
                placeholder="Age"
                errorMessage={errors?.age?.message}
              />
              {/* <DatePicker2
                register={register}
                control={control}
                errorMessage={errors?.birthday?.message}
                name="birthday"
              /> */}
            </div>
            <Button onClick={() => handleNextStep('age', 3)} text="Continue" />
          </div>
        </div>
      </div>
    );
  };

  const renderStepThree = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="Steps">
          <div className="Aff4Message">
            <img src={PROFILEONE} alt="" />
            <div className="RectMessage MessageSmall">
              <p>
                Nice! Let’s keep going,{' '}
                <span style={{ color: '#9F0220' }}>what age do you prefer?</span>
              </p>
            </div>
          </div>
          <div className="Aff4FormContainer">
            <div className="ButtonContainers">
              <div className="DropDownContainer">
                <Dropdown
                  onChange={(e) => setStartAge(e.target.value)}
                  register={register('age_from')}
                  className="Dropdown-Select"
                  error={errors?.age_from?.message}
                  placeholder="Age from"
                  name="value"
                  options={fromAges}
                />
              </div>
              <div className="DropDownContainer">
                <Dropdown
                  disabled={!startAge}
                  register={register('age_to')}
                  className="Dropdown-Select"
                  error={errors?.age_to?.message}
                  placeholder="Age to"
                  name="value"
                  options={toAges}
                />
              </div>
            </div>
            <Button
              className={`${
                !errors?.age_to?.message && !errors?.age_from?.message ? 'ButtonMessage' : ''
              }`}
              onClick={() => handleNextStepTwoInput('age_from', 'age_to', 4)}
              text="Continue"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderStepFour = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="Steps">
          <div className="Aff4Message">
            <img src={PROFILEONE} alt="" />
            <div className="RectMessage MessageSmall">
              <p>
                And <span style={{ color: '#9F0220' }}>which location??</span>
              </p>
            </div>
          </div>
          <div className="Aff4FormContainer">
            <div className="InputContainers">
              <ZipcodeInput
                register={register}
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                clearErrors={clearErrors}
                errors={errors}
                setLoadingZipCode={setLoadingZipCode}
              />
            </div>
            <Button
              onClick={() => handleNextStep('zip_code', 5)}
              text={loadingZipCode ? 'Loading...' : 'Continue'}
              className={loadingZipCode ? 'DisabledButton' : ''}
              disabled={loadingZipCode}
            />
          </div>
        </div>
      </div>
    );
  };
  const renderStepFive = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="Steps">
          <div className="Aff4Message">
            <img src={PROFILEONE} alt="" />
            <div className="RectMessage">
              <p>
                Silly me, I nearly forgot to ask...{' '}
                <span style={{ color: '#9F0220' }}>are you a man or a woman?</span>
              </p>
            </div>
          </div>
          <div className="Aff4FormContainer">
            {enumPublic?.gender.map((value) => {
              if (value !== 'prefer not to tell') {
                return (
                  <Button
                    key={value}
                    className={value === 'male' ? 'Inverted' : ''}
                    onClick={() => handleGenderValue(value)}
                    text={`I AM A ${value === 'male' ? 'MAN' : 'WOMAN'}`}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderStepSix = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="Steps">
          <div className="Aff4Message">
            <img src={PROFILEONE} alt="" />
            <div className="RectMessage MessageSmall">
              <p>
                Thanks. Now <span style={{ color: '#9F0220' }}>create a username</span> for your
                profile
              </p>
            </div>
          </div>
          <div className="Aff4FormContainer">
            <div className="InputContainers">
              <Input
                register={register}
                name="username"
                placeholder="Username"
                errorMessage={errors?.username?.message}
                onChange={(e) => {
                  setValue('username', e.target.value);
                  clearErrors('username');
                  clearErrors('email');
                }}
              />
            </div>
            <Button onClick={() => handleNextStep('username', 7)} text="Continue" />
          </div>
        </div>
      </div>
    );
  };

  const emailValidation = () => {
    const username = getValues('username');
    const password = getValues('password');
    const age = getValues('age');
    const birthYear = new Date().getFullYear() - age;
    const gender = getValues('gender');
    const seeking = getValues('seeking');
    const city = getValues('city');
    const email = getValues('email');
    const zip_code = getValues('zip_code');
    const landerId = googleLogin ? 7 : '4typed_email';
    const data = {
      email,
      username,
      password,
      password_confirmation: password,
      click_id: clickID,
      affiliate_id: affiliate,
      lander_id: landerId,
      profile: {
        age: age,
        ...(age ? { birthday: `${birthYear}-01-01` } : {}),
        city: city,
        zip_code: zip_code,
        seeking: seeking,
        gender: gender
      },
      ...(source ? { source_id: source } : {})
    };
    validateEmail(data, {
      onSuccess: (res) => {
        handleAuthReactivation(res, email, navigate, () => {
          setValue('email', getValues('email'));
          clearErrors('email');
          handleNextStep('email', 8);
        });
      },
      onError: (err) => {
        setError('email', {
          type: 'manual',
          message: err.data.errors.email[0]
        });
      }
    });
  };

  const renderStepSeven = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="Steps">
          <div className="Aff4Message">
            <img src={PROFILEONE} alt="" />
            <div className="RectMessage MessageBig">
              <p>
                I’ve found <span style={{ color: '#9F0220' }}>49</span> singles in your area. To
                view their profiles, I’ll create you a localflirt.com account right now for free.
                <span style={{ color: '#9F0220' }}> What’s your e-mail?</span>
              </p>
            </div>
          </div>
          <div className="Aff4FormContainer">
            <div className="InputContainers">
              <Input
                register={register}
                name="email"
                placeholder="johndoe@gmail.com"
                errorMessage={errors?.email?.message}
                onChange={(e) => {
                  setValue('email', e.target.value);
                  clearErrors('username');
                  clearErrors('email');
                }}
                onKeyDown={(e) => {
                  setGoogleLogin(false);
                  if (e.keyCode === 32) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            {/* <div className="TextContainer">OR</div>
            <div className="GoogleLoginContainer">
              <GoogleLogin
                clientId="49889973396-1mklb7sakkn7mkkjrd4h947hsfnnf0t8.apps.googleusercontent.com"
                buttonText="Register with Google"
                width="100%"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                // isSignedIn={true}
              />
            </div> */}
            {/* <Button onClick={() => handleNextStep('email', 8)} text="Continue" /> */}
            <Button
              onClick={emailValidation}
              text="Continue"
              disabled={isLoading}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    );
  };
  const renderStepEight = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="Steps">
          <div className="Aff4Message">
            <img src={PROFILEONE} alt="" />
            <div className="RectMessage">
              <p>
                Nearly there! I just need you to{' '}
                <span style={{ color: '#9F0220' }}>pick a secure password</span> to protect your
                account
              </p>
            </div>
          </div>
          <div className="Aff4FormContainer">
            <div className="InputContainers">
              <Input
                register={register}
                name="password"
                placeholder="Password"
                errorMessage={errors?.password?.message}
                onChange={(e) => {
                  setValue('password', e.target.value);
                  clearErrors('password');
                }}
                // onKeyPress={handleEnterClicked}
                type="password"
              />
            </div>
            <Button onClick={() => handleNextStep('password', 9)} text="Continue" />
          </div>
        </div>
      </div>
    );
  };

  const renderStepNine = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="Steps">
          <div className="Aff4Message">
            <img src={PROFILEONE} alt="" />
            <div className="RectMessage">
              <p>
                Done! I’ll open your account on the below details.{' '}
                <span style={{ color: '#9F0220' }}>Please confirm.</span>
              </p>
            </div>
          </div>
          <div className="Aff4FormContainer">
            <div className="InputContainers">
              <div className="Checkbox-Container">
                <div className="Checkbox-Holder">
                  <Checkbox onChange={checkboxChange} name="userName" initChecked={true} />
                  <p>Username: {getValues('username')}</p>
                </div>
                <div className="Checkbox-Holder">
                  <Checkbox onChange={checkboxChange} name="email" initChecked={true} />
                  <p>E-mail address: {getValues('email')}</p>
                </div>
              </div>
            </div>

            <Button
              className={loading ? 'DisabledButton' : ''}
              onClick={submitForm}
              text={loading ? 'Loading...' : 'Confirm'}
            />
          </div>
        </div>
      </div>
    );
  };
  const onSubmit = (data) => {
    setLoading(true);
    const birthYear = new Date().getFullYear() - data.age;
    const landerId = googleLogin ? 4 : '4typed_email';
    let submitData = { ...data, seeking, gender };
    let registerData = {};
    if (source) {
      registerData = {
        username: submitData.username,
        email: submitData.email,
        password: submitData.password,
        password_confirmation: submitData.password,
        terms_and_conditions: 1,
        click_id: clickID,
        affiliate_id: affiliate,
        source_id: source,
        lander_id: landerId,
        profile: {
          seeking: submitData.seeking,
          gender: submitData.gender,
          city: submitData.city,
          zip_code: submitData.zip_code,
          birthday: `${birthYear}-01-01`,
          age: data.age
        }
      };
    } else {
      registerData = {
        username: submitData.username,
        email: submitData.email,
        password: submitData.password,
        password_confirmation: submitData.password,
        terms_and_conditions: 1,
        click_id: clickID,
        affiliate_id: affiliate,
        lander_id: landerId,
        profile: {
          seeking: submitData.seeking,
          gender: submitData.gender,
          city: submitData.city,
          zip_code: submitData.zip_code,
          birthday: `${birthYear}-01-01`,
          age: data.age
        }
      };
    }

    axios
      .post('/api/auth/member/register', registerData, {
        headers: {
          doNotAddUserTokenAuthorization: true
        }
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          localStorage.setItem('current-email', data.email);
          localStorage.setItem('affiliateSiteLockdown', new Date());
          // memberLogin(registerData).then((res) =>
          //   saveToken(res.data.access_token, res.data.expires_in)
          // );
          // showEmailVerificationInstruction();
          const loginData = {
            username: res.data.user.username,
            email: res.data.user.email,
            password: registerData.password
          };
          memberLogin(loginData)
            .then((res) => {
              setLoading(true);
              saveToken(res.data.access_token, res.data.expires_in);
              localStorage.setItem('userData', JSON.stringify(res.data.user));
              localStorage.setItem('affiliateSiteLockdown', new Date());
              googleLogin
                ? authMe(res.data.access_token)
                    .then((response) => {
                      setLoading(false);
                      setUserLocalData(response.data);
                      window.location.href = '/members-area';
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err);
                    })
                : showEmailVerificationInstruction();
              // setUserLocalData(res.data.user);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        } else {
          throw new Error('Something went wrong!');
        }
      })
      .catch((err) => {
        setLoading(false);
        setGoogleLogin(false);
        const data = err.response.data.errors;
        Object.keys(err.response.data.errors).forEach((name) => {
          let message = data[name][0];
          setError(name, { type: 'manual', message });
          // if (name === "username") {
          //   setCurrentFormField(6);
          // }
          if (name === 'email') {
            setCurrentFormField(7);
          }
        });
      });
  };

  const submitForm = (e) => {
    handleSubmit((data) => onSubmit(data))(e);
  };
  return (
    <div className="SignUpAff4">
      {/*<Helmet>
        <script>localStorage.setItem("accGuid", "2abd06aa-ef0d-449f-ac71-bd12413dace8")</script>
        <script src="https://pushcity1.xyz/ace-push.js" defer></script>
      </Helmet>*/}
      <form className="form" onSubmit={(e) => e.preventDefault(e)}>
        {currentFormField === 1 && renderStepOne()}
        {currentFormField === 2 && renderStepTwo()}
        {currentFormField === 3 && renderStepThree()}
        {currentFormField === 4 && renderStepFour()}
        {currentFormField === 5 && renderStepFive()}
        {currentFormField === 6 && renderStepSix()}
        {currentFormField === 7 && renderStepSeven()}
        {currentFormField === 8 && renderStepEight()}
        {currentFormField === 9 && renderStepNine()}
      </form>
    </div>
  );
};

export default SignUpAff4;
