import './ImageComponent.scss';

import React from 'react';

const ImageComponent = (props) => {
  const { onClick, url, className, imgClassName } = props;
  const classes = ['ImageComponent', className];

  return (
    <div className={classes.join(' ')}>
      <img
        className={imgClassName}
        onClick={onClick}
        src={url}
        alt={url}
        onContextMenu={(e) => e.preventDefault()}
      />
    </div>
  );
};

export default ImageComponent;
