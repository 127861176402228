import './Username.scss';

import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Age({ handleNextStep, errors, setValue, clearErrors, watch }) {
  return (
    <>
      <div className="UsernameContainer">
        <div className="UsernameImage"></div>
        <p className="UsernameText">Enter your Username</p>
        <Input
          placeholder="Username"
          name="username"
          {...{
            errors,
            setValue,
            clearErrors
          }}
        />
        <Button
          handleNextStep={() => handleNextStep(['username'])}
          text="SUBMIT"
          disabled={!watch('username')}
        ></Button>
      </div>
    </>
  );
}
