import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';
import { UserPreferences } from '../../model/classes/UserPreferences';

export const key = ['USER_PREFERENCE_DATA'];

export const useGetUserPreferences = (userId: number) =>
  useQuery(
    [...key],
    async (): Promise<UserPreferences> => {
      const { data } = await axiosInstance.get(`api/users/${userId}/preferences`);
      return data.data;
    },
    {
      enabled: !!userId,
      retry: 0
    }
  );
