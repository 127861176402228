import './BodyType.scss';

import { useState } from 'react';

import big from '../../../../../../../../assets/images/Lander18/thumbs/big.png';
import huge from '../../../../../../../../assets/images/Lander18/thumbs/huge.png';
import medium from '../../../../../../../../assets/images/Lander18/thumbs/medium.png';
import small from '../../../../../../../../assets/images/Lander18/thumbs/small.png';
import Button from '../../UI/Button/Button';
import VerticalOption from '../../UI/VerticalOption/VerticalOption';

export default function BodyType({ onNextStep }) {
  const [activeOption, setActiveOption] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleOptionClick = (option) => {
    setActiveOption(option);
    setButtonDisabled(false);
  };
  return (
    <div className="BodyTypeContainer">
      <div className="StepContent">
        <p className="BodyTypeText">How big would you like her tits to be?</p>
        <div className="VerticalOptionMainContainer">
          <VerticalOption
            title="Small"
            backgroundImage={small}
            onClick={() => handleOptionClick('Small')}
            isActive={activeOption === 'Small'}
          />
          <VerticalOption
            title="Medium"
            backgroundImage={medium}
            onClick={() => handleOptionClick('Medium')}
            isActive={activeOption === 'Medium'}
          />
          <VerticalOption
            title="Big"
            backgroundImage={big}
            onClick={() => handleOptionClick('Big')}
            isActive={activeOption === 'Big'}
          />
          <VerticalOption
            title="Huge"
            backgroundImage={huge}
            onClick={() => handleOptionClick('Huge')}
            isActive={activeOption === 'Huge'}
          />
        </div>
        <div className="ButtonContainer">
          <Button
            handleNextStep={() => onNextStep()}
            text="CONTINUE"
            disabled={buttonDisabled}
          ></Button>
        </div>
      </div>
    </div>
  );
}
