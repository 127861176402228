import './ZipCode.scss';

import React, { useEffect, useState } from 'react';

import LanderChat from '../../LanderChat/LanderChat';
import Input from '../../UI/Input/Input';

export default function ZipCode({
  watch,
  typeOnlyNumbers,
  errors,
  setValue,
  clearErrors,
  setIsValid,
  landerId
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = {
    '14-1': [{ content: 'Awesome! Where do you live? Enter your ZIP Code:' }],
    '14-2': [{ content: 'Awesome! Where do you live? Enter your ZIP Code:' }],
    '14-3': [{ content: 'Awesome! Where do you live? Enter your ZIP Code:' }],
    '14-4': [{ content: 'Awesome! Where do you live? Enter your ZIP Code:' }]
  };

  const zip_code = watch('zip_code');

  useEffect(() => {
    setIsValid(!!zip_code);
  }, [zip_code, setIsValid]);

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat
        landerId={landerId}
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
      />

      <div className={isElementVisible ? 'ToggleElement SelectOptions' : 'Hidden SelectOptions'}>
        <div className="InputsContainer">
          <Input
            placeholder="Enter your ZIP Code here..."
            name="zip_code"
            maxLength={5}
            onKeyDown={typeOnlyNumbers}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
      </div>
    </>
  );
}
