import axios from 'axios';

export const urls = {
  profiles: '/api/profiles',
  newProfiles: '/api/profiles/browse',
  inboxProfiles: '/api/member/inbox/profile',
  addFavorite: '/api/favorite-profiles',
  images: 'api/images',
  homePageProfiles: 'api/public/clean/profiles',
  profileAction: 'api/profile-actions'
};

export const getProfiles = () => {
  return axios.get(`${urls.newProfiles}?sort=random`);
};

export const getPublicProfiles = () => {
  return axios.get(`${urls.homePageProfiles}`);
};

export const getImages = () => {
  return axios.get(`${urls.profiles}?include=gallery_images`);
};

// export const getAlbumImages = ({ name }) => {
//   return axios.get(`${urls.profiles}/${name}`);
// };

export const getAvatarImage = () => {
  return axios.get(`${urls.profiles}?include=avatarImage`);
};

export const filterProfiles = (params, pageNum) => {
  let filterParams = '';
  let pagination = '';

  params.forEach((p, index) => {
    if (p.value) {
      const isLast = params.length - 1 === index;
      const endDelimiter = isLast ? '' : '&';
      filterParams += `filter[${p.key}]=${p.value}${endDelimiter}`;
    }
  });

  if (pageNum && pageNum !== 0) {
    pagination += `page=${pageNum}${filterParams.length !== 0 ? '&' : ''}`;
  }

  return axios.get(`${urls.newProfiles}?${pagination}${filterParams}`);
};

export const getProfileById = (profileId) => {
  return axios.get(`${urls.profiles}/${profileId}`);
};

export const getInboxProfileById = (profileId) => {
  return axios.get(`${urls.inboxProfiles}/${profileId}`);
};

export const getProfileImages = (profileId) => {
  return axios.get(`${urls.profiles}/${profileId}?include=gallery_images `);
};

// export const getProfilePictures = (name, userToken) => {
//   return axios.get(name, {
//     headers: {
//       Authorization: `Bearer ${userToken}`,
//       Accept: "application.json",
//     },
//     responseType: "blob",
//   });
// };

export const getProfilePictures = (name) => {
  return axios.get(name, {
    headers: {
      Accept: 'application.json'
    },
    responseType: 'blob'
  });
};

export const getVisitors = (page) => {
  return axios.get(`${urls.profiles}?filter%5Bvisitor_profiles%5D=1&per_page=30&page=${page}`);
};

export const getMyFavorites = (userToken) => {
  return axios.get(urls.addFavorite, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const updateProfilePictures = (formData, userID, userToken) => {
  formData.append('_method', 'PATCH');
  formData.append('version', 1);
  console.log('FORM DATA ==> ', formData);
  return axios.post(`${urls.profiles}/${userID}`, formData, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const addToFavorite = (userId, userToken) => {
  const updateData = {
    profile_id: userId
  };
  return axios.post(`${urls.addFavorite}`, updateData, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const patchProfileZip = (data, userID, userToken) => {
  return axios.post(
    `${urls.profiles}/${userID}`,
    {
      ...data,
      _method: 'PATCH'
    },
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  );
};

export const deleteGalleryImage = (image_name, userToken) => {
  return axios.delete(`${urls.images}/${image_name}`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const profileAction = (id, action, userToken) => {
  const actionData = {
    action: action
  };
  return axios.post(`${urls.profileAction}/${id}`, actionData, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const getProfilesForMatchGame = () => {
  return axios.get(`${urls.profiles}?filter[match-game]=1&per_page=1`);
};

export const getNextProfileForMatchGame = (url) => {
  return axios.get(`${urls.profiles}?${url}`);
};

export const filterMatchGameProfiles = (params) => {
  let filterParams = '';
  let filterMatchGame = '?filter[match-game]=1&per_page=1&';

  params.forEach((p, index) => {
    if (p.value) {
      const isLast = params.length - 1 === index;
      const endDelimiter = isLast ? '' : '&';
      filterParams += `filter[${p.key}]=${p.value}${endDelimiter}`;
    }
  });
  return axios.get(`${urls.profiles}?${filterMatchGame}${filterParams}`);
};

export const setMatchGameFilters = (data) => {
  localStorage.setItem('matchGameFilters', JSON.stringify(data));
};

export const getMatchGameFilters = () => {
  return JSON.parse(localStorage.getItem('matchGameFilters'));
};
