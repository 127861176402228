import './Age.scss';

import { InputNumber } from '../../../../../Inputs';
import Button from '../../UI/Button/Button';

export default function Age({ handleNextStep, register, watch, errors }) {
  return (
    <div className="AgeContainer">
      <div className="StepContent">
        <p className="AgeText">How old are you?</p>
        <div className="InputMainContainer">
          <InputNumber
            name="age"
            {...register('age')}
            placeholder="Age"
            errorMessage={errors?.age?.message}
          />
        </div>
        <div className="ButtonContainer">
          <Button
            handleNextStep={() => handleNextStep(['age'])}
            text="CONTINUE"
            disabled={!watch('age')}
          ></Button>
        </div>
      </div>
    </div>
  );
}
