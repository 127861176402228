import ANALSEX from '../../../assets/images/InterestsBadges/anal_sex.png';
import EROTICMASSAGE from '../../../assets/images/InterestsBadges/erotic_massage.png';
import EXCHANGEPICTURE from '../../../assets/images/InterestsBadges/exchange_pictures.png';
import KISSING from '../../../assets/images/InterestsBadges/kissing.svg';
import LINGERIE from '../../../assets/images/InterestsBadges/lingerie.svg';
import ORALSEX from '../../../assets/images/InterestsBadges/oral_sex.png';
import PUBLICSEX from '../../../assets/images/InterestsBadges/public_sex.png';
import SAFESEX from '../../../assets/images/InterestsBadges/safe_sex.png';
import THREESOME from '../../../assets/images/InterestsBadges/threesome.png';

export const interestsObject = {
  kissing: {
    title: 'Kissing',
    image: KISSING
  },
  'exchanging pictures': {
    title: 'Exchanging Pictures',
    image: EXCHANGEPICTURE
  },
  'safe sex': {
    title: 'Safe Sex',
    image: SAFESEX
  },
  'erotic massage': {
    title: 'Erotic Massage',
    image: EROTICMASSAGE
  },
  lingerie: {
    title: 'Lingerie',
    image: LINGERIE
  },
  'oral sex': {
    title: 'Oral Sex',
    image: ORALSEX
  },
  threesome: {
    title: 'Threesome',
    image: THREESOME
  },
  'anal sex': {
    title: 'Anal Sex',
    image: ANALSEX
  },
  'public sex': {
    title: 'Public Sex',
    image: PUBLICSEX
  }
};
