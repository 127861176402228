import './SubscriptionCard.scss';

import { StarFilled } from '@ant-design/icons';
import { Divider } from 'antd';
import React from 'react';

import BADGE from '../../assets/images/Pricing/badge_icon.svg';
import Button from '../Button/Button';
import ImageComponent from '../ImageComponent/ImageComponent';

const SubscriptionCard = (props) => {
  const { badge, title, price, messages, billingFrequency, onClick } = props;
  return (
    <div className={badge ? 'SubscriptionCard Badged' : 'SubscriptionCard'}>
      {badge && (
        <div className="Badge">
          <ImageComponent className="BadgeImage" url={BADGE} />
          <span>MOST POPULAR</span>
        </div>
      )}
      <div className="PackageTitle">
        <StarFilled /> {title}
      </div>
      <div className="MessagesNumber">{messages}</div>
      <div className="MessagesNumberText">FREE MESSAGES PER 30 DAYS</div>
      <Divider dashed={true} />
      <div className="PricePerMonth">
        <span>$ {price} </span> / month
      </div>
      <div className="BilledPeriod">
        Billed every <span>{billingFrequency / 30} month (s)</span>
      </div>
      <Button text="I WANT THIS" onClick={onClick} />
    </div>
  );
};

export default SubscriptionCard;
