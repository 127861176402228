import './SettingsTab.scss';

import {
  CheckOutlined,
  CloseOutlined,
  CreditCardOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone
} from '@ant-design/icons';
import { Form, Input, Modal, Switch } from 'antd';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import Button from '../../../../components/Button/Button';
import { useLogout } from '../../../../hooks/useLogout';
// import LoaderComponent from '../../../../components/LoaderCompoment/LoaderComponent';
import { userData as userRecoil } from '../../../../recoil/atoms/userDataAtom';
import { TipInfoComponent } from '../../../components/tipInfoComponent/TipInfoComponent';
import { UserEmail } from '../../../model/classes/UserEmail';
import { UserPassword } from '../../../model/classes/UserPassword';
import { useGetUserAboutMe } from '../../../services/myProfile/useGetUserAboutMe';
import { useInterfacePreferenceData } from '../../../services/myProfile/useInterfacePreferenceData';
import { useUpdateUserData } from '../../../services/myProfile/useUpdateUserData';
import { useUpdateUserEmail } from '../../../services/myProfile/useUpdateUserEmail';
import { useUpdateUserPassword } from '../../../services/myProfile/useUpdateUserPassword';
import { useRemoveCreditCard } from '../../../services/payment/useRemoveCreditCard';

interface SelectOptionsData {
  value: string | null;
  label: string;
  name: string;
}

interface SettingsType {
  isMobile: boolean;
  renderTipMessage: any;
}

export const SettingsTab = ({ isMobile, renderTipMessage }: SettingsType) => {
  const [passwordForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const [switchForm] = Form.useForm();
  const logout = useLogout();
  const [user, setUser] = useRecoilState(userRecoil);
  //@ts-ignore
  const userId = user?.data?.profile.id;
  const { data: enumData } = useInterfacePreferenceData();
  const { mutate: removeCreditCard, isLoading: removingCard } = useRemoveCreditCard();
  const { mutate: updatePassword, isLoading: updatingPasssword } = useUpdateUserPassword();
  const { mutate: updateEmail, isLoading: updatingEmail } = useUpdateUserEmail();
  const { data: userGeneralData, isLoading } = useGetUserAboutMe(userId);
  const { mutate: saveUserStatus, isLoading: saveStatusLoader } = useUpdateUserData();

  useEffect(() => {
    const formData = {
      show_online_status: userGeneralData?.show_online_status,
      private_location: userGeneralData?.private_location
    };
    switchForm.setFieldsValue(formData);
    //@ts-ignore
    user && emailForm.setFieldValue('current_email', user?.data?.email ? user?.data.email : '');
  }, [user, emailForm, userGeneralData]);

  const changePassword = (value: UserPassword) => {
    const data = {
      current_password: value.current_password,
      new_password: value.new_password,
      new_password_confirmation: value.new_password_confirmation
    };
    updatePassword({ payload: data });
  };

  const changeEmail = (value: UserEmail) => {
    const data = {
      current_email: value.current_email,
      password: value.password,
      new_email: value.new_email,
      new_email_confirmation: value.new_email_confirmation
    };
    updateEmail(
      { payload: data },
      {
        onSuccess: (res) => {
          const newUserData = { ...user, email: value.new_email };
          localStorage.setItem('userData', JSON.stringify(newUserData));
          setUser(newUserData);
          localStorage.setItem('token', res?.access_token);

          setTimeout(() => {
            logout();
          }, 5000);
        }
      }
    );
  };

  // const renderLoader = () => {
  //   return (
  //     <div className="settings-loading">
  //       <LoaderComponent />
  //     </div>
  //   );
  // };

  const saveStatus = (checked: boolean, name: string) => {
    const data = {
      [name]: checked
    };
    saveUserStatus({ userId: userId, payload: data });
  };

  const [modal, contextHolder] = Modal.useModal();
  const confirmRemoveCard = () => {
    modal.confirm({
      title: 'Confirm removing credit card',
      icon: <ExclamationCircleOutlined />,
      content: (
        <p>
          Are you sure you want to remove existing payment details?
          <br />
          You can enter new card details next time you pay.
        </p>
      ),
      okText: 'Confirm',
      cancelText: 'Cancel',
      okButtonProps: { className: 'confirm-credit-card-ok' },
      onOk: () => removeCreditCard()
    });
  };

  return (
    <div className="settings-tab">
      {contextHolder}
      <div className="form-container">
        <div className="form-left-side">
          <Form
            form={passwordForm}
            name="password_change"
            layout="vertical"
            onFinish={changePassword}
          >
            <Form.Item label="Password Change" className="password-change-container">
              <Form.Item
                name="current_password"
                label="Current Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!'
                  },
                  {
                    min: 6,
                    message: 'Must be between 6 to 16 characters'
                  },
                  {
                    max: 16,
                    message: 'Must be between 6 to 16 characters'
                  }
                ]}
              >
                <Input.Password
                  placeholder="Current password"
                  size="large"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <Form.Item
                name="new_password"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!'
                  },
                  {
                    min: 6,
                    message: 'Must be between 6 to 16 characters'
                  },
                  {
                    max: 16,
                    message: 'Must be between 6 to 16 characters'
                  }
                ]}
              >
                <Input.Password
                  placeholder="New password"
                  size="large"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <Form.Item
                name="new_password_confirmation"
                label="Repeat New Password"
                dependencies={['new_password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!'
                  },
                  {
                    min: 6,
                    message: 'Must be between 6 to 16 characters'
                  },
                  {
                    max: 16,
                    message: 'Must be between 6 to 16 characters'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('The password that you entered do not match!')
                      );
                    }
                  })
                ]}
              >
                <Input.Password
                  placeholder="Repeat new password"
                  size="large"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <Button
                className="SignUpButton"
                text="CHANGE PASSWORD"
                htmlType="submit"
                loading={updatingPasssword}
              />
            </Form.Item>
          </Form>
          <Form form={switchForm} name="switch_form" layout="vertical" onFinish={changePassword}>
            <Form.Item
              name="show_online_status"
              label="Show when I am online"
              valuePropName="checked"
            >
              <Switch
                //@ts-ignore
                defaultChecked={userGeneralData?.show_online_status}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => saveStatus(checked, 'show_online_status')}
                loading={saveStatusLoader}
              />
            </Form.Item>
            <Form.Item
              name="private_location"
              label="Keep my location private"
              valuePropName="checked"
            >
              <Switch
                defaultChecked={Boolean(userGeneralData?.private_location)}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => saveStatus(checked, 'private_location')}
                loading={saveStatusLoader}
              />
            </Form.Item>
          </Form>
          <Form.Item className="remove-card-container" label="Remove credit card">
            <Button
              className="SignUpButton"
              text="Remove credit Card"
              icon={<CreditCardOutlined />}
              onClick={confirmRemoveCard}
              loading={removingCard}
              disabled={!user.data.has_spent_before || user.data.user_has_card_removed}
            />
          </Form.Item>
        </div>
        <div className="form-right-side">
          <Form form={emailForm} name="email_change" layout="vertical" onFinish={changeEmail}>
            <Form.Item label="Email Change" className="email-change-container">
              <Form.Item
                name="current_email"
                label="Current Email"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!'
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!'
                  }
                ]}
              >
                <Input placeholder="Current Email" size="large" type="email" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Current Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!'
                  },
                  {
                    min: 6,
                    message: 'Must be between 6 to 16 characters'
                  },
                  {
                    max: 16,
                    message: 'Must be between 6 to 16 characters'
                  }
                ]}
              >
                <Input.Password
                  placeholder="Current password"
                  size="large"
                  autoComplete="new-password"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <Form.Item
                name="new_email"
                label="New Email"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!'
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!'
                  }
                ]}
              >
                <Input placeholder="New Email" size="large" type="email" />
              </Form.Item>
              <Form.Item
                name="new_email_confirmation"
                label="Repeat New Email"
                dependencies={['new_email']}
                hasFeedback
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!'
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_email') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The email that you entered do not match!'));
                    }
                  })
                ]}
              >
                <Input placeholder="Repeat New Email" size="large" type="email" />
              </Form.Item>
              <Button
                className="SignUpButton"
                text="CHANGE EMAIL"
                htmlType="submit"
                loading={updatingEmail}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="tip-container">
        {!isMobile ? <TipInfoComponent message={renderTipMessage()} /> : ''}
      </div>
    </div>
  );
};
