import { useNavigate } from '@reach/router';
import { AxiosError } from 'axios';
import React, { ReactNode, useRef } from 'react';

import { queryClient } from '../../../../../../App';
import { authLogout } from '../../../../../../services/auth-service/auth.service';
import { showRootPage } from '../../../../../../services/navigation-service/navigation.service';
import { cleanupToken } from '../../../../../../services/token-service/token.service';
import { cleanupUserData } from '../../../../../../services/user-service/user.service';
import { useContextMenu } from '../../../../../../V2/hooks/useContextMenu';
import { key as googleApiKey } from '../../../../../../V2/services/auth/useGetGoogleApiKey';

const renderNotifications = (item: { text: string }, visitors: number, notifications: number) => {
  switch (item.text) {
    case 'Messages':
      return (
        <span
          className={
            notifications > 0 ? 'Item-Notification bounce ' : 'Item-Notification Eclipse-none'
          }
        >
          {notifications}
        </span>
      );
    case 'Visitors':
      return (
        <span
          className={visitors > 0 ? 'Item-Notification bounce ' : 'Item-Notification Eclipse-none'}
        >
          {visitors}
        </span>
      );
    default:
  }
};
interface SidebarItemProps {
  item: { name: string; text: string; icon: ReactNode; navigate: string; class: string };
  visitors: number;
  notifications: number;
  closeSidebar: () => void;
}
export const SidebarItem = ({ item, visitors, notifications, closeSidebar }: SidebarItemProps) => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const openNewTab = () => {
    const newTab = window.open('', '_blank');
    if (newTab) newTab.location.href = item.navigate;
  };
  const context = [
    {
      action: openNewTab,
      text: 'Open in new tab'
    }
  ];
  const { showMenu, menu } = useContextMenu({ context, elem: ref });
  const logoutSuccess = () => {
    queryClient.invalidateQueries(googleApiKey);
    cleanupToken();
    cleanupUserData();
    localStorage.clear();
    showRootPage();
  };
  const logutFailure = (error: AxiosError) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
    }
    showRootPage();
  };

  const handleLogOut = () => {
    authLogout().then(logoutSuccess).catch(logutFailure);
  };
  const goTo = () => navigate(item.navigate).then(() => closeSidebar());

  return (
    <>
      <div
        ref={ref}
        onClick={() => (item.name === 'signout' ? handleLogOut() : goTo())}
        className="Sidebar-Item"
      >
        <span>{item.icon}</span>
        <span>{item.text}</span>
        {renderNotifications(item, visitors, notifications)}
      </div>
      {showMenu && menu}
    </>
  );
};
