import {
  AppstoreOutlined,
  DollarOutlined,
  EyeOutlined,
  HeartOutlined,
  UserOutlined
} from '@ant-design/icons';
import { IoChatbubbleOutline, IoCopyOutline } from 'react-icons/io5';
import { MdLocalFireDepartment } from 'react-icons/md';
import { VscSignOut } from 'react-icons/vsc';

import { MEMBERS_ROUTES } from '../../members_routes';
import {
  browseProfilesPage,
  credits,
  matchGame,
  myFavorites,
  myMatches,
  myProfile,
  showMessagesPage,
  visitors
} from '../../navigation.service.js';

const iconStyle = { fontSize: '1.5rem' };
const SidebarData = [
  {
    text: 'Messages',
    notification: 42,
    route: showMessagesPage,
    icon: <IoChatbubbleOutline style={iconStyle} />,
    bounce: true,
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MESSAGESNEW}`
  },
  {
    text: 'Browse Profiles',
    notification: '',
    route: browseProfilesPage,
    icon: <AppstoreOutlined style={iconStyle} />,
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}`
  },
  {
    text: 'Match Game',
    notification: '',
    route: matchGame,
    class: 'Match-Game',
    icon: <IoCopyOutline style={iconStyle} />,
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MATCHGAME}`
  },
  {
    text: 'My Matches',
    notification: '',
    route: myMatches,
    icon: <MdLocalFireDepartment style={iconStyle} />,
    class: 'My-Matches',
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MYMATCHES}`
  },
  {
    text: 'My Favorites',
    notification: '',
    route: myFavorites,
    icon: <HeartOutlined style={iconStyle} />,
    class: 'My-Favorites',
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MYFAVORITES}`
  },
  {
    text: 'Visitors',
    notification: 32,
    route: visitors,
    icon: <EyeOutlined style={iconStyle} />,
    class: 'Visitors',
    bounce: true,
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.VISITORS}`
  },
  {
    text: 'Credits',
    route: credits,
    icon: <DollarOutlined style={iconStyle} />,
    class: 'Credits',
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.CREDITS}`
  },
  {
    text: 'My Profile',
    notification: '',
    route: myProfile,
    icon: <UserOutlined style={iconStyle} />,
    class: 'My-Profile',
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MYPROFILE}`
  },
  {
    text: 'Sign Out',
    icon: <VscSignOut style={iconStyle} />,
    notification: '',
    class: 'SignOut',
    name: 'signout'
  }
];

export default SidebarData;
