import './Email.scss';

import { useState } from 'react';

import GoogleLoginButton from '../../../../../../../../components/GoogleLoginButton/GoogleLoginButton';
import LanderChat from '../../LanderChat/LanderChat';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Email({
  setValue,
  errors,
  getValues,
  clearErrors,
  emailLoading,
  handleNextStep,
  setGoogleLogin,
  handleGoogleLogin,
  landerId,
  options,
  watch
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const landerGoogleClassName = `RedGoogleLoginButton version${landerId}`;

  const messages = {
    '13-1': [
      {
        content:
          'I’m checking and see over 1,500 profiles in your area.  Enter your email and I’ll notify you when they start contacting you!'
      }
    ],
    '13-2': [
      {
        content:
          'You’re in luck, we have over <span style="color: black">1,420</span> girls near you! Enter your Email so I can notify you when they message you!'
      }
    ],
    '13-3': [
      {
        content:
          'Awesome, you’re almost done! Enter your email so we can notify you when they start contacting you!'
      }
    ],
    '13-4': [
      {
        content: 'Nice job, handsome! \n Tell me your Email address and start chatting:'
      }
    ]
  };

  const submit = async () => {
    await handleNextStep(['email']);
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <div className="EmailContainer">
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer13">
          <Input
            placeholder="Email"
            name="email"
            type="email"
            value={getValues('email')}
            onChange={() => {
              setGoogleLogin(false);
            }}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
        {options.hasSSO && <p className="DividerText13-1">OR</p>}
        <div className="ButtonsContianer">
          <>
            {options.hasSSO && (
              <GoogleLoginButton
                className={landerGoogleClassName}
                text="Register with Google"
                handleGoogleLogin={(res) =>
                  handleGoogleLogin(
                    res,
                    [
                      'email',
                      options.autogeneratePassword ? 'randPass' : null,
                      options.autogenerateUsername ? 'randUsr' : null
                    ].filter(Boolean)
                  )
                }
              />
            )}
            <Button
              className={`ButtonDark`}
              handleNextStep={() =>
                handleNextStep(
                  [
                    'email',
                    options.autogeneratePassword ? 'randPass' : null,
                    options.autogenerateUsername ? 'randUsr' : null
                  ].filter(Boolean)
                )
              }
              text="NEXT"
              loading={emailLoading}
              disabled={!watch('email')}
            />
          </>
        </div>
      </div>
    </div>
  );
}
