import './Password.scss';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useState } from 'react';

import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Password({ handleNextStep, errors, setValue, clearErrors, watch }) {
  const [seePassword, setSeePassword] = useState(false);

  return (
    <>
      <div className="PasswordContainer">
        <div className="PasswordImage"></div>
        <p className="PasswordText">Create your password</p>
        <div className="PasswordInputContainer">
          <Input
            placeholder="Password"
            name="password"
            type={seePassword ? `text` : `password`}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
          <div className="ShowPasswordIcon" onClick={() => setSeePassword(!seePassword)}>
            {seePassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          </div>
        </div>
        <Button
          handleNextStep={() => handleNextStep(['password'])}
          text="SUBMIT"
          disabled={!watch('password')}
        ></Button>
      </div>
    </>
  );
}
