import './ChatStarters.scss';

import { useParams } from '@reach/router';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import { useChatStarters } from '../../../../../../../../core/messages/useChatStarters';

const { Option } = Select;
const ChatStarters = (props) => {
  const params = useParams();
  const { data, isLoading } = useChatStarters();
  const { getStarter, starter } = props;

  const selectIceBreaker = (e) => {
    getStarter(e);
  };

  const renderSelect = () => {
    return (
      <Select
        popupClassName="chat-starters-list"
        style={{ width: '100%' }}
        name="chatStarters"
        placeholder={'Pick Your Opening Line'}
        id="chatStarters"
        onChange={selectIceBreaker}
        value={starter}
        key={params.id}
        loading={isLoading}
      >
        {data?.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    );
  };

  const renderChatStarter = () => {
    return (
      <>
        <div className={'label'}>Don't be shy</div>
        <div className="SelectContainer">{data && renderSelect()}</div>
      </>
    );
  };

  return <div className="ChatStarters">{renderChatStarter()}</div>;
};

export default ChatStarters;

ChatStarters.propTypes = {
  getStarter: PropTypes.func.isRequired,
  starter: PropTypes.string.isRequired
};
