import './Age.scss';

import { InputNumber } from '../../../../../Inputs';
import Button from '../../UI/Button/Button';

export default function Age({
  handleNextStep,
  typeOnlyNumbers,
  watch,
  errors,
  setValue,
  clearErrors,
  register
}) {
  return (
    <>
      <div className="AgeContainer">
        <p className="AgeText">How old are you?</p>
        <div className="InputContainer">
          <InputNumber
            name="age"
            {...register('age')}
            placeholder="Age"
            errorMessage={errors?.age?.message}
          />
        </div>
        <Button
          handleNextStep={() => handleNextStep(['age'])}
          text="SUBMIT"
          disabled={!watch('age')}
        ></Button>
      </div>
    </>
  );
}
