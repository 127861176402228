import './JoinOptions.scss';

import { useEffect } from 'react';

import BaseButton from '../../../../../../../../components/Button/Button';
import GoogleLoginButton from '../../../../../../../../components/GoogleLoginButton/GoogleLoginButton';

export default function Location({ nextStep, errors, handleGoogleLogin }) {
  useEffect(() => {
    if (errors.email) {
      nextStep();
    }
  }, [errors?.email]);

  return (
    <>
      <span className="CountdownLabel CountdownLabelCenter">Join with a single click</span>

      <GoogleLoginButton
        text="Sign up with Google"
        handleGoogleLogin={handleGoogleLogin}
        className="Button MoreOptionsButton MoreOptionsButtonGoogle"
      />

      <BaseButton className="MoreOptionsButton" onClick={nextStep}>
        More options
      </BaseButton>
    </>
  );
}
