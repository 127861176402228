import React, { useState } from 'react';

import athleticBg from '../../../../../../../../assets/images/Lander10/athletic.png';
import curvyBg from '../../../../../../../../assets/images/Lander10/curvy.png';
import largeBg from '../../../../../../../../assets/images/Lander10/large.png';
import HorizontalOption from '../../HorizontalOption/HorizontalOption';
import LanderChat from '../../LanderChat/LanderChat';

export default function Relationship({ onNextStep }) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = [{ content: 'What woman’s body type do you prefer?' }];

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat messages={messages} endChatAction={toggleElementVisibility} />
      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <HorizontalOption title="Athletic" backgroundImage={athleticBg} onClick={onNextStep} />
        <HorizontalOption title="Curvy" backgroundImage={curvyBg} onClick={onNextStep} />
        <HorizontalOption title="Large" backgroundImage={largeBg} onClick={onNextStep} />
      </div>
    </>
  );
}
