import { globalHistory } from '@reach/router';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { userData as userRecoil } from '../../recoil/atoms/userDataAtom';
import { useShowPopUnder } from '../../V2/services/popunder/useShowPopUnder';
// import { runAdZone } from './adZone';
import {
  executePopUnder,
  getPopUnderExpiration,
  // removeAdZoneWidget,
  removePopUnderOverlay,
  // showAdZoneWidget,
  updatePopUnderHref
} from './helpers';

const usePopUnder = () => {
  const [userData] = useRecoilState(userRecoil);
  const { refetch } = useShowPopUnder();

  useEffect(() => {
    if (userData?.data) {
      updatePopUnderHref();
    }
  });

  // useEffect(() => {
  //   runAdZone();
  // }, []);

  useEffect(() => {
    const shouldShowPopUnder = async () => {
      const { data } = await refetch();
      return data?.showPopUnder;
    };

    const shouldDisplayPopUnder = () => {
      const popUnderExpirationValue = getPopUnderExpiration();
      const popUnderExpirationDate = popUnderExpirationValue
        ? new Date(parseInt(popUnderExpirationValue))
        : null;

      return !popUnderExpirationDate || new Date() >= popUnderExpirationDate;
    };

    const handleShowPopUnder = () => {
      if (window.location.pathname !== '/members-area' && !shouldDisplayPopUnder()) {
        removePopUnderOverlay();
        // removeAdZoneWidget();
        return;
      }

      shouldShowPopUnder().then((showPopUnder) => {
        if (showPopUnder) {
          shouldDisplayPopUnder() ? executePopUnder() : removePopUnderOverlay();

          // if (window.location.pathname === '/members-area') {
          //   showAdZoneWidget();
          // }
        } else {
          removePopUnderOverlay();
          // removeAdZoneWidget();
        }
      });
    };

    const initPopUnder = async () => {
      try {
        handleShowPopUnder();
      } catch (error) {
        console.error('Error initializing pop under:', error);
      }
    };

    if (userData?.data) {
      initPopUnder();
      updatePopUnderHref();
    }

    const triggerPopUnder = globalHistory.listen(({ location }) => {
      // if (location.pathname !== '/members-area') {
      //   removeAdZoneWidget();
      // }

      if (userData?.data) {
        initPopUnder();
        updatePopUnderHref();
      }
    });

    return () => {
      triggerPopUnder();
    };
  }, [userData?.data, refetch]);

  return null;
};

export default usePopUnder;
