import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';

import { queryClient } from '../../../App';
import { axiosInstance } from '../../../axios/axiosInstance';
import { UserPreferences } from '../../model/classes/UserPreferences';
import { key as UserDetails } from './useGetUserDetails';

interface PreferencesData {
  userId: number;
  payload: UserPreferences;
}
interface ErrorResponse {
  message: string;
  errors: string[];
}

function transformErrorData(data: any): ErrorResponse {
  const result: ErrorResponse = {
    message: data.message,
    errors: []
  };

  if (data.errors)
    for (const [, value] of Object.entries(data.errors)) {
      // @ts-ignore
      result.errors.push(value[0]);
    }

  return result;
}

export const useUpdateUserPreferences = () =>
  useMutation(
    async ({ userId, payload }: PreferencesData) => {
      const { data } = await axiosInstance.post(`api/users/${userId}/preferences`, {
        ...payload,
        _method: 'PATCH'
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(UserDetails);
        notification.success({ message: 'Update success' });
      },
      onError: (err: AxiosError) => {
        if (err.response) {
          const data = transformErrorData(err.response.data);
          notification.error({
            message: 'Update error ' + err.response?.status,
            description: data.errors.map((error: string) => {
              return (
                <p key={error} style={{ marginBottom: '5px' }}>
                  {error}
                </p>
              );
            })
          });
        }
      }
    }
  );
