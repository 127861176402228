import './style.scss';

import { CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';

interface CanvasImageProp {
  src: string;
  previewSrc?: string;
  enablePreview?: boolean;
}
export const CanvasImage = ({ src, previewSrc, enablePreview }: CanvasImageProp) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const previewCanvas = useRef<HTMLCanvasElement>(null);
  const [preview, setPreview] = useState(false);
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const img = new Image();
    if (canvas) {
      canvas.oncontextmenu = function () {
        return false;
      };
      canvas.onclick = () => {
        enablePreview && setPreview(true);
      };
      //ctx && ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = canvas.clientWidth;
      img.src = src;
      img.onload = img.onerror = null;
      canvas.height = canvas.width * (4 / 3);
    }

    if (ctx && canvas) {
      ctx.fillStyle = '#000000';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      img.onload = () => {
        if (!ctx) return;
        const canvasAspectRatio = canvas.width / canvas.height;
        const imgAspectRatio = img.width / img.height;
        let newWidth: number, newHeight: number, x: number, y: number;
        if (canvasAspectRatio > imgAspectRatio) {
          newWidth = canvas.height * imgAspectRatio;
          newHeight = canvas.height;
          x = (canvas.width - newWidth) / 2;
          y = 0;
        } else {
          newWidth = canvas.width;
          newHeight = canvas.width / imgAspectRatio;
          x = 0;
          y = (canvas.height - newHeight) / 2;
        }
        ctx.drawImage(img, x, y, newWidth, newHeight);
      };
      if (canvas) {
        const prevImgCtx = canvas?.getContext('2d');
        if (prevImgCtx) {
          prevImgCtx.globalAlpha = 1;
          prevImgCtx.fillStyle = 'black';
          prevImgCtx.fillRect(0, 0, canvas.width, canvas.height);
          prevImgCtx.fill();
        }
      }
    }
  }, [preview, !src]);
  useEffect(() => {
    const canvas = previewCanvas.current;
    const ctx = canvas?.getContext('2d');
    const img = new Image();
    if (canvas) {
      canvas.oncontextmenu = function () {
        setPreview(false);
        return false;
      };
      canvas.onclick = () => {
        return false;
      };
      ctx && ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = canvas.clientWidth;
      img.src = previewSrc || src;
      img.onload = img.onerror = null;
    }
    if (ctx && canvas) {
      img.onload = () => {
        if (!ctx) return;
        const canvasAspectRatio = canvas.width / canvas.height;
        const imgAspectRatio = img.width / img.height;
        let newWidth: number, newHeight: number, x: number, y: number;
        if (canvasAspectRatio > imgAspectRatio) {
          newWidth = canvas.height * imgAspectRatio;
          newHeight = canvas.height;
          x = (canvas.width - newWidth) / 2;
          y = 0;
        } else {
          newWidth = canvas.width;
          newHeight = canvas.width / imgAspectRatio;
          x = 0;
          y = (canvas.height - newHeight) / 2;
        }
        ctx.drawImage(img, x, y, newWidth, newHeight);
      };
    }
  }, [preview, previewSrc, src]);
  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e && e.key === 'Escape') setPreview(false);
    });
    return () => {
      document.removeEventListener('keydown', (e) => {
        console.log(e);
      });
    };
  }, []);
  return (
    <>
      <canvas ref={canvasRef} />
      {preview && (
        <div>
          <canvas ref={previewCanvas} className={'preview'} height={800} />
          <CloseOutlined className={'tools close right'} onClick={() => setPreview(false)} />
        </div>
      )}
    </>
  );
};
