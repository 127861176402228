import './ChangeCC.scss';

import React, { useState } from 'react';

import CARD from '../../../assets/images/Modal/credit-card-solid.svg';
import ADD from '../../../assets/images/Modal/plus-solid.svg';
import { purchaseCredit } from '../../../services/payment-services/payment.service';
import { getToken } from '../../../services/token-service/token.service';
import LoaderComponent from '../../LoaderCompoment/LoaderComponent';

const ChangeCC = (props) => {
  const token = getToken();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const { paymentData } = props;
  console.log(paymentData);
  const handleClick = (type) => {
    setLoadingPayment(true);
    if (type === 'old') {
      const data = { ...paymentData, charge: true };
      return purchaseCredit(data, token)
        .then((res) => {
          const url = res.data.redirect_url;
          const redirect = window.location.href === url ? url + '?paymentstatus=0' : url;
          window.open(redirect, '_self');
        })
        .catch((err) => {
          setLoadingPayment(false);
          console.log(err);
        });
    }
    const data = { ...paymentData, charge: false };
    return purchaseCredit(data, token)
      .then((res) => {
        window.open(res.data.redirect_url, '_self');
      })
      .catch((err) => {
        setLoadingPayment(false);
        console.log(err);
      });
  };

  const renderLoader = () => {
    return (
      <div className="Loading">
        <div className="LoadingText">
          <span>Processing</span>
          <LoaderComponent />
        </div>
      </div>
    );
  };

  return (
    <div className="ChangeCardWrapp">
      {loadingPayment && renderLoader()}
      <div
        className={loadingPayment ? 'KeepOldCard Disabled' : 'KeepOldCard'}
        onClick={() => handleClick('old')}
      >
        <img className="KeepOldCardImg" src={CARD} alt="card icon" />
        <div className="KeepOldCardTextWrapp">
          <p className="KeepOldCardText">Buy now</p>
          <p className="KeepOldCardNote">(Purchase with previously used card)</p>
        </div>
      </div>
      <div className="separator"></div>
      <div
        className={loadingPayment ? 'AddNewCard Disabled' : 'AddNewCard'}
        onClick={() => handleClick('new')}
      >
        <img className="AddNewCardImg" src={ADD} alt="plus sign" />
        <p className="AddNewCardText">Add new credit card</p>
      </div>
    </div>
  );
};

export default ChangeCC;
