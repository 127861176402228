import './PhotoAlbum.scss';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Carousel, Modal } from 'antd';
import React, { useState } from 'react';

import { queryClient } from '../../../../../../../../App';
import delete_icon from '../../../../../../../../assets/images/MembersArea/MyProfile/delete_icon.svg';
import ImageNPreview from '../../../../../../../../components/ImageNPreview/ImageNPreview';
import SuccessMessage from '../../../../../../../../components/SuccessMessage/SuccessMessage';
import { useDeleteUserPhotos } from '../../../../../../../../V2/services/myProfile/useDeleteUserPhotos';
import { key as UserGalleryKey } from '../../../../../../../../V2/services/myProfile/useGetUserGallery';

const PhotoAlbum = (props) => {
  const { galleryImages, userData, visible, setVisible, hideModal, slider, currentImg } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);
  const { mutate: deleteUserPhoto } = useDeleteUserPhotos();
  const showModalDelete = () => {
    setIsModalVisible(true);
  };
  const handleCancelModal = () => {
    setIsModalVisible(false);
  };
  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          color: 'black',
          fontSize: '0px',
          lineHeight: '1.5715'
        }}
        onClick={onClick}
      >
        <LeftOutlined style={{ color: '#808080', fontSize: 25 }} />
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          color: 'black',
          fontSize: '0px',
          lineHeight: '1.5715'
        }}
        onClick={onClick}
      >
        <RightOutlined style={{ color: '#808080', fontSize: 25 }} />
      </div>
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = (id) => {
    deleteUserPhoto(id, {
      onSuccess: () => {
        setIsModalVisible(false);
        setVisible(false);
        queryClient.invalidateQueries(UserGalleryKey);
      }
    });
  };

  return (
    <div className="PhotoAlbum">
      <SuccessMessage
        className="success-message photo-album-delete-success"
        message={'Photos successfully deleted!'}
        showMessage={showSuccessMessage}
        setShowMessage={setShowSuccessMessage}
      />
      <SuccessMessage
        className="failure-message photo-album-delete-fail"
        message={'Fail to delete photo'}
        showMessage={showFailureMessage}
        setShowMessage={setShowFailureMessage}
      />
      {galleryImages ? (
        <Modal open={visible} onCancel={handleCancel} title={null} footer={null}>
          <div className="PhotoAlbum">
            <Carousel
              arrows
              {...settings}
              ref={(ref) => {
                slider.current = ref;
              }}
            >
              {galleryImages
                ? galleryImages.map((img, index) => {
                    return (
                      <div className="CarouselPage" key={index}>
                        <ImageNPreview
                          name={img?.url}
                          nameFullSize={img?.url}
                          className={'PhotoAlbumImage'}
                          classNameImg={'PhotoAlbumImageImg'}
                          type="gallery_images"
                        />
                        <div className="PhotoAlbumImageInfoWrapper">
                          <div className="PhotoAlbumImageInfo">
                            <div className="PhotoALbumImageDetails">
                              <p className="GaleryImageUsername">{userData?.profile?.username}</p>
                            </div>
                          </div>
                          <div className="DeleteIconWrapper" onClick={showModalDelete}>
                            <img alt="delete icon" src={delete_icon} className="DeleteIcon"></img>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </Carousel>
          </div>
        </Modal>
      ) : (
        <Modal open={visible} onCancel={hideModal} title={null} footer={null}>
          <p>No Photos in the album</p>
        </Modal>
      )}
      <Modal
        className="DeleteModal"
        title="Delete photo"
        open={isModalVisible}
        onCancel={handleCancelModal}
        footer={[
          <Button key="back" onClick={handleCancelModal}>
            No
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              handleOk(currentImg.id);
            }}
          >
            Yes
          </Button>
        ]}
      >
        <p className="DeletePhotoText">Are you sure you want to delete this photo?</p>
      </Modal>
    </div>
  );
};

export default PhotoAlbum;
