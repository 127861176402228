import './Lander11.scss';

import { useNavigate } from '@reach/router';
import { useEffect, useState } from 'react';

import loaderImg from '../../../../../../assets/images/Lander10/loader.png';
import logo from '../../../../../../assets/images/Lander11/logo.png';
import steps from './data/steps';
import { FallbackProvider } from './FallbackProvider';

export default function Lander11(props) {
  const navigate = useNavigate();
  const landerSteps = steps(props.landerId.includes('sso'));
  const { nextStep, currentFormField, submitForm, handleNextStep } = props;
  const isLastStep = currentFormField >= landerSteps.length + 1;
  const [countdown, setCountdown] = useState('06:07:01');

  useEffect(() => {
    const targetTime = new Date();
    targetTime.setHours(targetTime.getHours() + 6);
    targetTime.setMinutes(targetTime.getMinutes() + 7);
    targetTime.setSeconds(targetTime.getSeconds() + 3);

    // Start the countdown interval
    const intervalId = setInterval(() => {
      // Calculate the remaining time
      const currentTime = new Date();
      const remainingTime = targetTime - currentTime;

      // Check if the countdown has reached zero or negative
      if (remainingTime <= 0) {
        clearInterval(intervalId);
        navigate('/'); // Redirect to the home page
      } else {
        // Format the remaining time into hours, minutes, and seconds
        const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
        const seconds = Math.floor((remainingTime / 1000) % 60);

        // Format the countdown string
        const countdownString = `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        setCountdown(countdownString);
      }
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (isLastStep) {
      submitForm();
    }
  }, [currentFormField]);

  const fields = {
    Email: 'email',
    Username: 'username',
    Location: 'zip_code'
  };

  const CurrentStep = landerSteps[Math.min(currentFormField, landerSteps.length) - 1] || {};

  return (
    <div>
      <div className="CountdownLanderNavbar">
        <img src={logo} height={53} />
      </div>
      <div className="CountdownLanderContainer">
        {isLastStep && (
          <div className="LoadingOverlay">
            <img src={loaderImg} alt="Loader" />
          </div>
        )}

        <FallbackProvider>
          <form
            className="LanderContent"
            onSubmit={(e) => {
              e.preventDefault();
              const currentStepField = fields[CurrentStep.name];
              if (currentStepField) {
                handleNextStep([currentStepField]);
              }
            }}
          >
            {CurrentStep && (
              <div className="LanderContentInner">
                <div className="InnerHeader">Make a date today!</div>
                <div className="InnerContainer">
                  <CurrentStep onNextStep={nextStep} handleNextStep={handleNextStep} {...props} />
                </div>
              </div>
            )}
          </form>

          {countdown && (
            <div className="Countdown">
              <span className="CountdownFree">Free</span> for men for the next {countdown}
            </div>
          )}
        </FallbackProvider>
      </div>
    </div>
  );
}
