import '../style.scss';

import { Modal, ModalProps } from 'antd';
import React, { FC } from 'react';

import Button from '../../../../../components/Button/Button';
import { CreditsPackage } from '../../../../model/classes/CreditsPackage';
import { PurchaseType } from '../../../../model/enum/PurchaseType';
import { useCheckOutPayment } from '../../../../services/payment/useCheckOutPayment';

interface GiftConfirmModalProps extends ModalProps {
  pkg?: Partial<CreditsPackage>;
  name?: string;
  profileId?: number;
  onSuccess: () => void;
}
export const GiftConfirmModal: FC<GiftConfirmModalProps> = ({
  pkg,
  name,
  profileId,
  onSuccess,
  ...props
}) => {
  const { mutate: buy, isLoading } = useCheckOutPayment();
  const buyCredits = () => {
    pkg?.id &&
      profileId &&
      buy(
        {
          type: PurchaseType.GIFTING,
          package_id: pkg?.id,
          profile_id: profileId,
          redirect_url: window.location.href
        },
        {
          onSuccess: (data, payload) => {
            if (data.redirect_url === payload.redirect_url) {
              onSuccess();
            } else window.open(data.redirect_url, '_self');
          }
        }
      );
  };
  return (
    <Modal footer={null} closable={!isLoading} {...props}>
      <div className={'modal-body'}>
        Gift <span className={'name'}>{name}</span>{' '}
        <span className={'no-of-credits'}>{pkg?.number_of_messages}</span> Credits and Receive More
        Flirts
      </div>
      <Button onClick={buyCredits} loading={isLoading}>
        GIFT NOW
      </Button>
      <Button
        type={'link'}
        className={'select-different'}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          props.onCancel && props.onCancel(e)
        }
      >
        Select Different Bundle
      </Button>
    </Modal>
  );
};
