import Age from '../Steps/Age/Age';
import BodyType from '../Steps/BodyType/BodyType';
import Email from '../Steps/Email/Email';
import Gender from '../Steps/Gender/Gender';
import GenderOfInterest from '../Steps/GenderOfInterest/GenderOfInterest';
import Password from '../Steps/Password/Password';
import Relationship from '../Steps/Relationship/Relationship';
import ThankYou from '../Steps/ThankYou/ThankYou';
import Username from '../Steps/Username/Username';
import ZipCode from '../Steps/ZipCode/ZipCode';

function generateSteps(options) {
  let data = [
    {
      component: Relationship,
      percent: 0
    },
    {
      component: Gender,
      percent: 10
    },
    {
      component: GenderOfInterest,
      percent: 20
    },
    {
      component: Age,
      percent: 40,
      field: 'age'
    },
    {
      component: BodyType,
      percent: 50
    },
    {
      component: ZipCode,
      percent: 70,
      field: 'zip_code'
    },
    {
      component: Username,
      percent: 80,
      field: 'username'
    },
    {
      component: Password,
      percent: 90,
      field: 'password'
    },
    {
      component: Email,
      percent: 100,
      field: 'email'
    },
    {
      component: ThankYou
    }
  ];

  if (options.hideLocation) {
    data = data.filter((item) => item.field !== 'zip_code');
  }

  if (options.autogeneratePassword) {
    data = data.filter((item) => item.field !== 'password');
  }

  return data;
}

export default generateSteps;
