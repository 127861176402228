import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../axios/axiosInstance';

export const key = ['INBOX'];
const url = 'api/member/inbox/conversations';
export const useGetInbox = (isActive) =>
  useInfiniteQuery(
    [...key],
    async ({ pageParam = 1 }) => {
      const { data } = await axiosInstance.get(url, {
        params: {
          page: pageParam,
          per_page: 100
        }
      });
      return data.conversations;
    },
    {
      enabled: isActive,
      getNextPageParam: (nextPage) => {
        if (nextPage.next_page_url) return nextPage.current_page + 1;
      }
    }
  );
