import './FooterMembersArea.scss';

import { useLocation } from '@reach/router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { userLogout } from '../../../../../../actions/rootActions/rootActions';
import LogoText from '../../../../../../assets/images/MembersAreaNavbar/LogoText-Footer.svg';
import { showMembersArea } from '../../../../../../redux/actions/authentication';
import { authLogout } from '../../../../../../services/auth-service/auth.service';
import { showRootPage } from '../../../../../../services/navigation-service/navigation.service';
import { cleanupToken } from '../../../../../../services/token-service/token.service';
import { cleanupUserData } from '../../../../../../services/user-service/user.service';
import { MEMBERS_ROUTES } from '../../members_routes';
import { contact, fAQ, pricing, showMembersRootPage } from '../../navigation.service';

const FooterMembersArea = (sideBar) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const mobileView = useSelector(({ welcomeMessage }) => welcomeMessage.mobileView);

  const logoutSuccess = () => {
    cleanupToken();
    cleanupUserData();
    dispatch(userLogout());
    showRootPage();
    dispatch(showMembersArea(false));
  };

  const redirectHome = () => {
    showMembersRootPage();
  };

  const logoutUser = () => {
    localStorage.removeItem('userData');
    authLogout().then(logoutSuccess);
  };

  useEffect(() => {
    // console.log(sideBar);
  }, [sideBar]);

  let dontDisplayFooterOnMobilePage = [
    '/members-area/messages',
    `/members-area/messages/${location.pathname.split('/')[3]}`,
    `/members-area/messagesnew/${location.pathname.split('/')[3]}`,
    `/members-area/member-profile/${location.pathname.split('/')[3]}`
  ];
  return (
    <>
      {mobileView && dontDisplayFooterOnMobilePage.includes(location.pathname) ? null : (
        <div className={sideBar.sidebarValue ? 'FooterWrapper SideBarOpen' : 'FooterWrapper'}>
          <div className={sideBar.sidebarValue ? 'FooterSideBar UpperFooter' : 'UpperFooter'}>
            <div className="Footer-Links">
              <div className="Quick-Links">
                <p className="Links-Header Quick">QUICK LINKS </p>
                <ul className="Quick-Links-List">
                  <li className="QuickLink">
                    <a href={`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.ABOUT}`}>About Us</a>
                  </li>
                  <li className="QuickLink" onClick={fAQ}>
                    <a href={`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.FAQ}`}>FAQs</a>
                  </li>
                  <li className="QuickLink" onClick={pricing}>
                    <a href={`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.PRICING}`}>Pricing</a>
                  </li>
                  <li className="QuickLink" onClick={contact}>
                    <a href={`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.CONTACTUS}`}>
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="LogoText-Wrapper">
                <img className="FooterLogo" onClick={redirectHome} src={LogoText} alt="logo" />
                <p className={sideBar.sidebarValue ? 'Para-SidebarOpen' : 'Footer-Description'}>
                  LocalFlirt is a discrete and satisfying place to live out your wildest desires!{' '}
                  <br /> Flirt and meet with like-minded adults near you now.
                </p>
                <p className={sideBar.sidebarValue ? 'AddressFooter-Sidebar' : 'AddressFooter'}>
                  Gochamie Inc, 20 North Orange Avenue Suite 1100 11th Floor, <br />
                  Orlando, FL 32801
                </p>
                <p className="ContactNumber-Footer">877-381-0324</p>
              </div>
              <div className="Account-Links">
                <p className="Links-Header Account">YOUR ACCOUNT </p>
                <div className="Links-List">
                  <ul className="Account-Links-List">
                    <li>
                      <a href={`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MYFAVORITES}`}>
                        My Favorites
                      </a>
                    </li>
                    <li>
                      <a href={`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.VISITORS}`}>
                        My Visitors
                      </a>
                    </li>
                    <li>
                      <a href={`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.CREDITS}`}>
                        Get More Credits
                      </a>
                    </li>
                    <li onClick={logoutUser}>Sign Out</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="LowerFooter">
            <div className="Copyright-Text">
              Copyright © {new Date().getFullYear()} LocalFlirt. All Rights Reserved.
            </div>
            <div className="Terms-Container">
              <a href={`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.TERMS}`}>
                Terms and Conditions
              </a>{' '}
              |{' '}
              <a href={`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.PRIVACY}`}>Privacy Policy</a>{' '}
              | <a href={`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.CONTACTUS}`}>Support</a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FooterMembersArea;
