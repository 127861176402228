import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@reach/router';
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import * as yup from 'yup';

import PageNotFound from '../../../../components/PageNotFound/PageNotFound';
import { useLoginMember } from '../../../../core/APIHooks/useLoginMember';
import { useGetCityByZipCode } from '../../../../core/APIHooks/userData/useGetCityByZipCode';
import { useRegisterUser } from '../../../../core/APIHooks/useRegisterUser';
import { useEmailValidation } from '../../../../core/emailValidation/useEmailValidation';
import { GeneratePassword } from '../../../../hooks/GeneratePassword';
import { GenerateUsername } from '../../../../hooks/GenerateUsername';
import { userData } from '../../../../recoil/atoms/userDataAtom';
import { authMe } from '../../../../services/auth-service/auth.service';
import { showEmailVerificationInstruction } from '../../../../services/navigation-service/navigation.service';
import { saveToken } from '../../../../services/token-service/token.service';
import { setUserLocalData } from '../../../../services/user-service/user.service';
import { handleAuthReactivation } from '../../helpers/authReactivation';
import landerComponents from './data/landers';

// ToDo: Refactor later
const schema = (lander) => {
  const landerId = parseInt(lander.match(/\d+/));

  let baseSchema = yup.object().shape({
    username: yup
      .string()
      .required('Username is required!')
      .min(6, 'Must be between 6 to 16 characters')
      .max(16, 'Must be between 6 to 16 characters')
      .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
    age: yup
      .number()
      .typeError('Age is required!')
      .required('Age is required!')
      .test('len', 'You must be at least 18 years old', (val) => val && val > 17)
      .test('len', 'Invalid age', (val) => val && val < 100),
    password: yup
      .string()
      .required('Password is required!')
      .min(6, 'Must be between 6 to 16 characters')
      .max(16, 'Must be between 6 to 16 characters')
      .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed')
  });

  if (landerId == 11) {
    baseSchema = baseSchema.shape({
      city: yup.string().required('Location is required!')
    });
  }

  if (landerId == 9) {
    baseSchema = baseSchema.shape({
      gender: yup.string().required('Gender is required!'),
      height: yup.string().required('Height is required!'),
      age_from: yup.string().required('Please select age from option'),
      age_to: yup.string().required('Please select age to option')
    });
  }

  // landers without zip_code validation
  const regex = new RegExp(`/13-(1|2|3|4)/.*noloc|18-(1|2)`);
  const hasNoLocationField = regex.test(window.location.href);

  if (!hasNoLocationField) {
    baseSchema = baseSchema.shape({
      zip_code: yup
        .string()
        .required('Zip code is required!')
        .test('numbers', 'Zip code should only contain numbers', (val) => /^\d+$/.test(val))
        .test('len', 'Must be exactly 5 characters', (val) => val && val.trim().length === 5)
    });
  }

  return baseSchema;
};

const LanderWrapper = (props) => {
  const { landerId } = props;
  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const clickID = urlParams.get('clickId');
  const affiliate = urlParams.get('affiliate');
  const source = urlParams.get('source');
  const [currentFormField, setCurrentFormField] = useState(1);
  const [googleLogin, setGoogleLogin] = useState(false);
  const [fromAges, setFromAges] = useState([]);
  const [toAges, setToAges] = useState([]);
  const [startAge, setStartAge] = useState();
  const setUserData = useSetRecoilState(userData);

  const { mutate: registerUser, isLoading: registerLoading } = useRegisterUser();
  const { mutate: validateEmail, isLoading: emailLoading } = useEmailValidation();
  const { mutate: getCity, isLoading: cityLoading } = useGetCityByZipCode();
  const { mutate: loginMember, isLoading: loginLoader } = useLoginMember();

  const {
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
    trigger,
    watch,
    register,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema(landerId))
  });

  useEffect(() => {
    let days = [];
    for (let i = 100; i >= 18; i--) {
      days.push({
        label: i,
        value: i
      });
    }
    setFromAges(days.reverse());
  }, []);

  useEffect(() => {
    let days = [];
    if (startAge)
      for (let i = 100; i >= startAge; i--) {
        days.push({
          label: i,
          value: i
        });
      }
    setToAges(days.reverse());
  }, [startAge]);

  const typeOnlyNumbers = (e) => {
    const regExp = /[a-zA-Z()+-/*.,]/g;
    if (e.key.length === 1 && regExp.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleNextStep = async (type) => {
    const isValid = await trigger(type);
    if (type.includes('email')) {
      emailValidation(type);
    } else if (type.includes('zip_code')) {
      zipCodeCity(type);
    } else if (isValid) {
      nextStep();
    }
  };

  const nextStep = () => {
    setCurrentFormField((prev) => prev + 1);
  };

  const prevStep = () => {
    setCurrentFormField((prev) => prev - 1);
  };

  const zipCodeCity = async (type) => {
    const isValid = await trigger(type);
    const data = {
      zip_code: getValues('zip_code')
    };

    getCity(data, {
      onSuccess: (data) => {
        const zipCodeData = data.data && data.data[0];

        // Check if location and ZIP Code are not matching
        if (
          isValid &&
          zipCodeData &&
          landerId.includes(11) &&
          getValues('city') !== zipCodeData.city
        ) {
          setError('city', {
            type: 'custom',
            message: 'Location and ZIP Code are not matching.'
          });
        } else if (isValid && zipCodeData) {
          // Check if ZIP Code data exists
          if (!getValues('city')) {
            setValue('city', zipCodeData.city);
          }
          clearErrors('zip_code');
          nextStep();
        } else if (data.data.length === 0) {
          // Handle non-existent ZIP Code
          setValue('city', '');
          setError('zip_code', {
            type: 'custom',
            message: 'Non-existent zipcode'
          });
        }
      },
      // Error callback
      onError: (err) => {
        setValue('city', '');
        if (err.response.data.errors.zip_code) {
          setError('zip_code', { type: 'manual', message: err.response.data.errors.zip_code });
        }
      }
    });
  };

  const emailValidation = async (type, isSSO = false) => {
    const isValid = await trigger(type);
    const idForLanderSSO = landerId.replace('sso', '');
    const registerLanderId = isSSO ? idForLanderSSO : `${idForLanderSSO}typed_email`;
    const email = getValues('email');
    const username = getValues('username');
    const password = getValues('password');
    const age = getValues('age');
    const birthYear = new Date().getFullYear() - age;
    const gender = getValues('gender');
    const seeking = getValues('seeking');
    const city = getValues('city');
    const zip_code = getValues('zip_code');
    const data = {
      email,
      username,
      password,
      password_confirmation: password,
      click_id: clickID,
      affiliate_id: affiliate,
      lander_id: registerLanderId,
      profile: {
        age: age,
        ...(age ? { birthday: `${birthYear}-01-01` } : {}),
        city: city,
        zip_code: zip_code,
        seeking: seeking,
        gender: gender
      },
      ...(source ? { source_id: source } : {})
    };
    validateEmail(data, {
      onSuccess: (res) => {
        if (!isValid) {
          return;
        }

        handleAuthReactivation(res, email, navigate, () => {
          clearErrors('email');
          type.includes('randUsr') && setValue('username', GenerateUsername(email));
          type.includes('randPass') && setValue('password', GeneratePassword(12));
          nextStep();
        });
      },
      onError: (err) => {
        setError('email', {
          type: 'manual',
          message: err.data.errors.email[0]
        });
      }
    });
  };

  const responseGoogle = (res, validateTypes = 'email') => {
    let userObject = jwt_decode(res.credential);
    setValue('email', userObject?.email);
    handleGoogleLogin(userObject, validateTypes);
  };

  const handleGoogleLogin = (user, validateTypes = 'email') => {
    setValue('email', user?.email);
    setGoogleLogin(true);
    emailValidation(validateTypes, true);
  };

  const submitForm = (e) => {
    handleSubmit((data) => onSubmit(data))(e);
  };

  const onSubmit = (data) => {
    const idForLanderSSO = landerId.replace('sso', '');
    const registerLanderId = googleLogin ? idForLanderSSO : `${idForLanderSSO}typed_email`;
    const birthYear = new Date().getFullYear() - data.age;
    const formData = {
      ...data,
      ...(source ? { source_id: source } : {}),
      password_confirmation: data.password,
      click_id: clickID,
      affiliate_id: affiliate,
      lander_id: registerLanderId,
      profile: {
        city: data.city,
        zip_code: data.zip_code,
        ...(data.age ? { birthday: `${birthYear}-01-01` } : {}),
        age: data.age,
        gender: data.gender,
        seeking: data.seeking
      }
    };
    registerUser(
      { params: formData },
      {
        onSuccess: async (regData, variables) => {
          localStorage.setItem('current-email', regData.user.email);
          const loginData = {
            username: regData.user.username,
            email: regData.user.email,
            password: variables.params.password
          };

          loginMember(
            { params: loginData },
            {
              onSuccess: async (regData) => {
                saveToken(regData.access_token, regData.expires_in);
                localStorage.setItem('userData', JSON.stringify(regData.user));
                localStorage.setItem('affiliateSiteLockdown', new Date());

                const handleAuthSuccess = (response) => {
                  setUserLocalData(response.data);
                  setUserData(response.data);
                  window.location.href = '/members-area';
                };

                const handleAuthError = (err) => {
                  console.log(err);
                };

                const isEmailVerified = regData.user.email_verified_at;
                const token = regData.access_token;

                if (googleLogin || isEmailVerified) {
                  authMe(token).then(handleAuthSuccess).catch(handleAuthError);
                } else if (googleLogin) {
                  window.location.href = '/members-area';
                } else {
                  showEmailVerificationInstruction();
                }
              }
            }
          );
        }
      }
    );
  };

  const renderLander = () => {
    const { component: LanderComponent, props } = landerComponents[landerId];

    if (!LanderComponent) {
      return <PageNotFound />;
    }

    return (
      <LanderComponent
        nextStep={nextStep}
        handleNextStep={handleNextStep}
        currentFormField={currentFormField}
        submitForm={submitForm}
        setValue={setValue}
        getValues={getValues}
        clearErrors={clearErrors}
        errors={errors}
        loading={registerLoading}
        setStartAge={setStartAge}
        fromAges={fromAges}
        toAges={toAges}
        cityLoading={cityLoading}
        emailLoading={emailLoading}
        typeOnlyNumbers={typeOnlyNumbers}
        watch={watch}
        responseGoogle={responseGoogle}
        setGoogleLogin={setGoogleLogin}
        landerId={landerId}
        prevStep={prevStep}
        control={control}
        register={register}
        handleGoogleLogin={handleGoogleLogin}
        {...props}
      />
    );
  };

  return <>{renderLander()}</>;
};

export default LanderWrapper;
