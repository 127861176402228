import './Pictures.scss';

import React, { useRef } from 'react';
import { useRecoilState } from 'recoil';

import TIP from '../../../../../../../assets/images/MembersArea/amber.jpg';
import drag_and_drop from '../../../../../../../assets/images/MembersArea/MyProfile/drag_and_drop.svg';
import DragAndDrop from '../../../../../../../components/DragAndDrop/DragAndDrop';
import LoaderComponent from '../../../../../../../components/LoaderCompoment/LoaderComponent';
import { userData as userRecoil } from '../../../../../../../recoil/atoms/userDataAtom';
import GalleryImages from '../Pictures/GalleryImages/GalleryImages';
import ProfileNav from '../ProfileNav/ProfileNav';

const Pictures = () => {
  const [user] = useRecoilState(userRecoil);
  const { data } = user;
  const profilePhoto = useRef();

  return (
    <div>
      <ProfileNav />
      <div className="PicturesWrapper">
        {
          <div className="ProfilePhoto">
            <p className="ProfilePhotoText">Profile Photo</p>
            {!data ? (
              <div className="LoaderComponentWrapper">
                <LoaderComponent />
              </div>
            ) : (
              <div style={{ maxWidth: 400 }}>
                <DragAndDrop
                  currentPhoto={data?.profile?.profile_image}
                  currentPhotoFullSize={data?.profile?.profile_image}
                  setCurrentPhoto={data?.profile?.profile_image}
                  fileRef={profilePhoto}
                  userID={data?.profile.id}
                  multiple={false}
                  type={'profile_image'}
                  className={'change-photo'}
                  btnclasses={'btn-change-photo'}
                  successMessage={'Profile photo updated!'}
                  failureMessage={'Profile photo update failed!'}
                  dndImage={drag_and_drop}
                  text={'Drag and drop an image, or Browse'}
                  btnText={'Upload'}
                />
              </div>
            )}
          </div>
        }
        <div className="AdditionalPhotosWrapper">
          <div className={'Tip'}>
            <div className="image-box">
              <img onContextMenu={(e) => e.preventDefault()} src={TIP} alt="tip" />
            </div>
            <p className="TipText">
              Amber’s Tip: Adding photos makes it more likely you will get matches. Members want to
              see you just as much as you want to see them.
            </p>
          </div>
          <p className="AdditionalPhotosText">Add Additional Photos</p>
          <GalleryImages />
        </div>
      </div>
    </div>
  );
};

export default Pictures;
