import * as actionTypes from '../types/messageConversationStatus';

const initialState = { status: false };

const newConversationStatus = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NEW_CONVERSATION_STATUS:
      const status = action.payload;
      return {
        status
      };
    default:
      return state;
  }
};

export default newConversationStatus;
