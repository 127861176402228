import './ZipCode.scss';

import React, { useState } from 'react';

import LanderChat from '../../LanderChat/LanderChat';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function ZipCode({
  handleNextStep,
  typeOnlyNumbers,
  errors,
  setValue,
  clearErrors,
  cityLoading
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = [
    { content: 'In which radius would you like to search?' },
    { content: 'Just enter the Zip Code of your city. \n You can set the radius later.' },
    { content: 'Where do you live?' }
  ];

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat messages={messages} endChatAction={toggleElementVisibility} />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer">
          <Input
            placeholder="Enter Zip Code"
            name="zip_code"
            maxLength={5}
            onKeyDown={typeOnlyNumbers}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
        <div className="ButtonsContianer">
          <Button
            text="NEXT"
            handleNextStep={() => handleNextStep(['zip_code'])}
            loading={cityLoading}
          />
        </div>
      </div>
    </>
  );
}
