import './BodyType.scss';

import Button from '../../UI/Button/Button';

export default function Relationship({ onNextStep }) {
  return (
    <div className="BodyTypeContainer">
      <div className="BodyTypeImage"></div>
      <p className="BodyTypeText">What woman’s body type do you prefer?</p>
      <Button handleNextStep={() => onNextStep()} text="NORMAL"></Button>
      <Button handleNextStep={() => onNextStep()} text="CURVY"></Button>
      <Button handleNextStep={() => onNextStep()} text="LARGE"></Button>
    </div>
  );
}
