import React, { useState } from 'react';

import DINERBLUE from '../../../../../../../../assets/images/Lander12/blue/dinner.png';
import HOMEBLUE from '../../../../../../../../assets/images/Lander12/blue/home.png';
import HOTELBLUE from '../../../../../../../../assets/images/Lander12/blue/hotel.png';
import DOGGYORANGE from '../../../../../../../../assets/images/Lander12/orange/doggy.png';
import MISSIONARYORANGE from '../../../../../../../../assets/images/Lander12/orange/missionary.png';
import ORALORANGE from '../../../../../../../../assets/images/Lander12/orange/oral.png';
import PUBLICPURPLE from '../../../../../../../../assets/images/Lander12/purple/public.png';
import THEIRSPURPLE from '../../../../../../../../assets/images/Lander12/purple/theirs.png';
import YOURSPURPLE from '../../../../../../../../assets/images/Lander12/purple/yours.png';
import ANALRED from '../../../../../../../../assets/images/Lander12/red/anal.png';
import ORALRED from '../../../../../../../../assets/images/Lander12/red/oral.png';
import VAGINALRED from '../../../../../../../../assets/images/Lander12/red/vaginal.png';
import HorizontalOption from '../../HorizontalOption/HorizontalOption';
import LanderChat from '../../LanderChat/LanderChat';

export default function Relationship({ onNextStep, landerId }) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = {
    '12-1': [
      {
        content: 'Great, you’ll find plenty of suitable women. When it comes to sex, do you prefer:'
      }
    ],
    '12-2': [
      {
        content: 'Perfect, you’ll find a lot of matches. Just tell me, in bed do you prefer:'
      }
    ],
    '12-3': [
      {
        content: 'Great, you’ll see lots of suitable women. Do you prefer to meet:'
      }
    ],
    '12-4': [
      {
        content:
          'Great, you’ll find plenty of suitable women. When it comes to the first meeting, do you prefer:'
      }
    ]
  };

  const options = {
    '12-1': [
      {
        title: 'VAGINAL',
        img: VAGINALRED
      },
      {
        title: 'ORAL',
        img: ORALRED
      },
      {
        title: 'ANAL',
        img: ANALRED
      }
    ],
    '12-2': [
      {
        title: 'MISSIONARY',
        img: MISSIONARYORANGE
      },
      {
        title: 'DOGGY',
        img: DOGGYORANGE
      },
      {
        title: 'ORAL',
        img: ORALORANGE
      }
    ],
    '12-3': [
      {
        title: 'AT YOURS',
        img: YOURSPURPLE
      },
      {
        title: 'AT THEIRS',
        img: THEIRSPURPLE
      },
      {
        title: 'IN PUBLIC',
        img: PUBLICPURPLE
      }
    ],
    '12-4': [
      {
        title: 'HOME',
        img: HOMEBLUE
      },
      {
        title: 'HOTEL',
        img: HOTELBLUE
      },
      {
        title: 'DINER',
        img: DINERBLUE
      }
    ]
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />
      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        {options[`${landerId}`].map((item) => {
          return (
            <HorizontalOption
              key={item.title}
              title={item.title}
              backgroundImage={item.img}
              onClick={onNextStep}
              landerId={landerId}
            />
          );
        })}
      </div>
    </>
  );
}
