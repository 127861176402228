import './subscriptionCard.scss';

import { StarFilled } from '@ant-design/icons';
import { Divider, notification } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import { Featured } from '../../../../assets/icons';
import Button from '../../../../components/Button/Button';
import { useAuthMe } from '../../../../services/authHooks/useAuthMe';
import { currencySymbol } from '../../../helpers/currency';
import { Subscription } from '../../../model/classes/Subscription';
import { PurchaseType } from '../../../model/enum/PurchaseType';
import { Triggers } from '../../../model/Triggers';
import { useCheckOutPayment } from '../../../services/payment/useCheckOutPayment';

interface SubscriptionCardProps {
  packageDef: Subscription;
  redirectUrl?: string;
  onSuccess?: () => void;
  trigger?: Triggers | null;
  profile?: number | null;
  setPurchasing: (e: boolean) => void;
  purchasingInProgress: boolean;
}

export const SubscriptionCard = ({
  packageDef,
  redirectUrl,
  onSuccess,
  trigger,
  profile,
  setPurchasing,
  purchasingInProgress
}: SubscriptionCardProps) => {
  const [loading, setLoading] = useState(false);
  const { id, name, color, number_of_messages, frequency, price, currency, featured } = packageDef;
  const duration = moment.duration(frequency, 'days');
  const durationInMonths = duration.asMonths();
  const { mutate: me } = useAuthMe();

  const { mutate: buy } = useCheckOutPayment();
  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
  const buyCredit = debounce(() => {
    setLoading(true);
    redirectUrl &&
      buy(
        {
          package_id: id,
          type: PurchaseType.SUBSCRIPTION,
          redirect_url: redirectUrl,
          subscription_trigger: trigger,
          profile_id: profile
        },
        {
          onSuccess: (data, payload) => {
            if (data.redirect_url === payload.redirect_url) {
              sleep(10000).then(() => {
                me();
                notification.success({ message: 'Success' });
                onSuccess && onSuccess();
              });
            } else {
              me();
              window.open(data.redirect_url, '_self');
            }
            setLoading(false);
            setPurchasing(false);
          },
          onError: () => setLoading(false)
        }
      );
  }, 1000);
  const handleClick = () => {
    setPurchasing(true);
    buyCredit();
  };
  moment.updateLocale('en', {
    duration: {
      months: {
        singular: 'month',
        plural: 'months',
        past: 'month',
        future: 'month'
      }
    }
  });

  let durationString = duration.humanize();

  if (
    Math.round(durationInMonths) < 2 ||
    (Math.round(durationInMonths) >= 11 && Math.round(durationInMonths) <= 17)
  ) {
    durationString = durationString.substring(2, durationString.length);
  }
  return (
    <div className={`subscription-card ${featured ? ' featured' : null}`}>
      {featured ? (
        <div className="icon-wrapper">
          <div className={'icon'}>
            <Featured />
          </div>
          <span>MOST POPULAR</span>
        </div>
      ) : null}
      <div className="PackageTitle" style={{ color }}>
        <StarFilled /> {name}
      </div>
      <div className="num-messages" style={{ color }}>
        {number_of_messages}
      </div>
      <div className="num-messages-text">FREE MESSAGES PER 30 DAYS</div>
      <Divider dashed={true} />
      <div className="month-price">
        <span style={{ color }}>
          {currencySymbol(currency)} {price}{' '}
        </span>{' '}
        / month
      </div>
      <div className="frequency">
        Billed every <span>{durationString}</span>
      </div>
      <Button
        disabled={purchasingInProgress}
        type={'default'}
        onClick={() => handleClick()}
        loading={loading}
        className={'card-button'}
        style={{
          color: featured ? color : '#9F0220',
          borderColor: featured ? color : '#9F0220'
        }}
      >
        I WANT THIS
      </Button>
    </div>
  );
};
