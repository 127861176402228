import { useState } from 'react';

import DOMINANTBLUE from '../../../../../../../../assets/images/Lander12/blue/dominant.png';
import NORMALBLUE from '../../../../../../../../assets/images/Lander12/blue/normal.png';
import SUBMISSIVEBLUE from '../../../../../../../../assets/images/Lander12/blue/submissive.png';
import LARGEORANGE from '../../../../../../../../assets/images/Lander12/orange/large.png';
import NORMALORANGE from '../../../../../../../../assets/images/Lander12/orange/normal.png';
import SLIMORANGE from '../../../../../../../../assets/images/Lander12/orange/slim.png';
import BLONDEPURPLE from '../../../../../../../../assets/images/Lander12/purple/blonde.png';
import BROWNPURPLE from '../../../../../../../../assets/images/Lander12/purple/brown.png';
import REDPURPLE from '../../../../../../../../assets/images/Lander12/purple/red.png';
import DOMINANTRED from '../../../../../../../../assets/images/Lander12/red/dominant.png';
import NORMALRED from '../../../../../../../../assets/images/Lander12/red/normal.png';
import SUBMISSIVERED from '../../../../../../../../assets/images/Lander12/red/submissive.png';
import HorizontalOption from '../../HorizontalOption/HorizontalOption';
import LanderChat from '../../LanderChat/LanderChat';

export default function Relationship({ onNextStep, landerId }) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = {
    '12-1': [
      {
        content: 'Perfect! So, what type of woman do you want?'
      }
    ],
    '12-2': [
      {
        content: 'Great! So, what type of woman do you prefer?'
      }
    ],
    '12-3': [
      {
        content: 'Perfect! So, what type of woman do you want?'
      }
    ],
    '12-4': [
      {
        content: 'A strong man, great! So, what type of woman do you need?'
      }
    ]
  };

  const options = {
    '12-1': [
      {
        title: 'DOMINANT',
        img: DOMINANTRED
      },
      {
        title: 'NORMAL',
        img: NORMALRED
      },
      {
        title: 'SUBMISSIVE',
        img: SUBMISSIVERED
      }
    ],
    '12-2': [
      {
        title: 'SLIM',
        img: SLIMORANGE
      },
      {
        title: 'NORMAL',
        img: NORMALORANGE
      },
      {
        title: 'LARGE',
        img: LARGEORANGE
      }
    ],
    '12-3': [
      {
        title: 'BLONDE',
        img: BLONDEPURPLE
      },
      {
        title: 'BROWN',
        img: BROWNPURPLE
      },
      {
        title: 'RED',
        img: REDPURPLE
      }
    ],
    '12-4': [
      {
        title: 'DOMINANT',
        img: DOMINANTBLUE
      },
      {
        title: 'NORMAL',
        img: NORMALBLUE
      },
      {
        title: 'SUBMISSIVE',
        img: SUBMISSIVEBLUE
      }
    ]
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(true);
  };

  return (
    <>
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />
      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        {options[`${landerId}`].map((item) => {
          return (
            <HorizontalOption
              key={item.title}
              title={item.title}
              backgroundImage={item.img}
              onClick={onNextStep}
              landerId={landerId}
            />
          );
        })}
      </div>
    </>
  );
}
