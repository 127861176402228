import './PreferencesTab.scss';

import { Form, Select, Slider } from 'antd';
import { SliderMarks } from 'antd/es/slider';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import Button from '../../../../components/Button/Button';
import LoaderComponent from '../../../../components/LoaderCompoment/LoaderComponent';
import { userData as userRecoil } from '../../../../recoil/atoms/userDataAtom';
import { TipInfoComponent } from '../../../components/tipInfoComponent/TipInfoComponent';
import { values } from '../../../data/height';
import { UserPreferences } from '../../../model/classes/UserPreferences';
import { PreferenceData } from '../../../model/interfaces/PreferenceData';
import { useGetUserPreferences } from '../../../services/myProfile/useGetUserPreferences';
import { useInterfacePreferenceData } from '../../../services/myProfile/useInterfacePreferenceData';
import { useUpdateUserPreferences } from '../../../services/myProfile/useUpdateUserPreferences';

interface SelectOptionsData {
  value: string | null;
  label: string;
  name: string;
}
interface PreferenceType {
  isMobile: boolean;
  renderTipMessage: any;
}

export const PreferencesTab = ({ isMobile, renderTipMessage }: PreferenceType) => {
  const [preferencesForm] = Form.useForm();
  const [user] = useRecoilState(userRecoil);
  const userId = user?.data?.id;
  const { data: enumData } = useInterfacePreferenceData();
  const { data: userPreferencesData, isLoading } = useGetUserPreferences(userId);
  const { mutate: updateUserPreferences } = useUpdateUserPreferences();

  useEffect(() => {
    preferencesForm.setFieldsValue({
      ...userPreferencesData,
      ...{
        height:
          userPreferencesData?.height != null ? values.indexOf(userPreferencesData?.height) : -1
      }
    });
  }, [userPreferencesData, preferencesForm]);

  const selectData = (type: keyof PreferenceData) => {
    let options: SelectOptionsData[] = [];

    if (type !== 'ethnicity') {
      options = [
        {
          value: '',
          label: '---',
          name: type
        }
      ];
    }
    enumData &&
      enumData[type]?.map((value: string) => {
        const arr = value.split('/');
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join('/');
        options.push({
          value: value,
          label:
            str2 === 'Native american'
              ? 'Native American'
              : str2 === 'Middle eastern'
              ? 'Middle Eastern'
              : str2,
          name: type
        });
      });
    return options;
  };

  const marks: SliderMarks = {
    0: '4.9',
    24: '6.9'
  };

  const formatter = (value?: number) => {
    return value !== undefined ? values[value] : '';
  };

  const onFinish = (value: UserPreferences) => {
    const data = {
      body_build: value.body_build,
      age: value.age,
      ethnicity: value.ethnicity,
      civil_status: value.civil_status,
      hair_color: value.hair_color,
      eye_color: value.eye_color,
      height: value?.height != undefined ? values[parseInt(value.height)]?.toString() : undefined,
      availability: value.availability
    };

    updateUserPreferences({ userId: userId, payload: data });
  };

  const renderLoader = () => {
    return (
      <div className="preferences-loading">
        <LoaderComponent />
      </div>
    );
  };
  return (
    <div className="preferences-tab">
      <Form
        className="form-container"
        form={preferencesForm}
        name="user_preferences"
        layout="vertical"
        onFinish={onFinish}
      >
        {isLoading ? (
          renderLoader()
        ) : (
          <>
            <div className="form-left-side">
              <Form.Item name="body_build" label="Body Type Preference">
                <Select placeholder="Body Type" size="large" options={selectData('body_build')} />
              </Form.Item>
              <Form.Item name="age" label="Age Preference">
                <Select placeholder="Age" size="large" options={selectData('age')} />
              </Form.Item>
              <Form.Item name="ethnicity" label="Ethnicity Preference (max 2)">
                <Select
                  placeholder="Ethnicity"
                  mode="multiple"
                  size="large"
                  options={selectData('ethnicity')}
                  allowClear={false}
                />
              </Form.Item>
              <Form.Item name="civil_status" label="I Prefer Women">
                <Select placeholder="Status" size="large" options={selectData('civil_status')} />
              </Form.Item>
            </div>
            <div className="form-right-side">
              <Form.Item name="eye_color" label="Eye Color Preference">
                <Select placeholder="Eye Color" size="large" options={selectData('eye_color')} />
              </Form.Item>
              <Form.Item name="hair_color" label="Hair Color Preference">
                <Select placeholder="Hair Color" size="large" options={selectData('hair_color')} />
              </Form.Item>
              <Form.Item
                name="availability"
                label="Preferred Availability (at what time are you most active online)"
              >
                <Select
                  placeholder="Availability"
                  size="large"
                  options={selectData('availability')}
                />
              </Form.Item>
              <Form.Item className="height-container" name="height" label="Height Preference">
                <Slider
                  marks={marks}
                  min={0}
                  max={values.length - 1}
                  step={1}
                  tooltip={{ formatter }}
                />
              </Form.Item>
              <Button className="SignUpButton" text="SAVE CHANGES" htmlType="submit" />
            </div>
          </>
        )}
      </Form>
      <div className="tip-container">
        {!isMobile ? <TipInfoComponent message={renderTipMessage()} /> : ''}
      </div>
    </div>
  );
};
