import './Password.scss';

import React, { useEffect, useState } from 'react';

import LanderChat from '../../LanderChat/LanderChat';
import Input from '../../UI/Input/Input';

export default function Password({ setValue, errors, clearErrors, watch, setIsValid, landerId }) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = {
    '14-1': [{ content: 'And your password...' }],
    '14-2': [{ content: 'And your password...' }],
    '14-3': [{ content: 'And your password...' }],
    '14-4': [{ content: 'And your password...' }]
  };

  const password = watch('password');

  useEffect(() => {
    setIsValid(!!password);
  }, [password, setIsValid]);

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat
        landerId={landerId}
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
      />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer">
          <Input
            placeholder="Your password..."
            name="password"
            type="password"
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
      </div>
    </>
  );
}
