import './Gender.scss';

import {
  manBgImageV1,
  manBgImageV1Mobile,
  womanBgImageV1,
  womanBgImageV1Mobile
} from '../../../../../../../../assets/images/Lander15/version1';
import {
  manBgImageV2,
  manBgImageV2Mobile,
  womanBgImageV2,
  womanBgImageV2Mobile
} from '../../../../../../../../assets/images/Lander15/version2';
import {
  manBgImageV3,
  manBgImageV3Mobile,
  womanBgImageV3,
  womanBgImageV3Mobile
} from '../../../../../../../../assets/images/Lander15/version3';
import {
  manBgImageV4,
  manBgImageV4Mobile,
  womanBgImageV4,
  womanBgImageV4Mobile
} from '../../../../../../../../assets/images/Lander15/version4';
import VerticalOption from '../../VerticalOption/VerticalOption';

export default function Gender({
  watch,
  setValue,
  setIsValid,
  landerId,
  handleNextStep,
  currentStep,
  isElementVisible,
  setIsElementVisible,
  getResponsiveImage
}) {
  const avatars = {
    '15-1': {
      male: getResponsiveImage(manBgImageV1Mobile, manBgImageV1),
      female: getResponsiveImage(womanBgImageV1Mobile, womanBgImageV1)
    },
    '15-2': {
      male: getResponsiveImage(manBgImageV2Mobile, manBgImageV2),
      female: getResponsiveImage(womanBgImageV2Mobile, womanBgImageV2)
    },
    '15-3': {
      male: getResponsiveImage(manBgImageV3Mobile, manBgImageV3),
      female: getResponsiveImage(womanBgImageV3Mobile, womanBgImageV3)
    },
    '15-4': {
      male: getResponsiveImage(manBgImageV4Mobile, manBgImageV4),
      female: getResponsiveImage(womanBgImageV4Mobile, womanBgImageV4)
    }
  };

  const gender = watch('gender');

  const handleOptionClick = (option) => {
    setValue('gender', option);
    handleNextStep([currentStep.field]);
    setIsValid(false);
    setIsElementVisible(false);
  };

  return (
    <div className="GenderContainer">
      <div
        className={
          isElementVisible
            ? 'ToggleElement GenderOptions SelectOptions'
            : 'Hidden GenderOptions SelectOptions'
        }
      >
        <VerticalOption
          title="Man"
          backgroundImage={avatars[`${landerId}`].male}
          onClick={() => handleOptionClick('male')}
          isActive={gender === 'male'}
        />
        <VerticalOption
          title="Woman"
          backgroundImage={avatars[`${landerId}`].female}
          onClick={() => handleOptionClick('female')}
          isActive={gender === 'female'}
        />
      </div>
    </div>
  );
}
