import * as actionTypes from '../types/notifications';

const initialState = [];

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATIONS:
      return action.payload;
    case actionTypes.UPDATE_NOTIFICATIONS:
      return [action.payload];
    case actionTypes.DISMISS_NOTIFICATION:
      return state.filter((note) => !action.payload.includes(note.id));
    default:
      return state;
  }
};

export default notifications;
