import PropTypes from 'prop-types';

import { queryClient } from '../../../../../../App';
import { useSocket, wsEvents } from '../../../../../../core/sockets/useSocket';
import useEmailOffer from '../../../../../../V2/hooks/useEmailOffer';
import { ChatTabs } from './components/ChatTabs/ChatTabs';

export const Messages = ({ children }) => {
  useSocket({
    type: wsEvents.MESSAGE_RECEIVED,
    callBack: () => {
      queryClient.invalidateQueries('INBOX');
      queryClient.invalidateQueries('LATEST');
    }
  });

  useEmailOffer();

  return (
    <div className="MessagesInbox">
      <div className="MessagesContainer full">
        <ChatTabs />
        <div className={'messages-wrapper'}>{children}</div>
      </div>
    </div>
  );
};

Messages.propTypes = {
  children: PropTypes.node.isRequired
};
