import './LoginModal.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import SECURELOGIN from '../../assets/images/HomePage/lock.png';
import { showMembersArea } from '../../redux/actions/authentication';
import { authMe, memberLogin } from '../../services/auth-service/auth.service';
import {
  showEmailVerificationInstruction,
  showMembersAreaPage
} from '../../services/navigation-service/navigation.service';
import { saveToken } from '../../services/token-service/token.service';
import {
  cleanupUserData,
  getUserData,
  setUserLocalData
} from '../../services/user-service/user.service';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import { Form } from '../Form/Form';
import Input from '../Form/Input/Input';
import ImageComponent from '../ImageComponent/ImageComponent';
import LoaderComponent from '../LoaderCompoment/LoaderComponent';
import ForgotPassword from '../modals/ForgotPassword/ForgotPassword';
import ModalDialog from '../modals/ModalDialog/ModalDialog';
import SignUpFlow from '../SignUpFlow/SignUpFlow';
import SuccessMessage from '../SuccessMessage/SuccessMessage';

const schema = yup.object().shape({
  username: yup.string().required('Username is required!'),
  password: yup.string().required('Password is required!')
});

const LoginModal = (props) => {
  const { openModal, closeModal, modalRef, verifyModalRef, getRegisterData } = props;
  const submitRef = useRef(null);
  const forgotPassword = useRef(null);
  const userData = getUserData();
  const registerModalRef = useRef(null);
  const [rememberMe, getRememberMe] = useState(false);
  const [loading, isLoading] = useState(false);
  const [loginInvalidError, getLoginInvalidError] = useState('');
  const [showSuccesMessage, setShowSuccesMessage] = useState(false);
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState(false);
  const [authetificationSuccess, setAuthetificationSuccess] = useState(false);
  const pathname = localStorage.getItem('pathname');
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const checkboxChange = (e, value) => {
    getRememberMe(value);
  };

  const getLoggedUserData = (accessToken) => {
    return authMe(accessToken).then(saveUserData).catch(saveUserDataFailed);
  };

  const saveUserData = (response) => {
    setUserLocalData(response.data);
    dispatch(showMembersArea(true));
    var now = moment(new Date());
    var created = moment(response?.data?.data?.created_at);
    var duration = moment.duration(now.diff(created));
    var hours = duration.asHours();
    const username = response.data.data.username;

    if (
      response?.data?.data?.click_id &&
      !response?.data?.data?.email_verified_at &&
      !response?.data?.data?.auto_verified_at &&
      Math.round(hours) < 48
    ) {
      showEmailVerificationInstruction();
    } else {
      showMembersAreaPage(pathname);
    }
  };

  const saveUserDataFailed = (err) => {
    if (err.response.status === 403) {
      const data = JSON.parse(err.response.data.message);
      const userData = { data: data };
      setUserLocalData(userData);
      showEmailVerificationInstruction();
      return;
    }
    return console.log(err);
  };

  const loginFailed = (err) => {
    setAuthetificationSuccess(false);
    isLoading(false);
    getLoginInvalidError('Incorrect Login Details Please Try Again');
    console.log(err);
  };

  const loginSuccess = (response) => {
    setAuthetificationSuccess(true);
    getLoginInvalidError();
    if (response.status === 200) {
      saveToken(response.data.access_token, response.data.expires_in);
      getLoggedUserData(response.data.access_token);
    }
  };

  const onSubmit = (data) => {
    cleanupUserData();
    setAuthetificationSuccess(false);
    isLoading(true);
    getLoginInvalidError();
    memberLogin(data, rememberMe).then(loginSuccess).catch(loginFailed);
  };

  const closeNewModal = (ref) => {
    ref.current.closeModal();
  };

  const openNewModal = (ref) => {
    ref.current.openModal();
  };

  const renderLoader = () => {
    return (
      <div className="Loading">
        <LoaderComponent />
      </div>
    );
  };

  return (
    <div className="LoginModal">
      {loading && renderLoader()}
      <div className="Title">Log In</div>
      <p className="Subtitle">Login to your account and start flirting!</p>
      <Form className="LoginForm" onSubmit={handleSubmit(onSubmit)}>
        <Input
          register={register}
          type="text"
          name="username"
          placeholder="Username"
          errorMessage={errors?.username?.message}
        />
        <Input
          register={register}
          type="password"
          name="password"
          placeholder="Password"
          errorMessage={errors?.password?.message}
          additionalError={loginInvalidError}
        />
        <div className="Checkbox-Container">
          <div className="Checkbox-Holder">
            <Checkbox onChange={checkboxChange} name="rememberMe" /> <span>Remember me</span>
          </div>
          <div
            onClick={openNewModal.bind(null, forgotPassword)}
            className="ForgotPassword-Container"
          >
            Forgot Password ?
          </div>
        </div>
        <input type="submit" ref={submitRef} hidden />
        <Button
          className="SubmitButton"
          type="submit"
          onClick={() => {
            submitRef.current.click();
          }}
          text="Login"
        />
      </Form>
      <div className="InfoSafetyInfo">
        <ImageComponent url={SECURELOGIN} /> Your login information is encrypted and stored safely
        with us
      </div>
      <div className="NotYetMemberInfo">
        Not yet a member?{' '}
        <span onClick={openNewModal.bind(null, registerModalRef)}> Join for free</span>
      </div>
      <ModalDialog ref={forgotPassword}>
        <ForgotPassword
          isResetSuccess={setForgotPasswordMessage}
          closeModal={closeModal}
          modalRef={forgotPassword}
        />
      </ModalDialog>
      <SuccessMessage
        showMessage={showSuccesMessage}
        setShowMessage={setShowSuccesMessage}
        message="A verification email has been sent to you"
      />
      <SuccessMessage
        showMessage={forgotPasswordMessage}
        setShowMessage={setForgotPasswordMessage}
        message="A link to reset your password has been sent to your email address"
      />
      <SuccessMessage
        showMessage={authetificationSuccess}
        setShowMessage={setAuthetificationSuccess}
        message="Authentication Successful. Loading…"
      />
      <SignUpFlow modalRef={registerModalRef} />
    </div>
  );
};

export default LoginModal;
