import Age from '../Steps/Age/Age';
import BodyType from '../Steps/BodyType/BodyType';
import Email from '../Steps/Email/Email';
import Gender from '../Steps/Gender/Gender';
import Relationship from '../Steps/Relationship/Relationship';
import Username from '../Steps/Username/Username';
import ZipCode from '../Steps/ZipCode/ZipCode';

function generateSteps(autogenerate) {
  const data = [
    {
      component: Gender,
      percent: 0
    },
    {
      component: Relationship,
      percent: 10
    },
    {
      component: Age,
      percent: 20,
      field: 'age'
    },
    {
      component: BodyType,
      percent: 40
    },
    {
      component: ZipCode,
      percent: 60,
      field: 'zip_code'
    },
    {
      component: autogenerate ? Email : Username,
      percent: 80,
      field: autogenerate ? 'email' : ['username', 'password']
    },
    {
      component: autogenerate ? Username : Email,
      percent: 100,
      field: autogenerate ? ['username', 'password'] : 'email'
    }
  ];

  if (autogenerate) {
    data.splice(3, 1);
    data.splice(1, 1);
  }

  return data;
}

export default generateSteps;
