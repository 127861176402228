const hairColors = ['none', 'blonde', 'red', 'black', 'brown'];
const bodyTypes = ['none', 'lean', 'big', 'normal', 'athletic'];
const selectDefault = '-- Select --';
const adjectives = [
  'Alluring',
  'Seductive',
  'Handsome',
  'Irresistible',
  'Charming',
  'Attractive',
  'Captivating',
  'Magnetic',
  'Striking',
  'Dashing',
  'Hot',
  'Strong',
  'Awesome',
  'Big',
  'Muscle',
  'Sexy',
  'Gorgeous',
  'Sizzling',
  'Stunning',
  'Hunky',
  'Suave',
  'Masculine',
  'Fit',
  'Stylish',
  'Sophisticated',
  'Enchanting',
  'Desirable',
  'Exquisite',
  'Confident',
  'Alluring',
  'Dreamy'
];

export { adjectives, bodyTypes, hairColors, selectDefault };
