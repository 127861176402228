import './Age.scss';

import React, { useEffect } from 'react';

import { InputNumber } from '../../../../../Inputs';
import Button from '../../UI/Button/Button';

export default function Age({
  typeOnlyNumbers,
  errors,
  setValue,
  clearErrors,
  setIsValid,
  isValid,
  watch,
  landerId,
  handleNextStep,
  currentStep,
  isElementVisible,
  setIsElementVisible,
  register
}) {
  const age = watch('age');

  useEffect(() => {
    setIsValid(!!age);
  }, [age, setIsValid]);

  return (
    <>
      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer">
          <InputNumber
            name="age"
            {...register('age')}
            placeholder="Age"
            errorMessage={errors?.age?.message}
          />
          <Button
            handleNextStep={() => {
              handleNextStep([currentStep.field]);
            }}
            text="Submit"
            disabled={!isValid || Object.keys(errors).length > 0}
          />
        </div>
      </div>
    </>
  );
}
