import './Location.scss';

import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import arrowLeftIcon from '../../../../../../../../assets/images/Lander11/arrow-left.svg';
import { useGetCityByZipCode } from '../../../../../../../../core/APIHooks/userData/useGetCityByZipCode';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';
import { CountdownSelect as Select } from '../../UI/Select/Select';

export default function Location({
  handleNextStep,
  typeOnlyNumbers,
  errors,
  setValue,
  getValues,
  prevStep,
  control,
  cityLoading,
  clearErrors,
  watch
}) {
  const zipCode = watch('zip_code');
  const [cities, setCities] = useState([]);

  const { mutate: getCity } = useGetCityByZipCode();

  useEffect(() => {
    const debouncedAction = debounce((value) => {
      if (!value) {
        setCities([]);
        setValue('city', '');
        return;
      }

      const data = {
        zip_code: value
      };

      getCity(data, {
        onSuccess: (data) => {
          setCities(data.data.map((c) => ({ value: c.city, label: c.city, zip_code: c.zip_code })));
          setValue('city', data.data[0].city);
        },
        // Error callback
        onError: (err) => {
          setValue('city', '');
          setCities([]);
        }
      });
    }, 500);

    debouncedAction(zipCode);

    return () => {
      debouncedAction.cancel();
    };
  }, [zipCode]);

  const handleSelect = (value) => {
    setValue('zip_code', value.zip_code);
  };

  return (
    <>
      <div className="LocationForm">
        <div className="LocationField">
          <div className="CountdownLabelWithBack">
            <span className="CountdownLabel">ZIP Code</span>
            <span className="CountdownBack CountdownBackSm" onClick={prevStep}>
              <img src={arrowLeftIcon} />
              Back
            </span>
          </div>
          <Input
            name="zip_code"
            maxLength={5}
            value={getValues('zip_code')}
            onKeyDown={typeOnlyNumbers}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
        <div className="LocationField">
          <div className="CountdownLabelWithBack">
            <span className="CountdownLabel">Location</span>
            <span className="CountdownBack CountdownBackLg" onClick={prevStep}>
              <img src={arrowLeftIcon} />
              Back
            </span>
          </div>

          <Select name="city" control={control} options={cities} onSelect={handleSelect} />
        </div>
      </div>

      <Button text="Next" loading={cityLoading} onClick={() => handleNextStep(['zip_code'])} />
    </>
  );
}
