import './Email.scss';

import arrowLeftIcon from '../../../../../../../../assets/images/Lander11/arrow-left.svg';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Email({
  setValue,
  errors,
  prevStep,
  getValues,
  clearErrors,
  emailLoading,
  setGoogleLogin,
  handleNextStep
}) {
  const submit = async () => {
    await handleNextStep(['email']);
  };

  const goBack = () => {
    clearErrors();
    prevStep();
  };

  return (
    <>
      <div className="UsernameForm">
        <div className="UsernameField">
          <div className="CountdownLabelWithBack">
            <span className="CountdownLabel">Email</span>
            <span className="CountdownBack" onClick={goBack}>
              <img src={arrowLeftIcon} />
              Back
            </span>
          </div>
          <Input
            placeholder="It will not be shared"
            name="email"
            type="email"
            value={getValues('email')}
            onChange={() => {
              setGoogleLogin(false);
            }}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
      </div>

      <Button text="Create Profile" onClick={() => submit()} loading={emailLoading} />

      <span className="CountdownPolicy">
        By clicking on the <span>“Create Profile”</span> I agree with the terms and confirm I am
        aged 18 years or older.
      </span>
    </>
  );
}
