import Age from '../Steps/Age/Age';
import Email from '../Steps/Email/Email';
import Gender from '../Steps/Gender/Gender';
import Username from '../Steps/Username/Username';
import ZipCode from '../Steps/ZipCode/ZipCode';

function generateSteps(options) {
  const data = [
    {
      component: Gender
    },
    {
      component: Age
    },
    {
      component: ZipCode
    },
    {
      component: Email
    },
    {
      component: Username
    }
  ];

  if (options.hideLocation) {
    data.splice(2, 1);
  }

  return data;
}

export default generateSteps;
