import './SentMessage.scss';

import React from 'react';

import ImageNPreview from '../../../../../../../../../components/ImageNPreview/ImageNPreview';
import { getUserData } from '../../../../../../../../../services/user-service/user.service';

const SentTime = (props) => {
  const { messageTime } = props;

  if (messageTime) {
    const time = new Date(messageTime);
    // var realTime = moment(time).format('HH:mm');
    return (
      <div className="SentTime">
        {time.getHours()}:{time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()}
      </div>
    );
  }
  return null;
};

const SentMessage = (props) => {
  const {
    text,
    messageTime,
    notEnoughCredits,
    image,
    openModal,
    notEnoughCredistRef,
    purchaseCreditsRef
  } = props;
  const userData = getUserData();

  const isCharge = () => {
    // const purchaseCredit = userData?.credit_history.filter(
    //   (credit) => credit.action === 'purchase',
    // );
    // if (purchaseCredit?.length > 0) {
    //   return true;
    // } else {
    //   return false;
    // }
    return userData?.credit_history.length > 0;
  };
  const charge = isCharge();

  const purchaseCreditModal = () => {
    return charge ? openModal(purchaseCreditsRef) : openModal(notEnoughCredistRef);
  };

  return (
    <div className="SentMessageContainer">
      {notEnoughCredits && (
        <div className="NotEnoughCredits" onClick={purchaseCreditModal}>
          Buy credits to send message
        </div>
      )}
      <div className={notEnoughCredits ? 'SentMessage Error' : 'SentMessage'}>
        <div className="Message">
          {image && <ImageNPreview name={image} nameFullSize={image} />}
          {text}
          <SentTime messageTime={messageTime} />
        </div>
      </div>
    </div>
  );
};

export default SentMessage;
