import './Gender.scss';

import { useState } from 'react';

import FEMALEBLACK from '../../../../../../../../assets/images/Lander13/black/female.png';
import MALEBLACK from '../../../../../../../../assets/images/Lander13/black/male.png';
import FEMALEBLUE from '../../../../../../../../assets/images/Lander13/blue/female.png';
import MALEBLUE from '../../../../../../../../assets/images/Lander13/blue/male.png';
import FEMALEPURPLE from '../../../../../../../../assets/images/Lander13/purple/female.png';
import MALEPURPLE from '../../../../../../../../assets/images/Lander13/purple/male.png';
import FEMALERED from '../../../../../../../../assets/images/Lander13/red/female.png';
import MALERED from '../../../../../../../../assets/images/Lander13/red/male.png';
import LanderChat from '../../LanderChat/LanderChat';
import VerticalOption from '../../VerticalOption/VerticalOption';

export default function Gender({ onNextStep, setValue, landerId }) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = {
    '13-1': [
      {
        content: 'Hello, I’m Emily. Tell me your Gender and I’ll find you a local hook-up.'
      }
    ],
    '13-2': [
      {
        content:
          'Hi handsome! I’m Mia. Do you want to connect with some sexy girls? Please tell me, are you:'
      }
    ],
    '13-3': [
      {
        content:
          'I’m Isabella, nice to meet you! Let me give you some fun! Please tell me, are you:'
      }
    ],
    '13-4': [
      {
        content:
          'My name is Sophia and I’m looking forward to please you! Tell me your gender first:'
      }
    ]
  };

  const avatars = {
    '13-1': {
      male: MALEBLACK,
      female: FEMALEBLACK
    },
    '13-2': {
      male: MALERED,
      female: FEMALERED
    },
    '13-3': {
      male: MALEPURPLE,
      female: FEMALEPURPLE
    },
    '13-4': {
      male: MALEBLUE,
      female: FEMALEBLUE
    }
  };

  const handleGenderSelect = (gender) => {
    setValue('gender', gender);
    onNextStep();
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <div className="GenderContainer">
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />
      <div className={isElementVisible ? 'Options ToggleElement' : 'Options Hidden'}>
        <VerticalOption
          title="MALE"
          backgroundImage={avatars[`${landerId}`].male}
          onClick={() => handleGenderSelect('male')}
          landerId={landerId}
        />
        <VerticalOption
          title="FEMALE"
          backgroundImage={avatars[`${landerId}`].female}
          onClick={() => handleGenderSelect('female')}
          landerId={landerId}
        />
      </div>
    </div>
  );
}
