import { useState } from 'react';

import {
  casualSexV1,
  casualSexV1Mobile,
  coupleOfDatesV1,
  coupleOfDatesV1Mobile,
  seriousRelationshipV1,
  seriousRelationshipV1Mobile
} from '../../../../../../../../assets/images/Lander15/version1';
import {
  casualSexV2,
  casualSexV2Mobile,
  coupleOfDatesV2,
  coupleOfDatesV2Mobile,
  seriousRelationshipV2,
  seriousRelationshipV2Mobile
} from '../../../../../../../../assets/images/Lander15/version2';
import {
  casualSexV3,
  casualSexV3Mobile,
  coupleOfDatesV3,
  coupleOfDatesV3Mobile,
  seriousRelationshipV3,
  seriousRelationshipV3Mobile
} from '../../../../../../../../assets/images/Lander15/version3';
import {
  casualSexV4,
  casualSexV4Mobile,
  coupleOfDatesV4,
  coupleOfDatesV4Mobile,
  seriousRelationshipV4,
  seriousRelationshipV4Mobile
} from '../../../../../../../../assets/images/Lander15/version4';
import VerticalOption from '../../VerticalOption/VerticalOption';

export default function Relationship({
  setIsValid,
  landerId,
  handleNextStep,
  currentStep,
  isElementVisible,
  setIsElementVisible,
  getResponsiveImage
}) {
  const [activeOption, setActiveOption] = useState(null);
  const avatars = {
    '15-1': {
      casualSexBg: getResponsiveImage(casualSexV1Mobile, casualSexV1),
      coupleOfDates: getResponsiveImage(coupleOfDatesV1Mobile, coupleOfDatesV1),
      seriousRelationship: getResponsiveImage(seriousRelationshipV1Mobile, seriousRelationshipV1)
    },
    '15-2': {
      casualSexBg: getResponsiveImage(casualSexV2Mobile, casualSexV2),
      coupleOfDates: getResponsiveImage(coupleOfDatesV2Mobile, coupleOfDatesV2),
      seriousRelationship: getResponsiveImage(seriousRelationshipV2Mobile, seriousRelationshipV2)
    },
    '15-3': {
      casualSexBg: getResponsiveImage(casualSexV3Mobile, casualSexV3),
      coupleOfDates: getResponsiveImage(coupleOfDatesV3Mobile, coupleOfDatesV3),
      seriousRelationship: getResponsiveImage(seriousRelationshipV3Mobile, seriousRelationshipV3)
    },
    '15-4': {
      casualSexBg: getResponsiveImage(casualSexV4Mobile, casualSexV4),
      coupleOfDates: getResponsiveImage(coupleOfDatesV4Mobile, coupleOfDatesV4),
      seriousRelationship: getResponsiveImage(seriousRelationshipV4Mobile, seriousRelationshipV4)
    }
  };

  const handleOptionClick = (option) => {
    setActiveOption(option);
    handleNextStep([currentStep.field]);
    setIsValid(false);
    setIsElementVisible(false);
  };

  return (
    <div className="RelationshipContainer">
      <div
        className={isElementVisible ? 'ToggleElement SelectOptions' : 'Hidden RelationshipOptions'}
      >
        <VerticalOption
          title="Casual Sex"
          backgroundImage={avatars[`${landerId}`].casualSexBg}
          onClick={() => handleOptionClick('Casual Sex')}
          isActive={activeOption === 'Casual Sex'}
        />
        <VerticalOption
          title="Couple of dates"
          backgroundImage={avatars[`${landerId}`].coupleOfDates}
          onClick={() => handleOptionClick('Couple of dates')}
          isActive={activeOption === 'Couple of dates'}
        />
        <VerticalOption
          title="Serious Relationships"
          backgroundImage={avatars[`${landerId}`].seriousRelationship}
          onClick={() => handleOptionClick('Serious Relationships')}
          isActive={activeOption === 'Serious Relationships'}
        />
      </div>
    </div>
  );
}
