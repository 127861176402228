import { useMutation } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';
import { UserProfile } from '../../../core/APIHooks/userData/useGetUserData';

const key = ['FRESHCHAT_RESTORE_ID'];

export interface IFreshChat {
  restoreId: string;
}

export const useUpdateUserFreshChatRestoreId = () =>
  useMutation({
    mutationKey: [...key],
    mutationFn: async (restoreId: IFreshChat): Promise<UserProfile> => {
      const { data } = await axiosInstance.post('/api/v2/users/set-restore-id', {
        restore_id: restoreId
      });
      return data;
    }
  });
