import './Lander18.scss';

import { useEffect } from 'react';

import loaderImg from '../../../../../../assets/images/Lander17/loader.gif';
import useZoomLevel from '../../../../../../hooks/useZoomLevel';
import generateSteps from './data/steps';
import { FallbackProvider } from './FallbackProvider';

const hasOption = (slug) => {
  const regex = new RegExp(`/18-(1|2|)/.*${slug}`);
  return regex.test(window.location.href);
};

export default function Lander18(props) {
  const { isZoomed, zoomLevel } = useZoomLevel();
  const { nextStep, currentFormField, submitForm, handleNextStep, landerId } = props;
  const steps = generateSteps(landerId);
  const isLastStep = currentFormField >= steps.length + 1;
  const landerVersionClassName = isZoomed
    ? `LanderContainer18 version${landerId} zoomed${zoomLevel}`
    : `LanderContainer18 version${landerId}`;
  const landerContentInnerClassName = `LanderContentInner18 version${landerId}`;
  const hasSSO = hasOption('sso');

  useEffect(() => {
    if (isLastStep) {
      submitForm();
    }
  }, [currentFormField]);

  const currentStep = steps[Math.min(currentFormField, steps.length) - 1];
  const { component: CurrentStep } = currentStep || {};

  return (
    <div className={landerVersionClassName}>
      <div className="BackgroundOverlay18">
        <div className="leftImage"></div>
        <div className="rightImage"></div>
      </div>
      {isLastStep && (
        <div className="LoadingOverlay">
          <img src={loaderImg} alt="Loader" />
        </div>
      )}

      <form
        className="LanderContent"
        onSubmit={(e) => {
          e.preventDefault();
          if (currentStep.field) {
            handleNextStep([currentStep.field]);
          }
        }}
      >
        <FallbackProvider>
          {CurrentStep && (
            <div className={landerContentInnerClassName}>
              <CurrentStep
                onNextStep={nextStep}
                handleNextStep={handleNextStep}
                hasSSO={hasSSO}
                {...props}
              />
            </div>
          )}
        </FallbackProvider>
      </form>
    </div>
  );
}
