import axios from 'axios';

export const urls = {
  conversations: '/api/member/messages/conversations',
  singleConversations: 'api/member/inbox/conversations/messages',
  conversationNewInbox: 'api/member/inbox/conversations',
  conversationNew: 'api/member/inbox/conversations/latest',
  message: '/api/member/messages',
  messageSettings: '/api/settings',
  conversationsTab: 'api/member/messages/conversations/latest'
};

export const getConversations = (userToken, loadMoreConv) => {
  return axios.get(
    loadMoreConv
      ? `${urls.conversations}?paginated=1&${loadMoreConv}`
      : `${urls.conversations}?paginated=1`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  );
};

export const getNewConversationsInbox = (userToken, page) => {
  return axios.get(
    page
      ? `${urls.conversationNewInbox}?page=${page}&per_page=20`
      : `${urls.conversationNewInbox}?per_page=20`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  );
};

export const getNewConversations = (userToken, page) => {
  return axios.get(
    page
      ? `${urls.conversationNew}?page=${page}&per_page=20`
      : `${urls.conversationNew}?per_page=20`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  );
};

export const getConversationsTab = (userToken, loadMoreConv) => {
  return axios.get(
    loadMoreConv
      ? `${urls.conversationsTab}?paginated=1&${loadMoreConv}`
      : `${urls.conversationsTab}?paginated=1`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  );
};

export const getConversationByID = (convId, userToken) => {
  return axios.get(`${urls.conversations}/${convId}`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const getSingleConversationByID = (convId, userToken, page) => {
  return axios.get(`${urls.singleConversations}/${convId}?${page ? `page=${page}` : 'page=1'}`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const sendMessage = (profileId, body, formData, userToken) => {
  let messageData = {};
  if (formData) {
    formData.append('receiver_profile_id', profileId);
    if (body) {
      formData.append('body', body);
    }
  } else {
    messageData = {
      receiver_profile_id: profileId,
      body: body
    };
  }

  return axios.post(`${urls.message}`, formData ? formData : messageData, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const getChatStarters = (userToken) => {
  return axios.get(`${urls.messageSettings}/chat-starters`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const saveFailedMessages = (body, time) => {
  const oldData = JSON.parse(localStorage.getItem('failedMessages'));
  const newData = {
    messageBody: body,
    messageTime: time
  };
  const data = !oldData ? [newData] : [...oldData, newData];

  localStorage.setItem('failedMessages', JSON.stringify(data));
};

export const getFailedMessages = () => {
  const messages = JSON.parse(localStorage.getItem('failedMessages'));
  return [messages];
};

export const clearFailedMessages = () => {
  return localStorage.removeItem('failedMessages');
};
