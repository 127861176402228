import './Username.scss';

import React, { useState } from 'react';

import LanderChat from '../../LanderChat/LanderChat';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Username({
  setValue,
  errors,
  clearErrors,
  handleNextStep,
  landerId,
  getValues,
  autogenerate
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const landerButtonClassName = `version${landerId}`;

  const messages = {
    '12-1': [
      {
        content: 'You’re in luck, we have over 1,000 girls near you! Create username and password'
      }
    ],
    '12-2': [
      {
        content:
          'It’s your lucky day, we have over  1,000  girls nearby! Create username and password'
      }
    ],
    '12-3': [
      {
        content: autogenerate
          ? 'Wow, we have a lot of members in your area. Choose a username'
          : 'Wow, we have a lot of members in your area. Create username and password'
      }
    ],
    '12-4': [
      {
        content:
          'We have more than 100 women within 10 miles of your location. Create username and password:'
      }
    ]
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer12">
          <Input
            placeholder="Username"
            name="username"
            value={getValues('username')}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
          {!autogenerate && (
            <Input
              placeholder="Enter your password"
              name="password"
              type="password"
              {...{
                errors,
                setValue,
                clearErrors
              }}
            />
          )}
        </div>
        <div className="ButtonsContianer">
          <Button
            className={landerButtonClassName}
            text="NEXT"
            handleNextStep={() => handleNextStep(['username', 'password'])}
          />
        </div>
      </div>
    </>
  );
}
