import Age from '../Steps/Age/Age';
import Email from '../Steps/Email/Email';
import GenderOfInterest from '../Steps/GenderOfInterest/GenderOfInterest';
import JoinOptions from '../Steps/JoinOptions/JoinOptions';
import Location from '../Steps/Location/Location';
import Password from '../Steps/Password/Password';
import Username from '../Steps/Username/Username';

const data = (isSSO) => [
  GenderOfInterest,
  Location,
  Age,
  Username,
  Password,
  ...(isSSO ? [JoinOptions] : []),
  Email
];

export default data;
