import './ConversationHeader.scss';

import React from 'react';
import { useDispatch } from 'react-redux';

import BACK from '../../../../../../../../assets/images/back_arrow.png';
import ImageComponent from '../../../../../../../../components/ImageComponent/ImageComponent';
import ImageN from '../../../../../../../../components/ImageN/ImageN';
import { setNewConversationId } from '../../../../../../../../redux/actions/messageConversationId';
import { showMessagesPage } from '../../../../navigation.service';

const ConversationHeader = (props) => {
  const dispatch = useDispatch();
  const { selectedProfileData, setMobileComponentActive } = props;
  console.log(selectedProfileData);
  const renderOnlineStatus = () => {
    return (
      <div className="OnlineStatusContainer">
        <span className="OnlineStatus"></span>
        <span> Online</span>
      </div>
    );
  };

  const renderOfflineStatus = () => {
    return (
      <div className="OfflineStatusContainer">
        <span className="OfflineStatus"></span>
        <span> Offline</span>
      </div>
    );
  };

  const viewProfile = () => {
    if (window.innerWidth < 501) {
      setMobileComponentActive(2);
    }
  };

  const backToConversationList = () => {
    dispatch(setNewConversationId(''));
    showMessagesPage();
  };

  const renderBackArrow = () => {
    return (
      <div className="BackArrowContainer">
        <ImageComponent url={BACK} onClick={backToConversationList} />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <React.Fragment>
        {window.innerWidth < 501 && renderBackArrow()}
        <div className="UserPhotoContainer" onClick={viewProfile}>
          <ImageN name={selectedProfileData?.profile_image} />
        </div>
        <div className="UserNameContainer" onClick={viewProfile}>
          <div className="Username">{selectedProfileData?.username}</div>
          <div className="UserStatusPreview">
            {selectedProfileData?.online_status ? renderOnlineStatus() : renderOfflineStatus()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return <div className="ConversationHeader">{renderHeader()}</div>;
};

export default ConversationHeader;
