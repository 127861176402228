import './membersPricing.scss';

import { useLocation, useNavigate } from '@reach/router';
import { QueryKey } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { queryClient } from '../../../App';
import { key as UserKey, useGetUserData } from '../../../core/APIHooks/userData/useGetUserData';
import { MEMBERS_ROUTES } from '../../../pages/MainView/components/MembersAreaMain/members_routes';
import { Page } from '../../components/layout/Page';
import { SubscriptionCard } from '../../components/shared/SubscriptionCard/SubscriptionCard';
import { Triggers } from '../../model/Triggers';
import { useGetSubscriptionPackages } from '../../services/subscription/useGetSubscriptionPackages';
import { PricingFooter } from './components/PricingFooter';

const userQueryKey: QueryKey = UserKey;
export const MembersPricing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: user } = useGetUserData();
  const state = location.state as { trigger?: Triggers | null; id?: number | null };
  const trigger = state?.trigger;
  const id = state?.id;
  const { data } = useGetSubscriptionPackages();
  const [purchasing, setPurchasing] = useState<boolean>(false);
  const getFinalUrl = (trigger?: Triggers | null, id?: number | null) => {
    switch (trigger) {
      case Triggers.FAVORITES:
        return `${location.origin}/members-area/my-favorites`;
      case Triggers.PROFILE:
        return `${location.origin}/members-area/member-profile/${id}`;
      case Triggers.VISITORS:
        return `${location.origin}/members-area/visitors`;
      default:
        return location.href;
    }
  };
  useEffect(() => {
    user?.data.hasPremiumSubscription && navigate(MEMBERS_ROUTES.MEMBERSAREA);
  }, [user?.data.hasPremiumSubscription]);
  const redirectOnSuccess = async () => {
    try {
      await queryClient.setQueryData(userQueryKey, {
        ...user,
        data: { ...user?.data, premium: true, hasPremiumSubscription: true }
      });
      navigate(getFinalUrl(trigger, id));
    } catch (e) {
      console.error(e);
    }
  };
  const renderPackages = () =>
    data?.map((pkg) => (
      <SubscriptionCard
        packageDef={pkg}
        key={pkg.id}
        redirectUrl={getFinalUrl(trigger, id)}
        trigger={trigger || Triggers.VISITORS}
        profile={id}
        setPurchasing={setPurchasing}
        purchasingInProgress={purchasing}
        onSuccess={redirectOnSuccess}
      />
    ));
  return (
    <Page>
      <h1 className="pricing-header">
        Become our <span>Premium</span> member and you will get <br /> more benefits!
      </h1>
      <div className={'subscriptions'}>{renderPackages()}</div>
      <PricingFooter />
    </Page>
  );
};
