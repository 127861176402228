import './style.scss';

import React, { FC } from 'react';
import { useRecoilState } from 'recoil';

import { Featured, Limited } from '../../../../assets/icons';
import { userData as userRecoil } from '../../../../recoil/atoms/userDataAtom';
import { currencySymbol } from '../../../helpers/currency';
import { formatNumberWithDecimalPlaces } from '../../../helpers/numberHelpers';
import { CreditsPackage } from '../../../model/classes/CreditsPackage';

interface GiftCardProps extends Partial<CreditsPackage> {
  onClick: (id: number, credits: number) => void;
}
export const GiftCard: FC<GiftCardProps> = ({
  percentage,
  background_color,
  footer_color,
  number_of_messages,
  id,
  currency,
  total_price,
  featured,
  limited,
  text_color,
  onClick
}) => {
  const [user] = useRecoilState(userRecoil);
  const creditCardVersion = user?.data?.package_layout;

  return (
    <div className={creditCardVersion === 1 ? 'card-credit' : 'card-credit-v2'}>
      <div
        className={`card-wrapper`}
        style={{ backgroundColor: background_color }}
        onClick={() => id && number_of_messages && onClick(id, number_of_messages)}
      >
        <div className="card-amount-container">
          <div className={'card-amount'} style={{ color: text_color }}>
            {number_of_messages}
          </div>
          <div className={'card-subtext'}>messages</div>
        </div>
        <div className={'card-price-container'}>
          <div className={'buy-price'}>
            {currency && currencySymbol(currency)} {formatNumberWithDecimalPlaces(total_price, 2)}
          </div>
          <div className={'card-discount'}>Save ${percentage}%</div>
        </div>
        <div className={'card-buy-now'} style={{ backgroundColor: footer_color }}>
          <div className={'buy-title'}>Gift now</div>
          <div className={'buy-title-sm'}>Gift</div>
        </div>
        <div className={'card-icon-container'}>
          {featured ? <Featured /> : null}
          {limited ? <Limited /> : null}
        </div>
      </div>
    </div>
  );
};
