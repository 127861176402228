import { saveToken } from '../../../services/token-service/token.service';
import { ROUTES } from '../../routes';

export function handleAuthReactivation(res, email, navigate, callback) {
  const { redirect_url, auth_data } = res;

  if (redirect_url === null && auth_data === null) {
    localStorage.setItem('current-email', email);
    window.location.href = ROUTES.EMAILVERIFICATIONINSTRUCTION;
    return;
  }

  if (redirect_url) {
    window.location.href = redirect_url;
    return;
  }

  if (auth_data) {
    const { access_token, expires_in, user } = auth_data;
    saveToken(access_token, expires_in);
    localStorage.setItem('userData', JSON.stringify(user));
    window.location.href = ROUTES.MEMBERSAREA;
  } else {
    callback();
  }
}
