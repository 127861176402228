import './AboutUs.scss';

import React from 'react';

import SIGNATURE from '../../../../../assets/images/AboutUs/amber.png';
import ABOUTUS from '../../../../../assets/images/AboutUs/woman_about.png';
import HEADERIMAGE from '../../../../../assets/images/HeadersImages/About-Us-Header.png';
import HeaderLayout from '../../../../../components/HeaderLayout/HeaderLayout';
import ImageComponent from '../../../../../components/ImageComponent/ImageComponent';

const AboutUs = () => {
  const title = 'About Us';
  const description = 'Uncovering the Web’s best kept secret';
  const image = HEADERIMAGE;
  const img = SIGNATURE;
  return (
    <div className="AboutUs">
      <HeaderLayout
        title={title}
        description={description}
        image={image}
        className={'AboutUsImage'}
      />
      <div className="AboutUs-Container">
        <div className="AboutUs-Content">
          <div className="ImagePlaceholder">
            <ImageComponent url={ABOUTUS} />
          </div>
          <div className="Text-Content">
            <p>
              LocalFlirt is dedicated to providing adult users with a discrete place to browse,
              match and message other profiles on the website.
            </p>
            <p>
              We want you to feel safe expressing your wildest dreams and desires with other
              like-minded adults.
            </p>
            <p>
              Browse through thousands of profiles until you find your match…that’s when the fun
              starts!
            </p>
            <p>
              This site is recommended for adults who are open for fun in a friendly and flirtatious
              environment, our profiles are chatty and active so don’t shy away.
            </p>
            <p>
              Should you have any questions, feel free to contact us at <br className="br" />
              <a
                className="Support-Text"
                href="mailto:support@localflirt.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@localflirt.com
              </a>
            </p>
            <ImageComponent url={img} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
