import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';

const url = 'api/v2/profiles/browse';
const key = ['BROWSE'];
export const useGetProfiles = (page = 1, ageRange, hair, body, perPage = 30) =>
  useQuery([key, page, ageRange, hair, body], async () => {
    const { data } = await axiosInstance.get(url, {
      params: {
        per_page: perPage,
        page: page,
        from: ageRange ? decodeURI(ageRange).split(',')[0] : undefined,
        to: ageRange ? decodeURI(ageRange).split(',')[1] : undefined,
        'hair_color[]': hair ? decodeURI(hair) : undefined,
        'body_build[]': body ? decodeURI(body) : undefined
      }
    });
    return data;
  });
