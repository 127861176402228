import Age from '../Steps/Age/Age';
import BodyType from '../Steps/BodyType/BodyType';
import Email from '../Steps/Email/Email';
import EmailShorter from '../Steps/EmailShorter/EmailShorter';
import Gender from '../Steps/Gender/Gender';
import Info from '../Steps/Info/Info';
import SignInProceed from '../Steps/SignInProceed/SignInProceed';
import TypeOfGirl from '../Steps/TypeOfGirl/TypeOfGirl';
import UsernamePassword from '../Steps/UsernamePassword/UsernamePassword';
import Warning from '../Steps/Warning/Warning';

function generateSteps(landerId) {
  const data = [
    {
      component: Warning
    },
    {
      component: Info
    },
    {
      component: TypeOfGirl
    },
    {
      component: BodyType
    },
    {
      component: SignInProceed
    },
    {
      component: Gender
    },
    {
      component: Age
    },
    {
      component: UsernamePassword
    },
    {
      component: Email
    }
  ];

  const data2 = [
    {
      component: Warning
    },
    {
      component: Info
    },
    {
      component: TypeOfGirl
    },
    {
      component: BodyType
    },
    {
      component: SignInProceed
    },
    {
      component: Age
    },
    {
      component: EmailShorter
    }
  ];

  return landerId === '18-1' ? data : data2;
}

export default generateSteps;
