import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';

import { axiosInstance } from '../../../axios/axiosInstance';
import { PaymentPayload } from '../../model/interfaces/PaymentPayload';
import { SubscriptionPayload } from '../../model/interfaces/SubscriptionPayload';

export const useCheckOutPayment = (trigger: string | null = null) =>
  useMutation(
    async (payload: PaymentPayload | SubscriptionPayload): Promise<{ redirect_url: string }> => {
      const { data } = await axiosInstance.post('api/payments/checkout', { ...payload });
      return data;
    },
    {
      onError: (error) => {
        const err = error as AxiosError;
        const message = err.response?.data?.message;
        if (trigger?.includes('email_offer')) {
          return;
        }
        notification.error({ message: message ?? 'Purchase error!' });
      }
    }
  );
