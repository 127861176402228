import './ModalLogo.scss';

import React from 'react';

import SIGNUPLOGO from '../../../assets/images/LandingPage/LocalFlirt-red-logo-black.svg';
import ImageComponent from '../../ImageComponent/ImageComponent';

const ModalLogo = () => {
  return (
    <div className="ModalLogo">
      <ImageComponent className="LogoImage" url={SIGNUPLOGO} />
    </div>
  );
};

export default ModalLogo;
