import './Conversation.scss';

import React, { useState } from 'react';

import ChatStarters from './ChatStarters/ChatStarters';
import ConversationContainer from './ConversationContainer/ConversationContainer';
import ConversationHeader from './ConversationHeader/ConversationHeader';
import MessageInput from './MessageInput/MessageInput';

const Conversation = (props) => {
  const {
    selectedProfileData,
    conversation,
    loadingConversation,
    setLoadingConversation,
    setConversation,
    openModal,
    notEnoughCredistRef,
    purchaseCreditsRef,
    setMobileComponentActive,
    mobileComponentActive
  } = props;
  const [message, getMessage] = useState();
  const [starter, getStarter] = useState();
  const [notEnoughCredits, setNotEnoughCredits] = useState();

  return (
    <div className={mobileComponentActive === 1 ? 'Conversation' : 'Conversation MobileClosed'}>
      <ConversationHeader
        selectedProfileData={selectedProfileData}
        setMobileComponentActive={setMobileComponentActive}
      />
      <ChatStarters getStarter={getStarter} />
      <ConversationContainer
        messages={conversation}
        sentMessage={message}
        loadingConversation={loadingConversation}
        setLoadingConversation={setLoadingConversation}
        setConversation={setConversation}
        notEnoughCredits={notEnoughCredits}
        openModal={openModal}
        notEnoughCredistRef={notEnoughCredistRef}
        purchaseCreditsRef={purchaseCreditsRef}
      />
      <MessageInput
        selectedProfileData={selectedProfileData}
        getMessage={getMessage}
        starter={starter}
        getStarter={getStarter}
        openModal={openModal}
        notEnoughCredistRef={notEnoughCredistRef}
        purchaseCreditsRef={purchaseCreditsRef}
        setConversation={setConversation}
        setNotEnoughCredits={setNotEnoughCredits}
      />
    </div>
  );
};

export default Conversation;
