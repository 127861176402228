import './SignUpAff6NoZip.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@reach/router';
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import LOGO from '../../../../assets/images/FunnelPage/aff1Logo.png';
import PROFILEONE from '../../../../assets/images/FunnelPage/aff3prof1.png';
import PROFILETWO from '../../../../assets/images/FunnelPage/aff3prof2.png';
import PROFILETHREE from '../../../../assets/images/FunnelPage/aff3prof3.png';
import PROFILEFOUR from '../../../../assets/images/FunnelPage/aff3prof4.png';
import CHATICON from '../../../../assets/images/FunnelPage/chatIcon.png';
import HEARTICON from '../../../../assets/images/FunnelPage/heartIcon.png';
import PROFILEPLUSICON from '../../../../assets/images/FunnelPage/profilePlusIcon.png';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Form/Input/Input';
import ImageComponent from '../../../../components/ImageComponent/ImageComponent';
import { useEmailValidation } from '../../../../core/emailValidation/useEmailValidation';
import { authMe, memberLogin } from '../../../../services/auth-service/auth.service';
import { showEmailVerificationInstruction } from '../../../../services/navigation-service/navigation.service';
import { saveToken } from '../../../../services/token-service/token.service';
import { setUserLocalData } from '../../../../services/user-service/user.service';
import { handleAuthReactivation } from '../../helpers/authReactivation';
import { InputNumber } from '../Inputs';

const schema = yup.object().shape({
  username: yup
    .string()
    .required('Username is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  password: yup
    .string()
    .required('Password is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  age: yup
    .number()
    .typeError('Age is required!')
    .required('Age is required!')
    .test('len', 'You must be at least 18 years', (val) => val && val > 17)
});

const SignUpAff6NoZip = () => {
  const [currentFormField, setCurrentFormField] = useState(1);
  const [loadingZipCode, setLoadingZipCode] = useState(true);
  const [googleLogin, setGoogleLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const { mutate: validateEmail, isLoading } = useEmailValidation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const clickID = urlParams.get('clickId');
  const affiliate = urlParams.get('affiliate');
  const source = urlParams.get('source');
  const navigate = useNavigate();

  const zipCode = 90001; // add with MaxMind dynamic zip code
  const cityCode = 'Los Angeles'; // add with MaxMind dynamic zip code

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
    trigger,
    formState: { errors },
    control
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const emailValidation = () => {
    const username = getValues('username');
    const password = getValues('password');
    const age = getValues('age');
    const birthYear = new Date().getFullYear() - age;
    const gender = getValues('gender');
    const seeking = getValues('seeking');
    const city = getValues('city');
    const email = getValues('email');
    const zip_code = getValues('zip_code');
    const landerId = googleLogin ? 7 : '6typed_email';
    const data = {
      email,
      username,
      password,
      password_confirmation: password,
      click_id: clickID,
      affiliate_id: affiliate,
      lander_id: landerId,
      profile: {
        age: age,
        ...(age ? { birthday: `${birthYear}-01-01` } : {}),
        city: city,
        zip_code: zip_code,
        seeking: seeking,
        gender: gender
      },
      ...(source ? { source_id: source } : {})
    };
    validateEmail(data, {
      onSuccess: (res) => {
        handleAuthReactivation(res, email, navigate, () => {
          setValue('email', getValues('email'));
          clearErrors('email');
          submitForm();
        });
      },
      onError: (err) => {
        setError('email', {
          type: 'manual',
          message: err.data.errors.email[0]
        });
      }
    });
  };

  const renderMediaContainer = (image, favourite, chats) => {
    return (
      <div className="LogoAndMedia">
        <div className="LogoContainer">
          <ImageComponent url={LOGO} />
        </div>
        <div className="MediaContainer">
          <div className="IconHolder AffImageContainer">
            <ImageComponent url={image} />
            <ImageComponent url={PROFILEPLUSICON} className="ProfilePlusIcon" />
          </div>
          <div className="IconHolder FavouriteContainer">
            <div>
              <ImageComponent url={HEARTICON} />
            </div>
            <div>{favourite}</div>
          </div>
          <div className="IconHolder ChatContainer">
            <div>
              <ImageComponent url={CHATICON} />
            </div>
            <div>{chats}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderInfo = (username, tag) => {
    return (
      <div className="InfoContainer">
        <div className="UsernameContainer">{username}</div>
        <div className="TagContainer">{tag}</div>
      </div>
    );
  };

  const handleNextStep = async (type, view) => {
    const isValid = await trigger(type);
    if (isValid) {
      setCurrentFormField(view);
    }
  };

  const handleNextStepTwoInput = (typeOne, typeTwo, view) => {
    trigger(typeOne)
      .then(() => trigger(typeTwo))
      .then(() => {
        if (Object.keys(errors).length === 0) {
          setCurrentFormField(view);
        }
      });
  };

  const renderStepOne = () => {
    return (
      <div className="Steps StepOne">
        {renderMediaContainer(PROFILEONE, '654.2k', '10.7k')}
        <div className="FormAndInfoContainer">
          {renderInfo('@Carla22', '#sitonyou')}
          <div className="FormContainer">
            <div className="TitleContainer">Have you ever been on sex date?</div>
            <div className="FormHolder">
              <Button className="Inverted" onClick={() => setCurrentFormField(2)} text="No" />
              <Button onClick={() => setCurrentFormField(2)} text="Yes" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStepTwo = () => {
    return (
      <div className="Steps StepTwo">
        {renderMediaContainer(PROFILETWO, '634.9k', '11.7k')}
        <div className="FormAndInfoContainer">
          {renderInfo('@JamieLynn', '#cumming')}
          <div className="FormContainer">
            <div className="TitleContainer">Confirm you are over 18 to continue</div>
            <div className="InputContainers">
              {/* <DatePicker
                control={control}
                name="birthday"
                placeholder="Birthdate"
                errorMessage={errors?.birthday?.message}
                className="AffFlowInput"
              /> */}
              <InputNumber
                name="age"
                {...register('age')}
                placeholder="Age"
                errorMessage={errors?.age?.message}
              />
              {/* <DatePicker2
                register={register}
                control={control}
                errorMessage={errors?.birthday?.message}
                name="birthday"
              /> */}
            </div>
            <div className="ButtonHolder">
              <Button onClick={() => handleNextStep('age', 3)} text="Continue" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStepThree = () => {
    return (
      <div className="Steps StepThree">
        {renderMediaContainer(PROFILETHREE, '250.2k', '30.7k')}
        <div className="FormAndInfoContainer">
          {renderInfo('@Pippa_21', '#fuckmeeyes')}
          <div className="FormContainer">
            <div className="TitleContainer">Create Your Username & Password</div>
            <div className="InputContainers">
              <Input
                register={register}
                name="username"
                placeholder="Username"
                errorMessage={errors?.username?.message}
                onChange={(e) => {
                  setValue('username', e.target.value);
                  clearErrors('username');
                  clearErrors('email');
                }}
                // onKeyPress={handleEnterClicked}
              />
              <Input
                register={register}
                name="password"
                placeholder="Password"
                errorMessage={errors?.password?.message}
                onChange={(e) => {
                  setValue('password', e.target.value);
                  clearErrors('password');
                }}
                // onKeyPress={handleEnterClicked}
                type="password"
              />
            </div>
            <div className="ButtonHolder">
              <Button
                onClick={() => handleNextStepTwoInput('username', 'password', 4)}
                text="Continue"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const responseGoogle = (res) => {
    setValue('email', res.profileObj?.email);
    setGoogleLogin(true);
    document.getElementById('submitFormRef').click();
  };

  const renderStepFour = () => {
    return (
      <div className="Steps StepFour">
        {renderMediaContainer(PROFILEFOUR, '684.2k', '12.7k')}
        <div className="FormAndInfoContainer">
          {renderInfo('@serena27', '#openforyou')}
          <div className="FormContainer">
            <div className="TitleContainer">Start meeting local girls now, enter an email.</div>
            <div className="InputContainers">
              <Input
                register={register}
                name="email"
                placeholder="johndoe@gmail.com"
                errorMessage={errors?.email?.message}
                onChange={(e) => {
                  setValue('email', e.target.value);
                  clearErrors('username');
                  clearErrors('email');
                }}
                onKeyDown={(e) => {
                  setGoogleLogin(false);
                  if (e.keyCode === 32) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            {/* <div className="TextContainer">OR</div>
            <div className="GoogleLoginContainer">
              <GoogleLogin
                clientId="49889973396-1mklb7sakkn7mkkjrd4h947hsfnnf0t8.apps.googleusercontent.com"
                buttonText="Register with Google"
                width="100%"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                // isSignedIn={true}
              />
            </div> */}
            <div className="ButtonHolder">
              <Button
                id="submitFormRef"
                onClick={emailValidation}
                text={loading ? 'Loading...' : 'Begin'}
                className={loading ? 'DisabledButton' : ''}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onSubmit = (data) => {
    setLoading(true);
    setValue('email', data.email);
    const birthYear = new Date().getFullYear() - data.age;
    const landerId = googleLogin ? 6 : '6typed_email';
    let registerData = {};
    if (source) {
      registerData = {
        username: data.username,
        email: data.email,
        password: data.password,
        password_confirmation: data.password,
        terms_and_conditions: 1,
        click_id: clickID,
        affiliate_id: affiliate,
        source_id: source,
        lander_id: landerId,
        profile: {
          birthday: `${birthYear}-01-01`,
          age: data.age
        }
      };
    } else {
      registerData = {
        username: data.username,
        email: data.email,
        password: data.password,
        password_confirmation: data.password,
        terms_and_conditions: 1,
        click_id: clickID,
        affiliate_id: affiliate,
        lander_id: landerId,
        profile: {
          birthday: `${birthYear}-01-01`,
          age: data.age
        }
      };
    }

    axios
      .post('/api/auth/member/register', registerData, {
        headers: {
          doNotAddUserTokenAuthorization: true
        }
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          localStorage.setItem('current-email', data.email);
          localStorage.setItem('affiliateSiteLockdown', new Date());
          // memberLogin(registerData).then((res) =>
          //   saveToken(res.data.access_token, res.data.expires_in)
          // );
          // showEmailVerificationInstruction();
          const loginData = {
            username: res.data.user.username,
            email: res.data.user.email,
            password: registerData.password
          };
          memberLogin(loginData)
            .then((res) => {
              setLoading(true);
              saveToken(res.data.access_token, res.data.expires_in);
              localStorage.setItem('userData', JSON.stringify(res.data.user));
              localStorage.setItem('affiliateSiteLockdown', new Date());
              googleLogin
                ? authMe(res.data.access_token)
                    .then((response) => {
                      setLoading(false);
                      setUserLocalData(response.data);
                      window.location.href = '/members-area';
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err);
                    })
                : showEmailVerificationInstruction();
              // setUserLocalData(res.data.user);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        } else {
          throw new Error('Something went wrong!');
        }
      })
      .catch((err) => {
        setLoading(false);
        setGoogleLogin(false);
        const data = err.response.data.errors;
        Object.keys(err.response.data.errors).forEach((name) => {
          let message = data[name][0];
          setError(name, { type: 'manual', message });
          // if (name === "username") {
          //   setCurrentFormField(3);
          // }
          if (name === 'email') {
            setCurrentFormField(4);
          }
        });
      });
  };

  const submitForm = (e) => {
    handleSubmit((data) => onSubmit(data))(e);
  };

  return (
    <div className="SignUpAff6NoZip">
      {/*<Helmet>
        <script>localStorage.setItem("accGuid", "2abd06aa-ef0d-449f-ac71-bd12413dace8")</script>
        <script src="https://pushcity1.xyz/ace-push.js" defer></script>
      </Helmet>*/}
      <form className="form" onSubmit={(e) => e.preventDefault(e)}>
        {currentFormField === 1 && renderStepOne()}
        {currentFormField === 2 && renderStepTwo()}
        {currentFormField === 3 && renderStepThree()}
        {currentFormField === 4 && renderStepFour()}
        {/* {currentFormField === 5 && renderStepFive()} */}
      </form>
    </div>
  );
};

export default SignUpAff6NoZip;
