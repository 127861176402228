import './style.scss';

import { forwardRef, Ref } from 'react';

interface IInputNumber {
  placeholder?: string;
  errorMessage: string;
}

const removeSpecialCharacters = (e: any) => {
  // replacing no's other than 0-9
  e.target.value = e.target.value.replace(/[^0-9 ]/g, '');
  // to replace special characters
  e.target.value = e.target.value.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '');
};

const checkLength = (e: any) => {
  const KEYS = {
    leftKey: 37,
    rightKey: 39,
    backspace: 8,
    deleteKey: 46,
    digitZero: 48,
    digitNine: 57
  };
  if (
    e.keyCode === KEYS.backspace ||
    e.keyCode === KEYS.deleteKey ||
    e.keyCode === KEYS.rightKey ||
    e.keyCode === KEYS.leftKey
  ) {
    return true;
  }
  if (
    e.keyCode < KEYS.digitZero ||
    e.keyCode > KEYS.digitNine ||
    e.target.value.length === e.target.maxLength ||
    e.shiftKey
  ) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }
  return true;
};

export const InputNumber = forwardRef(
  ({ placeholder, errorMessage, ...props }: IInputNumber, ref: Ref<HTMLInputElement>) => {
    return (
      <div className="InputField">
        <input
          ref={ref}
          placeholder={placeholder}
          className="Input"
          type="tel"
          pattern="[0-9]*"
          maxLength={2}
          onKeyDown={checkLength}
          onKeyUp={removeSpecialCharacters}
          {...props}
        />
        {errorMessage ? <div className="Error-Container Active">{errorMessage}</div> : null}
      </div>
    );
  }
);
