import './Age.scss';

import React, { useState } from 'react';

import { InputNumber } from '../../../../../Inputs';
import LanderChat from '../../LanderChat/LanderChat';
import Button from '../../UI/Button/Button';

export default function Age({
  handleNextStep,
  typeOnlyNumbers,
  errors,
  setValue,
  clearErrors,
  register
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = [{ content: 'Type your age.' }];

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat messages={messages} endChatAction={toggleElementVisibility} />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer">
          <div className="InputContainer">
            <InputNumber
              name="age"
              {...register('age')}
              placeholder="Age"
              errorMessage={errors?.age?.message}
            />
          </div>
        </div>
        <div className="ButtonsContianer">
          <Button text="NEXT" handleNextStep={() => handleNextStep(['age'])} />
        </div>
      </div>
    </>
  );
}
