import './style.scss';

import { notification, Select } from 'antd';
import { useState } from 'react';

import { queryClient } from '../../../../App';
import { useGetGiftPackages } from '../../../../core/gifting/useGetGiftPackages';
import { key as ConvKey } from '../../../../core/messages/useGetConversationById';
import { CreditsPackage } from '../../../model/classes/CreditsPackage';
import { GiftConfirmModal } from './components/GiftConfirmModal';

const { Option } = Select;
interface GiftDropDownProps {
  name?: string;
  profileId?: number;
}
export const GiftDropDown = ({ name, profileId }: GiftDropDownProps) => {
  const { data } = useGetGiftPackages();
  const [open, setOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<Partial<CreditsPackage> | undefined>(
    undefined
  );
  const selectGiftPackage = async (id: number) => {
    const pkg = data?.find((item) => item.id === id);
    await setSelectedPackage(pkg);
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setSelectedPackage(undefined);
  };
  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
  const options = () =>
    data?.map((pkg) => (
      <Option
        value={pkg.id}
        label={`Gift her ${pkg.number_of_messages} messages (${pkg.total_price}) - SAVE ${pkg.percentage}%`}
        key={pkg.id}
      >
        Gift her{' '}
        <span style={{ color: pkg.limited ? 'green' : 'red', fontWeight: 700 }}>
          {pkg.number_of_messages}
        </span>{' '}
        messages ({pkg.total_price}) - SAVE {pkg.percentage}%
      </Option>
    ));
  const onSuccess = () => {
    sleep(5000).then(() => queryClient.invalidateQueries([...ConvKey, profileId?.toString()]));
    notification.success({ message: `${name} received your gift` });
    setOpen(false);
    setSelectedPackage(undefined);
  };
  return (
    <>
      <div className={'gift-dropdown'}>
        <div className={'label'}>Gift your girl</div>
        <div style={{ width: '100%' }}>
          <Select
            style={{ width: '100%' }}
            placeholder="Choose credit bundle"
            optionLabelProp="label"
            value={selectedPackage?.id ?? null}
            onChange={selectGiftPackage}
          >
            {options()}
          </Select>
        </div>
      </div>
      <GiftConfirmModal
        open={open}
        onCancel={closeModal}
        name={name}
        profileId={profileId}
        pkg={selectedPackage}
        onSuccess={onSuccess}
      />
    </>
  );
};
