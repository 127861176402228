import { useNavigate } from '@reach/router';
import { useQuery } from '@tanstack/react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';

import { axiosInstance } from '../../../axios/axiosInstance';

export const key = ['GET_GOOGLE_API_KEY'];

export const useGetGoogleApiKey = (code: string) => {
  const navigate = useNavigate();
  return useQuery(
    [key],
    async () => {
      const { data } = await axiosInstance.get(`/api/auth/member/google-login?code=${code}`);
      return data.token;
    },
    {
      retry: 0,
      enabled: !!code,
      onError: (error: AxiosError) => {
        if (error) {
          navigate('/', { replace: true });
          notification.error({ message: 'User does not exist.' });
        }
      }
    }
  );
};
