import './Login.scss';

import { GoogleCircleFilled } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@reach/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import SECURELOGIN from '../../assets/images/HomePage/lock.png';
import { showMembersArea } from '../../redux/actions/authentication';
import { SET_NOTIFICATIONS } from '../../redux/types/notifications';
import { authMe } from '../../services/auth-service/auth.service';
import { useLogin } from '../../services/authHooks/useLogin';
import {
  showEmailVerificationInstruction,
  showMembersAreaPage
} from '../../services/navigation-service/navigation.service';
import { saveToken, TOKEN } from '../../services/token-service/token.service';
import { cleanupUserData, setUserLocalData } from '../../services/user-service/user.service';
import { useGetGoogleApiKey } from '../../V2/services/auth/useGetGoogleApiKey';
import { useGetGoogleAuthUrl } from '../../V2/services/auth/useGetGoogleAuthUrl';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import { Form } from '../Form/Form';
import Input from '../Form/Input/Input';
import ImageComponent from '../ImageComponent/ImageComponent';
import LoaderComponent from '../LoaderCompoment/LoaderComponent';
import ForgotPassword from '../modals/ForgotPassword/ForgotPassword';
import ModalDialog from '../modals/ModalDialog/ModalDialog';
import SignUpFlow from '../SignUpFlow/SignUpFlow';
import SuccessMessage from '../SuccessMessage/SuccessMessage';

const schema = yup.object().shape({
  username: yup.string().required('Username is required!'),
  password: yup.string().required('Password is required!')
});

const Login = () => {
  const navigate = useNavigate();
  const { mutate: getGoogleUrl } = useGetGoogleAuthUrl();
  const { mutate: logIn } = useLogin();
  const submitRef = useRef(null);
  const forgotPassword = useRef(null);
  const registerModalRef = useRef(null);
  const [rememberMe, getRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginInvalidError, getLoginInvalidError] = useState('');
  const [showSuccesMessage, setShowSuccesMessage] = useState(false);
  const [authetificationSuccess, setAuthetificationSuccess] = useState(false);
  const dispatch = useDispatch();
  const pathname = localStorage.getItem('pathname');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const reactivate = urlParams.get('reactivate');
  const userDataLocalStorage = JSON.parse(localStorage.getItem('userData'));
  const code = urlParams.get('code');
  const { data: token } = useGetGoogleApiKey(code);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const checkboxChange = (e, value) => {
    getRememberMe(value);
  };

  const getLoggedUserData = (accessToken) => {
    return authMe(accessToken).then(saveUserData).catch(saveUserDataFailed);
  };

  const saveUserData = (response) => {
    dispatch(showMembersArea(true));
    const messageNotifications = response.data.data.unread_message_notifications;
    dispatch({
      type: SET_NOTIFICATIONS,
      payload: Number(messageNotifications) || ''
    });
    setUserLocalData(response.data);
  };

  const saveUserDataFailed = (err) => {
    if (err.response.status === 403) {
      showEmailVerificationInstruction();
      return;
    }
    return console.log(err);
  };

  const loginFailed = (err) => {
    setAuthetificationSuccess(false);
    setLoading(false);
    getLoginInvalidError('Incorrect Login Details Please Try Again');
  };

  const loginSuccess = (response) => {
    setAuthetificationSuccess(true);
    getLoginInvalidError();
    if (
      response.user.click_id &&
      !response.user.email_verified_at &&
      !response.user.auto_verified_at
    ) {
      localStorage.setItem('current-email', response.data.user.email);
      localStorage.setItem('current-username', response.data.user.username);
      localStorage.setItem('userData', JSON.stringify(response.data.user));
    }
    if (response) {
      saveToken(response.access_token, response.expires_in);
      getLoggedUserData(response.access_token);
      localStorage.setItem('current-email', response.user.email);
      localStorage.setItem('current-username', response.user.username);
      localStorage.setItem('userData', JSON.stringify(response.user));
      showMembersAreaPage(pathname);
    }
  };

  const onSubmit = (data) => {
    const formData = {
      ...data,
      ...(reactivate ? { reactivate: true } : {})
    };
    logIn(
      { credentials: { ...formData }, rememberMe },
      {
        onSuccess: (res) => {
          loginSuccess(res);
        },
        onError: (err) => {
          loginFailed(err);
        }
      }
    );
    cleanupUserData();
    setAuthetificationSuccess(false);
    setLoading(true);
    getLoginInvalidError();
    //memberLogin(data, rememberMe).then(loginSuccess).catch(loginFailed);
  };

  const closeModal = (ref) => {
    ref.current.closeModal();
  };

  const openModal = (ref) => {
    ref.current.openModal();
  };

  const renderLoader = () => {
    return (
      <div className="Loading">
        <LoaderComponent />
      </div>
    );
  };

  const getGoogleAuthUrl = () => {
    setLoading(true);
    getGoogleUrl(
      {},
      {
        onSuccess: (url) => {
          navigate(url);
          setLoading(false);
        },
        onError: () => setLoading(false)
      }
    );
  };

  const hasCode = useMemo(() => Boolean(code), [code]);
  const hasToken = Boolean(token);
  const isUserIdLocalStorage = Boolean(userDataLocalStorage?.id);
  useEffect(() => {
    if (hasToken && !isUserIdLocalStorage) {
      localStorage.setItem(TOKEN, token);
    }

    if (isUserIdLocalStorage && hasToken) {
      window.location.replace('/members-area');
    }
  }, [token, hasToken, isUserIdLocalStorage, userDataLocalStorage]);

  return (
    <div className="Login">
      {(loading || hasCode) && renderLoader()}
      <div className="Title">Member’s Only</div>
      <Form className="LoginForm" onSubmit={handleSubmit(onSubmit)}>
        <Input
          register={register}
          type="text"
          name="username"
          placeholder="Username"
          errorMessage={errors?.username?.message}
        />
        <Input
          register={register}
          type="password"
          name="password"
          placeholder="Password"
          errorMessage={errors?.password?.message}
          additionalError={loginInvalidError}
          className="password"
        />
        <div className="Checkbox-Container">
          <div className="Checkbox-Holder">
            <Checkbox onChange={checkboxChange} name="rememberMe" /> <span>Remember me</span>
          </div>
          <div onClick={openModal.bind(null, forgotPassword)} className="ForgotPassword-Container">
            Forgot Password?
          </div>
        </div>
        <input type="submit" ref={submitRef} hidden />
        <Button
          className="SubmitButton"
          type="submit"
          onClick={() => {
            submitRef.current.click();
          }}
          text="Sign in"
        />
      </Form>
      <div className="DividerContainer">
        <div className="Line" />
        <span>OR</span>
        <div className="Line" />
      </div>
      <Button className="SubmitButton Secondary" onClick={getGoogleAuthUrl}>
        Sign in with <GoogleCircleFilled />
      </Button>
      <div className="InfoSafetyInfo">
        <ImageComponent url={SECURELOGIN} /> Your login information is encrypted and stored safely
        with us
      </div>
      <div className="NotYetMemberInfo">
        No access? <span onClick={openModal.bind(null, registerModalRef)}> Join for free</span>
      </div>
      <ModalDialog ref={forgotPassword}>
        <ForgotPassword
          isResetSuccess={setShowSuccesMessage}
          closeModal={closeModal}
          modalRef={forgotPassword}
        />
      </ModalDialog>
      <SuccessMessage
        showMessage={showSuccesMessage}
        setShowMessage={setShowSuccesMessage}
        message="A verification email has been sent to you"
      />
      <SuccessMessage
        showMessage={authetificationSuccess}
        setShowMessage={setAuthetificationSuccess}
        message="Authentication Successful. Loading…"
      />
      <SignUpFlow modalRef={registerModalRef} />
    </div>
  );
};

export default Login;
