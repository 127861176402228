import './style.scss';

import { CommentOutlined } from '@ant-design/icons';
import { useNavigate } from '@reach/router';
import { Card } from 'antd';
import moment from 'moment/moment';
import React, { useEffect, useRef } from 'react';

import { Online } from '../../assets/images';
import Logo from '../../assets/images/MembersAreaNavbar/LogoText.svg';
import { MEMBERS_ROUTES } from '../../pages/MainView/components/MembersAreaMain/members_routes';
import { useContextMenu } from '../../V2/hooks/useContextMenu';
import Button from '../Button/Button';
import NewProfileBadge from '../NewProfileBadge/NewProfileBadge';

const { Meta } = Card;

export const ProfileCard = ({ profile, disableSend }) => {
  const { username, id, birthday, online_status, profile_image, is_new } = profile;
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const cardRef = useRef(null);
  const openNewTab = () => {
    const newTab = window.open('', '_blank');
    if (newTab && id)
      newTab.location.href = `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MEMBERPROFILE}/${id}`;
  };
  const { showMenu, menu } = useContextMenu({
    context: [{ action: () => openNewTab(), text: 'Open in new tab' }],
    elem: cardRef
  });
  const goToProfile = () =>
    navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MEMBERPROFILE}/${id}`);
  useEffect(() => {
    const imageUrl = profile_image; //avatar_image.url;
    const img = new Image();
    const logo = new Image();
    img.src = imageUrl;
    img.onload = img.onerror = null;
    logo.src = Logo;
    logo.onload = logo.onerror = null;

    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (canvas)
      canvas.oncontextmenu = function () {
        return false;
      };
    img.onload = () => {
      if (ctx) {
        canvas.width = cardRef.current?.clientWidth;
        canvas.height = canvasRef.current?.clientHeight;
        const canvasAspectRatio = canvas.width / canvas.height;
        const imgAspectRatio = img.width / img.height;
        let newWidth, newHeight, x, y;
        if (canvasAspectRatio > imgAspectRatio) {
          newWidth = canvas.width;
          newHeight = canvas.width / imgAspectRatio;
          x = 0;
          y = canvas.height - newHeight;
        } else {
          newWidth = canvas.height * imgAspectRatio;
          newHeight = canvas.height;
          x = (canvas.width - newWidth) / 2;
          y = 0;
        }
        ctx.drawImage(img, x, y, newWidth, newHeight);
      }
    };
  }, [profile_image]);

  return (
    <>
      <div className={'profile'}>
        <Card
          ref={cardRef}
          cover={
            <>
              {!!online_status && <OnlineStatus />}
              {!!is_new && <NewProfileBadge />}
              {<canvas ref={canvasRef} data-id={id} style={{ height: '100%', width: '100%' }} />}
            </>
          }
          style={{ aspectRatio: 1.5 / 2 }}
          onClick={() => goToProfile()}
          className={disableSend ? 'hideButton' : 'showButton'}
        >
          <Meta title={`${username}, ${moment().diff(birthday, 'years')}`} />
        </Card>
        {!disableSend && (
          <Button
            size={'default'}
            text="SEND A MESSAGE"
            icon={<CommentOutlined />}
            onClick={() =>
              navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MESSAGESNEW}/${id}`)
            }
            style={{ zIndex: !disableSend ? 9 : 'auto' }}
          />
        )}
      </div>
      {showMenu && menu}
    </>
  );
};

const OnlineStatus = () => (
  <img
    src={Online}
    style={{
      width: 32,
      height: 32,
      position: 'absolute',
      top: 5,
      right: 5,
      zIndex: 5
    }}
    alt="is_online"
  />
);
