import './Password.scss';

import React, { useEffect } from 'react';

import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Password({
  setValue,
  errors,
  clearErrors,
  watch,
  setIsValid,
  landerId,
  isValid,
  handleNextStep,
  currentStep,
  isElementVisible,
  setIsElementVisible
}) {
  const password = watch('password');

  useEffect(() => {
    setIsValid(!!password);
  }, [password, setIsValid]);

  return (
    <>
      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer">
          <Input
            placeholder="Your password..."
            name="password"
            type="password"
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
          <Button
            handleNextStep={() => {
              handleNextStep([currentStep.field]);
              // setIsValid(false);
              // setIsElementVisible(false);
            }}
            text="Submit"
            disabled={!isValid || Object.keys(errors).length > 0}
          />
        </div>
      </div>
    </>
  );
}
