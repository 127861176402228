import './MembersPricing.scss';

import { globalHistory } from '@reach/router';
import { Divider } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PAYMENTMETHODS from '../../../../../../assets/images/Pricing/payment_methods_icons.png';
import PREMIUM1 from '../../../../../../assets/images/Pricing/premium_1.svg';
import PREMIUM2 from '../../../../../../assets/images/Pricing/premium_2.svg';
import PREMIUM3 from '../../../../../../assets/images/Pricing/premium_3.svg';
import ImageComponent from '../../../../../../components/ImageComponent/ImageComponent';
import LoaderComponent from '../../../../../../components/LoaderCompoment/LoaderComponent';
import SubscriptionCard from '../../../../../../components/SubscriptionCard/SubscriptionCard';
import { setPricing } from '../../../../../../redux/actions/pricing';
import { purchaseCredit } from '../../../../../../services/payment-services/payment.service';
import { getBillingBundles } from '../../../../../../services/prcing-service/pricing.service';
import { getToken } from '../../../../../../services/token-service/token.service';

const MembersPricing = () => {
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const token = getToken();
  const subscriptionTriggers = useSelector((state) => state.subscriptionTriggers);
  const [price, setPrice] = useState();

  // console.log('currentPricing', currentPricing);

  const getCurrentPricing = useCallback(async () => {
    try {
      const response = await getBillingBundles();
      dispatch(setPricing(response.data.data[0].billing.bundles));
      setPrice(response.data.data[0].billing.bundles);
      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch]);

  useEffect(() => {
    const unlisten = globalHistory.listen((route) => {
      if (
        !route.location.pathname.includes('favorites') &&
        !route.location.pathname.includes('visitors') &&
        !route.location.pathname.includes('members-pricing')
      ) {
        // dispatch(resetTrigger());
      }
    });

    return () => unlisten();
  }, [dispatch]);

  useEffect(() => {
    if (subscriptionTriggers.subscription_trigger) {
      localStorage.setItem('subscription_trigger', subscriptionTriggers.subscription_trigger);

      if (localStorage.getItem('subscription_trigger') === 'visitors page') {
        const currentUrl = window.location.host;
        const redirectionUrl = '/members-area/visitors';
        const finalRedirectUrl = currentUrl + redirectionUrl;
        localStorage.setItem('redirect_url', finalRedirectUrl);
      }
      if (localStorage.getItem('subscription_trigger') === 'favorites page') {
        const currentUrl = window.location.host;
        const redirectionUrl = '/members-area/my-favorites';
        const finalRedirectUrl = currentUrl + redirectionUrl;
        localStorage.setItem('redirect_url', finalRedirectUrl);
      }

      if (localStorage.getItem('subscription_trigger') === 'profile') {
        const currentUrl = window.location.host;
        const redirectionUrl = '/members-area/profile/';
        const currentUserId = localStorage.getItem('userId');
        const finalRedirectUrl = currentUrl + redirectionUrl + currentUserId;
        localStorage.setItem('redirect_url', finalRedirectUrl);
      }
    }

    getCurrentPricing();
  }, [getCurrentPricing, subscriptionTriggers.subscription_trigger]);
  const handleClick = (title) => {
    const bundle = title.toLowerCase();
    let redirect_url;

    let data = {
      type: 'subscription',
      bundle: bundle,
      redirect_url
    };

    if (subscriptionTriggers.subscription_trigger === 'favorites page') {
      const currentUrl = window.location.host;
      const redirectionUrl = '/members-area/my-favorites';
      const finalRedirectUrl = currentUrl + redirectionUrl;

      data = {
        ...data,
        subscription_trigger: subscriptionTriggers.subscription_trigger,
        redirect_url: finalRedirectUrl
      };
    }

    if (subscriptionTriggers.subscription_trigger === 'visitors page') {
      const currentUrl = window.location.host;
      const redirectionUrl = '/members-area/visitors';
      const finalRedirectUrl = currentUrl + redirectionUrl;

      data = {
        ...data,
        subscription_trigger: subscriptionTriggers.subscription_trigger,
        redirect_url: finalRedirectUrl
      };
    }

    if (subscriptionTriggers.subscription_trigger === 'profile') {
      const currentUrl = window.location.host;
      const redirectionUrl = '/members-area/profile/';
      const currentUserId = localStorage.getItem('userId');
      const finalRedirectUrl = currentUrl + redirectionUrl + currentUserId;

      data = {
        ...data,
        subscription_trigger: subscriptionTriggers.subscription_trigger,
        redirect_url: finalRedirectUrl,
        profile_id: currentUserId
      };
    }

    if (!subscriptionTriggers.subscription_trigger) {
      data = {
        ...data,
        subscription_trigger: subscriptionTriggers.subscription_trigger,
        redirect_url: localStorage.getItem('redirect_url')
      };
    }

    if (
      !subscriptionTriggers.subscription_trigger &&
      localStorage.getItem('subscription_trigger') === 'profile'
    ) {
      data = {
        ...data,
        subscription_trigger: localStorage.getItem('subscription_trigger'),
        redirect_url: localStorage.getItem('redirect_url'),
        profile_id: localStorage.getItem('userId')
      };
    }

    if (subscriptionTriggers.subscription_trigger === 'profile') {
      data = { ...data, ...subscriptionTriggers };
    }
    purchaseCredit(data, token)
      .then((res) => {
        window.open(res.data.redirect_url, '_self');
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div>
      <div className="MembersPricing">
        <h6 className="MembersPricing-Header">
          Become our <span>Premium</span> member and you will get <br /> more benefits!
        </h6>
        {!loader ? (
          <div className="MembersPricing-Wrapper">
            <ul className="PricingCards-List">
              {price.map((card) => {
                return (
                  <SubscriptionCard
                    key={card.title}
                    title={card.title}
                    price={card.price}
                    messages={card.free_messages}
                    billingFrequency={card.billing_frequency}
                    badge={card.title === 'gold' ? 'most_popular' : ''}
                    onClick={() => handleClick(card.title)}
                  />
                );
              })}
            </ul>
          </div>
        ) : (
          <LoaderComponent />
        )}
        <p className="Pricing-Info">
          By becoming a <span>PREMIUM</span> member you will be able to:
        </p>
        <div className="PremiumBenefits">
          <div className="PremiumBenefit">
            <ImageComponent className="BenefitImage" url={PREMIUM1} />
            <p>
              Access ALL photos of every <br /> profile
            </p>
          </div>
          <div className="PremiumBenefit">
            <ImageComponent className="BenefitImage" url={PREMIUM2} />
            <p>
              See who is visiting your <br /> profile
            </p>
          </div>
          <div className="PremiumBenefit">
            <ImageComponent className="BenefitImage" url={PREMIUM3} />
            <p>
              Access to My Favorites page <br /> where you can save all other <br /> profiles you
              like
            </p>
          </div>
        </div>
        <Divider dashed={true} />
        <p className="Pricing-Info">100% secure and discreet payments</p>
        <div className="PaymentMethodsContainer">
          <ImageComponent className="BenefitImage" url={PAYMENTMETHODS} />
        </div>
      </div>
    </div>
  );
};

export default MembersPricing;
