import { useMutation } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';

export const useGetCityByZipCode = () =>
  useMutation(async ({ zip_code }) => {
    const { data } = await axiosInstance.get(`/api/zip-codes`, {
      params: { 'filter[search]': zip_code }
    });
    return data;
  });
