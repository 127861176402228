import './Age.scss';

import { useState } from 'react';

import { InputNumber } from '../../../../../Inputs';
import LanderChat from '../../LanderChat/LanderChat';
import Button from '../../UI/Button/Button';

export default function Age({
  handleNextStep,
  typeOnlyNumbers,
  errors,
  setValue,
  clearErrors,
  landerId,
  watch,
  autogenerate,
  register
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = {
    '13-1': [
      {
        content:
          'Thanks! Please tell me your Age. Our members are only interested in 25+ males who can give them a good time:'
      }
    ],
    '13-2': [
      {
        content:
          'Thanks! Please tell me your Age. Our members are only interested in 25+ males who can give them a good time:'
      }
    ],
    '13-3': [
      {
        content: 'Nice! Keep forward! \n Please tell me your Age:'
      }
    ],
    '13-4': [
      {
        content: 'Nicely done! \n Please tell me how old are you, sweetie?'
      }
    ]
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <div className="AgeContainer">
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer">
          <div className="InputContainer">
            <InputNumber
              name="age"
              {...register('age')}
              placeholder="Age"
              errorMessage={errors?.age?.message}
            />
          </div>
        </div>
        <div className="ButtonsContianer">
          <Button
            className={`ButtonDark`}
            text="NEXT"
            handleNextStep={() => handleNextStep(['age'])}
            disabled={!watch('age')}
          />
        </div>
      </div>
    </div>
  );
}
