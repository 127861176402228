import axios, { AxiosError, AxiosResponse } from 'axios';

import { showRootPage } from '../services/navigation-service/navigation.service';
import { cleanupToken, getToken } from '../services/token-service/token.service';
import { cleanupUserData } from '../services/user-service/user.service';

const BASE_URL = `${process.env.REACT_APP_API_URL}/`;
export const axiosInstance = axios.create({ baseURL: BASE_URL });

const cleanup = () => {
  cleanupToken();
  cleanupUserData();
};

axiosInstance.interceptors.request.use(async (config) => {
  if (!config.headers.doNotAddUserTokenAuthorization) {
    const token = getToken();
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  delete config.headers.doNotAddUserTokenAuthorization;
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401 && error.response?.config.url !== '/auth') {
      cleanup();
      showRootPage();
    }
    return Promise.reject(error.response as AxiosResponse<AxiosError>);
  }
);
