import './VerticalOption.scss';

import React from 'react';

export default function VerticalOption({ title, backgroundImage, onClick }) {
  return (
    <div
      className="VerticalOption"
      onClick={onClick}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="BackgroundOverlay"></div>
      <span>{title}</span>
    </div>
  );
}
