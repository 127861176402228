import './GenderOfInterest.scss';

import React, { useState } from 'react';

import manBgImage from '../../../../../../../../assets/images/Lander10/interested-in-man.png';
import womanBgImage from '../../../../../../../../assets/images/Lander10/interested-in-woman.png';
import { useGetEnumPublic } from '../../../../../../../../core/APIHooks/useGetEnumPublic';
import LanderChat from '../../LanderChat/LanderChat';
import VerticalOption from '../../VerticalOption/VerticalOption';

export default function GenderOfInterest({ onNextStep, setValue }) {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const { data: enumPublic } = useGetEnumPublic();

  const messages = [{ content: 'Who are you looking for?' }];

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  const handleSeekingSelect = (gender) => {
    setValue('seeking', gender);
    onNextStep();
  };

  return (
    <>
      <LanderChat messages={messages} endChatAction={toggleElementVisibility} />
      <div className={isElementVisible ? 'Options ToggleElement' : 'Options Hidden'}>
        {enumPublic?.seeking.map((value) => {
          return (
            <VerticalOption
              key={value}
              title={value}
              backgroundImage={value === 'men' ? manBgImage : womanBgImage}
              onClick={() => handleSeekingSelect(value)}
            />
          );
        })}
      </div>
    </>
  );
}
