import './ValidateEmailTypo.scss';

import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

const ValidateEmailTypo = ({ email, setValue, color }) => {
  const emailProviders = ['gmail.com', 'hotmail.com', 'outlook.com', 'yahoo.com', 'aol.com'];
  const [emailDebounce] = useDebounce(email, 500);
  const [renderMessage, setRenderMessage] = useState();

  let correctedEmail = email && email?.toLowerCase();

  const selectCorrectEmail = (email) => {
    setValue('email', email);
    setRenderMessage(null);
  };

  useEffect(() => {
    correctedEmail?.includes('@') && emailTypoValidate();
  }, [emailDebounce]);

  const findBestMatch = (word, words) => {
    let bestMatch = '';
    let bestScore = 0;
    for (let i = 0; i < words.length; i++) {
      const currentWord = words[i];
      let score = 0;
      for (let j = 0; j < word.length; j++) {
        if (currentWord.indexOf(word[j]) === j) {
          score++;
        }
      }
      if (score > bestScore) {
        bestMatch = currentWord;
        bestScore = score;
      }
      if (word === bestMatch) {
        bestScore = 100;
      }
      if (
        word === 'com' ||
        word === '.com' ||
        word === '.co' ||
        word === '.c' ||
        word === '.cm' ||
        word === '.om' ||
        word === '.'
      ) {
        bestMatch = 'gmail.com';
      }
    }
    return { bestScore, bestMatch };
  };

  const emailTypoValidate = () => {
    if (correctedEmail) {
      for (let i = 0; i < emailProviders.length; i++) {
        const word = emailProviders[i];
        const emailProvTypo = email.slice(email.indexOf('@') + 1);
        const emailLocalPart = correctedEmail.split('@')[0] + '@';
        const match = findBestMatch(emailProvTypo, emailProviders);
        if (match.bestScore < 100) {
          setRenderMessage(
            <div className="ValidateEmailTypo" style={{ color: color }}>
              Did you mean{' '}
              <span
                onClick={() => selectCorrectEmail(emailLocalPart.concat(match.bestMatch))}
                style={{ color: color }}
              >
                {emailLocalPart.concat(match.bestMatch)}
              </span>
            </div>
          );
        } else {
          setRenderMessage(null);
        }
      }
    }
  };

  if (!correctedEmail?.includes('@')) {
    return null;
  }

  if (!renderMessage) {
    return null;
  }
  return renderMessage ? renderMessage : null;
};

export default ValidateEmailTypo;
