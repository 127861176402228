import React from 'react';

const Dropdown = ({ options, name, valueName, onChange, ...rest }) => {
  return (
    <>
      <select
        className="Dropdown-Select"
        defaultValue={rest.defaultselected?.[valueName] || ''}
        {...rest}
        {...rest.register}
        onChange={onChange}
      >
        {options
          ? options.map((o, index) => {
              return (
                <option key={o.id || index} value={o[valueName]}>
                  {o[name]}
                </option>
              );
            })
          : null}
        {(rest.placeholder && options.length === 0) ||
        (!rest.defaultValue && !rest.defaultselected) ? (
          <option value="">{rest.placeholder}</option>
        ) : null}
      </select>
      {rest.error ? <div style={{ color: 'red', fontSize: '12px' }}>{rest.error}</div> : null}
    </>
  );
};

export default Dropdown;
