import './ImageNPreview.scss';

import { Image } from 'antd';
import React from 'react';

const ImageNPreview = (props) => {
  const { onClick, name, nameFullSize, className, classNameImg } = props;
  const classes = ['ImageComponent', className];

  return (
    <div className={classes.join(' ')}>
      <Image
        className={classNameImg}
        onClick={onClick}
        src={name}
        preview={{
          src: nameFullSize
        }}
        alt={name}
        onContextMenu={(e) => e.preventDefault()}
      />
    </div>
  );
};

export default ImageNPreview;
