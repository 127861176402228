import './ZipCode.scss';

import { useState } from 'react';

import LanderChat from '../../LanderChat/LanderChat';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function ZipCode({
  handleNextStep,
  typeOnlyNumbers,
  errors,
  setValue,
  clearErrors,
  cityLoading,
  landerId,
  watch
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = {
    '13-1': [
      {
        content: 'Thanks! \n Please tell me your location:'
      }
    ],
    '13-2': [
      {
        content: 'Awesome, you’re almost done! \n Tell me your location:'
      }
    ],
    '13-3': [
      {
        content: 'Thank you! \n Where are you located?'
      }
    ],
    '13-4': [
      {
        content: 'Thanks! We are almost done and you can start chatting. Where are you living?'
      }
    ]
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <div className="ZipCodeContainer">
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer12">
          <Input
            placeholder="Enter Zip Code"
            name="zip_code"
            maxLength={5}
            onKeyDown={typeOnlyNumbers}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
        <div className="ButtonsContianer">
          <Button
            className={`ButtonDark`}
            text="NEXT"
            handleNextStep={() => handleNextStep(['zip_code'])}
            loading={cityLoading}
            disabled={!watch('zip_code')}
          />
        </div>
      </div>
    </div>
  );
}
