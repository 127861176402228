import { useState } from 'react';

import manBgImageBlue from '../../../../../../../../assets/images/Lander14/blue/interested/interested-in-man.png';
import womanBgImageBlue from '../../../../../../../../assets/images/Lander14/blue/interested/interested-in-woman.png';
import manBgImageLight from '../../../../../../../../assets/images/Lander14/light/interested/interested-in-man.png';
import womanBgImageLight from '../../../../../../../../assets/images/Lander14/light/interested/interested-in-woman.png';
import manBgImagePink from '../../../../../../../../assets/images/Lander14/pink/interested/interested-in-man.png';
import womanBgImagePink from '../../../../../../../../assets/images/Lander14/pink/interested/interested-in-woman.png';
import manBgImageRed from '../../../../../../../../assets/images/Lander14/red/interested/interested-in-man.png';
import womanBgImageRed from '../../../../../../../../assets/images/Lander14/red/interested/interested-in-woman.png';
import { useGetEnumPublic } from '../../../../../../../../core/APIHooks/useGetEnumPublic';
import LanderChat from '../../LanderChat/LanderChat';
import VerticalOption from '../../VerticalOption/VerticalOption';

export default function GenderOfInterest({ watch, setValue, setIsValid, landerId }) {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const { data: enumPublic } = useGetEnumPublic();

  const gender = watch('seeking');

  const messages = {
    '14-1': [{ content: 'Great! What are you looking for:' }],
    '14-2': [{ content: 'Perfect! And you are interested in:' }],
    '14-3': [{ content: 'Perfect! And you are interested in:' }],
    '14-4': [{ content: 'Perfect! And you are interested in:' }]
  };

  const avatars = {
    '14-1': {
      male: manBgImageRed,
      female: womanBgImageRed
    },
    '14-2': {
      male: manBgImageBlue,
      female: womanBgImageBlue
    },
    '14-3': {
      male: manBgImagePink,
      female: womanBgImagePink
    },
    '14-4': {
      male: manBgImageLight,
      female: womanBgImageLight
    }
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  const handleSeekingSelect = (gender) => {
    setValue('seeking', gender);
    setIsValid(true);
  };

  return (
    <>
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />
      <div
        className={
          isElementVisible
            ? 'Options SelectOptions Interests ToggleElement'
            : 'Hidden Options SelectOptions Interests'
        }
      >
        {enumPublic?.seeking.map((value) => {
          return (
            <VerticalOption
              key={value}
              title={value}
              backgroundImage={
                value === 'men' ? avatars[`${landerId}`].male : avatars[`${landerId}`].female
              }
              onClick={() => handleSeekingSelect(value)}
              isActive={gender === value}
            />
          );
        })}
      </div>
    </>
  );
}
