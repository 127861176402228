import './Age.scss';

import { useEffect } from 'react';

import arrowLeftIcon from '../../../../../../../../assets/images/Lander11/arrow-left.svg';
import Button from '../../UI/Button/Button';
import { CountdownSelect as Select } from '../../UI/Select/Select';

export default function Age({ handleNextStep, control, prevStep, setValue, getValues }) {
  const ageOptions = Array.from({ length: 82 }, (_, index) => index + 18).map((o) => ({
    value: o,
    label: `${o} years`
  }));

  useEffect(() => {
    if (!getValues('age')) {
      setValue('age', ageOptions[0].value);
    }
  }, []);

  return (
    <>
      <div className="CountdownLabelWithBack">
        <span className="CountdownLabel">Age</span>
        <span className="CountdownBack" onClick={prevStep}>
          <img src={arrowLeftIcon} />
          Back
        </span>
      </div>

      <Select name="age" control={control} options={ageOptions} />

      <Button text="NEXT" onClick={() => handleNextStep(['age'])} />
    </>
  );
}
