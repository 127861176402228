import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';

import { queryClient } from '../../../App';
import { axiosInstance } from '../../../axios/axiosInstance';
import { key as UserProfileKey } from '../../../core/APIHooks/userData/useGetUserData';
import { key as UserDetails } from './useGetUserDetails';
import { key as UserGallery } from './useGetUserGallery';

interface PhotosData {
  userId: number;
  file: File;
  type: string;
}

interface ErrorResponse {
  message: string;
  errors: string[];
}

function transformErrorData(data: any): ErrorResponse {
  const result: ErrorResponse = {
    message: data.message,
    errors: []
  };

  if (data.errors)
    for (const [, value] of Object.entries(data.errors)) {
      // @ts-ignore
      result.errors.push(value[0]);
    }

  return result;
}
export const useUpdateUserPhotos = () =>
  useMutation(
    async ({ userId, file, type }: PhotosData) => {
      const formData = new FormData();
      formData.append('_method', 'PATCH');
      formData.append('version', '1');
      if (type === 'gallery_images') {
        formData.append(`${type}[]`, file);
      } else {
        formData.append(type, file);
      }

      const { data } = await axiosInstance.post(`api/profiles/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          _method: 'PATCH'
        }
      });
      return data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(UserDetails);
        queryClient.invalidateQueries(UserProfileKey);
        queryClient.invalidateQueries([...UserGallery, variables.userId]);
        notification.success({ message: 'Update success' });
      },
      onError: (err: unknown) => {
        const error = err as AxiosError;
        if (error.response) {
          const data = transformErrorData(error.response.data);
          if (error.response?.status === 413) {
            notification.error({
              message: 'You can upload files up to a maximum size of 18MB at once'
            });
          } else {
            notification.error({
              message: 'Update error ' + error.response?.status,
              description: data.errors.map((error: string) => {
                return (
                  <p key={error} style={{ marginBottom: '5px' }}>
                    {error}
                  </p>
                );
              })
            });
          }
        }
      }
    }
  );
