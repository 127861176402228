import './Age.scss';

import React, { useState } from 'react';

import { InputNumber } from '../../../../../Inputs';
import LanderChat from '../../LanderChat/LanderChat';
import Button from '../../UI/Button/Button';

export default function Age({
  handleNextStep,
  typeOnlyNumbers,
  errors,
  setValue,
  clearErrors,
  landerId,
  autogenerate,
  register
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const landerButtonClassName = `version${landerId}`;

  const messages = {
    '12-1': [
      {
        content: 'That’s kinda hot. How old are you sweetheart?'
      }
    ],
    '12-2': [
      {
        content: 'That’s sexy. Tell me, how old are you? Please type your age:'
      }
    ],
    '12-3': [
      {
        content: autogenerate
          ? 'Thanks gorgeous. Let me know how old you are. Please type your age:'
          : 'We have lots of them here! Just let me know how old you are? Please type your age:'
      }
    ],
    '12-4': [
      {
        content: 'That’s really hot. How old are you? Please type your age:'
      }
    ]
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer12">
          <div className="InputContainer">
            <InputNumber
              name="age"
              {...register('age')}
              placeholder="Age"
              errorMessage={errors?.age?.message}
            />
          </div>
        </div>
        <div className="ButtonsContianer">
          <Button
            className={landerButtonClassName}
            text="NEXT"
            handleNextStep={() => handleNextStep(['age'])}
          />
        </div>
      </div>
    </>
  );
}
