import './style.scss';

import { useNavigate } from '@reach/router';
import { Modal, ModalProps, notification, Spin } from 'antd';
import React, { FC } from 'react';

import { queryClient } from '../../../../App';
import { PaymentFooter } from '../../../../assets/images';
import LoaderPulse from '../../../../components/LoaderPulse/LoaderPulse';
import { useGetGiftPackages } from '../../../../core/gifting/useGetGiftPackages';
import { key as ConvKey } from '../../../../core/messages/useGetConversationById';
import { GiftCard } from '../../../components/shared/gifting-card/GiftCard';
import { PurchaseType } from '../../../model/enum/PurchaseType';
import { useCheckOutPayment } from '../../../services/payment/useCheckOutPayment';

interface GiftModalProps extends ModalProps {
  profileId: number;
  profileUsername: string;
  redirect: string;
}
export const GiftModal: FC<GiftModalProps> = ({
  profileId,
  profileUsername,
  redirect,
  ...props
}) => {
  const navigate = useNavigate();
  const { data } = useGetGiftPackages();
  const { mutate: buy, isLoading } = useCheckOutPayment();
  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const buyCredits = (id: number, credits: number) => {
    const url = `${redirect}${profileId}`;
    id &&
      profileId &&
      buy(
        {
          type: PurchaseType.GIFTING,
          package_id: id,
          profile_id: profileId,
          redirect_url: `${window.location.origin}/members-area/giftProfile/${profileId}`
        },
        {
          onSuccess: (data, payload) => {
            if (data.redirect_url === payload.redirect_url) {
              navigate(url)
                .then(() =>
                  sleep(5000).then(() => {
                    queryClient.invalidateQueries([...ConvKey, profileId?.toString()]);
                  })
                )
                .then(() =>
                  notification.success({
                    message: `You gifted ${profileUsername} ${credits} credits`
                  })
                );
            } else window.open(data.redirect_url, '_self');
          }
        }
      );
  };
  const packages = data?.map((pkg) => <GiftCard {...pkg} key={pkg.id} onClick={buyCredits} />);
  const style = {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
    gap: 20,
    'flex-wrap': 'wrap'
  };
  return (
    <Modal
      width={'80%'}
      footer={
        <div className={'modal-footer'}>
          <img src={PaymentFooter} alt={'footer'} />
        </div>
      }
      className={'gift-modal'}
      bodyStyle={style}
      maskClosable
      mask
      {...props}
    >
      <Spin
        spinning={isLoading}
        indicator={
          <div className={'modal-loader'}>
            <LoaderPulse />
          </div>
        }
      >
        <div style={style}>{packages}</div>
      </Spin>
    </Modal>
  );
};
