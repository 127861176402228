import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';

import { queryClient } from '../../../App';
import { axiosInstance } from '../../../axios/axiosInstance';
import { key as Me } from '../../../core/APIHooks/userData/useGetUserData';

export const useRemoveCreditCard = () =>
  useMutation(
    async () => {
      return await axiosInstance.post('/api/users/remove-card');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...Me]);
        notification.success({ message: 'Card removed!' });
      }
    }
  );
