import Lander9 from '../Landers/Lander9/Lander9';
import Lander9EmailValid from '../Landers/Lander9EmailValid/Lander9EmailValid';
import Lander9NoPswd from '../Landers/Lander9NoPswd/Lander9NoPswd';
import Lander9NoUsr from '../Landers/Lander9NoUsr/Lander9NoUsr';
import Lander9sso from '../Landers/Lander9sso/Lander9sso';
import Lander10 from '../Landers/Lander10/Lander10';
import Lander11 from '../Landers/Lander11/Lander11';
import Lander12 from '../Landers/Lander12/Lander12';
import Lander13 from '../Landers/Lander13/Lander13';
import Lander14 from '../Landers/Lander14/Lander14';
import Lander15 from '../Landers/Lander15/Lander15';
import Lander17 from '../Landers/Lander17/Lander17';
import Lander18 from '../Landers/Lander18/Lander18';

export default {
  9: { component: Lander9, props: {} },
  '9sso': { component: Lander9sso, props: {} },
  '9nousr': { component: Lander9NoUsr, props: {} },
  '9nousrsso': { component: Lander9NoUsr, props: {} },
  '9nopswd': { component: Lander9NoPswd, props: {} },
  '9nopswdsso': { component: Lander9NoPswd, props: {} },
  '9nopswdssorec': { component: Lander9NoPswd, props: { recaptcha: true } },
  10: { component: Lander10, props: {} },
  '10sso': { component: Lander10, props: {} },
  '9eval': { component: Lander9EmailValid, props: {} },
  11: { component: Lander11, props: {} },
  '11sso': { component: Lander11, props: {} },

  '12-1': { component: Lander12, props: {} },
  '12-2': { component: Lander12, props: {} },
  '12-3': { component: Lander12, props: {} },
  '12-4': { component: Lander12, props: {} },

  '13-1': { component: Lander13, props: {} },
  '13-1/nousr': { component: Lander13, props: {} },
  '13-1/nopswd': { component: Lander13, props: {} },
  '13-1/nousrnopswd': { component: Lander13, props: {} },
  '13-1/noloc': { component: Lander13, props: {} },
  '13-1/nolocnopswd': { component: Lander13, props: {} },
  '13-2': { component: Lander13, props: {} },
  '13-2/nousr': { component: Lander13, props: {} },
  '13-2/nopswd': { component: Lander13, props: {} },
  '13-2/nousrnopswd': { component: Lander13, props: {} },
  '13-2/noloc': { component: Lander13, props: {} },
  '13-2/nolocnopswd': { component: Lander13, props: {} },
  '13-3': { component: Lander13, props: {} },
  '13-3/nousr': { component: Lander13, props: {} },
  '13-3/nopswd': { component: Lander13, props: {} },
  '13-3/nousrnopswd': { component: Lander13, props: {} },
  '13-3/noloc': { component: Lander13, props: {} },
  '13-3/nolocnopswd': { component: Lander13, props: {} },
  '13-4': { component: Lander13, props: {} },
  '13-4/nousr': { component: Lander13, props: {} },
  '13-4/nopswd': { component: Lander13, props: {} },
  '13-4/nousrnopswd': { component: Lander13, props: {} },
  '13-4/noloc': { component: Lander13, props: {} },
  '13-4/nolocnopswd': { component: Lander13, props: {} },
  '13-1/sso': { component: Lander13, props: {} },
  '13-1/nousrsso': { component: Lander13, props: {} },
  '13-1/nopswdsso': { component: Lander13, props: {} },
  '13-1/nousrnopswdsso': { component: Lander13, props: {} },
  '13-1/nolocsso': { component: Lander13, props: {} },
  '13-1/nolocnopswdsso': { component: Lander13, props: {} },
  '13-2/sso': { component: Lander13, props: {} },
  '13-2/nousrsso': { component: Lander13, props: {} },
  '13-2/nopswdsso': { component: Lander13, props: {} },
  '13-2/nousrnopswdsso': { component: Lander13, props: {} },
  '13-2/nolocsso': { component: Lander13, props: {} },
  '13-2/nolocnopswdsso': { component: Lander13, props: {} },
  '13-3/sso': { component: Lander13, props: {} },
  '13-3/nousrsso': { component: Lander13, props: {} },
  '13-3/nopswdsso': { component: Lander13, props: {} },
  '13-3/nousrnopswdsso': { component: Lander13, props: {} },
  '13-3/nolocsso': { component: Lander13, props: {} },
  '13-3/nolocnopswdsso': { component: Lander13, props: {} },
  '13-4/sso': { component: Lander13, props: {} },
  '13-4/nousrsso': { component: Lander13, props: {} },
  '13-4/nopswdsso': { component: Lander13, props: {} },
  '13-4/nousrnopswdsso': { component: Lander13, props: {} },
  '13-4/nolocsso': { component: Lander13, props: {} },
  '13-4/nolocnopswdsso': { component: Lander13, props: {} },

  '14-1': { component: Lander14, props: {} },
  '14-1/sso': { component: Lander14, props: {} },
  '14-2': { component: Lander14, props: {} },
  '14-2/sso': { component: Lander14, props: {} },
  '14-3': { component: Lander14, props: {} },
  '14-3/sso': { component: Lander14, props: {} },
  '14-4': { component: Lander14, props: {} },
  '14-4/sso': { component: Lander14, props: {} },

  '15-1': { component: Lander15, props: {} },
  '15-1/sso': { component: Lander15, props: {} },
  '15-2': { component: Lander15, props: {} },
  '15-2/sso': { component: Lander15, props: {} },
  '15-3': { component: Lander15, props: {} },
  '15-3/sso': { component: Lander15, props: {} },
  '15-4': { component: Lander15, props: {} },
  '15-4/sso': { component: Lander15, props: {} },

  '17-1': { component: Lander17, props: {} },
  '17-1/sso': { component: Lander17, props: {} },

  '17-2': { component: Lander17, props: {} },
  '17-2/sso': { component: Lander17, props: {} },

  '17-3': { component: Lander17, props: {} },
  '17-3/sso': { component: Lander17, props: {} },

  '17-4': { component: Lander17, props: {} },
  '17-4/sso': { component: Lander17, props: {} },

  '18-1': { component: Lander18, props: {} },
  '18-2': { component: Lander18, props: {} }
};
