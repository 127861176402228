import React, { useState } from 'react';

import {
  curvyBgV1,
  curvyBgV1Mobile,
  largeBgV1,
  largeBgV1Mobile,
  normalBgV1,
  normalBgV1Mobile
} from '../../../../../../../../assets/images/Lander15/version1';
import {
  curvyBgV2,
  curvyBgV2Mobile,
  largeBgV2,
  largeBgV2Mobile,
  normalBgV2,
  normalBgV2Mobile
} from '../../../../../../../../assets/images/Lander15/version2';
import {
  curvyBgV3,
  curvyBgV3Mobile,
  largeBgV3,
  largeBgV3Mobile,
  normalBgV3,
  normalBgV3Mobile
} from '../../../../../../../../assets/images/Lander15/version3';
import {
  curvyBgV4,
  curvyBgV4Mobile,
  largeBgV4,
  largeBgV4Mobile,
  normalBgV4,
  normalBgV4Mobile
} from '../../../../../../../../assets/images/Lander15/version4';
import VerticalOption from '../../VerticalOption/VerticalOption';

export default function BodyType({
  setIsValid,
  landerId,
  handleNextStep,
  currentStep,
  isElementVisible,
  setIsElementVisible,
  getResponsiveImage
}) {
  const [activeOption, setActiveOption] = useState(null);
  const avatars = {
    '15-1': {
      normal: getResponsiveImage(normalBgV1Mobile, normalBgV1),
      curvy: getResponsiveImage(curvyBgV1Mobile, curvyBgV1),
      large: getResponsiveImage(largeBgV1Mobile, largeBgV1)
    },
    '15-2': {
      normal: getResponsiveImage(normalBgV2Mobile, normalBgV2),
      curvy: getResponsiveImage(curvyBgV2Mobile, curvyBgV2),
      large: getResponsiveImage(largeBgV2Mobile, largeBgV2)
    },
    '15-3': {
      normal: getResponsiveImage(normalBgV3Mobile, normalBgV3),
      curvy: getResponsiveImage(curvyBgV3Mobile, curvyBgV3),
      large: getResponsiveImage(largeBgV3Mobile, largeBgV3)
    },
    '15-4': {
      normal: getResponsiveImage(normalBgV4Mobile, normalBgV4),
      curvy: getResponsiveImage(curvyBgV4Mobile, curvyBgV4),
      large: getResponsiveImage(largeBgV4Mobile, largeBgV4)
    }
  };
  const handleOptionClick = (option) => {
    setActiveOption(option);
    handleNextStep([currentStep.field]);
    setIsValid(false);
    setIsElementVisible(false);
  };

  return (
    <>
      <div
        className={
          isElementVisible ? 'ToggleElement SelectOptions Body' : 'Hidden SelectOptions Body'
        }
      >
        <VerticalOption
          title="Normal"
          backgroundImage={avatars[`${landerId}`].normal}
          onClick={() => handleOptionClick('Normal')}
          isActive={activeOption === 'Normal'}
        />
        <VerticalOption
          title="Curvy"
          backgroundImage={avatars[`${landerId}`].curvy}
          onClick={() => handleOptionClick('Curvy')}
          isActive={activeOption === 'Curvy'}
        />
        <VerticalOption
          title="Large"
          backgroundImage={avatars[`${landerId}`].large}
          onClick={() => handleOptionClick('Large')}
          isActive={activeOption === 'Large'}
        />
      </div>
    </>
  );
}
