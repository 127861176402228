import './Protection.scss';

import Button from '../../UI/Button/Button';

export default function Protection({ onNextStep }) {
  return (
    <div className="ProtectionContainer">
      <p className="ProtectionText">Do you agree to use a condom when having sex?</p>
      <Button handleNextStep={() => onNextStep()} text="NO"></Button>
      <Button handleNextStep={() => onNextStep()} text="YES"></Button>
    </div>
  );
}
