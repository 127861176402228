import './HomePage.scss';

import React, { useEffect, useRef, useState } from 'react';

import ModalDialog from '../../../../../components/modals/ModalDialog/ModalDialog';
import PasswordReset from '../../../../../components/PasswordReset/PasswordReset';
import PasswordResetConfirmationMessage from '../../../../../components/PasswordResetConfirmationMessage/PasswordResetConfirmationMessage';
import SuccessMessage from '../../../../../components/SuccessMessage/SuccessMessage';
import Unsubscribe from '../../../../../components/Unsubscribe/Unsubscribe';
import HomePageDescription from '../components/HomePageDescription/HomePageDescription';
import HomePageProfiles from '../components/HomePageProfiles/HomePageProfiles';
import WelcomeSection from '../components/WelcomeSection/WelcomeSection';
import WhyChooseFlirt from '../components/WhyChooseFlirt/WhyChooseFlirt';

const HomePage = () => {
  const resetPassword = useRef(null);
  const unsubscribeRef = useRef(null);

  const resetPasswordConfirmMessage = useRef(null);
  const resetPasswordFailedMessage = useRef(null);
  const [showSuccesMessage, setShowSuccesMessage] = useState(false);
  const [username, getUsername] = useState('');
  const [email, getEmail] = useState('');
  const [token, getToken] = useState('');

  const [unsubscribeUrl, getUnsubscribeUrl] = useState('');

  const openModal = (ref) => {
    ref.current.openModal();
  };

  const closeModal = (ref) => {
    ref.current.closeModal();
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const passwordReset = urlParams.get('password_reset');
    const token = urlParams.get('token');
    const email = urlParams.get('email');
    const username = urlParams.get('username');

    getUsername(username);
    getEmail(email);
    getToken(token);

    const unsubscribe = urlParams.get('unsubscribe');
    const unsubscribe_url = urlParams.get('unsubscribe_url');

    getUnsubscribeUrl(unsubscribe_url);

    if (passwordReset) {
      openModal(resetPassword);
    }
    if (unsubscribe) {
      openModal(unsubscribeRef);
    }
  }, []);

  const passwordResetModal = () => {
    return (
      <ModalDialog ref={resetPassword}>
        <PasswordReset
          closeModal={closeModal}
          token={token}
          modalRef={resetPassword}
          username={username}
          email={email}
          openModal={openModal}
          successMessageRef={resetPasswordConfirmMessage}
          failureMessageRef={resetPasswordFailedMessage}
        />
      </ModalDialog>
    );
  };

  const passwordResetMessage = () => {
    return (
      <ModalDialog ref={resetPasswordConfirmMessage} hideLogo={true}>
        <PasswordResetConfirmationMessage
          closeModal={closeModal}
          modalRef={resetPasswordConfirmMessage}
          failureMessageRef={resetPasswordFailedMessage}
        />
      </ModalDialog>
    );
  };

  const unsubscribeModal = () => {
    return (
      <ModalDialog ref={unsubscribeRef}>
        <Unsubscribe
          closeModal={closeModal}
          unsubscribeUrl={unsubscribeUrl}
          isUnsubscribeSuccess={setShowSuccesMessage}
          modalRef={unsubscribeRef}
          openModal={openModal}
        />
      </ModalDialog>
    );
  };

  return (
    <div className="HomePage">
      <WelcomeSection />
      <HomePageDescription />
      <HomePageProfiles />
      <WhyChooseFlirt />
      {passwordResetModal()}
      {passwordResetMessage()}
      {unsubscribeModal()}
      <SuccessMessage
        showMessage={showSuccesMessage}
        setShowMessage={setShowSuccesMessage}
        message="Unsubscribe successful"
      />
    </div>
  );
};

export default HomePage;
