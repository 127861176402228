import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { userData } from '../../recoil/atoms/userDataAtom';
import { getToken } from '../../services/token-service/token.service';
import { createSocketConnection } from './createSocketConnection';

export const wsEvents = {
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  MESSAGE_RECEIVED: 'MESSAGE_RECEIVED'
};
function listenChannel(callBack, channel, event) {
  window.Echo.private(channel).listen(event, (payload) => {
    callBack(payload);
  });

  return function cleanUp() {
    window.Echo.leaveChannel(`private-${channel}`);
  };
}
const token = getToken();
export const useSocket = ({ type, callBack }) => {
  const [user] = useRecoilState(userData);
  useEffect(() => {
    createSocketConnection(token);
    switch (type) {
      case wsEvents.PAYMENT_SUCCESS: {
        return listenChannel(callBack, `users.${user?.data?.id}`, '.successful.payment');
      }
      case wsEvents.MESSAGE_RECEIVED: {
        return listenChannel(callBack, `users.${user?.data?.id}`, '.message.received');
      }
    }
    return false;
  });
};
