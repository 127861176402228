import './ConversationUserListAll.scss';

import { FileImageOutlined } from '@ant-design/icons';
import parse from 'html-react-parser';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';

import PROFILE from '../../../../../../../../assets/images/Temp/ProfileImageSmall.jpg';
import ImageComponent from '../../../../../../../../components/ImageComponent/ImageComponent';
import ImageN from '../../../../../../../../components/ImageN/ImageN';
import { setNewConversationId } from '../../../../../../../../redux/actions/messageConversationId';
import { setNewConversationStatus } from '../../../../../../../../redux/actions/messageConversationStatus';
import { setUnreadNotificationNumber } from '../../../../../../../../redux/actions/unreadNotificationNumber';
import {
  DISMISS_NOTIFICATION,
  SET_NOTIFICATIONS
} from '../../../../../../../../redux/types/notifications';
import {
  clearFailedMessages,
  getNewConversations,
  getNewConversationsInbox
} from '../../../../../../../../services/message-services/message.services';
import { markNotificationAsRead } from '../../../../../../../../services/notification-service/notification-service';
import { getToken } from '../../../../../../../../services/token-service/token.service';
import { getUserData } from '../../../../../../../../services/user-service/user.service';
import { showMessagesPage } from '../../../../navigation.service';

let conversationCall = {
  inbox: getNewConversationsInbox,
  conversation: getNewConversations
};

const ConversationUserListAll = ({ name, setMobileComponentActive }) => {
  const userData = getUserData();
  const allRef = useRef();
  const singleConvRef = useRef();
  const singleConvRefActive = useRef();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [inboxList, setInboxList] = useState([]);
  const getUrl = window.location.pathname;
  const sendMessageProfileId = getUrl.split('/').pop();

  const scrollToTopRef = useRef();

  const dispatch = useDispatch();
  const { newConversationStatus } = useSelector((state) => state);

  const lastMessageHandler = useCallback(
    (data) => {
      setInboxList(data.data);
      dispatch(setNewConversationStatus(false));

      const activeConv = data?.data?.filter((conv) =>
        inboxList.some((value) => conv.id === value.id)
      );
      Number(sendMessageProfileId) === activeConv[0]?.profile_id &&
        (activeConv[0].unread_messages = 0);
      page !== data.current_page && setPage(1);
      activeConv[0] &&
        Number(sendMessageProfileId) === activeConv[0].profile_id &&
        (activeConv[0].unread_messages = 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, page, Number(sendMessageProfileId)]
  );

  const itemListHandler = useCallback(
    (data) => {
      setInboxList((prev) => {
        return [
          ...new Set([
            ...prev,
            ...data.data?.filter((value) => !prev.some((prevValue) => value.id === prevValue.id))
          ])
        ];
      });
      setHasMore(page < data.last_page ? true : false);
    },
    [page]
  );

  useEffect(() => {
    const newConv = inboxList?.filter(
      (conv) => Number(conv?.profile_id) === Number(sendMessageProfileId)
    );
    newConv.length > 0
      ? dispatch(setNewConversationId(newConv[0]?.id))
      : dispatch(setNewConversationId(''));
    // console.log("CLICK", singleConvRefActive?.current);
    singleConvRefActive?.current?.click();
  }, [sendMessageProfileId, inboxList]);

  useEffect(() => {
    getToken() &&
      conversationCall[name](getToken(), newConversationStatus.status ? 1 : page)
        .then((res) =>
          newConversationStatus.status
            ? lastMessageHandler(res?.data.conversations)
            : itemListHandler(res?.data.conversations)
        )
        .catch((err) => console.log(err));
  }, [itemListHandler, lastMessageHandler, name, newConversationStatus.status, page, getToken()]);

  useEffect(() => {
    if (Number(sendMessageProfileId) && inboxList) {
      let convId = inboxList
        ?.filter((item) => item.profile_id === Number(sendMessageProfileId))
        .map((item) => item.id)[0];
      convId && dispatch(setNewConversationId(convId));
      let notNumber = inboxList
        ?.filter((item) => item.profile_id === Number(sendMessageProfileId))
        .map((item) => item.unread_messages)[0];
      notNumber && dispatch(setUnreadNotificationNumber(notNumber));
    }
  }, [dispatch, inboxList, sendMessageProfileId]);

  const renderOnlineStatus = () => {
    return (
      <div className="OnlineStatusContainer">
        <span className="OnlineStatus"></span>
      </div>
    );
  };

  const onScroll = () => {
    if (allRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = allRef.current;
      if (Math.round(scrollTop) + clientHeight + 5 >= scrollHeight) {
        if (hasMore) {
          setPage((prevPage) => prevPage + 1);
          setHasMore(false);
        }
      }
    }
  };

  const updateNotificationsLocal = useCallback(
    (noteIdArr, unreadMessages) => {
      const notifications = userData?.unread_message_notifications;
      const newNotifications = Number(notifications) > 0 && Number(notifications) - unreadMessages;
      const dataFromLocalStorage = JSON.parse(localStorage.getItem('userData'));
      const newDataFromLocalStorage = {
        ...dataFromLocalStorage,
        unread_message_notifications: newNotifications
      };
      newNotifications &&
        dispatch({
          type: SET_NOTIFICATIONS,
          payload: Number(newNotifications) || ''
        });
      localStorage.setItem('userData', JSON.stringify(newDataFromLocalStorage));
    },
    [userData?.unread_message_notifications]
  );

  const selectConversation = useCallback(
    (conversationId, selectedUserId, unreadMessages) => {
      setMobileComponentActive(1);
      const notificationsId = inboxList
        ?.filter((conv) => conv.id === conversationId)
        .map(
          (value) => value.unread_messages > 0
          // && value.last_message.notification_id
        );
      notificationsId[0] !== false &&
        markNotificationAsRead(conversationId, getToken())
          .then((res) => {
            const activeConv = inboxList?.filter((conv) => conv.id === conversationId);
            activeConv[0] && (activeConv[0].unread_messages = 0);
            updateNotificationsLocal([conversationId], unreadMessages);
            dispatch({
              type: DISMISS_NOTIFICATION,
              payload: notificationsId
            });
          })
          .catch((err) => console.log(err));

      dispatch(setNewConversationId(conversationId));

      let notNumber = inboxList
        ?.filter((item) => item.profile_id === Number(sendMessageProfileId))
        .map((item) => item.unread_messages)[0];

      dispatch(setUnreadNotificationNumber(notNumber));
      clearFailedMessages();

      Number(sendMessageProfileId) !== selectedUserId && showMessagesPage(selectedUserId);
    },
    [dispatch, inboxList, sendMessageProfileId, updateNotificationsLocal]
  );

  const stringToHTML = (text) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(text, 'text/html');
    return parse(doc.body.innerHTML);
  };

  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '%d s',
      ss: '%d s',
      m: '1min',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      w: '1w',
      ww: '%d2',
      M: '1m',
      MM: '%dm',
      y: '1y',
      yy: '%d y'
    }
  });

  return (
    <div className="ConversationUserListAllContainer" onScroll={onScroll} ref={allRef}>
      {inboxList &&
        inboxList.map((item) => (
          <div
            className={
              Number(sendMessageProfileId) === item.profile_id
                ? 'UserInfoContainer Active'
                : 'UserInfoContainer'
            }
            key={item.id}
            onClick={selectConversation.bind(null, item.id, item.profile_id, item.unread_messages)}
            ref={
              Number(sendMessageProfileId) === item.profile_id ? singleConvRefActive : singleConvRef
            }
          >
            <div className="UserPhotoContainer">
              {item.avatar_image ? (
                <ImageN name={item.avatar_image} />
              ) : (
                <ImageComponent url={PROFILE} />
              )}
              {((moment(item?.last_message && item?.last_message.created_at).isAfter(
                moment().subtract(5, 'minutes')
              ) &&
                item?.last_message?.sender_profile_id === item.profile_id) ||
                item?.online_status !== 0) &&
                renderOnlineStatus()}
            </div>
            <div className="Notifications-Info">
              <div className="UserNameContainer">
                <div className="Username">{item.username}</div>
                <div className="MessagePreview">
                  {item?.last_message?.body ? (
                    stringToHTML(item?.last_message?.body)
                  ) : (
                    <FileImageOutlined />
                  )}
                </div>
              </div>
              <div className="StatusContainer">
                <div className="Timecontainer">
                  <Moment fromNow ago>
                    {moment
                      .utc(item?.last_message && item?.last_message.created_at)
                      .local()
                      .format()}
                  </Moment>
                </div>
                <div
                  className={
                    item.unread_messages > 0
                      ? 'MessageCountContainer'
                      : 'MessageCountContainer none'
                  }
                >
                  <span>{item?.unread_messages}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      {hasMore ? <div className="HasMore">LOADING MORE ...</div> : null}
    </div>
  );
};

export default ConversationUserListAll;
