import './Password.scss';

import arrowLeftIcon from '../../../../../../../../assets/images/Lander11/arrow-left.svg';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Password({ setValue, errors, prevStep, clearErrors, handleNextStep }) {
  return (
    <>
      <div className="UsernameForm">
        <div className="UsernameField">
          <div className="CountdownLabelWithBack">
            <span className="CountdownLabel">Password</span>
            <span className="CountdownBack" onClick={prevStep}>
              <img src={arrowLeftIcon} />
              Back
            </span>
          </div>
          <Input
            placeholder="Enter your password"
            name="password"
            type="password"
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
      </div>

      <Button text="Next" onClick={() => handleNextStep(['password'])} />
    </>
  );
}
