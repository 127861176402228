import React from 'react';

import ArrowBack from '../../../../../assets/images/FunnelPage/arrow-back.svg';
import Button from '../../../../../components/Button/Button';
// import DatePicker from "../../../../../components/Form/DatePicker/DatePicker";
import { InputNumber } from '../../Inputs';

const BirthdayInput = ({ control, register, errors, trigger, clickHandler, prevView, ...rest }) => {
  const handleNextStep = () => {
    trigger('age').then(() => {
      const errorBirthday = Object.keys(errors).filter((err) => err === 'age');
      if (errorBirthday.length === 0) {
        clickHandler();
      }
    });
  };
  return (
    <div className="birthday">
      <div className="arrow-back" onClick={prevView}>
        <img src={ArrowBack} alt="arrow back" />
      </div>
      <p>Your age will be displayed on your profile</p>
      <InputNumber
        name="age"
        {...register('age')}
        placeholder="Age"
        errorMessage={errors?.age?.message}
      />
      <Button text="next" onClick={handleNextStep} />
    </div>
  );
};

export default BirthdayInput;
