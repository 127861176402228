import './FAQ.scss';

import { Collapse } from 'antd';
import React from 'react';

import COLLAPSE from '../../../../../assets/images/FAQ/collapse.svg';
import EXPAND from '../../../../../assets/images/FAQ/expand.svg';
import HEADERIMAGE from '../../../../../assets/images/HeadersImages/FAQ-Header.jpg';
import HeaderLayout from '../../../../../components/HeaderLayout/HeaderLayout';

const FAQ = () => {
  const Data = [
    {
      question: 'Q How does LocalFlirt work?',
      answer:
        'LocalFlirt has been established to provide a safe and secure place for adults to browse and match with other profiles, with the purpose of exchanging messages and entering into exciting conversations about dating, flirting and any other topic you can dream of. '
    },
    {
      question: 'Q Do I have to pay to sign up for LocalFlirt?',
      answer:
        'No, access to LocalFlirt is currently free for all new users. You can sign up today and start browsing profiles instantly. You will also have access to our Match game and can start talking to profiles that catch your eye.'
    },
    {
      question: 'Q How do I chat to other profiles?',
      answer:
        'The chat service on LocalFlirt is simple and easy to use. You can send a message to any other profile by writing a message in the chat function found on the Messages section of the members area. You can also select the Send Message button on the page of your favorite profile.'
    },
    {
      question: 'Q Are there any limitations on number of messages I can send?',
      answer:
        'Of course not! Why would we want to restrict the fun? There are no limitations on number of messages or number of characters you can send in each message. You can even share photos!'
    },
    {
      question: 'Q How do I purchase credits for sending a message?',
      answer:
        'You can purchase credits from the Credits page inside the members area. The cost will vary depending on how many credits you buy. The bigger the credit pack, the better overall value. We accept Visa and Mastercard. Unfortunately, we are currently unable to accept PayPal, CashApp or any other form of payment.'
    },
    {
      question: 'Q How can I change my credit card details?',
      answer:
        'You can remove your existing card details by going to the Settings tab in My Profile and selecting ‘Remove Card Details’. Next time you purchase credits you will be directed to the Credit Card form where you can enter your new card details.'
    },
    {
      question: 'Q How goes the Gifting credits feature work?',
      answer:
        'You can gift credits to your favorite profiles so they can continue chatting with you. This works just like purchasing credits for yourself except they are credited to the account of the profile you are chatting with. The bigger the credit pack, the better overall value.'
    },
    {
      question: 'Q How does the Premium Membership work?',
      answer:
        'If you want to access the media gallery (blurred photos) on a profile, see who has viewed your profile or view your favorite profiles list you will need to sign up for a Premium Membership. A Premium Membership includes free messages that will be credited to your account every 30 days.'
    },
    {
      question: 'Q How can I change my username and password?',
      answer:
        'To change these, simply go to the Settings tab in My Profile. Please note the username and password should be letters and numbers only and must be between 6 and 16 characters.'
    }
  ];

  const title = 'Frequently Asked Questions';
  const description = "What's on your mind?";
  const image = HEADERIMAGE;
  const { Panel } = Collapse;

  return (
    <div className="FAQ">
      <HeaderLayout title={title} description={description} image={image} className={'FAQ-Image'} />
      <div className="FAQ-Container Container">
        <Collapse
          ghost="true"
          defaultActiveKey={[0]}
          expandIcon={({ isActive }) =>
            isActive ? <img src={COLLAPSE} alt="collapse" /> : <img src={EXPAND} alt="expand" />
          }
          expandIconPosition="right"
        >
          {Data.map((panel) => {
            return (
              <Panel header={panel.question} key={panel.question}>
                <p className="Panel-Answer">{panel.answer}</p>
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </div>
  );
};

export default FAQ;
