import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../axios/axiosInstance';

export const key = ['MATCHES'];
export const useGetMatches = (page, per_page) =>
  useQuery(
    [key, page],
    async () => {
      const { data } = await axiosInstance.get('/api/v2/profiles/matches', {
        params: {
          page,
          'filter[matched_profiles]': 1,
          per_page
        }
      });
      return data;
    },
    {
      retry: 0
    }
  );
