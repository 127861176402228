import './Gender.scss';

import Button from '../../UI/Button/Button';

export default function Gender({ onNextStep, setValue }) {
  const handleGenderSelect = (gender) => {
    setValue('gender', gender);
    onNextStep();
  };

  return (
    <div className="GenderContainer">
      <p className="GenderText">What are you looking for ?</p>
      <Button
        handleNextStep={() => handleGenderSelect('male')}
        text="I AM LOOKING FOR A MAN"
      ></Button>
      <Button
        handleNextStep={() => handleGenderSelect('female')}
        text="I AM LOOKING FOR A WOMAN"
      ></Button>
    </div>
  );
}
