import './LoaderPulse.scss';

import React from 'react';

const LoaderPulse = () => {
  return (
    <div className="ripple-loader">
      <div></div>
      <div></div>
    </div>
  );
};

export default LoaderPulse;
