import './SuccessMessage.scss';

import React, { useEffect, useState } from 'react';

const SuccessMessage = (props) => {
  const { className, message, showMessage, setShowMessage } = props;
  const [show, setShow] = useState(showMessage);
  const [addClass, setAddClass] = useState(showMessage);
  const activeClass = addClass ? 'Active' : '';
  const classes = ['SuccessMessage', className, activeClass];

  useEffect(() => {
    setShow(showMessage);
    setAddClass(showMessage);

    const classChange = setTimeout(() => {
      setAddClass(false);
    }, 3000);

    const timeId = setTimeout(() => {
      setShow(false);
      setShowMessage(false);
    }, 5000);

    return () => {
      clearTimeout(classChange);
      clearTimeout(timeId);
    };
  }, [setShowMessage, showMessage]);

  return show && <div className={classes.join(' ')}>{message}</div>;
};

export default SuccessMessage;
