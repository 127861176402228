import * as actionTypes from '../types/sidebar.js';

const initialState = {
  sidebar: false
};

const sideBar = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SIDEBAR:
      return {
        ...state,
        sidebar: action.payload
      };
    default:
      return { ...state };
  }
};

export default sideBar;
