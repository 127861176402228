import axios from 'axios';

export const urls = {
  settings: '/api/settings',
  publicCredits: '/api/public/credits'
};

export const getBillingBundles = () => {
  return axios.get(`${urls.settings}`);
};

export const getBillingCredits = () => {
  return axios.get(`${urls.settings}/credit`);
};

export const getCreditsPublic = () => {
  return axios.get(`${urls.publicCredits}`);
};
