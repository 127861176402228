import './GeneralTab.scss';

import { Form, Input, Select } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { getDaysInMonth, getYear, sub } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

import Button from '../../../../components/Button/Button';
import LoaderComponent from '../../../../components/LoaderCompoment/LoaderComponent';
import { userData as userRecoil } from '../../../../recoil/atoms/userDataAtom';
import { getCityZipCode } from '../../../../services/city-service/city.service';
import { TipInfoComponent } from '../../../components/tipInfoComponent/TipInfoComponent';
//@ts-ignore
import { UserAboutMe } from '../../../model/classes/UserAboutMe';
import { useGetUserAboutMe } from '../../../services/myProfile/useGetUserAboutMe';
import { useInterfacePreferenceData } from '../../../services/myProfile/useInterfacePreferenceData';
import { useUpdateUserData } from '../../../services/myProfile/useUpdateUserData';

interface GeneralType {
  isMobile: boolean;
  renderTipMessage: any;
}

export const GeneralTab = ({ isMobile, renderTipMessage }: GeneralType) => {
  const [generalForm] = Form.useForm();
  const [user] = useRecoilState(userRecoil);
  const [birthdayDay, setBirthdayDay] = useState<string | null>();
  const [birthdayMonth, setBirthdayMonth] = useState<string | null>();
  const [birthdayYear, setBirthdayYear] = useState<string | null>();
  const [zipcodeCities, setZipcodeCities] = useState<BaseOptionType[]>([]);
  //@ts-ignore
  const userId = user?.data?.profile.id;
  const { data: enumData } = useInterfacePreferenceData();
  const { data: userGeneralData, isLoading } = useGetUserAboutMe(userId);
  const { mutate: saveUserAboutMe } = useUpdateUserData();

  const handleBirthdayChange = (value: string, option: BaseOptionType) => {
    option.name === 'birthday-day' && setBirthdayDay(option.value);
    option.name === 'birthday-month' && setBirthdayMonth(option.value);
    option.name === 'birthday-year' && setBirthdayYear(option.value);
  };

  useEffect(() => {
    const date = userGeneralData ? new Date(userGeneralData.birthday) : new Date();
    const year = date.toLocaleDateString('en-US', { year: 'numeric' });
    const month = date.toLocaleDateString('en-US', { month: '2-digit' });
    const day = date.toLocaleDateString('en-US', { day: '2-digit' });
    setBirthdayDay(day);
    setBirthdayMonth(month);
    setBirthdayYear(year);
    const formData = {
      username: userGeneralData?.username,
      description: userGeneralData?.description,
      gender: userGeneralData?.gender,
      birthday: `${year}/${month}/${day}`,
      day: day,
      month: month,
      year: year,
      zip_code: userGeneralData?.zip_code,
      city: userGeneralData?.city,
      show_online_status: userGeneralData?.show_online_status,
      private_location: userGeneralData?.private_location
    };
    generalForm.setFieldsValue(formData);
  }, [userGeneralData, generalForm]);

  const range = (start: number, stop: number, step: number) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
  const daysList = userGeneralData
    ? range(1, getDaysInMonth(new Date(userGeneralData.birthday)), 1)
    : range(1, getDaysInMonth(new Date()), 1);
  const daysNumbers = daysList.map((item, index) => {
    const valueDay = item < 10 ? `0${item}` : `${item}`;
    return {
      itemd: index,
      value: valueDay,
      label: `${valueDay}`,
      name: 'birthday-day'
    };
  });

  const monthsNames = useMemo(() => {
    let months = [];
    for (let i = 0; i < 12; i++) {
      const valueMonth = i < 9 ? `0${i + 1}` : `${i + 1}`;
      months.push({
        id: i + 1,
        value: valueMonth,
        label: `${enUS.localize?.month(i)}`,
        name: 'birthday-month'
      });
    }
    return months;
  }, []);

  const minimumYear = useMemo(() => {
    return getYear(
      sub(new Date(), {
        years: 100,
        months: 0,
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      })
    );
  }, []);

  const legalYears = useMemo(() => {
    return getYear(
      sub(new Date(), {
        years: 18,
        months: 0,
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      })
    );
  }, []);

  const yearsList = useMemo(() => {
    const yearRange = range(minimumYear, legalYears, 1).reverse();
    return yearRange.map((item, index) => {
      return {
        id: index,
        value: `${item}`,
        label: `${item}`,
        name: 'birthday-year'
      };
    });
  }, []);

  const onFinish = (value: UserAboutMe) => {
    const data = {
      username: value.username,
      description: value.description,
      gender: value.gender,
      birthday: `${birthdayYear}-${birthdayMonth}-${birthdayDay}`,
      zip_code: value.zip_code,
      city: value.city
    };
    saveUserAboutMe({ userId: userId, payload: data });
  };

  const handleZipCodeChange = useCallback(async (e: any) => {
    const zipcode = e.target.value;
    if (zipcode.length !== 0) {
      try {
        const response = await getCityZipCode(zipcode);
        const { data } = response.data;
        const modifiedArray = data.map((item: any) => {
          return { id: item.id, label: item.city, value: item.zip_code, name: 'city' };
        });
        setZipcodeCities(modifiedArray);
        generalForm.setFieldValue('city', modifiedArray[0].label);
        if (zipcode.length !== 0 && zipcode.length <= 5) {
          setZipcodeCities(modifiedArray);
          generalForm.setFieldValue('city', modifiedArray[0].label);
        }
        if (data.length === 0 || zipcode.length > 5) {
          setZipcodeCities([]);
          setZipcodeCities([{ id: 0, label: 'No Cities Found', value: '', name: 'city' }]);
        }
      } catch {
        setZipcodeCities([]);
      }
    }
    if (zipcode.length === 0) {
      setZipcodeCities([{ id: 0, label: '', value: '', name: 'city' }]);
    }
  }, []);

  const handleCityZip = (value: string, option: BaseOptionType) => {
    generalForm.setFieldValue('zip_code', option.value);
    generalForm.setFieldValue('city', option.label);
  };

  const renderLoader = () => {
    return (
      <div className="general-loading">
        <LoaderComponent />
      </div>
    );
  };

  return (
    <div className="general-tab">
      {/* {isMobile ? <TipInfoComponentMobile message={renderTipMessage()} /> : ''} */}

      <Form
        className="form-container"
        form={generalForm}
        name="user_general"
        layout="vertical"
        onFinish={onFinish}
      >
        {' '}
        {isLoading ? (
          renderLoader()
        ) : (
          <>
            <div className="form-left-side">
              <Form.Item name="username" label="Username">
                <Input placeholder="Username" size="large" />
              </Form.Item>
              <Form.Item name="gender" label="Gender">
                <Select
                  placeholder="Gender"
                  size="large"
                  options={[
                    { value: 'male', label: 'Male' },
                    { value: 'female', label: 'Female' },
                    { value: 'prefer not to tell', label: 'Prefer not to tell' }
                  ]}
                />
              </Form.Item>
            </div>
            <div className="form-right-side">
              <Form.Item name="birthday" label="Birthdate">
                <div className="birthday-input-container">
                  <Form.Item name="day" rules={[{ required: true }]}>
                    <Select
                      placeholder="Day"
                      size="large"
                      onChange={handleBirthdayChange}
                      options={daysNumbers}
                      value={birthdayDay}
                    />
                  </Form.Item>
                  <Form.Item name="month" rules={[{ required: true }]}>
                    <Select
                      placeholder="Month"
                      size="large"
                      onChange={handleBirthdayChange}
                      options={monthsNames}
                      value={birthdayMonth}
                    />
                  </Form.Item>
                  <Form.Item name="year" rules={[{ required: true }]}>
                    <Select
                      placeholder="Year"
                      size="large"
                      onChange={handleBirthdayChange}
                      options={yearsList}
                      value={birthdayYear}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
              <div className="zip-code-city-container">
                <Form.Item name="zip_code" label="Zip Code">
                  <Input placeholder="Zip Code" size="large" onChange={handleZipCodeChange} />
                </Form.Item>
                <Form.Item name="city" label="City">
                  <Select
                    placeholder="City"
                    size="large"
                    onChange={handleCityZip}
                    options={zipcodeCities}
                  />
                </Form.Item>
              </div>
              <Button className="SignUpButton" text="SAVE CHANGES" htmlType="submit" />
            </div>
          </>
        )}
      </Form>

      <div className="tip-container">
        {!isMobile ? <TipInfoComponent message={renderTipMessage()} /> : ''}
      </div>
    </div>
  );
};
