import './TabsComponent.scss';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { showMessagesPage } from '../../pages/MainView/components/MembersAreaMain/navigation.service';
import { setNewConversationId } from '../../redux/actions/messageConversationId';

const TabsComponent = (props) => {
  const dispatch = useDispatch();
  const { getSelectedProfileData, getRefreshConvList, refreshConvList } = props;
  const [state, setState] = useState({
    activeTab: 1
  });

  const changeActiveTab = (tab) => {
    setState({ activeTab: tab });
    if (props.getActiveTab) props.getActiveTab(tab);
  };

  const onTabChange = (id) => {
    dispatch(setNewConversationId(''));
    // getSelectedProfileData("");
    changeActiveTab(id);
    showMessagesPage();
    // getRefreshConvList((prevVal) => prevVal + 1);
  };

  const renderContent = (item, index) => {
    const { activeTab } = state;
    const { id, content } = item;
    const classes = activeTab === id ? ['Content', 'Active'] : ['Content'];
    return (
      <div key={`tab-content-${index}`} className={classes.join(' ')}>
        {activeTab === id ? content : null}
      </div>
    );
  };

  const renderTabs = () => {
    const titles = props.tabsData.map(renderTitle);
    const contents = props.tabsData.map(renderContent);

    return (
      <div className="Tabs">
        <ul className="nav nav-tabs">{titles}</ul>
        <div className="Tab-Content">{contents}</div>
      </div>
    );
  };

  const renderTitle = (item, index) => {
    const { activeTab } = state;
    const { id, title, ref } = item;
    let classes = activeTab === id ? ['active', 'Title-Container'] : ['Title-Container'];
    if (activeTab - 1 === id) classes.push('Previous');

    return (
      <div
        className={classes.join(' ')}
        key={`tab-title-${index}`}
        onClick={onTabChange.bind(null, id)}
        ref={ref}
      >
        {title}
      </div>
    );
  };

  return <div className="TabsComponent">{renderTabs()}</div>;
};

export default TabsComponent;
