import './CreditsCard.scss';

import { Card, Divider } from 'antd';
import React, { useState } from 'react';

const { Meta } = Card;

const CreditsCard = (props) => {
  const { title, messageNumber, discount, price, badge } = props;
  const [action] = useState(() => {
    switch (title) {
      case 'gold':
        return 'most_popular';
      case 'diamond':
        return 'limited_offer';
      default:
        return null;
    }
  });
  const className =
    action === 'most_popular' ? 'MostPopular' : action === 'limited_offer' ? 'LimitedOffer' : '';
  const cardClass = ['CreditCard', className];
  const priceClass = ['Price', className];

  return (
    <>
      <div className={cardClass.join(' ')}>
        <div className="BadgeContainer">{badge}</div>
        <div className="DataHolder">
          <p className={priceClass.join(' ')}>{messageNumber}</p>
          <p className="Messages">MESSAGES</p>
          <Divider />
          <p className="MoneySave">$ 2.00</p>
          <p className="PercentageSave">Save {discount}%</p>
        </div>
        <Meta className="CardFooter" title="BUY NOW" description={`$ ` + price} />
      </div>
      {action && (
        <div
          className={action === 'most_popular' ? 'InfoText MostPopular' : 'InfoText LimitedOffer'}
        >
          {action === 'most_popular' ? 'MOST POPULAR' : 'LIMITED OFFER'}
        </div>
      )}
    </>
  );
};

export default CreditsCard;
