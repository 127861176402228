import './style.scss';

import { Divider, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { PaymentFooter } from '../../../assets/images';
import { cancelSpecial } from '../../../recoil/atoms/cancelSpecial';
import { userProfile } from '../../../recoil/selectors/userProfile';
import { useGetAllPackages } from '../../services/packages/useGetAllPackages';
import { CreditsCard } from '../shared/CreditsCard/CreditsCard';

export const SpecialOfferModal = () => {
  const [shallCancelSpecial, setShallCacncelSpecial] = useRecoilState(cancelSpecial);
  const { data } = useGetAllPackages();
  const profile = useRecoilValue(userProfile);
  const [show, setShow] = useState<boolean>(false);
  const specialOffer = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('special'))));
  useEffect(() => {
    let timeoutId;

    if (specialOffer) {
      timeoutId = setTimeout(() => {
        setShow(specialOffer.special);
      }, 30000);
    }
    if (shallCancelSpecial && timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }
  }, [shallCancelSpecial, specialOffer]);
  const renderPackages = (isSpecial: PhpBool) => {
    return (data?.filter((item) => item.is_special_offer === isSpecial) ?? []).map((pkg) => (
      <CreditsCard redirectUrl={location.href} packageDef={pkg} key={pkg.id} version={1} />
    ));
  };
  const closeModal = () => {
    localStorage.setItem('special', JSON.stringify({ ...specialOffer, special: false }));
    setShallCacncelSpecial(true);
    setShow(false);
  };
  const shouldShow = () => {
    if (shallCancelSpecial) return false;
    else return show && renderPackages(1).length > 0;
  };
  return (
    <Modal
      footer={
        <div className={'modal-footer'}>
          <img src={PaymentFooter} alt={'footer'} />
        </div>
      }
      open={shouldShow()}
      onCancel={closeModal}
      maskClosable={false}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      className="special-offer"
    >
      <div className={'header'}>
        <span>{profile?.username}</span>, this special offer
        <br /> is just for you!
      </div>
      <div className={'hurry'}>Hurry up, this is a one time offer</div>
      {renderPackages(1)}
      <Divider dashed />
    </Modal>
  );
};
