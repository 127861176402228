import './EmailShorter.scss';

import check from '../../../../../../../../assets/images/Lander18/check.svg';
import GoogleLoginButton from '../../../../../../../../components/GoogleLoginButton/GoogleLoginButton';
import useZoomLevel from '../../../../../../../../hooks/useZoomLevel';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function EmailShorter({
  getValues,
  handleNextStep,
  watch,
  errors,
  setValue,
  emailLoading,
  clearErrors,
  setGoogleLogin,
  handleGoogleLogin
}) {
  const { isZoomed, zoomLevel } = useZoomLevel();

  return (
    <div
      className={isZoomed ? `EmailShorterContainer zoomed${zoomLevel}` : 'EmailShorterContainer'}
    >
      <div className="StepContent">
        <p className="EmailShortText">Add your Email address so you can:</p>
        <p className="EmailShortList">
          <img src={check} />
          Get nudes every day of these women
        </p>
        <p className="EmailShortList">
          <img src={check} />
          View details & profiles of these women
        </p>
        <p className="EmailShortList">
          <img src={check} />
          Chat in private with these women
        </p>
        <p className="EmailShortList">
          <img src={check} />
          Have fun every day!
        </p>
        <p className="EmailShortText">Your Email address</p>
        <div className="InputMainContainer">
          <Input
            placeholder="Email address"
            name="email"
            type="email"
            value={getValues('email')}
            onChange={() => {
              setGoogleLogin(false);
            }}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
          <GoogleLoginButton
            className="GreyGoogleLoginButton"
            text="Sign up with Google"
            handleGoogleLogin={(res) =>
              handleGoogleLogin(res, ['email', 'randPass', 'randUsr'].filter(Boolean))
            }
          />
        </div>
        <div className="ButtonContainer">
          <Button
            handleNextStep={() => handleNextStep(['email', 'randPass', 'randUsr'])}
            text="CONTINUE"
            loading={emailLoading}
            disabled={!watch('email')}
          ></Button>
        </div>
      </div>
    </div>
  );
}
