import './DragUpload.scss';

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { notification, Progress, Upload, UploadProps } from 'antd';
import React from 'react';

import { queryClient } from '../../../App';

const { Dragger } = Upload;
interface DragUploadProps extends UploadProps {
  name: string;
  accept?: string;
  multiple?: boolean;
  icon?: React.ReactNode;
  buttonText?: string;
  label?: string;
  setModalPhotoOpen?: any;
  queryKeyArray?: Array<any>;
}
export const DragUpload = ({
  name,
  accept,
  multiple = true,
  icon = <UploadOutlined />,
  buttonText = 'Browse',
  label = 'Click or drag and drop an images',
  queryKeyArray,
  setModalPhotoOpen,
  ...rest
}: DragUploadProps) => {
  const props: UploadProps = {
    name: name,
    accept: accept,
    multiple: multiple,
    progress: { gapPosition: 'bottom', className: 'progress-bar' },
    itemRender: (origin, file, fileList, actions) =>
      file.status &&
      file.status !== 'done' && (
        <div className="progress-item">
          <div className={'progress-header'}>
            <div>{file.name}</div>
            <div className={'progress-header-icon'} onClick={actions.remove}>
              <DeleteOutlined />
            </div>
          </div>
          <Progress
            status={
              file.status === 'error'
                ? 'exception'
                : file.status === 'uploading'
                ? 'active'
                : 'normal'
            }
            format={(percent) => (percent && percent < 100 ? percent?.toFixed(2) : percent) + '%'}
            percent={file.percent}
          />
        </div>
      ),
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        notification.success({ message: `${info.file.name} file uploaded successfully.` });
        setModalPhotoOpen && setModalPhotoOpen(false);
        queryKeyArray &&
          queryKeyArray.map((key) => {
            queryClient.invalidateQueries(key);
          });
      } else if (status === 'error') {
        notification.error({ message: `${info.file.name} file upload failed.` });
      }
    },
    ...rest
  };
  const token = localStorage.getItem('token');
  return (
    <div className={'drag-and-drop-container'}>
      <Dragger
        {...props}
        headers={{ Authorization: `Bearer ${token}` }}
        accept={accept}
        // className={'p-8 w-full h-[260px]'}
      >
        <div className={'drag-and-drop-area'}>
          <div>
            <p className="drag-and-drop-icon">{icon}</p>
          </div>
          <div className={'drag-and-drop-text-container'}>
            {/* <Button disabled={props.disabled} className={'mb-4'}>
              {buttonText}
            </Button> */}
            <p className="drag-and-drop-text">{label}</p>
          </div>
        </div>
      </Dragger>
    </div>
  );
};
