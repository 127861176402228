import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axiosInstance } from '../../../axios/axiosInstance';
import { FictitiousProfile } from '../../model/interfaces/FictitiousProfile';

export const key = ['MEMBER'];

export const useGetProfile = (id?: string) =>
  useQuery(
    [...key, id],
    async (): Promise<{ status?: number; data: FictitiousProfile | null; error?: string }> => {
      try {
        const { data } = await axiosInstance.get(`/api/v2/profiles/${id}`);
        return { data: data.data };
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          return {
            status: axiosError.response.status,
            data: null,
            error: axiosError.message
          };
        }
        // Handle non-Axios errors
        return {
          status: 500,
          data: null,
          error: 'An unexpected error occurred'
        };
      }
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false
    }
  );
