import React, { useState } from 'react';

import middleAgeBgBlue from '../../../../../../../../assets/images/Lander14/blue/age/middle-age.png';
import oldBgBlue from '../../../../../../../../assets/images/Lander14/blue/age/old.png';
import youngBgBlue from '../../../../../../../../assets/images/Lander14/blue/age/young.png';
import middleAgeBgLight from '../../../../../../../../assets/images/Lander14/light/age/middle-age.png';
import oldBgLight from '../../../../../../../../assets/images/Lander14/light/age/old.png';
import youngBgLight from '../../../../../../../../assets/images/Lander14/light/age/young.png';
import middleAgeBgPink from '../../../../../../../../assets/images/Lander14/pink/age/middle-age.png';
import oldBgPink from '../../../../../../../../assets/images/Lander14/pink/age/old.png';
import youngBgPink from '../../../../../../../../assets/images/Lander14/pink/age/young.png';
import middleAgeBgRed from '../../../../../../../../assets/images/Lander14/red/age/middle-age.png';
import oldBgRed from '../../../../../../../../assets/images/Lander14/red/age/old.png';
import youngBgRed from '../../../../../../../../assets/images/Lander14/red/age/young.png';
import LanderChat from '../../LanderChat/LanderChat';
import VerticalOption from '../../VerticalOption/VerticalOption';

export default function AgeOfInterest({ setIsValid, landerId }) {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const [activeOption, setActiveOption] = useState(null);

  const messages = {
    '14-1': [{ content: 'Good! What is  the ideal age of your new match?' }],
    '14-2': [{ content: 'Good! What is  the ideal age of your new match?' }],
    '14-3': [{ content: 'Good! What is  the ideal age of your new match?' }],
    '14-4': [{ content: 'Good! What is  the ideal age of your new match?' }]
  };

  const avatars = {
    '14-1': {
      young: youngBgRed,
      middle: middleAgeBgRed,
      old: oldBgRed
    },
    '14-2': {
      young: youngBgBlue,
      middle: middleAgeBgBlue,
      old: oldBgBlue
    },
    '14-3': {
      young: youngBgPink,
      middle: middleAgeBgPink,
      old: oldBgPink
    },
    '14-4': {
      young: youngBgLight,
      middle: middleAgeBgLight,
      old: oldBgLight
    }
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  const handleOptionClick = (option) => {
    setActiveOption(option);
    setIsValid(true);
  };

  return (
    <>
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />
      <div
        className={isElementVisible ? 'ToggleElement SelectOptions Age' : 'Hidden SelectOptions'}
      >
        <VerticalOption
          title="18 - 29"
          backgroundImage={avatars[`${landerId}`].young}
          onClick={() => handleOptionClick('18 - 29')}
          isActive={activeOption === '18 - 29'}
        />
        <VerticalOption
          title="30 - 45"
          backgroundImage={avatars[`${landerId}`].middle}
          onClick={() => handleOptionClick('30 - 45')}
          isActive={activeOption === '30 - 45'}
        />
        <VerticalOption
          title="45+"
          backgroundImage={avatars[`${landerId}`].old}
          onClick={() => handleOptionClick('45+')}
          isActive={activeOption === '45+'}
        />
      </div>
    </>
  );
}
