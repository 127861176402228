import './ImgCroper.scss';

import { LoadingOutlined, PictureOutlined } from '@ant-design/icons';
import { Image, notification, Upload, UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import ImgCrop from 'antd-img-crop';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { queryClient } from '../../../../../App';
import Button from '../../../../../components/Button/Button';
import { key as UserProfileKey } from '../../../../../core/APIHooks/userData/useGetUserData';
import { userData as userRecoil } from '../../../../../recoil/atoms/userDataAtom';
import { useRemoveProfilePhoto } from '../../../../services/myProfile/useRemoveProfilePhoto';
import { useUpdateUserPhotos } from '../../../../services/myProfile/useUpdateUserPhotos';

export const ImgCroper = ({ setModalPhotoOpen }: any) => {
  const [user] = useRecoilState(userRecoil);
  const [fileList, setFileList] = useState<UploadFile[]>();
  const userId = user?.data?.profile.id;
  const profilePhoto = user?.data?.profile?.profile_image;
  const BASE_URL = `${process.env.REACT_APP_API_URL}/`;
  const { mutate: saveUserPhoto, isLoading } = useUpdateUserPhotos();
  const { mutate: remove, isLoading: removing } = useRemoveProfilePhoto();
  const uploadButton = (
    <div>
      {isLoading ? (
        <LoadingOutlined style={{ fontSize: '30px', color: '#f8b6b6' }} />
      ) : (
        <PictureOutlined style={{ fontSize: '30px', color: '#f8b6b6' }} />
      )}
      <div style={{ marginTop: 8 }}>Upload Profile Photo</div>
    </div>
  );
  const beforeUploadImage = (file: RcFile) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'image/bmp' ||
      file.type === 'image/gif';
    if (!isJpgOrPng) {
      notification.error({
        message: 'You can only upload JPG/PNG/BMP/GIF file!'
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      notification.error({
        message: `Image ${file.name} must be smaller than 10MB!`
      });
    }
    return isJpgOrPng && isLt2M;
  };
  const uploadImage = (data: any) => {
    saveUserPhoto(
      { userId, file: data.file, type: 'profile_image' },
      {
        onSuccess: () => {
          setModalPhotoOpen && setModalPhotoOpen(false);
          queryClient.invalidateQueries(UserProfileKey);
        }
      }
    );
  };
  const handleChange = (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setFileList(fileList);
  };
  return (
    <div className="image-croper-container">
      <ImgCrop rotationSlider>
        <Upload
          beforeUpload={beforeUploadImage}
          className="image-cropper-preview"
          customRequest={uploadImage}
          listType="picture-card"
          showUploadList={false}
          fileList={fileList}
          onChange={handleChange}
        >
          {uploadButton}
        </Upload>
      </ImgCrop>
      <div className="profile-image-container">
        {profilePhoto ? (
          <div>
            <Image src={profilePhoto} alt="avatar" style={{ width: '100%', marginBottom: 5 }} />
            <Button onClick={() => remove()} loading={removing}>
              Remove
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
