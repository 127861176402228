import Age from '../Steps/Age/Age';
import AgeOfInterest from '../Steps/AgeOfInterest/AgeOfInterest';
import BodyType from '../Steps/BodyType/BodyType';
import Email from '../Steps/Email/Email';
import Gender from '../Steps/Gender/Gender';
import GenderOfInterest from '../Steps/GenderOfInterest/GenderOfInterest';
import Matches from '../Steps/Matches/Matches';
import Password from '../Steps/Password/Password';
import Relationship from '../Steps/Relationship/Relationship';
import Username from '../Steps/Username/Username';
import Warning from '../Steps/Warning/Warning';
import ZipCode from '../Steps/ZipCode/ZipCode';

const data = [
  {
    component: Relationship,
    percent: 0
  },
  {
    component: Gender,
    percent: 10
  },
  {
    component: GenderOfInterest,
    percent: 20
  },
  {
    component: Age,
    percent: 30,
    field: 'age'
  },
  {
    component: BodyType,
    percent: 40
  },
  {
    component: AgeOfInterest,
    percent: 50
  },
  {
    component: ZipCode,
    percent: 60,
    field: 'zip_code'
  },
  {
    component: Warning,
    percent: 60
  },
  {
    component: Username,
    percent: 70,
    field: 'username'
  },
  {
    component: Password,
    percent: 80,
    field: 'password'
  },
  {
    component: Matches,
    percent: 90
  },
  {
    component: Email,
    percent: 100,
    field: 'email'
  }
];

export default data;
