import * as actionTypes from '../types/profileAvatar.js';

const initialState = '';

const profileAvatar = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILE_AVATAR:
      return action.payload;
    default:
      return state;
  }
};

export default profileAvatar;
