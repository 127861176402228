import './LogoComponent.scss';

import React from 'react';

import HEADERIMAGE from '../../assets/images/LandingPage/LocalFlirt-red-logo-white.svg';
import { showRootPage } from '../../services/navigation-service/navigation.service';
import ImageComponent from '../ImageComponent/ImageComponent';

const LogoComponent = () => {
  const redirectHome = () => {
    showRootPage();
    // window.location.reload();
  };

  return (
    <div className="LogoComponent" onClick={redirectHome}>
      <ImageComponent url={HEADERIMAGE} />
    </div>
  );
};

export default LogoComponent;
