import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';
import { CreditsPackage } from '../../model/classes/CreditsPackage';

export const key = ['PACKAGES_LIST'];

export const useGetAllPackages = (enabled = true) =>
  useQuery(
    [...key],
    async (): Promise<Array<CreditsPackage>> => {
      const { data } = await axiosInstance.get('api/credit-packages', {
        transformRequest: (data, headers) => {
          delete headers.common['authorization'];
          return data;
        }
      });
      return data.data;
    },
    { staleTime: 1000, enabled }
  );
