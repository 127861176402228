import './Unsubscribe.scss';

import React, { useState } from 'react';

import { unsubscribe } from '../../services/user-service/user.service';
import Button from '../Button/Button';
import LoaderComponent from '../LoaderCompoment/LoaderComponent';

const Unsubscribe = (props) => {
  const { unsubscribeUrl, closeModal, modalRef, isUnsubscribeSuccess } = props;
  const [loading, isLoading] = useState(false);

  const unsubscribeFailed = (err) => {
    isLoading(false);
    isUnsubscribeSuccess(false);
    console.log(err);
  };

  const unsubscribeSuccess = (res) => {
    isLoading(false);
    isUnsubscribeSuccess(true);
    closeModal(modalRef);
  };

  const onSubmit = () => {
    isLoading(true);
    unsubscribe(decodeURIComponent(unsubscribeUrl))
      .then(unsubscribeSuccess)
      .catch(unsubscribeFailed);
  };

  const renderLoader = () => {
    return (
      <div className="Loading">
        <LoaderComponent />
      </div>
    );
  };

  return (
    <div className="Unsubscribe">
      {loading && renderLoader()}
      <div className="ResetPassword-Header">
        <h3>Unsubscribe</h3>
        <p>Click on confirm button to if you don`t want to receive any emails from LocalFlirt</p>
      </div>
      <div className="ResetPassword-Form">
        <Button className="SubmitButton" type="submit" onClick={onSubmit} text="CONFIRM" />
      </div>
    </div>
  );
};

export default Unsubscribe;
