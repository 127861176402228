// import { WarningFilled } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';

import { axiosInstance } from '../../../axios/axiosInstance';
import { UserPassword } from '../../model/classes/UserPassword';

interface PreferencesData {
  payload: UserPassword;
}

interface ErrorResponse {
  message: string;
  errors: string[];
}

function transformErrorData(data: any): ErrorResponse {
  const result: ErrorResponse = {
    message: data.message,
    errors: []
  };

  if (data.errors)
    for (const [, value] of Object.entries(data.errors)) {
      // @ts-ignore
      result.errors.push(value[0]);
    }

  return result;
}
export const useUpdateUserPassword = () =>
  useMutation(
    async ({ payload }: PreferencesData) => {
      const { data } = await axiosInstance.post(`/api/users/update-password`, {
        ...payload,
        _method: 'PATCH'
      });
      return data;
    },
    {
      onSuccess: () => notification.success({ message: 'Password Successfully Updated.' }),
      onError: (err: AxiosError) => {
        if (err.response) {
          const data = transformErrorData(err.response.data);
          notification.error({
            message: 'Update error ' + err.response?.status,
            description: data.errors.map((error: string) => {
              return (
                <p key={error} style={{ marginBottom: '5px' }}>
                  {error}
                </p>
              );
            })
          });
        }
      }
    }
  );
