import * as actionTypes from '../types/pricing.js';

const initialState = [];

const pricing = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PRICING:
      return [...state, ...action.payload];
    default:
      return [...state];
  }
};

export default pricing;
