import * as actionTypes from '../types/profile.js';

const initialState = {};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return { ...state };
  }
};

export default profile;
