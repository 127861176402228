import React, { useEffect, useState } from 'react';

import TYPING from '../../../../assets/images/chat-typing-indicator.gif';
import ADMIN from '../../../../assets/images/SignUpAff7Photos/image-admin.png';

const AnimatedMessages = ({
  text,
  text2,
  text3,
  setShowCard,
  messageBig,
  messageBig2,
  messageBig3,
  stepNo
}) => {
  //for animation
  const [showMessage, setShowMessage] = useState(false);
  const [showMessageContent, setShowMessageContent] = useState(false);
  const [typing, setTyping] = useState(false);
  const [adminPhoto, setAdminPhoto] = useState(true);

  const [showMessage2, setShowMessage2] = useState(false);
  const [showMessageContent2, setShowMessageContent2] = useState(false);
  const [secondMessage, setShowSecondMessage] = useState(false);
  const [typing2, setTyping2] = useState(false);
  const [adminPhoto2, setAdminPhoto2] = useState(false);

  const [showMessage3, setShowMessage3] = useState(false);
  const [showMessageContent3, setShowMessageContent3] = useState(false);
  const [thirdMessage, setShowThirdMessage] = useState(false);
  const [typing3, setTyping3] = useState(false);
  const [adminPhoto3, setAdminPhoto3] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowMessage(true), 500);
    setTimeout(() => setTyping(true), 1000);
    setTimeout(() => setTyping(false), 2000);
    setTimeout(() => setShowMessageContent(true), 2000);
    setTimeout(() => setShowSecondMessage(true), 2500);

    if (text2) {
      setTimeout(() => setAdminPhoto(false), 3000);
      setTimeout(() => setShowMessage2(true), 3000);
      setTimeout(() => setAdminPhoto2(true), 3000);
      setTimeout(() => setTyping2(true), 3500);
      setTimeout(() => setTyping2(false), 4500);
      setTimeout(() => setShowMessageContent2(true), 4500);
    }

    if (text3) {
      setTimeout(() => setAdminPhoto(false), 5000);
      setTimeout(() => setShowMessage3(true), 5000);
      setTimeout(() => setAdminPhoto2(false), 5000);
      setTimeout(() => setAdminPhoto3(true), 5000);
      setTimeout(() => setTyping3(true), 5500);
      setTimeout(() => setTyping3(false), 6500);
      setTimeout(() => setShowMessageContent3(true), 6500);
    }

    //  return setTimeout(() => setShowCard(1), 4500);
    if (stepNo && !text2) {
      setTimeout(() => setShowCard(stepNo), 3000);
    }
    if (stepNo && text2 && !text3) {
      setTimeout(() => setShowCard(stepNo), 5000);
    }
    if (stepNo && text2 && text3) {
      setTimeout(() => setShowCard(stepNo), 7000);
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}
    >
      <div className={`Aff7Message ${showMessage && 'show'}`}>
        <img src={ADMIN} alt="" className={`Avatar ${adminPhoto && 'show'}`} />
        {typing && (
          <div className={`RectMessageTyping ${typing && 'show'}`}>
            <img src={TYPING} className="Typing" />
          </div>
        )}
        {showMessageContent && adminPhoto && (
          <div
            className={`RectMessage ${messageBig && 'MessageBig'} ${
              showMessageContent && adminPhoto && 'show'
            }`}
          >
            <p>{text}</p>
          </div>
        )}
        {showMessageContent && !adminPhoto && (
          <div
            className={`RectMessageNoAvatar ${messageBig && 'MessageBig'} ${
              showMessageContent && !adminPhoto && 'show'
            }`}
          >
            <p>{text}</p>
          </div>
        )}
      </div>
      <div className={`Aff7Message ${showMessage2 && 'show'} ${messageBig && 'marginTop'}`}>
        <img src={ADMIN} alt="" className={`Avatar ${adminPhoto2 && 'show'}`} />
        {typing2 && (
          <div className={`RectMessageTyping ${typing2 && 'show'}`}>
            <img src={TYPING} className="Typing" />
          </div>
        )}
        {showMessageContent2 && adminPhoto && (
          <div
            className={`RectMessage ${messageBig2 && 'MessageBig'} ${
              showMessageContent2 && 'show'
            }`}
          >
            <p>{text2}</p>
          </div>
        )}
        {showMessageContent2 && !adminPhoto && (
          <div
            className={`RectMessageNoAvatar ${messageBig2 && 'MessageBig'} ${
              showMessageContent2 && !adminPhoto && 'show'
            }`}
          >
            <p>{text2}</p>
          </div>
        )}
      </div>
      <div className={`Aff7Message ${showMessage3 && 'show'} ${messageBig2 && 'marginTop'}`}>
        <img src={ADMIN} alt="" className={`Avatar ${adminPhoto3 && 'show'}`} />
        {typing3 && (
          <div className={`RectMessageTyping ${typing3 && 'show'}`}>
            <img src={TYPING} className="Typing" />
          </div>
        )}
        {showMessageContent3 && (
          <div
            className={`RectMessage ${messageBig3 && 'MessageBig'} ${
              showMessageContent3 && 'show'
            }`}
          >
            <p>{text3}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnimatedMessages;
