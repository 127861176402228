import './BasicFooter.scss';

import React from 'react';

import {
  showAboutUsPage,
  showContactPage,
  showFAQPage,
  showPricingPage,
  showPrivacyPolicyPage,
  showTermsAndConditionPage
} from '../../services/navigation-service/navigation.service';
import LogoComponent from '../LogoComponent/LogoComponent';

const BasicFooter = () => {
  return (
    <div className="BasicFooter">
      <div className="BasicFooter-Container Container">
        <div className="BasicFooter-Content">
          <div className="Links-Column">
            <div className="ListName">Quick Links</div>
            <p onClick={showAboutUsPage}>About Us</p>
            <p onClick={showFAQPage}>FAQs</p>
            <p onClick={showPricingPage}>Pricing</p>
            <p onClick={showContactPage}>Contact Us</p>
          </div>
          <div className="FooterLogo-Content phoneLogoContent">
            <LogoComponent />
            <p>
              LocalFlirt is a discrete and satisfying place to live out your wildest desires! Flirt
              and meet with like-minded adults near you now
            </p>
          </div>
          <div className="Corporation-Column">
            <div className="ListName">Corporation</div>
            <p>Gochamie Inc</p>
            <p>20 North Orange Avenue Suite 1100 11th Floor</p>
            <p>Orlando, FL 32801</p>
            <p>877-381-0324</p>
          </div>
        </div>
      </div>
      <div className="CopyrightFooter">
        <div className="CopyrightFooter-Container Container">
          <div className="Copyright-Text">
            Copyright © {new Date().getFullYear()} LocalFlirt. All Rights Reserved.
          </div>
          <div className="Terms-Container">
            <span className="Copyright-Text" onClick={showTermsAndConditionPage}>
              Terms and Conditions
            </span>{' '}
            |{' '}
            <span className="Copyright-Text" onClick={showPrivacyPolicyPage}>
              Privacy Policy
            </span>{' '}
            |{' '}
            <span className="Copyright-Text" onClick={showContactPage}>
              Support
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicFooter;
