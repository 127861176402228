import { useEffect, useState } from 'react';

export const useImageLoaded = (ref) => {
  const [loaded, setLoaded] = useState(false);

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (ref?.current && ref.current?.load) {
      onLoad();
    }
  }, [ref]);

  return [ref, loaded, onLoad];
};
