import { useMutation } from '@tanstack/react-query';

// import { queryClient } from '../../App';
import { axiosInstance } from '../../axios/axiosInstance';

export const useSaveUserProfile = () =>
  useMutation(
    async ({ id, data }) => {
      await axiosInstance.post(`/api/profiles/${id}`, { ...data, _method: 'PATCH' });
    }
    // {
    //   onSuccess: () => queryClient.invalidateQueries(key),
    // },
  );
