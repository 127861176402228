import './ZipCode.scss';

import React, { useState } from 'react';

import LanderChat from '../../LanderChat/LanderChat';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function ZipCode({
  handleNextStep,
  typeOnlyNumbers,
  errors,
  setValue,
  clearErrors,
  cityLoading,
  landerId
}) {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const landerButtonClassName = `version${landerId}`;

  const messages = {
    '12-1': [
      {
        content: 'Me too, babe. We’re almost there now. Please tell me your location:'
      }
    ],
    '12-2': [
      {
        content: 'Same as me! Almost done. Please tell me your location:'
      }
    ],
    '12-3': [
      {
        content: 'Sounds good. One more thing. Please tell me your location:'
      }
    ],
    '12-4': [
      {
        content: 'Nice choice. Nearly there… Please tell me your location:'
      }
    ]
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />

      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer12">
          <Input
            placeholder="Enter Zip Code"
            name="zip_code"
            maxLength={5}
            onKeyDown={typeOnlyNumbers}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
        </div>
        <div className="ButtonsContianer">
          <Button
            className={landerButtonClassName}
            text="NEXT"
            handleNextStep={() => handleNextStep(['zip_code'])}
            loading={cityLoading}
          />
        </div>
      </div>
    </>
  );
}
