import { RefObject, useEffect } from 'react';

interface UseOutsideClickProps {
  ref: RefObject<any>;
  action: () => void;
}
export const useOutsideClick = ({ ref, action }: UseOutsideClickProps) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }
    function handleTouchOutside(event: TouchEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleTouchOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [action, ref]);
};
