import './Gender.scss';

import React, { useState } from 'react';

import manBgImage from '../../../../../../../../assets/images/Lander10/i-am-man.png';
import womanBgImage from '../../../../../../../../assets/images/Lander10/i-am-woman.png';
import LanderChat from '../../LanderChat/LanderChat';
import VerticalOption from '../../VerticalOption/VerticalOption';

export default function Gender({ onNextStep, setValue }) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = [{ content: 'Are you a man or a woman?' }];

  const handleGenderSelect = (gender) => {
    setValue('gender', gender);
    onNextStep();
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(!isElementVisible);
  };

  return (
    <>
      <LanderChat messages={messages} endChatAction={toggleElementVisibility} />
      <div className={isElementVisible ? 'Options ToggleElement' : 'Options Hidden'}>
        <VerticalOption
          title="I am a man"
          backgroundImage={manBgImage}
          onClick={() => handleGenderSelect('male')}
        />
        <VerticalOption
          title="I am a woman"
          backgroundImage={womanBgImage}
          onClick={() => handleGenderSelect('female')}
        />
      </div>
    </>
  );
}
