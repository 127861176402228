import './Username.scss';

import React, { useEffect } from 'react';

import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

export default function Username({
  setValue,
  errors,
  clearErrors,
  getValues,
  watch,
  landerId,
  setIsValid,
  isValid,
  handleNextStep,
  currentStep,
  isElementVisible,
  setIsElementVisible
}) {
  const username = watch('username');

  useEffect(() => {
    setIsValid(!!username);
  }, [username, setIsValid]);

  return (
    <>
      <div className={isElementVisible ? 'ToggleElement' : 'Hidden'}>
        <div className="InputsContainer">
          <Input
            placeholder="Username"
            name="username"
            value={getValues('username')}
            status={errors.username?.message ? 'error' : ''}
            onChange={(e) => {
              setValue('username', e.target.value);
              clearErrors('username');
            }}
            {...{
              errors,
              setValue,
              clearErrors
            }}
          />
          <Button
            handleNextStep={() => {
              handleNextStep([currentStep.field]);
              // setIsValid(false);
              // setIsElementVisible(false);
            }}
            text="Submit"
            disabled={!isValid || Object.keys(errors).length > 0}
          />
        </div>
      </div>
    </>
  );
}
