import './SignUpAff5sso.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@reach/router';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import * as yup from 'yup';

import LOGO from '../../../../assets/images/FunnelPage/Aff4LogoText.svg';
import BUTTONRED from '../../../../assets/images/FunnelPage/Aff5ButtonRed.png';
import BUTTONHEART from '../../../../assets/images/FunnelPage/Aff5ButtonRedHeart.png';
import BUTTONWHITE from '../../../../assets/images/FunnelPage/Aff5ButtonWhite.png';
import BUTTONPLUS from '../../../../assets/images/FunnelPage/Aff5ButtonWhitePlus.png';
import CHECKED from '../../../../assets/images/FunnelPage/Aff5Checked.png';
import PROFILEEIGHT from '../../../../assets/images/FunnelPage/Aff5ProfileEight.png';
import PROFILEFIVE from '../../../../assets/images/FunnelPage/Aff5ProfileFive.png';
import PROFILEFOUR from '../../../../assets/images/FunnelPage/Aff5ProfileFour.png';
import PROFILEGROUP from '../../../../assets/images/FunnelPage/Aff5ProfileGroup.png';
import PROFILEONE from '../../../../assets/images/FunnelPage/Aff5ProfileOne.png';
import PROFILESEVEN from '../../../../assets/images/FunnelPage/Aff5ProfileSeven.png';
import PROFILESIX from '../../../../assets/images/FunnelPage/Aff5ProfileSix.png';
import PROFILETHREE from '../../../../assets/images/FunnelPage/Aff5ProfileThree.png';
import PROFILETWO from '../../../../assets/images/FunnelPage/Aff5ProfileTwo.png';
import PROFILE from '../../../../assets/images/FunnelPage/aff5StepOne.png';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Form/Input/Input';
import ImageComponent from '../../../../components/ImageComponent/ImageComponent';
import { useEmailValidation } from '../../../../core/emailValidation/useEmailValidation';
import { userData as userDataNew } from '../../../../recoil/atoms/userDataAtom';
import { authMe, memberLogin } from '../../../../services/auth-service/auth.service';
import { showEmailVerificationInstruction } from '../../../../services/navigation-service/navigation.service';
import { saveToken } from '../../../../services/token-service/token.service';
import { setUserLocalData } from '../../../../services/user-service/user.service';
import { handleAuthReactivation } from '../../helpers/authReactivation';
import { InputNumber } from '../Inputs';
import ZipcodeInput from './FormInputs/ZipcodeInput';

let stylesZip = {
  opacity: '0.1',
  pointerEvents: 'none'
};

let stylesFinal = {
  position: 'relative',
  bottom: '31em'
};

let initialMessage = [
  { msg: 'Profile approved', loader: true },
  { msg: 'Active profiles in your area', loader: true },
  { msg: 'Women want to meet you', loader: true },
  { msg: 'Congratulations: account successful ', loader: true }
];

const schema = yup.object().shape({
  username: yup
    .string()
    .required('Username is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  password: yup
    .string()
    .required('Password is required!')
    .min(6, 'Must be between 6 to 16 characters')
    .max(16, 'Must be between 6 to 16 characters')
    .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters or empty space allowed'),
  // email: yup.string().required('Email is required!').email('Invalid Email Address'),
  age: yup
    .number()
    .typeError('Age is required!')
    .required('Age is required!')
    .test('len', 'You must be at least 18 years', (val) => val && val > 17),
  zip_code: yup
    .string()
    .required('Zip code is required!')
    .test('numbers', 'Zip code should only contain numbers', (val) => /^\d+$/.test(val))
    .test('len', 'Must be exactly 5 characters', (val) => val && val.trim().length === 5)
});

const SignUpAff5sso = () => {
  const [currentFormField, setCurrentFormField] = useState(1);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const clickID = urlParams.get('clickId');
  const affiliate = urlParams.get('affiliate');
  const [loadingZipCode, setLoadingZipCode] = useState(false);
  const [googleLogin, setGoogleLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const source = urlParams.get('source');
  const [checkedMsg, setCheckedMsg] = useState(initialMessage);
  const [checked, setChecked] = useState(false);
  const setUserData = useSetRecoilState(userDataNew);
  const { mutate: validateEmail, isLoading } = useEmailValidation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const handleNextStep = async (type, view) => {
    const isValid = await trigger(type);
    if (isValid) {
      setCurrentFormField(view);
    }
  };

  const handleNextStepTwoInput = (typeOne, typeTwo, view) => {
    trigger(typeOne)
      .then(() => trigger(typeTwo))
      .then(() => {
        if (Object.keys(errors).length === 0) {
          setCurrentFormField(view);
        }
      });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentFormField === 2) {
        setCurrentFormField(3);
      }
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [currentFormField]);

  const timers = useRef([]);
  const checkedMsgs = useRef(checkedMsg);

  useEffect(() => {
    const time = async () => {
      let timer;
      return new Promise((res) => {
        timer = setTimeout(() => {
          res(timer);
        }, 1000);
      });
    };

    async function main() {
      if (currentFormField === 16) {
        for (const message of checkedMsgs.current) {
          const timer = await time();
          timers.current.push(timer);

          const newCheckedMsgs = checkedMsgs.current.map((msg, i) => {
            let loader = true;
            const currentIndex = checkedMsgs.current.findIndex((m) => m.msg === message.msg);
            if (currentIndex >= i) {
              loader = false;
            }

            return { ...msg, loader };
          });
          setCheckedMsg(newCheckedMsgs);
          let checkMsg = newCheckedMsgs.filter((msg) => msg.loader === false);
          setChecked(checkMsg.length === 4 && true);
        }
      }
    }
    main();
    let newTimer = timers.current;
    return () => {
      newTimer.forEach((t) => clearTimeout(t));
    };
  }, [currentFormField]);

  const renderMediaContainer = () => {
    return (
      <>
        <div className="NavbarAff">
          <ImageComponent url={LOGO} />
        </div>
      </>
    );
  };

  const renderSpinnerContainer = () => {
    return (
      <div className="spinner-eff spinner-eff-3">
        <div className="circle circle-1"></div>
        <div className="circle circle-2"></div>
        <div className="circle circle-3"></div>
        <div className="circle circle-4"></div>
        <div className="circle circle-5"></div>
        <div className="circle circle-6"></div>
        <p>Finding bad girls nearby...</p>
      </div>
    );
  };
  const renderButtonsContainer = (step) => {
    return (
      <div className="ButtonHolder">
        <div className="IconHolder AffImageContainer" onClick={() => setCurrentFormField(step)}>
          <ImageComponent url={BUTTONWHITE} />
          <ImageComponent url={BUTTONPLUS} className="ProfilePlusIcon" />
        </div>
        <div className="IconHolder AffImageContainer" onClick={() => setCurrentFormField(step)}>
          <ImageComponent url={BUTTONRED} />
          <ImageComponent url={BUTTONHEART} className="ProfilePlusIcon" />
        </div>
      </div>
    );
  };
  const renderMediaImageContainer = (image, name, distance) => {
    return (
      <>
        <div className="Profileholder">
          <img src={image} alt="" />
          <p>
            <span>{name}</span> {distance}
          </p>
        </div>
      </>
    );
  };

  const renderCheckedContainer = (value) => {
    return (
      <div className="CheckedProfile">
        {value.loader ? <div className="Loader"></div> : <img src={CHECKED} alt="check" />}

        <span>{value.msg}</span>
      </div>
    );
  };

  const renderFinalStep = (stylesFinal) => {
    return (
      <div style={stylesFinal && stylesFinal}>
        <div className="StepOne">
          <div className="MultiContent">
            <div className="FinalHead">
              <p>You’ve Got Girls!</p>
            </div>
            <ImageComponent url={PROFILEGROUP} />
            <p>We found 64 bad girls within 10 miles of your location. Are you ready?</p>
            <div className="ButtonHolder Final">
              <Button onClick={() => setCurrentFormField(15)} text="Final Step!" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStepOne = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="StepOne">
          <div className="MultiContent">
            <p>You’re only a few steps away from local girls who are ready to fuck</p>
            <ImageComponent url={PROFILE} />
            <p>
              <span>Warning! This is not a dating site.</span> Turn back if you don’t want pussy.
            </p>
            <Button onClick={() => setCurrentFormField(2)} text="I’m ready to fuck!" />
          </div>
        </div>
      </div>
    );
  };

  const renderStepTwo = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="StepTwo">{renderSpinnerContainer()}</div>
      </div>
    );
  };

  const renderStepThree = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="MultiSteps">
          {renderMediaImageContainer(PROFILEONE, 'Holly, 23,', ' 4 Miles Away')}
          <div className="TextHolder">
            <p>Are you comfortable receiving explicit images on your device?</p>
          </div>
          {renderButtonsContainer(4)}
        </div>
      </div>
    );
  };

  const renderStepFour = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="MultiSteps">
          {renderMediaImageContainer(PROFILETWO, 'Barbara, 28,', ' 6 Miles Away')}
          <div className="TextHolder">
            <p>
              <span>
                Can you tell us more about your preferences...
                <br />
              </span>
              Do skinny girls make you hard?
            </p>
          </div>
          {renderButtonsContainer(5)}
        </div>
      </div>
    );
  };
  const renderStepFive = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="MultiSteps">
          {renderMediaImageContainer(PROFILETHREE, 'Carly, 24,', ' 1 Mile Away')}
          <div className="TextHolder">
            <p>
              <span>
                Can you tell us more about your preferences...
                <br />
              </span>
              Do you like huge boobs?
            </p>
          </div>
          {renderButtonsContainer(6)}
        </div>
      </div>
    );
  };

  const renderStepSix = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="MultiSteps">
          {renderMediaImageContainer(PROFILEFOUR, 'Alissa, 19,', ' 10 Miles Away')}
          <div className="TextHolder">
            <p>
              <span>
                Can you tell us more about your preferences...
                <br />
              </span>
              Do you like a juicy fat ass?
            </p>
          </div>
          {renderButtonsContainer(7)}
        </div>
      </div>
    );
  };
  const renderStepSeven = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="StepOne">
          <div className="MultiContent">
            <p>You’re close to seeing explicit images, please confirm you’re over 18 to continue</p>
            <div className="InputContainers">
              <InputNumber
                name="age"
                {...register('age')}
                placeholder="Age"
                errorMessage={errors?.age?.message}
              />
            </div>
            <div className="ButtonHolder">
              <Button onClick={() => handleNextStep('age', 8)} text="CONTINUE" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderStepEight = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="MultiSteps">
          {renderMediaImageContainer(PROFILEFIVE, 'Simone, 25,', ' 3 Miles Away')}
          <div className="TextHolder">
            <p>
              What type of pussy do you like...
              <br />
              Black?
            </p>
          </div>
          {renderButtonsContainer(9)}
        </div>
      </div>
    );
  };

  const renderStepNine = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="MultiSteps">
          {renderMediaImageContainer(PROFILESIX, 'Jessica, 29,', ' 2 Miles Away')}
          <div className="TextHolder">
            <p>
              What type of pussy do you like...
              <br />
              White?
            </p>
          </div>
          {renderButtonsContainer(10)}
        </div>
      </div>
    );
  };

  const renderStepTen = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="MultiSteps">
          {renderMediaImageContainer(PROFILESEVEN, 'Azia, 27,', ' 3 Miles Away')}
          <div className="TextHolder">
            <p>
              What type of pussy do you like...
              <br />
              Asian?
            </p>
          </div>
          {renderButtonsContainer(11)}
        </div>
      </div>
    );
  };

  const renderStepEleven = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="StepOne">
          <div className="MultiContent">
            <p>Almost done. To start meeting local women, secure your account with a password</p>
            <div className="InputContainers">
              <Input
                register={register}
                name="username"
                placeholder="Username"
                errorMessage={errors?.username?.message}
                onChange={(e) => {
                  setValue('username', e.target.value);
                  clearErrors('username');
                  clearErrors('email');
                }}
                // onKeyPress={handleEnterClicked}
              />
              <Input
                register={register}
                name="password"
                placeholder="Password"
                errorMessage={errors?.password?.message}
                onChange={(e) => {
                  setValue('password', e.target.value);
                  clearErrors('password');
                }}
                type="password"
              />
            </div>
            <div className="ButtonHolder">
              <Button
                onClick={() => handleNextStepTwoInput('username', 'password', 12)}
                text="CONTINUE"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStepTwelve = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="MultiSteps">
          {renderMediaImageContainer(PROFILEEIGHT, 'Sophie, 20,', ' 12 Miles Away')}
          <div className="TextHolder">
            <p>
              This site is for hook ups only, the girls <br />
              don’t want relationships, are you sure <br />
              you’re okay with this?
              <br />
            </p>
          </div>
          {renderButtonsContainer(13)}
        </div>
      </div>
    );
  };

  const renderStepThirteen = (stylesZip) => {
    return (
      <div style={stylesZip && stylesZip}>
        {renderMediaContainer()}
        <div className="StepOne">
          <div className="MultiContent">
            <p>We want to find naughty women closest to you, where do you live?</p>
            <div className="InputContainers">
              <ZipcodeInput
                register={register}
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                clearErrors={clearErrors}
                errors={errors}
                setLoadingZipCode={setLoadingZipCode}
              />
            </div>
            <div className="ButtonHolder">
              <Button
                onClick={() => handleNextStep('zip_code', 14)}
                text={loadingZipCode ? 'Loading...' : 'Continue'}
                className={loadingZipCode ? 'DisabledButton' : ''}
                disabled={loadingZipCode}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStepFourteen = () => {
    return (
      <div>
        {renderStepThirteen(stylesZip)}
        {renderFinalStep(stylesFinal)}
      </div>
    );
  };

  const responseGoogle = (res) => {
    // console.log('resGoogle ==> ', res);
    // setValue('email', res.profileObj?.email);
    let userObject = jwt_decode(res.credential);
    setValue('email', userObject?.email);
    setGoogleLogin(true);
    // handleNextStep('email', 16);
    emailValidation(true);
    // document.getElementById("submitFormRef").click()
  };

  const emailValidation = (isSSO = false) => {
    const username = getValues('username');
    const password = getValues('password');
    const age = getValues('age');
    const birthYear = new Date().getFullYear() - age;
    const gender = getValues('gender');
    const seeking = getValues('seeking');
    const city = getValues('city');
    const email = getValues('email');
    const zip_code = getValues('zip_code');
    const landerId = isSSO ? 5 : '5typed_email';
    const data = {
      email,
      username,
      password,
      password_confirmation: password,
      click_id: clickID,
      affiliate_id: affiliate,
      lander_id: landerId,
      profile: {
        age: age,
        ...(age ? { birthday: `${birthYear}-01-01` } : {}),
        city: city,
        zip_code: zip_code,
        seeking: seeking,
        gender: gender
      },
      ...(source ? { source_id: source } : {})
    };
    validateEmail(data, {
      onSuccess: (res) => {
        handleAuthReactivation(res, email, navigate, () => {
          setValue('email', getValues('email'));
          clearErrors('email');
          handleNextStep('email', 16);
        });
      },
      onError: (err) => {
        setError('email', {
          type: 'manual',
          message: err.data.errors.email[0]
        });
      }
    });
  };

  const renderStepFifteen = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="StepOne">
          <div className="MultiContent">
            <div className="FinalHead">
              <p>
                <span>100% </span>
                <br /> FREE SIGN UP
              </p>
            </div>
            <p>
              We saved all your preferences. Now just enter your email below to start meeting local
              girls
            </p>
            <div className="InputContainers">
              <Input
                register={register}
                name="email"
                placeholder="johndoe@gmail.com"
                errorMessage={errors?.email?.message}
                onChange={(e) => {
                  setValue('email', e.target.value);
                  clearErrors('email');
                }}
                onKeyDown={(e) => {
                  setGoogleLogin(false);
                  if (e.keyCode === 32) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div className="TextContainer">OR</div>
            <div className="GoogleLoginContainer">
              <GoogleLogin
                text="Register with Google"
                width="100%"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                className="GoogleLoginButton"
              />
            </div>
            <div className="ButtonHolder">
              <Button
                onClick={emailValidation}
                text="START FUCKING NOW!"
                disabled={isLoading}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderStepSixteen = () => {
    return (
      <div>
        {renderMediaContainer()}
        <div className="StepOne">
          <div className="MultiContent">
            <div className="FinalHead">
              <p>Account Creating…</p>
            </div>
            {checkedMsg.map((value) => renderCheckedContainer(value))}
            <div className="ButtonHolder" style={!checked ? stylesZip : {}}>
              <Button
                onClick={submitForm}
                text={loading ? 'Loading...' : 'Click Here to Start Fucking!'}
                className={loading ? 'DisabledButton' : ''}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onSubmit = (data) => {
    setLoading(true);
    const birthYear = new Date().getFullYear() - data.age;
    const landerId = googleLogin ? 5 : '5typed_email';
    let registerData = {};
    if (source) {
      registerData = {
        username: data.username,
        email: data.email,
        password: data.password,
        password_confirmation: data.password,
        terms_and_conditions: 1,
        click_id: clickID,
        affiliate_id: affiliate,
        source_id: source,
        lander_id: landerId,
        profile: {
          city: data.city,
          zip_code: data.zip_code,
          birthday: `${birthYear}-01-01`,
          age: data.age
        }
      };
    } else {
      registerData = {
        username: data.username,
        email: data.email,
        password: data.password,
        password_confirmation: data.password,
        terms_and_conditions: 1,
        click_id: clickID,
        affiliate_id: affiliate,
        lander_id: landerId,
        profile: {
          city: data.city,
          zip_code: data.zip_code,
          birthday: `${birthYear}-01-01`,
          age: data.age
        }
      };
    }

    axios
      .post('/api/auth/member/register', registerData, {
        headers: {
          doNotAddUserTokenAuthorization: true
        }
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          localStorage.setItem('current-email', data.email);
          localStorage.setItem('affiliateSiteLockdown', new Date());
          // memberLogin(registerData).then((res) =>
          //   saveToken(res.data.access_token, res.data.expires_in)
          // );
          // showEmailVerificationInstruction();
          const loginData = {
            username: res.data.user.username,
            email: res.data.user.email,
            password: registerData.password
          };
          memberLogin(loginData)
            .then((res) => {
              setLoading(true);
              saveToken(res.data.access_token, res.data.expires_in);
              localStorage.setItem('userData', JSON.stringify(res.data.user));
              localStorage.setItem('affiliateSiteLockdown', new Date());
              googleLogin
                ? authMe(res.data.access_token)
                    .then((response) => {
                      setLoading(false);
                      setUserLocalData(response.data);
                      setUserData(response.data);
                      window.location.href = '/members-area';
                    })
                    .catch(() => {
                      setLoading(false);
                    })
                : showEmailVerificationInstruction();
              // setUserLocalData(res.data.user);
            })
            .catch(() => {
              setLoading(false);
            });
        } else {
          throw new Error('Something went wrong!');
        }
      })
      .catch((err) => {
        setLoading(false);
        setGoogleLogin(false);
        const data = err.response.data.errors;
        Object.keys(err.response.data.errors).forEach((name) => {
          let message = data[name][0];
          setError(name, { type: 'manual', message });
          // if (name === "username") {
          //   setCurrentFormField(11);
          // }
          if (name === 'email') {
            setCurrentFormField(15);
          }
        });
      });
  };

  const submitForm = (e) => {
    handleSubmit((data) => onSubmit(data))(e);
  };
  return (
    <div className="SignUpAff5sso">
      {/*<Helmet>
        <script>localStorage.setItem("accGuid", "2abd06aa-ef0d-449f-ac71-bd12413dace8")</script>
        <script src="https://pushcity1.xyz/ace-push.js" defer></script>
      </Helmet>*/}
      <form className="form" onSubmit={(e) => e.preventDefault(e)}>
        {currentFormField === 1 && renderStepOne()}
        {currentFormField === 2 && renderStepTwo()}
        {currentFormField === 3 && renderStepThree()}
        {currentFormField === 4 && renderStepFour()}
        {currentFormField === 5 && renderStepFive()}
        {currentFormField === 6 && renderStepSix()}
        {currentFormField === 7 && renderStepSeven()}
        {currentFormField === 8 && renderStepEight()}
        {currentFormField === 9 && renderStepNine()}
        {currentFormField === 10 && renderStepTen()}
        {currentFormField === 11 && renderStepEleven()}
        {currentFormField === 12 && renderStepTwelve()}
        {currentFormField === 13 && renderStepThirteen()}
        {currentFormField === 14 && renderStepFourteen()}
        {currentFormField === 15 && renderStepFifteen()}
        {currentFormField === 16 && renderStepSixteen()}
      </form>
    </div>
  );
};

export default SignUpAff5sso;
