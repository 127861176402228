import { useState } from 'react';

import manBgImageBlue from '../../../../../../../../assets/images/Lander14/blue/gender/i-am-man.png';
import womanBgImageBlue from '../../../../../../../../assets/images/Lander14/blue/gender/i-am-woman.png';
import manBgImageLight from '../../../../../../../../assets/images/Lander14/light/gender/i-am-man.png';
import womanBgImageLight from '../../../../../../../../assets/images/Lander14/light/gender/i-am-woman.png';
import manBgImagePink from '../../../../../../../../assets/images/Lander14/pink/gender/i-am-man.png';
import womanBgImagePink from '../../../../../../../../assets/images/Lander14/pink/gender/i-am-woman.png';
import manBgImageRed from '../../../../../../../../assets/images/Lander14/red/gender/i-am-man.png';
import womanBgImageRed from '../../../../../../../../assets/images/Lander14/red/gender/i-am-woman.png';
import LanderChat from '../../LanderChat/LanderChat';
import VerticalOption from '../../VerticalOption/VerticalOption';

export default function Gender({ watch, setValue, setIsValid, landerId }) {
  const [isElementVisible, setIsElementVisible] = useState(false);

  const messages = {
    '14-1': [{ content: 'Nice! I like this very much. Please tell me your gender?' }],
    '14-2': [{ content: 'Very nice. And what is your gender?' }],
    '14-3': [{ content: 'Very nice. And what is your gender?' }],
    '14-4': [{ content: 'Very nice. And what is your gender?' }]
  };

  const avatars = {
    '14-1': {
      male: manBgImageRed,
      female: womanBgImageRed
    },
    '14-2': {
      male: manBgImageBlue,
      female: womanBgImageBlue
    },
    '14-3': {
      male: manBgImagePink,
      female: womanBgImagePink
    },
    '14-4': {
      male: manBgImageLight,
      female: womanBgImageLight
    }
  };

  const gender = watch('gender');

  const toggleElementVisibility = () => {
    setIsElementVisible(true);
  };

  const handleOptionClick = (option) => {
    setValue('gender', option);
    setIsValid(true);
  };

  return (
    <div className="GenderContainer">
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />
      <div
        className={
          isElementVisible
            ? 'ToggleElement GenderOptions SelectOptions'
            : 'Hidden GenderOptions SelectOptions'
        }
      >
        <VerticalOption
          title="I am a Man"
          backgroundImage={avatars[`${landerId}`].male}
          onClick={() => handleOptionClick('male')}
          isActive={gender === 'male'}
        />
        <VerticalOption
          title="I am a Woman"
          backgroundImage={avatars[`${landerId}`].female}
          onClick={() => handleOptionClick('female')}
          isActive={gender === 'female'}
        />
      </div>
    </div>
  );
}
