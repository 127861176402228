import './ProfileNav.scss';

import { Link } from '@reach/router';
import React, { useEffect, useState } from 'react';

import { MEMBERS_ROUTES } from '../../../members_routes';

const ProfileNav = (props) => {
  const [activeProfile, setActiveProfile] = useState(true);
  const [activePictures, setActivePicture] = useState(false);
  const [activeSettings, setActiveSettings] = useState(false);

  const seeUrl = () => {
    if (window.location.pathname === '/members-area/my-profile/profile') {
      setActiveProfile(true);
      setActivePicture(false);
      setActiveSettings(false);
    }
    if (window.location.pathname === '/members-area/my-profile/pictures') {
      setActiveProfile(false);
      setActivePicture(true);
      setActiveSettings(false);
    }
    if (window.location.pathname === '/members-area/my-profile/settings') {
      setActiveProfile(false);
      setActivePicture(false);
      setActiveSettings(true);
    }
  };
  useEffect(() => {
    seeUrl();
  }, []);

  return (
    <div className="Profile-Nav">
      <Link to={`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.PROFILE}`}>
        <span onClick={() => seeUrl()} className={activeProfile ? 'Selected' : ''}>
          Profile
        </span>
      </Link>

      <Link to={`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.PROFILEPICTURES}`}>
        <span onClick={() => seeUrl()} className={activePictures ? 'Selected' : ''}>
          Pictures
        </span>
      </Link>
      <Link to={`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.PROFILESETTINGS}`}>
        <span onClick={() => seeUrl()} className={activeSettings ? 'Selected' : ''}>
          Settings
        </span>
      </Link>
      {props.children}
    </div>
  );
};

export default ProfileNav;
