import './TipInfoComponent.scss';

import TIP from '../../../assets/images/MembersArea/amber.jpg';

interface tipData {
  message: string | JSX.Element;
}

export const TipInfoComponent = ({ message }: tipData) => {
  return (
    <div className="tip-info-container">
      <div className="image-box">
        <img onContextMenu={(e) => e.preventDefault()} src={TIP} alt="tip" />
      </div>
      <div className="tip-text">{message}</div>
    </div>
  );
};
