export const MEMBERS_ROUTES = {
  MEMBERSAREA: '/members-area',
  MESSAGES: '/messages',
  MESSAGESNEW: '/messagesnew',
  MEMBERPROFILE: '/member-profile',
  BROWSEPROFILES: '/browse-profiles',
  MATCHGAME: '/match-game',
  MYMATCHES: '/my-matches',
  MYFAVORITES: '/my-favorites',
  VISITORS: '/visitors',
  CREDITS: '/credits',
  MYPROFILE: '/my-profile',
  MYPROFILEV2: '/my-profile-v2',
  PROFILE: '/my-profile/profile',
  PROFILEPICTURES: '/my-profile/pictures',
  PROFILESETTINGS: '/my-profile/settings',
  MEMBERSPRICING: '/members-pricing',
  ABOUT: '/about-us',
  FAQ: '/FAQ',
  PRICING: '/pricing',
  CONTACTUS: '/contact-us',
  TERMS: '/terms-and-conditions',
  PRIVACY: '/privacy-policy',
  PAYMENT: '/payment'
};
