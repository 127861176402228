import * as actionTypes from '../types/paymentNotification';

const initialState = '';

const paymentNotification = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAYMENT_NOTIFICATION:
      return action.payload;
    default:
      return state;
  }
};

export default paymentNotification;
