import './MyProfile.scss';
import '../../navigation.service';

import { Router } from '@reach/router';
import React from 'react';

import { MEMBERS_ROUTES } from '../../members_routes';
import Pictures from './Pictures/Pictures';
import Profile from './Profile/Profile';
import Settings from './Settings/Settings';

const MyProfile = () => {
  return (
    <div className="My-Profile">
      <Router>
        <Profile default path={`${MEMBERS_ROUTES.PROFILE}`} />
        <Pictures path={`${MEMBERS_ROUTES.PROFILEPICTURES}`} />
        <Settings path={`${MEMBERS_ROUTES.PROFILESETTINGS}`} />
      </Router>
    </div>
  );
};

export default MyProfile;
