import './Lander14.scss';

import { useEffect, useState } from 'react';

import logo from '../../../../../../assets/images/Lander11/logo.png';
import loaderImg from '../../../../../../assets/images/Lander14/loader.gif';
import Button from '../Lander14/UI/Button/Button';
import generateSteps from './data/steps';
import { FallbackProvider } from './FallbackProvider';

const hasOption = (slug) => {
  const regex = new RegExp(`/14-(1|2|3|4)/.*${slug}`);
  return regex.test(window.location.href);
};

export default function Lander14(props) {
  const { nextStep, currentFormField, submitForm, handleNextStep, errors, landerId } = props;
  const [isValid, setIsValid] = useState(false);

  const options = {
    autogenerateUsername: hasOption('nousr'),
    autogeneratePassword: hasOption('nopswd'),
    hideLocation: hasOption('noloc'),
    hasSSO: hasOption('sso')
  };

  const steps = generateSteps(options);
  const isLastStep = currentFormField >= steps.length + 1;
  const landerVersionClassName = `LanderContainer14 Version${landerId}`;

  useEffect(() => {
    if (isLastStep) {
      submitForm();
    }
  }, [currentFormField]);

  const currentStep = steps[Math.min(currentFormField, steps.length) - 1];
  const { component: CurrentStep } = currentStep || {};

  return (
    <div className={landerVersionClassName}>
      {isLastStep && (
        <div className="LoadingOverlay">
          <img src={loaderImg} alt="Loader" />
        </div>
      )}
      <form
        className="LanderContent"
        onSubmit={(e) => {
          e.preventDefault();

          if (currentStep.field) {
            handleNextStep([currentStep.field]);
          }
        }}
      >
        <div>
          <img src={logo}></img>
          <span>“You are in my inappropriate thoughts!”</span>
        </div>
        <div>
          <FallbackProvider>
            {CurrentStep && (
              <>
                <div className="LanderContentInner14">
                  <CurrentStep
                    onNextStep={nextStep}
                    handleNextStep={handleNextStep}
                    setIsValid={setIsValid}
                    options={options}
                    {...props}
                  />
                  <Button
                    handleNextStep={() => {
                      handleNextStep([currentStep.field]);
                      setIsValid(false);
                    }}
                    text="Submit"
                    disabled={!isValid || Object.keys(errors).length > 0}
                  />
                </div>
                <div className="ProgressBar">
                  <div className="Percentage" style={{ width: `${currentStep?.percent || 0}%` }}>
                    <span>{currentStep?.percent}% Completed</span>
                  </div>
                </div>
              </>
            )}
          </FallbackProvider>
        </div>
      </form>
    </div>
  );
}
