import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';

export interface MatchProfile {
  age: number;
  birthday: string;
  id: number;
  profile_status: string;
  public_avatar_url: string;
  username: string;
  is_new: boolean;
}
export const key = ['MATCH_GAME'];
export const useGetMatch = ({ filter }: { filter: any }) =>
  useQuery(
    [...key, JSON.stringify(filter)],
    async (): Promise<MatchProfile> => {
      const ageFilter = encodeURIComponent(filter.age_between);
      const { data } = await axiosInstance.get('api/v2/profiles/get-matched-profile', {
        params: {
          body_type: filter.body_type,
          hair_color: filter.hair_color,
          ...(filter.age_between ? { age_between: decodeURIComponent(ageFilter) } : {})
        }
      });
      return data.data;
    },
    {
      refetchOnWindowFocus: false
    }
  );
