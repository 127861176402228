import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';
import { AboutMeData } from '../../model/interfaces/AboutMeData';

export const key = ['ENUM_ABOUT_ME'];

export const useInterfaceAboutMeData = () =>
  useQuery([...key], async (): Promise<AboutMeData> => {
    const { data } = await axiosInstance.get('api/profiles/enum');
    return data;
  });
