export const messages = {
  '15-1': {
    1: [
      {
        content:
          'Hi...My name is Maria, and I want to take you through interesting journey with sexy questions... And guess what! You will connect with plenty of sexy women.'
      },
      {
        content: "Let's start!"
      },
      {
        content: 'What do you want?'
      }
    ],
    2: [
      {
        content: 'That’s nice! I am also into it!'
      },
      {
        content: 'Now tell me are you a man or a woman?'
      }
    ],
    3: [
      {
        content: 'Mmm, interesting! Are you interested in:'
      }
    ],
    4: [
      { content: 'Well that`s sexy!' },
      {
        content: 'Great! Now tell me sweetie, how old are you?'
      }
    ],
    5: [
      {
        content: 'Neat! What woman’s body type do you prefer?'
      }
    ],
    6: [
      {
        content: 'Lovely! Where do you live?'
      }
    ],
    7: [
      {
        content: 'We are near here... Please enter your Username'
      }
    ],
    8: [
      {
        content: 'Cool Username! And how about your password?'
      }
    ],
    9: [
      {
        content: 'Well done! Enter your email and I will inform you about new matches now.'
      }
    ]
  },
  '15-2': {
    1: [
      {
        content:
          'Hi...My name is Evelyn, and I want to take you through interesting journey with sexy questions... And gues what! You will connect with plenty of sexy women.'
      },
      {
        content: "Let's start!"
      },
      {
        content: 'What do you want?'
      }
    ],
    2: [
      {
        content: 'That’s nice! I am also into it!'
      },
      {
        content: 'Now tell me are you a man or a woman?'
      }
    ],
    3: [
      {
        content: 'Mmm, interesting! Are you interested in:'
      }
    ],
    4: [
      { content: 'Well that`s sexy!' },
      {
        content: 'Great! Now tell me sweetie, how old are you?'
      }
    ],
    5: [
      {
        content: 'Neat! What woman’s body type do you prefer?'
      }
    ],
    6: [
      {
        content: 'Lovely! Where do you live?'
      }
    ],
    7: [
      {
        content: 'We are near here... Please enter your Username'
      }
    ],
    8: [
      {
        content: 'Cool Username! And how about your password?'
      }
    ],
    9: [
      {
        content: 'Well done! Enter your email and I will inform you about new matches now.'
      }
    ]
  },
  '15-3': {
    1: [
      {
        content:
          'Hi...My name is Elizabeth, and I want to take you through interesting journey with sexy questions... And gues what! You will connect with plenty of sexy women.'
      },
      {
        content: "Let's start!"
      },
      {
        content: 'What do you want?'
      }
    ],
    2: [
      {
        content: 'That’s nice! I am also into it!'
      },
      {
        content: 'Now tell me are you a man or a woman?'
      }
    ],
    3: [
      {
        content: 'Mmm, interesting! Are you interested in:'
      }
    ],
    4: [
      { content: 'Well that`s sexy!' },
      {
        content: 'Great! Now tell me sweetie, how old are you?'
      }
    ],
    5: [
      {
        content: 'Neat! What woman’s body type do you prefer?'
      }
    ],
    6: [
      {
        content: 'Lovely! Where do you live?'
      }
    ],
    7: [
      {
        content: 'We are near here... Please enter your Username'
      }
    ],
    8: [
      {
        content: 'Cool Username! And how about your password?'
      }
    ],
    9: [
      {
        content: 'Well done! Enter your email and I will inform you about new matches now.'
      }
    ]
  },
  '15-4': {
    1: [
      {
        content:
          'Hi...My name is Mia, and I want to take you through interesting journey with sexy questions... And gues what! You will connect with plenty of sexy women.'
      },
      {
        content: "Let's start!"
      },
      {
        content: 'What do you want?'
      }
    ],
    2: [
      {
        content: 'That’s nice! I am also into it!'
      },
      {
        content: 'Now tell me are you a man or a woman?'
      }
    ],
    3: [
      {
        content: 'Mmm, interesting! Are you interested in:'
      }
    ],
    4: [
      { content: 'Well that`s sexy!' },
      {
        content: 'Great! Now tell me sweetie, how old are you?'
      }
    ],
    5: [
      {
        content: 'Neat! What woman’s body type do you prefer?'
      }
    ],
    6: [
      {
        content: 'Lovely! Where do you live?'
      }
    ],
    7: [
      {
        content: 'We are near here... Please enter your Username'
      }
    ],
    8: [
      {
        content: 'Cool Username! And how about your password?'
      }
    ],
    9: [
      {
        content: 'Well done! Enter your email and I will inform you about new matches now.'
      }
    ]
  }
};
