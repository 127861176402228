import { Modal } from 'antd';
import React from 'react';

import MatchNote from '../../../../../../components/modals/MatchNote/MatchNote';
import { MatchProfile } from '../../../../../../V2/services/matchGame/useGetMatch';

interface MatchModalProps {
  open: boolean;
  onClose: () => void;
  data: MatchProfile;
  keepPlaying: () => void;
}
export const MatchModal = ({ open, onClose, data, keepPlaying }: MatchModalProps) => {
  return (
    <Modal
      open={open}
      style={{ padding: 0 }}
      className={'match-modal'}
      footer={null}
      onCancel={onClose}
    >
      <MatchNote
        matchProfile={data}
        closeModal={onClose}
        matchName={data?.username}
        keepPlaying={keepPlaying}
      />
    </Modal>
  );
};
