import './GoogleLoginButton.scss';

import { GooglePlusOutlined } from '@ant-design/icons';
import { useGoogleLogin } from '@react-oauth/google';

const GoogleLoginButton = ({
  text,
  handleGoogleLogin,
  className,
  icon: Icon = GooglePlusOutlined
}) => {
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userObject = await new Promise((resolve) => {
        const xhr = new XMLHttpRequest();

        xhr.open('GET', `https://www.googleapis.com/oauth2/v3/userinfo`);
        xhr.setRequestHeader('Authorization', `Bearer ${tokenResponse.access_token}`);
        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) resolve(JSON.parse(this.responseText));
          else resolve({ err: '404' });
        };
        xhr.send();
      });

      handleGoogleLogin(userObject);
    },
    onError: (error) => handleGoogleLogin(error)
  });

  return (
    <div className={className || 'RedGoogleLoginButton'} onClick={() => login()}>
      <Icon /> <span>{text}</span>
    </div>
  );
};

export default GoogleLoginButton;
