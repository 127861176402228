import { useEffect } from 'react';

import { queryClient } from '../App';

const usePullToRefresh = (ref, onTrigger, isFetching, isMobile) => {
  useEffect(() => {
    const el = ref.current;
    if (!el) return;
    const TRIGGER_THRESHOLD = 100;

    const handleTouchStart = (startEvent) => {
      const el = ref.current;
      if (!el) return;
      const initialY = startEvent.touches[0].clientY;

      const handleTouchMove = (moveEvent) => {
        const el = ref.current;
        if (!el) return;
        const currentY = moveEvent.touches[0].clientY;
        const dy = currentY - initialY;
        el.style.transform = `translateY(${appr(dy)}px)`;
      };
      const MAX = 128;
      const k = 0.4;
      const appr = (x) => {
        return MAX * (1 - Math.exp((-k * x) / MAX));
      };

      const handleTouchEnd = (endEvent) => {
        const el = ref.current;
        if (!el) return;
        el.style.transform = 'translateY(0)';
        el.style.transition = 'transform 0.2s';
        const y = endEvent.changedTouches[0].clientY;
        const dy = y - initialY;
        if (dy > TRIGGER_THRESHOLD) {
          queryClient.invalidateQueries(onTrigger);
        }
        el.addEventListener('transitionend', onTransitionEnd);
        el.removeEventListener('touchmove', handleTouchMove);
        el.removeEventListener('touchend', handleTouchEnd);
      };
      el.addEventListener('touchmove', handleTouchMove);
      el.addEventListener('touchend', handleTouchEnd);

      const onTransitionEnd = () => {
        const el = ref.current;
        if (!el) return;
        el.style.transition = '';
        el.removeEventListener('transitionend', onTransitionEnd);
      };
    };
    isMobile && el.addEventListener('touchstart', handleTouchStart);

    const addPullIndicator = (el) => {
      const pullIndicator = document.createElement('div');
      pullIndicator.className = 'pull-indicator';
      pullIndicator.style.padding = '20px';
      pullIndicator.style.textAlign = 'center';
      pullIndicator.innerHTML = `<div className="LoaderComponent" style="
        height: 0;
        width: 0;
        padding: 15px;
        border: 4px solid #cbcbcb;
        border-right-color: #d41411;
        border-radius: 22px;
        animation: rotate 0.5s infinite linear;
        margin: auto;
      "></div>`;
      el.insertBefore(pullIndicator, el.firstChild);
    };

    const removePullIndicator = (el) => {
      const pullIndicator = el.querySelector('.pull-indicator');
      if (pullIndicator) {
        pullIndicator.remove();
      }
    };
    isFetching && isMobile ? addPullIndicator(ref.current) : removePullIndicator(ref.current);

    return () => {
      el.removeEventListener('touchstart', handleTouchStart);
    };
  }, [ref, isFetching]);
};

export default usePullToRefresh;
