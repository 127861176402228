import './Pricing.scss';

import React from 'react';

import HEADERIMAGE from '../../../../../assets/images/HeadersImages/Pricing-Header.png';
import HeaderLayout from '../../../../../components/HeaderLayout/HeaderLayout';
import { CreditsCard } from '../../../../../V2/components/shared/CreditsCard/CreditsCard';
import { useGetAllPackages } from '../../../../../V2/services/packages/useGetAllPackages';

const Pricing = () => {
  const title = 'Pricing';
  const description = 'Credits are only required to message other profiles';
  const image = HEADERIMAGE;
  const { data } = useGetAllPackages();

  const renderPackages = (isSpecial) => {
    return data
      ?.sort((a, b) => b.starter - a.starter)
      ?.filter((item) => item.is_special_offer === isSpecial)
      .map((pkg) => (
        <CreditsCard
          redirectUrl={'/'}
          packageDef={pkg}
          key={pkg.id}
          trigger={'credits_page'}
          noBuy={true}
        />
      ));
  };

  return (
    <div className="Pricing">
      <HeaderLayout
        title={title}
        description={description}
        image={image}
        className={'PricingImage'}
      />
      <div className="Pricing-Container ">
        <div className="Pricing-Content">
          <p>
            Access to LocalFlirt is free, howevever, to send messages to other profiles, you must
            first purchase credits. Each credit is used as a message token. The more credits you
            purchase, the better value it is per message. And trust us, you’ll be flirting fast and
            furious, so credit up!
          </p>
          <p className="Credits-Info">
            The credits are sold in several packages, current pricing is below:
          </p>
        </div>
        <div className={'pricing-page'}>{renderPackages(0)}</div>
      </div>
    </div>
  );
};

export default Pricing;
