import './ReceivedMessage.scss';

import parse from 'html-react-parser';
import React from 'react';

import ImageNPreview from '../../../../../../../../../components/ImageNPreview/ImageNPreview';

const SentTime = (props) => {
  const { messageTime } = props;

  if (messageTime) {
    const time = new Date(messageTime);
    return (
      <div className="ReceivedTime">
        {time.getHours()}:{time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()}
      </div>
    );
  }
  return null;
};

const stringToHTML = (text) => {
  var parser = new DOMParser();
  var doc = parser.parseFromString(text, 'text/html');
  return parse(doc.body.innerHTML);
};

const ReceivedMessage = (props) => {
  const { text, image, messageTime } = props;

  return (
    <div className="ReceivedMessageContainer">
      <div className="ReceivedMessage">
        <div className="Message">
          {image && <ImageNPreview name={image} nameFullSize={image} />}
          {text ? stringToHTML(text) : ''}
          {messageTime && <SentTime messageTime={messageTime} />}
        </div>
      </div>
    </div>
  );
};

export default ReceivedMessage;
