import { useState } from 'react';

import casualSexBlue from '../../../../../../../../assets/images/Lander14/blue/relationship/casual-sex.png';
import coupleOfDatesBlue from '../../../../../../../../assets/images/Lander14/blue/relationship/couple-of-dates.png';
import seriousRelationshipBlue from '../../../../../../../../assets/images/Lander14/blue/relationship/serious-relationships.png';
import casualSexLight from '../../../../../../../../assets/images/Lander14/light/relationship/casual-sex.png';
import coupleOfDatesLight from '../../../../../../../../assets/images/Lander14/light/relationship/couple-of-dates.png';
import seriousRelationshipLight from '../../../../../../../../assets/images/Lander14/light/relationship/serious-relationships.png';
import casualSexPink from '../../../../../../../../assets/images/Lander14/pink/relationship/casual-sex.png';
import coupleOfDatesPink from '../../../../../../../../assets/images/Lander14/pink/relationship/couple-of-dates.png';
import seriousRelationshipPink from '../../../../../../../../assets/images/Lander14/pink/relationship/serious-relationships.png';
import casualSexRed from '../../../../../../../../assets/images/Lander14/red/relationship/casual-sex.png';
import coupleOfDatesRed from '../../../../../../../../assets/images/Lander14/red/relationship/couple-of-dates.png';
import seriousRelationshipRed from '../../../../../../../../assets/images/Lander14/red/relationship/serious-relationships.png';
import LanderChat from '../../LanderChat/LanderChat';
import VerticalOption from '../../VerticalOption/VerticalOption';

export default function Relationship({ setIsValid, landerId }) {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const [activeOption, setActiveOption] = useState(null);

  const messages = {
    '14-1': [
      {
        content: 'Hello, I’m Lidia, nice to meet you! What do you want:'
      }
    ],
    '14-2': [
      {
        content: 'Hi, I’m Laura! You came here for what for:'
      }
    ],
    '14-3': [
      {
        content: 'Hi, I’m Laura! You came here for what for:'
      }
    ],
    '14-4': [
      {
        content: 'Hi, I’m Laura! You came here for what for:'
      }
    ]
  };

  const avatars = {
    '14-1': {
      casualSexBg: casualSexRed,
      coupleOfDates: coupleOfDatesRed,
      seriousRelationship: seriousRelationshipRed
    },
    '14-2': {
      casualSexBg: casualSexBlue,
      coupleOfDates: coupleOfDatesBlue,
      seriousRelationship: seriousRelationshipBlue
    },
    '14-3': {
      casualSexBg: casualSexPink,
      coupleOfDates: coupleOfDatesPink,
      seriousRelationship: seriousRelationshipPink
    },
    '14-4': {
      casualSexBg: casualSexLight,
      coupleOfDates: coupleOfDatesLight,
      seriousRelationship: seriousRelationshipLight
    }
  };

  const toggleElementVisibility = () => {
    setIsElementVisible(true);
  };

  const handleOptionClick = (option) => {
    setActiveOption(option);
    setIsValid(true);
  };

  return (
    <div className="RelationshipContainer">
      <LanderChat
        messages={messages[`${landerId}`]}
        endChatAction={toggleElementVisibility}
        landerId={landerId}
      />
      <div
        className={isElementVisible ? 'ToggleElement SelectOptions' : 'Hidden RelationshipOptions'}
      >
        <VerticalOption
          title="Casual Sex"
          backgroundImage={avatars[`${landerId}`].casualSexBg}
          onClick={() => handleOptionClick('Casual Sex')}
          isActive={activeOption === 'Casual Sex'}
        />
        <VerticalOption
          title="Couple of dates"
          backgroundImage={avatars[`${landerId}`].coupleOfDates}
          onClick={() => handleOptionClick('Couple of dates')}
          isActive={activeOption === 'Couple of dates'}
        />
        <VerticalOption
          title="Serious Relationships"
          backgroundImage={avatars[`${landerId}`].seriousRelationship}
          onClick={() => handleOptionClick('Serious Relationships')}
          isActive={activeOption === 'Serious Relationships'}
        />
      </div>
    </div>
  );
}
