import './VerifyAccount.scss';

import { Checkbox, Form, Input } from 'antd';
import React, { useState } from 'react';

import { ROUTES } from '../../pages/routes';
import { authMe, memberLogin } from '../../services/auth-service/auth.service';
import { showEmailVerificationInstruction } from '../../services/navigation-service/navigation.service';
import { saveToken } from '../../services/token-service/token.service';
import { registerUser, setUserLocalData } from '../../services/user-service/user.service';
import Button from '../Button/Button';
import LoaderComponent from '../LoaderCompoment/LoaderComponent';

const inputStyle = {
  border: '1px solid #d8d8d8',
  borderRadius: 8,
  color: '#000',
  fontSize: 14,
  height: 56,
  outline: 'none',
  padding: 15,
  textAlign: 'start',
  width: '100%'
};
const VerifyAccount = (props) => {
  const { closeModal, openModal, verifyModalRef, modalRef, registerData } = props;
  const [loginInvalidError, getLoginInvalidError] = useState('');
  const [termsChecked, getTermsChecked] = useState('');
  const [loading, isLoading] = useState(false);

  const getUserData = (accessToken) => {
    return authMe(accessToken).then(saveUserData);
  };
  const saveUserData = (response) => {
    setUserLocalData(response.data);
    closeModal(verifyModalRef);
    showEmailVerificationInstruction();
  };
  const loginFailed = () => {
    isLoading(false);
    getLoginInvalidError('Incorrect Login Details Please Try Again');
  };

  const loginSuccess = (response) => {
    if (response.status === 200) {
      console.log('LOGGED IN', response);
      saveToken(response.data.access_token, response.data.expires_in);
      getUserData(response.data.access_token);
    }
  };

  const termsCheckedTrue = (data) => {
    memberLogin(data).then(loginSuccess).catch(loginFailed);
  };
  const [form] = Form.useForm();

  const registerUserFailed = (error) => {
    isLoading(false);
  };

  const registerUserSucces = (res, prev) => {
    isLoading(false);
    localStorage.removeItem('clickId');
    const regData = {
      username: res.data.user.username,
      password: registerData.password
    };
    termsCheckedTrue(regData);
  };

  const onSubmit = () => {
    if (termsChecked) {
      isLoading(true);
      localStorage.setItem('current-email', registerData.email);
      console.log('ON SUBMIT !!!!!!');

      registerUser(registerData, registerData.gender, registerData.seeking, 1)
        .then((res) => registerUserSucces(res, registerData))
        .catch(registerUserFailed);
    }
  };
  const renderLoader = () => {
    return (
      <div className="Loading">
        <LoaderComponent />
      </div>
    );
  };

  const openTermsPage = () => {
    window.open(ROUTES.TERMS, '_blank');
  };
  const validateEmail = (_, value) => {
    const emailRegex = /^[a-zA-Z0-9._-]+(\+[a-zA-Z0-9._-]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (value && !emailRegex.test(value)) {
      return Promise.reject('Invalid email format');
    }
    if (!value) {
      return Promise.reject('Please enter email address');
    }
    return Promise.resolve();
  };

  const validateCheckbox = (_, value) => {
    if (!value) {
      getTermsChecked(false);
      return Promise.reject('Please check the checkbox');
    } else {
      getTermsChecked(true);
    }
    return Promise.resolve();
  };
  return (
    <div className="VerifyAccount">
      {loading && renderLoader()}
      <div className="Verify-Modal-Sample">
        <div className="Verify-Header-Container">
          <h2>Verify Your Details</h2>
          <p>Please confirm your details</p>
          <div style={{ color: '#9F0220A6' }}>{loginInvalidError}</div>
        </div>
        {registerData && (
          <Form
            className="VerifyForm"
            autoComplete={'off'}
            onFinish={(values) => {
              onSubmit(values);
            }}
            form={form}
            layout={'vertical'}
            wrapperCol={{ span: 24 }}
            initialValues={{ ...registerData, password: registerData.password }}
          >
            <Form.Item
              rules={[{ required: true }]}
              name={'username'}
              wrapperCol={{ sm: 24 }}
              style={{ width: '100%' }}
            >
              <Input
                style={inputStyle}
                type="text"
                name="username"
                placeholder="Create a Username"
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              name={'password'}
              wrapperCol={{ sm: 24 }}
              style={{ width: '100%' }}
            >
              <Input.Password
                style={inputStyle}
                type="password"
                name="password"
                placeholder="Create a Password"
                value={registerData.password}
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              rules={[{ validator: validateEmail }]}
              name={'email'}
              wrapperCol={{ sm: 24 }}
              style={{ width: '100%' }}
            >
              <Input style={inputStyle} name="email" placeholder="Email Address" disabled={true} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  validator: validateCheckbox,
                  message: 'Please accept Terms and Conditions to continue.',
                  validateTrigger: 'onSubmit'
                }
              ]}
              name={'terms'}
              wrapperCol={{ sm: 24 }}
              style={{ width: '100%' }}
              valuePropName="checked"
            >
              <Checkbox
                name="terms"
                style={{ color: '9F0220FF', fontWeight: 600, textDecoration: 'underline' }}
              >
                <div className="Checkbox-Container">
                  <div className="Checkbox-Holder">
                    <p>
                      I hereby confirm that I am 18 years or older and that I agree to the{' '}
                      <span onClick={openTermsPage}>Terms and Conditions</span>
                    </p>
                  </div>
                </div>
              </Checkbox>
            </Form.Item>
            <Form.Item wrapperCol={{ sm: 24 }} style={{ width: '100%' }}>
              <Button
                className="SubmitButton"
                htmlType="submit"
                text="I Accept"
                onClick={() => onSubmit()}
              />
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};
export default VerifyAccount;
