import { useEffect, useState } from 'react';

declare global {
  interface Window {
    fwSettings: { widget_id: number };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FreshworksWidget: any;
  }
}

export const useFreshdeskScript = (
  scriptUrl: string,
  windowId?: string
): [boolean, Error | null] => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const initializeScript = () => {
      window.fwSettings = {
        widget_id: 9000000934
      };

      if (typeof window.FreshworksWidget !== 'function') {
        window.FreshworksWidget = function () {
          // eslint-disable-next-line prefer-rest-params
          window.FreshworksWidget.q.push(arguments);
        };
        window.FreshworksWidget.q = [];
      }
    };

    initializeScript();

    const script = document.createElement('script');
    script.src = scriptUrl;
    script.async = true;

    const onLoad = () => {
      setLoaded(true);
    };

    const onError = (err: ErrorEvent) => {
      setError(new Error(`Failed to load script: ${scriptUrl}, ${err}`));
    };

    script.addEventListener('load', onLoad);
    script.addEventListener('error', onError);

    document.head.appendChild(script);

    return () => {
      script.removeEventListener('load', onLoad);
      script.removeEventListener('error', onError);
      document.head.removeChild(script);

      // Remove initialization script
      const initScript = document.getElementById('freshdesk-init-script');
      if (initScript && initScript.parentNode) {
        initScript.parentNode.removeChild(initScript);
      }

      if (windowId) {
        const elementsToRemove = document.querySelectorAll(windowId);
        elementsToRemove.forEach((element) => {
          element.remove();
        });
      }
      // Remove freshworks-container from body
      const freshworksContainer = document.querySelectorAll('#freshworks-container');
      if (freshworksContainer) {
        freshworksContainer.forEach((container) => {
          if (container.parentNode) {
            container.parentNode.removeChild(container);
          }
        });
      }
      // Remove freshworks-frame link from head
      const freshworksFrameLink = document.querySelectorAll('#freshworks-frame');
      if (freshworksFrameLink) {
        freshworksFrameLink.forEach((link) => {
          if (link.parentNode) {
            link.parentNode.removeChild(link);
          }
        });
      }
      // Remove all freshworks-frame iframes
      const freshworksFrames = document.querySelectorAll('iframe#freshworks-frame');
      freshworksFrames.forEach((frame) => {
        if (frame.parentNode) {
          frame.parentNode.removeChild(frame);
        }
      });
    };
  }, [scriptUrl, windowId]);

  return [loaded, error];
};
