import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';

import CALENDARIMAGE from '../../../assets/images/SignUp/calendar.png';
import ImageComponent from '../../ImageComponent/ImageComponent';

const DatePicker = ({ control, name, placeholder, errorMessage, placement, ...rest }) => {
  const years = range(1930, getYear(new Date()) + 1, 1);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  return (
    <div className="DatePickerField">
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <label htmlFor="datePicker">
            <ReactDatePicker
              renderCustomHeader={({ date, changeYear, changeMonth }) => (
                <div
                  className="titleInput"
                  style={{
                    margin: 10,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              placeholderText={placeholder}
              adjustDateOnChange
              autoComplete="off"
              dateFormat="YYY-MM-dd"
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              id="datePicker"
              popperPlacement={placement ? placement : ''}
              {...rest}
            />

            <div className="Error-Container">{errorMessage}</div>
            <ImageComponent url={CALENDARIMAGE} className="DatePickerIcon" />
          </label>
        )}
      />
    </div>
  );
};

export default DatePicker;
