import './Lander12.scss';

import { useEffect } from 'react';

import loaderImg from '../../../../../../assets/images/Lander10/loader.png';
import generateSteps from './data/steps';
import { FallbackProvider } from './FallbackProvider';

export default function Lander12(props) {
  const { nextStep, currentFormField, submitForm, handleNextStep, landerId } = props;
  const autogenerate = window.location.href.includes('/12-3/updated'); // If username and password should be generated automatically
  const steps = generateSteps(autogenerate);
  const isLastStep = currentFormField >= steps.length + 1;
  const landerVersionClassName = `LanderContainer12 version${landerId}`;
  const landerContentInnerClassName = `LanderContentInner12 version${landerId}`;
  const landerProgresClassName = `Percentage version${landerId}`;

  useEffect(() => {
    if (isLastStep) {
      submitForm();
    }
  }, [currentFormField]);

  const currentStep = steps[Math.min(currentFormField, steps.length) - 1];
  const { component: CurrentStep } = currentStep || {};

  return (
    <div className={landerVersionClassName}>
      <div className="BackgroundOverlay12"></div>
      {isLastStep && (
        <div className="LoadingOverlay">
          <img src={loaderImg} alt="Loader" />
        </div>
      )}

      <form
        className="LanderContent"
        onSubmit={(e) => {
          e.preventDefault();

          if (currentStep.field) {
            handleNextStep([currentStep.field]);
          }
        }}
      >
        <div className="ProgressBar">
          <div
            className={landerProgresClassName}
            style={{ width: `${currentStep?.percent || 0}%` }}
          >
            <span>{currentStep?.percent}%</span>
          </div>
        </div>
        <FallbackProvider>
          {CurrentStep && (
            <div className={landerContentInnerClassName}>
              <CurrentStep
                onNextStep={nextStep}
                autogenerate={autogenerate}
                handleNextStep={handleNextStep}
                {...props}
              />
            </div>
          )}
        </FallbackProvider>
      </form>
    </div>
  );
}
