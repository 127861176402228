import './MatchGameFilters.scss';

import { Form, Select, Slider } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../Button/Button';

const MatchGameFilters = ({ updateFilters }) => {
  const hairColors = useSelector((state) => state.staticData.hairColors);
  const bodyTypes = useSelector((state) => state.staticData.bodyTypes);
  const [minVal, setMinVal] = useState(18);
  const [maxVal, setMaxVal] = useState(100);
  const [form] = Form.useForm();
  return (
    <div className="MatchGame-Modal">
      <h4 className="MatchGame-Header">Match Game Settings</h4>
      <div className="Filters-Wrapper">
        <Form
          initialValues={{
            age_between: [minVal, maxVal],
            hair_color: 0,
            body_build: 0
          }}
          form={form}
          onFinish={(values) => {
            for (const key in values) {
              if (values[key] === 0) {
                delete values[key];
              }
            }
            updateFilters(values);
          }}
        >
          <div className="AgeRange">
            <div className="HeaderNumbers">
              <p className="AgeRangeSlider">Age Range</p>
              <div className="AgeRangeSliderNumbers">
                {minVal} - {maxVal}
              </div>
            </div>
            <Form.Item name={'age_between'}>
              <Slider
                range
                step={1}
                min={18}
                max={100}
                onChange={(value) => {
                  setMinVal(value[0]);
                  setMaxVal(value[1]);
                }}
              />
            </Form.Item>
          </div>
          <div className="HairColor">
            <label htmlFor="hair-color">Hair Color</label>
            <Form.Item name={'hair_color'}>
              <Select className="">
                <option value={0}>-- Select --</option>
                {hairColors.map((item) => {
                  return (
                    <option className="To-Uppercase" value={item.name} key={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className="BodyType">
            <label htmlFor="body-type">Body Type</label>
            <Form.Item name={'body_build'}>
              <Select className="">
                <option value={0}>-- Select --</option>
                {bodyTypes.map((item) => {
                  return (
                    <option value={item.name} key={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </Form>
      </div>
      <Button onClick={() => form.submit()} text="UPDATE" />
    </div>
  );
};

export default MatchGameFilters;
