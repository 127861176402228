import './DragAndDrop.scss';

import { Button as AntdButton, Modal } from 'antd';
import { useState } from 'react';

import delete_icon from '../../assets/images/MembersArea/MyProfile/delete_icon_profile.svg';
import { useRemoveProfilePhoto } from '../../V2/services/myProfile/useRemoveProfilePhoto';
import { useUpdateUserPhotos } from '../../V2/services/myProfile/useUpdateUserPhotos';
import Button from '../Button/Button';
import ImageNPreview from '../ImageNPreview/ImageNPreview';
import LoaderComponent from '../LoaderCompoment/LoaderComponent';
import SuccessMessage from '../SuccessMessage/SuccessMessage';

const DragAndDrop = (props) => {
  const {
    fileRef,
    userID,
    multiple,
    type,
    btnText,
    dndImage,
    successMessage,
    failureMessage,
    currentPhoto,
    currentPhotoFullSize
  } = props;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);
  const { mutate: saveUserPhoto, isLoading: loader } = useUpdateUserPhotos();
  const { mutate: remove, isLoading: removing } = useRemoveProfilePhoto();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const classes = ['drag-drop-zone'];
  if (props.className) {
    classes.push(props.className);
  }

  const textClasses = ['drag-drop-text'];
  if (props.textClasses) {
    textClasses.push(props.textClasses);
  }

  const btnclasses = ['button-wrapper'];
  if (props.btnclasses) {
    btnclasses.push(props.btnclasses);
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const fileArray = Array.from(e.dataTransfer.files);
    saveUserPhoto({
      userId: userID,
      file: fileArray[0],
      type: type
    });
  };

  const selectFile = (ref) => {
    ref.current.click();
  };

  const onBrowsedFileSelected = (e) => {
    const fileArray = Array.from(e.target.files);
    fileArray.length > 0 &&
      saveUserPhoto({
        userId: userID,
        file: fileArray[0],
        type: type
      });
  };

  const handleOk = () => {
    remove(undefined, { onSuccess: () => setIsModalVisible(false) });
  };

  return (
    <div
      className={classes.join(' ')}
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
    >
      <SuccessMessage
        className="success-message"
        message={successMessage}
        showMessage={showSuccessMessage}
        setShowMessage={setShowSuccessMessage}
      />
      <SuccessMessage
        className="failure-message"
        message={failureMessage}
        showMessage={showFailureMessage}
        setShowMessage={setShowFailureMessage}
      />
      <div className="ProfileImageWrapper">
        <div className="ImageContainer">
          {currentPhoto ? (
            <>
              <ImageNPreview name={currentPhoto} nameFullSize={currentPhotoFullSize} />
              <div className="DeleteIconWrapperProfie" onClick={() => setIsModalVisible(true)}>
                <img alt="delete icon" src={delete_icon} className="DeleteIconProfile" />
              </div>
            </>
          ) : loader ? (
            <LoaderComponent />
          ) : (
            <>
              <img alt="drag and drop icon" src={dndImage} />
              <p className={textClasses.join(' ')}>{}</p>
            </>
          )}
          <div onClick={selectFile.bind(null, fileRef)} className="DefaultButton">
            <Button text={btnText} className={btnclasses.join(' ')} />
            <input
              type="file"
              ref={fileRef}
              name="messagePhotos"
              accept=".png,.jpg,.jpeg"
              hidden
              multiple={multiple}
              onChange={onBrowsedFileSelected}
            />
          </div>
        </div>
        <Modal
          className="DeleteModal"
          title="Delete photo"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <AntdButton key="back" onClick={() => setIsModalVisible(false)}>
              No
            </AntdButton>,
            <AntdButton
              loading={removing}
              key="submit"
              type="primary"
              onClick={() => {
                handleOk();
              }}
            >
              Yes
            </AntdButton>
          ]}
        >
          <p className="DeletePhotoText">Are you sure you want to delete this photo?</p>
        </Modal>
      </div>
    </div>
  );
};
export default DragAndDrop;
