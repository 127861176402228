import './GalleryTab.scss';

import { useRecoilState } from 'recoil';

import { userData as userRecoil } from '../../../../recoil/atoms/userDataAtom';
import { TipInfoComponent } from '../../../components/tipInfoComponent/TipInfoComponent';
//@ts-ignore
import GalleryUpload from './galleryUpload/GalleryUpload';
//@ts-ignore
import { ImgCroper } from './imgCroper/ImgCroper';

interface GalleryType {
  isMobile: boolean;
  renderTipMessage: any;
}

export const GalleryTab = ({ isMobile, renderTipMessage }: GalleryType) => {
  const [user] = useRecoilState(userRecoil);
  const userId = user?.data?.profile.id;

  return (
    <div className="gallery-tab">
      <div className="form-container">
        <div className="profile-photo-container">
          <div className="gallery-photo-title">Add Profile Photo</div>
          <ImgCroper />
        </div>
        <div className="gallery-photo-container">
          <div className="gallery-photo-title">Add Gallery Photos</div>
          <GalleryUpload userId={userId} />
        </div>
      </div>
      <div className="tip-container">
        {!isMobile ? <TipInfoComponent message={renderTipMessage()} /> : ''}
      </div>
    </div>
  );
};
